export var VcListUtils = (function () {
    var CONTENTTYPES=["json","csv","xlsx"];

    var CSVINCLUDES=[
      {
        id:"days",
        label:"Days"
      },
      {
        id:"hours",
        label:"Hours"
      },
      {
        id:"current",
        label:"Current"
      },
      {
        id:"alerts",
        label:"Alerts"
      },
      {
        id:"events",
        label:"Events"
      },
    ]
    var CSVINCLUDESIDS=CSVINCLUDES.map(function(d) {
      return d.id;
    })

    return {
        CONTENTTYPES:CONTENTTYPES,
        CSVINCLUDES:CSVINCLUDES,

        CSVINCLUDESIDS:CSVINCLUDESIDS,
/*
        isValidCsvInclude:function(list) {
            return csvInclude(list, true)!==-1;
        },*/
        csvInclude:function(list) {
            var me=this;
            list=me.valuesInList(list);
            if (!list || !list.length) return false;
            var csvInclude=me.CSVINCLUDESIDS.reduce(function(a,d) {
              return (!a && list.indexOf(d)!=-1)?d:a;
            },null);
            return csvInclude;
              /*
            var index= me.CSVINCLUDESIDS.indexOf(list[0]);
            if (returnIndex) {
                return index;
            } 
            if (index===-1) return null;
            return me.CSVINCLUDESIDS[index];*/
        },
        processValidTableIncludeList:function(list, selectedValue) {
          let me=this;

          if (!selectedValue || me.CSVINCLUDESIDS.indexOf(selectedValue)===-1) {
            console.log("Invalid selected table include value: "+selectedValue)
            return list;
          }

          list=me.valuesInList(list);
          list=list.filter(d=> {
            if (me.CSVINCLUDESIDS.indexOf(d)!==-1 && d!==selectedValue) return false;
            return true;
          })

          if (list.indexOf(selectedValue)===-1) list.push(selectedValue)

          return list && me.setValuesInList(list);
        },
        valuesInList:function(list) {
            if (!list) return [];
            return list.split(",");
        },
        setValuesInList:function(list) {
          if (!list) return "";
          return list.join(",");
        },
        hasValueInList:function(list,value) {
            if (!list) return false;
            var vals=list.split(",");
            return vals.indexOf(value)!=-1;
        },
        hasAnyValueInList:function(list,values) {
          if (!list) return false;
          var vals=list.split(",");
          return values.reduce(function(a,d) {
            return a || vals.indexOf(d)!=-1
          },false);
      },
        setValueInList:function(list,value, include, clearall) {
    
            if (clearall) {
                return value;
            }
    
            var vals=(list && list.split(",")) || [];
            var index=vals.indexOf(value);
            if (include && index===-1) {
                vals.push(value);
            } else if (!include && index!==-1) {
                vals.splice(index,1);
            } 
            return vals.join(",");
        },
        filterArrayById:function(requiredValues, source) {
            return source.filter(function (d) {
              return requiredValues.indexOf(d.id)!==-1;
            })
        },
    }
}());