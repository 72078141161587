<template>
 
   <div class="py-1"  @click="toggleExpand" >
    <div v-if="isExpanded" class="mx-3 py-2 px-2 bg-light rounded-3">
      <div class="fs-lg d-flex flex-row">
        <div class="icon me-2"><div class="" :class="day.icon"></div></div>
        <span>{{formatDate(day)}}</span>
      </div>

      <template v-for="(period,periodIndex) in dailyPeriods">
      <div class="row  pb-1 mb-1 fs-xs border-bottom border-dark-subtle"  @click.stop="showPeriodHours[periodIndex]=!showPeriodHours[periodIndex]">
          
        <div class="col-3 d-flex flex-column justify-content-start">
          {{period.name}}
        </div>

        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Hi</div>
          <div class="" v-html="formattedValue(period,'tempmax')"></div>
          <div v-if="period.feelslikemax!==day.tempmax" class="fs-xxs">Feels:<span v-html="formattedValue(period,'feelslikemax')" /></div>
        </div>

        <div class="col-3 col-md-1  text-center">
          <div class="fs-xxs opacity-75">Lo</div>
          <div class="" v-html="formattedValue(period,'tempmin')"></div>
          <div v-if="period.feelslikemin!==period.tempmin" class="fs-xxs">Feels:<span v-html="formattedValue(period,'feelslikemin')" /></div>
        </div>
        
        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Precip</div>
          <div class="">{{formattedValue(period,'precipprob')}}</div>
          <div v-if="period.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(period,'precip')}}</div>
          <div v-if="period.precipremote" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(period,'precipremote', false, 'precip')}}</div>
          <div v-if="period.snow" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(period,'snow')}}</div>
        </div>
      </div>

      <div v-if="showPeriodHours[periodIndex]" v-for="(hour,i) in hourRange(day.hours,period.startHour, period.endHour )"  @click.stop="showPeriodMinutes[periodIndex]=!showPeriodMinutes[periodIndex]" class="row  pb-1 mb-1 fs-xs border-bottom border-dark-subtle">
        
        <div class="col-1 d-flex flex-column align-items-center justify-content-start">
          <div class="icon me-2"><div class="" :class="hour.icon"></div></div>
          <div class="fs-xxs">{{formatTime(hour)}}</div>
        </div>

        <div class="col-11 row">
          <div class="col-4 col-md-2 text-center">
            <div class="" v-html="formattedValue(hour,'temp')"></div>
            <div v-if="hour.feelslike!==hour.temp" class="fs-xxs">Feels:<span v-html="formattedValue(hour,'feelslike')" /></div>
          </div>
        
          
          <div class="col-3 col-md-2 text-center">
            <div class="fs-xxs opacity-75">Precip</div>
            <div class="">{{formattedValue(hour,'precipprob')}}</div>
            <div v-if="hour.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(hour,'precip')}}</div>
            <div v-if="hour.precipremote" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(hour,'precipremote', false, 'precip')}}</div>
            <div v-if="hour.snow" class="fs-xxs  d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(hour,'snow')}}</div>
          </div>

          <div class="col-3 col-md-2 text-center">
            <div class="fs-xxs opacity-75">Wind</div>
            <div class="d-flex justify-content-center" :title="formattedValue(hour,'wind')">
              <div class="position-relative"  style="width:15px" >
                <div class="position-absolute"  :style="'transform:rotate('+Math.round(hour.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
              </div>
              <span class="" v-html="formattedValue(hour,'windspeed', true)" />
            </div>
            <div class="fs-xxs" v-html="formattedValue(hour,'pressure')"></div>
          </div>

          <div class="col-2 col-md-1 fs-xs text-center">
            <div class="fs-xxs opacity-75">UVI</div>
            <div class="" v-html="formattedValue(hour,'uvindex2')"></div>
          </div>

        </div>

        <div v-if="showPeriodMinutes[periodIndex]" v-for="(minute,i) in hour.minutes"  class="row">
          
          <div class="col-2 d-flex flex-column align-items-center justify-content-start">
            <div class="fs-xxs">{{formatTime(minute)}}</div>
          </div>
          <div class="col-10 row">
            <div class="col-4 col-md-2 text-center">
              <div class="" v-html="formattedValue(minute,'temp')"></div>
              <div v-if="minute.feelslike!==minute.temp" class="fs-xxs">Feels:<span v-html="formattedValue(minute,'feelslike')" /></div>
            </div>
          
            
            <div class="col-4 col-md-2 text-center">
              <div class="fs-xxs opacity-75">Precip</div>
              <div class="">{{formattedValue(minute,'precip')}}</div>
              <div v-if="minute.reflectivity || minute.reflectivity2" class="">{{formattedValue(minute, "reflectivity")}} ({{formattedValue(minute, "reflectivity2")}}) dBZ </div>
              <!--<div v-if="minute.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(minute,'precip')}}</div>
              <div v-if="minute.precipremote" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(minute,'precipremote', false, 'precip')}}</div>
              <div v-if="minute.snow" class="fs-xxs  d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(minute,'snow')}}</div>-->
            </div>
          <div class="col-3 col-md-2 text-center">
          <div class="fs-xxs opacity-75">Wind</div>
            <div class="d-flex justify-content-center" :title="formattedValue(minute,'wind')">
              <div class="position-relative"  style="width:15px" >
                <div class="position-absolute"  :style="'transform:rotate('+Math.round(minute.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
              </div>
              <span class="" v-html="formattedValue(minute,'windspeed', true)" />
            </div>
            <div class="fs-xxs" v-html="formattedValue(minute,'pressure')"></div>
          </div>
        </div>


      </div>
      
          
    </div>
    </template>
    <div class="fw-bold mb-2">Daily Summary</div>
     <div class="d-flex align-items-center justify-content-center my-2" v-html="day.description" />
    <div class="row fs-xs fs-md-sm">
        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Hi</div>
          <div class="" v-html="formattedValue(day,'tempmax')"></div>
          <div v-if="day.feelslikemax!==day.tempmax" class="fs-xxs">Feels:<span v-html="formattedValue(day,'feelslikemax')" /></div>
        </div>
        <div class="col-3 col-md-1  text-center">
          <div class="fs-xxs opacity-75">Lo</div>
          <div class="" v-html="formattedValue(day,'tempmin')"></div>
          <div v-if="day.feelslikemin!==day.tempmin" class="fs-xxs">Feels:<span v-html="formattedValue(day,'feelslikemin')" /></div>
        </div>
      
        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Precip</div>
          <div class="">{{formattedValue(day,'precipprob')}}</div>
          <div v-if="day.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(day,'precip')}}</div>
          <div v-if="day.precipremote" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(day,'precipremote', false, 'precip')}}</div>
          <div v-if="day.snow" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(day,'snow')}}</div>
        </div>
    
        <div class="col-3 col-md-1 fs-xs text-center">
          <div class="fs-xxs opacity-75">UVI</div>
          <div class="" v-html="formattedValue(day,'uvindex2')"></div>
        </div>


        <div class="col-3 col-md-2 text-center" >
          <div class="fs-xxs opacity-75">AQI</div>
          <div class="d-none d-md-block">{{formattedValue(day,'aqi')}}</div>
          <div class="d-block d-md-none">{{formattedValue(day,'aqi',true)}}</div>
          <div v-if="day.aqius>50" class="d-none d-md-block fs-xxs">{{formattedValue(day,'aqielement')}}</div>
        </div>
      
        <div class="col-3 col-md-2 text-center">
          <div class="fs-xxs opacity-75">Wind</div>
          <div class="d-flex justify-content-center" :title="formattedValue(day,'wind')">
            <div class="position-relative"  style="width:15px" >
              <div class="position-absolute"  :style="'transform:rotate('+Math.round(day.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
            </div>
            <span class="" v-html="formattedValue(day,'windspeed', true)" />
          </div>
          <div class="fs-xxs" v-html="formattedValue(day,'pressure')"></div>
        </div>
        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Humidity</div>
          <div class="" v-html="formattedValue(day,'humidity')"></div>
          <div class="fs-xxs" v-html="formattedValue(day,'dew')"></div>
        </div>
      
        <div class="col-3 col-md-2 text-center">
          <div class="fs-xxs opacity-75">Sunrise/set</div>
          <div class="d-flex justify-content-center"><i class="bi-sun me-1" ></i>{{formatTime(day,'sunriseEpoch')}}</div>
          <div class="d-flex justify-content-center"><i class="bi-moon me-1" ></i>{{formatTime(day,'sunsetEpoch')}}</div>
        </div>
        <div class="d-none d-md-block col-md-3">&nbsp;</div>
        <div class="col-6 col-md-3">
            <div class="row fs-xxs justify-content-center">
              <div class="col-12 fs-xxs opacity-75 d-flex justify-content-center">Normal Hi</div>
              <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmax',0)"></div>
              <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmax',1)"></div>
              <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmax',2)"></div>
              <div class="col-4 d-flex justify-content-center">Min</div>
              <div class="col-4 d-flex justify-content-center">Mean</div>
              <div class="col-4 d-flex justify-content-center">Max</div>
            </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="row fs-xxs">
            <div class="col-12 fs-xxs opacity-75 d-flex justify-content-center">Normal Lo</div>
            <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmin',0)"></div>
            <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmin',1)"></div>
            <div class="col-4 d-flex justify-content-center" v-html="formatNormalValue(day,'tempmin',2)"></div>
            <div class="col-4 d-flex justify-content-center">Min</div>
            <div class="col-4 d-flex justify-content-center">Mean</div>
            <div class="col-4 d-flex justify-content-center">Max</div>
          </div>
        </div>
         <div class="d-none d-md-block col-md-3">&nbsp;</div>

      
     
      <!--<button class="col-1 btn btn-sm text-primary px-1 py-0" type="button"  @click="toggleHourly()"><i class="bi bi-clock" ></i> </button>-->

    </div>


    </div>
    <template v-else>
      <div class="flex-fill row fs-xs fs-md-sm">
      
      <div class="col-3 col-md-2 col-lg-2 d-flex flex-row align-items-center justify-content-start ps-md-5">
        <div class="icon me-2"><div class="" :class="day.icon"></div></div>
        <div class="d-none d-md-block">{{formatDate(day)}}</div>
        <div class="d-block d-md-none">{{formatDate(day, true)}}</div>
      </div>
      <div class="col-9 col-md-10 col-lg-10 row">

        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Hi</div>
          <div class="" v-html="formattedValue(day,'tempmax')"></div>
          <div v-if="day.feelslikemax!==day.tempmax" class="fs-xxs">Feels:<span v-html="formattedValue(day,'feelslikemax')" /></div>
        </div>
        <div class="col-3 col-md-1  text-center">
          <div class="fs-xxs opacity-75">Lo</div>
          <div class="" v-html="formattedValue(day,'tempmin')"></div>
          <div v-if="day.feelslikemin!==day.tempmin" class="fs-xxs">Feels:<span v-html="formattedValue(day,'feelslikemin')" /></div>
        </div>
        
        <div class="col-3 col-md-1 text-center">
          <div class="fs-xxs opacity-75">Precip</div>
          <div class="">{{formattedValue(day,'precipprob')}}</div>
          <div v-if="day.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(day,'precip')}}</div>
           <div v-if="day.precipremote" class="fs-xxs d-flex  justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(day,'precipremote', false, 'precip')}}</div>
          <div v-if="day.snow" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(day,'snow')}}</div>
        </div>
      
        <div class="col-3 col-md-1 fs-xs text-center">
          <div class="fs-xxs opacity-75">UVI</div>
          <div class="" v-html="formattedValue(day,'uvindex2')"></div>
        </div>

        <div class="d-none d-md-flex col-md-8 align-items-center" v-html="day.description" />
      
      </div>
     
    </div>
    </template>
    
    


    


    

  </div>
</template>

<script>
import {VcWidgetHelper} from './common_widget.js'
export default {
  name: 'daywidget',
  props: {
    days:Object,
    day: Object,
    unitGroup: String,
    timezone:String,
    isSelected:Boolean
  },
  data() {
    return {
      showPeriodHours:[false,false,false,false],
      showPeriodMinutes:[false,false,false,false],
    }
  },
  methods: {
    hourRange(hours, startHour, endHour) {
      return hours.filter((d,i)=> i>=startHour && i<endHour);

    },
   
    hourSummary(periodName,hours, startHour, endHour) {
       let me=this;
      let filteredHours=me.hourRange(hours,startHour,endHour);
      return filteredHours.reduce((a,d)=>{
        a.tempmax=!a.tempmax || d.temp>a.tempmax?d.temp:a.tempmax;
        a.tempmin=!a.tempmin || d.temp<a.tempmin?d.temp:a.tempmin;
        a.feelslikemax=!a.feelslikemax || d.feelslike>a.feelslikemax?d.feelslike:a.feelslikemax;
        a.feelslikemin=!a.feelslikemin || d.feelslike<a.feelslikemin?d.feelslike:a.feelslikemin;
        a.precipprob=!a.precipprob || d.precipprob>a.precipprob?d.precipprob:a.precipprob;
        a.precip+=d.precip>0?d.precip:0;
        a.precipremote+=d.precipremote>0?d.precipremote:0;
        a.snow+=d.snow>0?d.snow:0;
        return a;
      }, {
        name:periodName,
        startHour:startHour,
        endHour:endHour,
        tempmax:null,
        tempmin:null,
        feelslikemax:null,
        feelslikemin:null,
        precipprob:null,
        precip:0,
        precipremote:0,
        snow:0,

      })
    
    },
    validHours(hours) {
      if (!hours) return [];
      return hours.filter(d=>d.temp>0);
    },
    toggleExpand(e){
      let me=this;

      me.showPeriodHours.map(d=>false);
      me.showPeriodMinutes.map(d=>false);

      me.days.forEach(d=> {


        if (d!==me.day) {
          d.isExpanded=false;
         // d.showPeriods=false;
          //d.showHourly=false;
          return;
        }

        if (!d.isExpanded) {
          d.isExpanded=true;
        //  d.showPeriods=false;
          //d.showHourly=false;

        } /*else if (d.isExpanded && !d.showPeriods) {
          d.isExpanded=true;
          d.showPeriods=true;
          //d.showHourly=false;
        }         else if (d.isExpanded && d.showPeriods && !d.showHourly) {
          d.isExpanded=true;
          d.showPeriods=true;
          d.showHourly=true;
        } */else {
          d.isExpanded=false;
          //d.showPeriods=false;
         // d.showHourly=false;
        }
       
        
      });

      if (me.day.isExpanded) {
        let element = e.currentTarget;

        //this.$nextTick(() => {
           //element.scrollIntoView(true)
           element.scrollIntoView({ behavior: 'smooth' });
        //})
       
        //element.scrollTo(0, 0);
        //let top = element.offsetTop;
        //window.scrollTo(0, top);
      }
    },
    toggleHourly(period){
      period.showHourly=!period.showHourly;
      /*let me=this;

      me.days.forEach(d=> {
        d.showHourly= d===me.day && !me.day.showHourly;
      });
      */
     // me.isExpanded=!me.isExpanded;
    },
    /* COPIED FROM SUMMARY WIDGET :( **/
    formatNormalValue(period, element, index) {
        let me=this;
        if (!period || !period.normal) return "?";
        let normals;
        if (element==="rainsnow") {
          if (period.snow>0) {
            normals= period.normal["snow"];
          } else {
            normals= period.normal["precip"];
          }
        } else {
          normals=period.normal[element];
        }

        return VcWidgetHelper.formatElementValue(normals[index], element,me.unitGroup,null,"",false,true );
    },
    formattedValue(period, element, shortForm, formatElement) {
      formatElement=formatElement || element;
      var me=this;
      if (element==="rainsnow") {
        if (period.snow>0) return me.formatValue(period.snow, "snow");
        return me.formatValue(period.precip, "precip");
      }
      //if (element==="relectivity" || element==="relectivity2") return me.formatteddBZ(period[element],shortForm);

      if (element==="aqi") return me.formattedAqi(period,shortForm);
      if (element==="aqielement") return me.formattedAqiElement(period);
      if (element==="wind") return me.formattedWind(period);
      if (element==="moonphase") return me.formattedMoonPhase(period);
      if (element==="sunriseset") return me.formattedSunriseset(period);
      return me.formatValue(period[element], formatElement, shortForm);
    },
    /*
    formatteddBZ(value,shortForm) {
      if (!value && value!==0) return "-";
      return `${value} dBZ`
    },
    */
    formattedAqi(period,shortForm) {
      let aqi=period.aqius;
      if (!aqi && aqi!==0) return "-";
      return `${VcWidgetHelper.getAqiText(aqi,shortForm)} (${this.formatValue(aqi, "aqius")})`
    },
    formattedAqiElement(period) {
      let aqielement=period.aqielement;
      if (!aqielement) return "-";
      return `${VcWidgetHelper.getAqiElementText(aqielement)}`; // (${this.formatValue(aqi, "aqius")})
    },
    formattedWind(period) {
      return VcWidgetHelper.getCompassDirection(period.winddir)+" "+
      this.formatValue(period.windspeed, "wind");
    },
    formattedMoonPhase(period) {
      return VcWidgetHelper.getMoonPhaseDesc(period.moonphase);
    },
    formattedSunriseset(period) {
      return  this.formatValue(period.sunrise)+"-"+this.formatValue(period.sunset);
    },
    formatValue(value, element, shortForm) {
      var me=this;
      const fixed=shortForm?0:null;
      const emptyvalue=null, unicode=null;
      const noSymbol=shortForm;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup,   fixed, emptyvalue,  unicode, noSymbol );
    },
    formatTime(period, property) {
      if (!period) return "-";
      const value=period && property && period[property]? period[property]:period.datetimeEpoch;
      return VcWidgetHelper.formatTime(value, this.timezone);
    },
    formatDate(period,shortForm) {
      if (!period) return "-";
      if (VcWidgetHelper.isYesterday(period.datetimeEpoch, this.timezone)) return "Yesterday";
      if (VcWidgetHelper.isToday(period.datetimeEpoch, this.timezone)) return "Today";


      if (shortForm)  return VcWidgetHelper.formatDateVeryShort(period.datetimeEpoch, this.timezone);
      return VcWidgetHelper.formatDateShort(period.datetimeEpoch, this.timezone);
    },
  },
  computed: {
     dailyPeriods() {
      let me=this;
      let periods=[];
      periods.push(me.hourSummary("Overnight", me.day.hours, 0, 7));
      periods.push(me.hourSummary("Morning", me.day.hours, 7, 12));
      periods.push(me.hourSummary("Afternoon", me.day.hours, 12, 18));
      periods.push(me.hourSummary("Evening", me.day.hours, 18, 25));
      return periods;
    },
    isExpanded() {
      return this.day && this.day.isExpanded;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  

</style>
