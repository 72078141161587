
import './weather-forecast2.scss'


import "/src/main.js"
import "/src/vccommunicatorloader.js"

import { createApp } from 'vue'
import WeatherSummaryComponent from '/src/components/WeatherSummary2'


(function(w,d) {

  window.addEventListener("load",function(event) {
    createApp(WeatherSummaryComponent).mount("#weatherSummary2")
  },false);

})(window, document);