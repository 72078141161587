import "./vccommunicatorloader.js";
/*import {VcCommunicator} from "../../visualcrossing-core-js/src/vccommunicator";
import {VcGlobal} from "../../visualcrossing-core-js/src/vcglobal_conn";




window.vcCommunicator=new VcCommunicator({
    "serverroot":VcGlobal.SERVER_ROOT+"/VisualCrossingWebServices/rest/services/",
    "createsessionpkg":{
      requiredStripeProductId: "prod_ElEHd1q0NaQXG8",
      installId: "VCWebsite"
    }
});
*/
import { createApp } from 'vue'
import PageToolbarComponent from '/src/components/PageToolbar'
import SignInSignOutComponent from '/src/components/SignInSignOut'

(function(w,d) {


  window.addEventListener("load",function(event) {
    //console.log("registering account components...")
    createApp(PageToolbarComponent).mount("#accountToolbar");
    createApp(SignInSignOutComponent).mount("#signinsignout");
  },false);
})(window, document);


