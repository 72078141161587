
window.VcUtils=(function() {
    console.log("Starting vcutils...");
    //utility methods
    var daysBetween = function( d1, d2 ) {   
        var one_day=1000*60*60*24;    i
        d1 = d1.getTime();   
        d2 = d2.getTime(); 
        var difference_ms = d2 - d1;    
        return difference_ms/one_day; 
    } 
    var setCookie=function(cname, cvalue, days, minutes) {
      if (minutes) {
        days=1;
      } else if (!days && !minutes) {
        days=30;
        minutes=1;
      } else if (!minutes) {
        minutes=1;
      }
     
      var d = new Date();
      d.setTime(d.getTime() + (days * 24 * minutes * 60 * 1000));
      var expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    var getCookie=function(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    var uuid=function(length) {
        var seed;
        if (length) {
            seed='x'.repeat(length);
        } else {
            seed='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        }
        return seed.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });  
    }
    var debounce=function(func, wait, immediate) {
        var timeout;
        if (!wait) wait=250;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    
    var availableYears=function(minYear, maxYear) {
        if (!minYear) minYear=1970;
        if (!maxYear) maxYear=new Date().getFullYear();
        var years=[];
        for (var year=maxYear;year>=minYear;year--) years.push(year);
        return years;
    }
    var formatDateTime=function(d, dateonly) {
        if (!d) return null;
        var z = function(n) {return (n<10? '0':'')+n; }
        if (dateonly) {
            return d.getFullYear() + '-' + z(d.getMonth()+1) + '-' + z(d.getDate());
        }

        
          
        return d.getFullYear() + '-' + z(d.getMonth()+1) + '-' +
                z(d.getDate()) + 'T' + z(d.getHours()) + ':'  + z(d.getMinutes()) + 
                 ':' + z(d.getSeconds()); //+ sign + z(off/60|0) + z(off%60); 
    }
    var qs = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'));
    
    var pageName=function() {
        var path = window.location.pathname; 
        return path.split("/").pop(); 
    }


    var reloadpage=function(params) {
        var url = window.location.pathname+"?"; 
        var paramnames=Object.keys(params);
        
        paramnames.forEach(function (paramname) {
             url += "&"+paramname+"="+encodeURIComponent(params[paramname]);
        });
        
        window.location.href = url;
    }
    
    var isValidDate=function (d) {
      return d instanceof Date && !isNaN(d);
    }
    var parseDate =function(value) {
        if (!value) return null;
        var d= new Date(value);
        if (!isValidDate(d)) return null;
        return d;
    }
    var getDate=function(dayoffset) {
        var v1= new Date();
        if (dayoffset) v1.setDate(v1.getDate()+dayoffset);
        v1.setHours(0,0,0,0);
        return v1;
    }
    /*
    var formatDateTime2=function(epochSecs,tz, options) {
        var date=new Date(epochSecs*1000);
        var options = options || {
           month: 'short', 
           day:'numeric',
           hour: 'numeric', 
           minute: 'numeric',  
           hour12: false
           
         };
         if (tz) options.timeZone=tz;
         try {
            return (new Intl.DateTimeFormat('en-US', options).format(date));
         } catch(e) {
            console.error("Error formatting date with epoch sec value:"+epochSecs);
            return "?";
         }
     }
     var formatTime=function(epochSecs,tz) {
        return formatDateTime2(epochSecs,tz, {
            hour: 'numeric', 
            minute: 'numeric',  
            hour12: false
          });
     }
     var formatDate=function(epochSecs,tz) {
        return formatDateTime2(epochSecs,tz,{
            month: 'short', 
            day:'numeric'
          });
     }
    
     var formatDateIso=function(epochSecs,tz) {
        var date;
        if (epochSecs instanceof Date) {
            date=epochSecs;
        } else {
            date=new Date(epochSecs*1000);
        }

        
        var options = {
            timeZone: tz
          };
         
          try {
             return (new Intl.DateTimeFormat('sv', options).format(date));
          } catch(e) {
             console.error("Error formatting date with epoch sec value:"+epochSecs);
             return "?";
          }
    }
    */
    var getUniqueId=function(item) {
        return item.id || item.address || item.name ;
    }
/*
    var formatUnitValue=function(value, unit, fixed, emptyvalue) {
        if (!fixed && fixed!==0) fixed=1;
        
        if (!value && value!==0) {
            
            return emptyvalue || "-";
        }
        
        if (!unit) {
            return value;
        }
        
        if (unit==="degF") {
            return value.toFixed(fixed)+"&#8457;";
        }
        if (unit==="degF_U") {
            return value.toFixed(fixed)+"\u2109";
        }
        if (unit==="in") {
            return value.toFixed(fixed)+"\"";
        }
        if (unit) {
            return value.toFixed(fixed)+unit;
        }
        
        return value.toFixed(fixed);
        
        
    }
    */
    var measureElement=function(selection, measurements) {

        measurements.width = selection.node().getBoundingClientRect().width;
        measurements.height = selection.node().getBoundingClientRect().height;
        
    
        measurements.width = measurements.width - measurements.margin.left - measurements.margin.right,
        measurements.height = measurements.height - measurements.margin.top - measurements.margin.bottom;

        measurements.totalWidth = measurements.width + measurements.margin.left + measurements.margin.right;
        measurements.totalHeight = measurements.height + measurements.margin.top + measurements.margin.bottom;
    
        
    }
    /*
    var downloadData=function(value,filename, type) {
    
        if (!filename) filename="data.csv";
        if (!type) type="text/plain";		
        
        var file = new Blob([value], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
        }
    }
    var escapeXlsx=function(v) {

        if (!v && v!==0) return "";
        return  v;
    }
    var escapeCsv=function(v) {
        if (!v && v!==0) return "";
        v=String(v)
        if (v.indexOf(",")===-1) return v;
        v=v.replace(/\"/gi, "\"\"");
        return "\""+v+"\"";
    }
    */
    var subclassOf=function(base) {
        _subclassOf.prototype= base.prototype;
        return new _subclassOf();
    }
    function _subclassOf() {};
    /*
    var parseDatasource=function(rawData, format, callback) {
        //TODO - replace following dependency
        var lines;
        lines=$.csv.toArrays(rawData);
        
        if (!lines || lines.length===0) throw "No lines found in input data";

        var datasource= {
            columnIndexLookup:[],
			columns:{},
			rows:[]
		}
        lines[0].forEach(function(d,i) {
            if (d===null || d==="") {
                //throw "Column names cannot be empty. Column "+(i+1)+" is empty";
                d="Column "+(i+1);
            }
            var id=d.toLowerCase();
            datasource.columns[id]={
                id:id,
                name:d,
                index:i
            }
            datasource.columnIndexLookup[i]=id;
        });
        datasource.rows=lines.slice(1);
        callback(datasource);
    }
    */
    
    var transformDatasource=function(data) {

        if (!data) return null;
        if (typeof data==="string") {
          data=JSON.parse(data);
        }
        addColumnIndexLookup(data);
        /*
		var datasource= {
            columnIndexLookup:[],
			columns:{},
			rows:data.rows
		}
		data.columns.forEach(function(d, i) {
            
            d.index=i;
            datasource.columnIndexLookup[i]=d.id;
			datasource.columns[d.id]=d;
		});
        */
		return data;

    }

    var dateDiff=function(d1,d2) {
        if (!d1 || !d2) throw "Invalid date";
        d1.setHours(0,0,0,0);
        d2.setHours(0,0,0,0);
        return (d1.getTime()-d2.getTime())/86400000;
    }
    var isDateEqual=function(d1,d2) {
        if (!d1 && !d2) return true;
        if (!d1 || !d2) return false;
        d1.setHours(0,0,0,0);
        d2.setHours(0,0,0,0);
        return d1.getTime()===d2.getTime();
    }
    var isDateLess=function(d1,d2) {
        if (!d1 || !d2) throw "Invalid date";
        d1.setHours(0,0,0,0);
        d2.setHours(0,0,0,0);
        return d1.getTime()<d2.getTime();
    }
    var isDateGreater=function(d1,d2) {
        if (!d1 || !d2) throw "Invalid date";
        d1.setHours(0,0,0,0);
        d2.setHours(0,0,0,0);
        return d1.getTime()>d2.getTime();
    }
    var addColumnIndexLookup=function(datasource) {
        if (!datasource || datasource.columnMap) return;
        datasource.columnMap={}
        datasource.columns.forEach(function(d,i ) {
            datasource.columnMap[d.id]=i;
        });
    }
    var transformDatasourceRowToObject=function(datasource, rowIndex, parseDate, adjustToNextYear) {
        var output={};
        for (var columnIndex=0;columnIndex<datasource.columns.length;columnIndex++) {
            output[datasource.columns[columnIndex].id]=datasource.rows[rowIndex][columnIndex];
        }
        //eww!
        if (output.period && !output.datetime) {
            output.datetime=output.period;
            delete output.period;
        }

        if (parseDate && output.datetime) {
            output.date=parseDate(output.datetime);
            if (adjustToNextYear) {
                var currentDate=new Date();
                output.date.setYear(currentDate.getFullYear())
                if (output.date.getTime()<currentDate.getTime()) {
                    output.date.setYear(currentDate.getFullYear()+1)
                }
               
            }
        }

        return output;
    }
    var padNumber=function(v,places ) {
        v=v+"";
        while (v.length<places) v="0"+v;
        return v;
    }

    var isMobile=function() {
        return (/Mobi|Android/i.test(navigator.userAgent));
    }

    var formatQueryData=function(queryData,dataFormat) {
        var me=this;
        if (!queryData) return '';
        if (dataFormat==="parsed") {
          return JSON.stringify(queryData,null, 2);
        } else if (dataFormat==="rawunencoded"){
            return Object.keys(queryData).reduce(function(a,d) {
                return (a?a+"&":"")+d+"="+queryData[d];
                },null);
        } else {
          return Object.keys(queryData).reduce(function(a,d) {
            return (a?a+"&":"")+d+"="+encodeURIComponent(queryData[d]);
          },null);
        }
    }
    /*
    var copyToClipboard=function(text) {
        // Create new element
      var el = document.createElement('textarea');
      // Set value (string to be copied)
      el.value = text;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute('readonly', '');
      el.style = {position: 'absolute', left: '-9999px'};
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand('copy');
      // Remove temporary element
      document.body.removeChild(el);
    }
    */
    var validateEmail=function(email, callback) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(email)) {
            callback.onSuccess();
        } else {
            callback.onFail("Not a valid email address");
        }
    }
    var isValidDate=function(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }
    var populateLocationFromMyLocation=function(cb) {
        
        $.get( 'https://api.ipdata.co/?api-key=2a3ba97e6c19d40545bdd9942b0d1cd06da89daeedadf02b3ed83929', function( response ) {
            var pieces=[];
            if (response.city) pieces.push(response.city);
            
            if (response.region_code) {
                pieces.push(response.region_code);
            } else if(response.region) {
                pieces.push(response.region);
            }
            if (response.country_code) {
                pieces.push(response.country_code);
            } else if(response.country_name) {
                pieces.push(response.country_name);
            }
            
            var address="";
            
            for (var i=0;i<pieces.length;i++) {
                if (address.length>0) address+=", ";
                address+=pieces[i];
            }
            
         
            
            cb(address); 
         
        });
        
    
    }


    var calculateRegression=function ( data, type, parameters) {
        var reg;
        switch (type) {
            case "linear": reg= regression.linear(data, parameters); break;
            case "exponential": reg= regression.exponential(data, parameters);break;
            case "logarithmic": reg= regression.logarithmic(data, parameters);break;
            case "powerlaw": reg= regression.linear(data, parameters);break;
            case "polynomial": reg= regression.polynomial(data, parameters);break;
        }
        if (reg) reg.type=type;
        return reg;
        
    }
    var showTooltip=function(focusX, focusY, selector, html) {
        if (!html) html="";
        var tooltip=d3.select(selector);

        tooltip.style("visibility", "visible")
                      .style("left", 
                       (focusX) + "px")
                        .style("top",
                       (focusY+5) + "px");
                       
         tooltip=tooltip.select(".contents")
         tooltip.text(html);    

         

        return tooltip;
    }

    var hideTooltip=function(selector) {
        d3.select(selector)
            .style("visibility", "hidden")
    }
    
    var attachTooltips=function() {
        var tooltipItems=d3.select("body");
        tooltipItems.on("mousemove", function(d,i) {
            var item=d3.event.srcElement;//d3.select(this);
            if (!item)return;
            //var html=item.attr("tooltip");
            var html=item.getAttribute("tooltip")
            if (html) {
                var rect=item.getBoundingClientRect();
                var focusX=rect.left+rect.width/2;
                var focusY=rect.top+rect.height;
                VcUtils.showTooltip(focusX, focusY, ".tooltip.help", html);
            } else {
                VcUtils.hideTooltip(".tooltip.help");
            }

            /*var item=d3.select(this);
         
            var html=item.attr("tooltip");

            var rect=this.getBoundingClientRect();
            var focusX=rect.left+rect.width/2;
            var focusY=rect.top+rect.height;

            VcUtils.showTooltip(focusX, focusY, html);
            */
        })
        /*var tooltipItems=d3.selectAll("[tooltip]");

        tooltipItems.on("mouseover", function(d,i) {
            var item=d3.select(this);
            var location=d3.select(this.parentNode.parentNode).datum();
            var html=item.attr("tooltip");

            var rect=this.getBoundingClientRect();
            var focusX=rect.left+rect.width/2;
            var focusY=rect.top+rect.height;

            VcUtils.showTooltip(focusX, focusY, html);
        })

        tooltipItems.on("mouseout", function(d,i) {
          d3.select(this).classed("hover", false);
          VcUtils.hideTooltip();
        })
        */
    }
    var isString=function(value) {
        return (typeof value === 'string' || value instanceof String);
    }
    var isObject=function(value) {
        return typeof value === 'object' && value !== null
    }
    var truncate=function(input, prefixLength, suffixLength) {
		if (!input) return "";
		if (suffixLength<0) suffixLength=0;
		var totalLength=prefixLength+suffixLength;
		var actualLength=input.length;
		if (actualLength<totalLength) return input;
		
		if (suffixLength==0) return input.substring(0, prefixLength);
		
		return input.substring(0, prefixLength)+"..."+(suffixLength>0?input.substring(actualLength-suffixLength, actualLength):"");
    }
    
    var compressToBase64=function(input){
        if (input == null)
          return '';
        var output = '';
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i_0 = 0;
        input = LZString.compress(input);
        while (i_0 < input.length * 2) {
          if (i_0 % 2 == 0) {
            chr1 = input.charCodeAt(i_0 / 2) >> 8;
            chr2 = input.charCodeAt(i_0 / 2) & 255;
            i_0 / 2 + 1 < input.length?(chr3 = input.charCodeAt(i_0 / 2 + 1) >> 8):(chr3 = NaN);
          }
           else {
            chr1 = input.charCodeAt((i_0 - 1) / 2) & 255;
            if ((i_0 + 1) / 2 < input.length) {
              chr2 = input.charCodeAt((i_0 + 1) / 2) >> 8;
              chr3 = input.charCodeAt((i_0 + 1) / 2) & 255;
            }
             else 
              chr2 = chr3 = NaN;
          }
          i_0 += 3;
          enc1 = chr1 >> 2;
          enc2 = (chr1 & 3) << 4 | chr2 >> 4;
          enc3 = (chr2 & 15) << 2 | chr3 >> 6;
          enc4 = chr3 & 63;
          isNaN(chr2)?(enc3 = enc4 = 64):isNaN(chr3) && (enc4 = 64);
          output = output + LZString._keyStr.charAt(enc1) + LZString._keyStr.charAt(enc2) + LZString._keyStr.charAt(enc3) + LZString._keyStr.charAt(enc4);
        }
        return output;
  }

  var removeArrayItem=function(array,item) {
    var me=this;
    var index=array.indexOf(item);
    if (index!==-1) {
      array.splice(index,1);
    }
  }
  /*
  var queryString = (function(a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i)
    {
        var p=a[i].split('=');
        if (p.length != 2) continue;
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
    })(window.location.search.substr(1).split('&'));

    */
    return {
        "removeArrayItem":removeArrayItem,
        //"formatDateTime2":formatDateTime2,
        //"formatDate":formatDate,
        //"formatTime":formatTime,
        //"formatDateIso":formatDateIso,
        "compressToBase64":compressToBase64,
       // "copyToClipboard":copyToClipboard,
        "formatQueryData":formatQueryData,
        "isMobile":isMobile,
        "truncate":truncate,
        "isString":isString,
        "isObject":isObject,
        "addColumnIndexLookup":addColumnIndexLookup,
        "isDateEqual":isDateEqual,
        "isDateLess":isDateLess,
        "isDateGreater":isDateGreater,
        "isValidDate":isValidDate,
        "dateDiff":dateDiff,
        "validateEmail":validateEmail,
       // "parseDatasource":parseDatasource,
        "transformDatasource":transformDatasource,
        "transformDatasourceRowToObject":transformDatasourceRowToObject,
        "debounce":debounce,
        "uuid":uuid,
        "availableYears":availableYears,
        "formatDateTime":formatDateTime,
        "qs":qs,
        "pageName":pageName,
        "reloadpage":reloadpage,
        "isValidDate":isValidDate, 
        "getDate":getDate,
        "measureElement":measureElement,
        //"downloadData":downloadData,
        "subclassOf":subclassOf,
        "populateLocationFromMyLocation":populateLocationFromMyLocation,
        "calculateRegression":calculateRegression,
        "setCookie":setCookie,
        "getCookie":getCookie,
        "daysBetween":daysBetween,
        "showTooltip":showTooltip,
        "hideTooltip":hideTooltip,
        "attachTooltips":attachTooltips,
        "getUniqueId":getUniqueId,
        "padNumber":padNumber,
       // "escapeCsv":escapeCsv,
        //"escapeXlsx":escapeXlsx,

    }
})();