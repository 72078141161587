//import { VisualCrossingConstants } from "./commonobjects";
import {VcGlobal} from "./vcglobal_conn";
import {VcLocationsStorage} from "./vclocationsstorage";

console.log("VcCommunicator Class")
export function VcCommunicator(config) {
    this.config=config;
    this.sessionId=null;
    this.sessionState=null;
    this.isInited=false;
    this.gclid=null;
    this.gtagclient_id=null;
    this.gtagsession_id=null;
    this.init();
};

/*
VcCommunicator.prototype.HTTP_REQUEST_MULTIPART_NAME_VC_UPLOAD_INFO = "VC-Upload-Info";
VcCommunicator.prototype.HTTP_REQUEST_MULTIPART_NAME_VC_UPLOAD_CONTENT = "VC-Upload-Content";
*/
VcCommunicator.prototype.init=function() {
    var me=this;
    me.isInited=false;
    console.log("Starting vc communicator instance")

    
    if (window["gtag"]) {
        const gclidPromise = new Promise(resolve => {
            gtag('get', 'G-MFKKR9DRRN', 'gclid', resolve)
        });

        gclidPromise.then((gclid) => {
           try {
                const url = window.location && new URL(window.location.href);
                const searchParams = url && url.searchParams;
                let extraInfo=null;
                if (!gclid) {
                    gclid=searchParams && searchParams.get('gclid'); 
                }
                /*
                if (gclid) {
                    let utm_campaign=searchParams && searchParams.get('utm_campaign');
                    let utm_source=searchParams && searchParams.get('utm_source');
                    let utm_term=searchParams && searchParams.get('utm_term');
                    
                    extraInfo=("c:"+utm_campaign);
                    extraInfo=extraInfo+", src:"+utm_source;
                    extraInfo=extraInfo+", trm:"+utm_term;
                }
                */
                me.gclid=gclid;
                const clientIdPromise = new Promise(resolve => {
                    gtag('get', 'G-MFKKR9DRRN', 'client_id', resolve)
                });
                
                clientIdPromise.then((client_id) => {
                    me.gtagclient_id=client_id;
                    me.pageTrack(extraInfo);
                })
            } catch (e) {
                me.gclid="";
                me.gtagclient_id="Error:"+e;
                me.pageTrack();
            }
        })
        
        const sessionIdPromise = new Promise(resolve => {
            gtag('get', 'G-MFKKR9DRRN', 'session_id', resolve)
        });

        sessionIdPromise.then((session_id) => {
            me.gtagsession_id=session_id;
        })
    }


    me.serverinfo().then(function (response) {
        var sessionId=me.getCookie('sessionId');
        // console.log("Connected to the a vc server..."+sessionId);
        if (sessionId) {
           
            me.createsession(null,null, true, sessionId)
                .then(function (response) {
                    me.sessionId=sessionId;
                    me.isInited=true;
                    console.log("Attached to a session...");
                }).catch((error) => {
                    console.log(error);
                    me.sessionId=null;
                    me.isInited=true;
                    me.deleteCookie('sessionId');
                });
        } else {
            me.isInited=true;
            me.dispatchEvent('createsessionfailed',"No existing session id"); 
            return;
        }
    }).catch((error) => {
        console.log(error);
        me.dispatchEvent('createsessionfailed',"Unknown error occurred"); 
    });


}

VcCommunicator.prototype.pageTrack=function(extraInfo) {
    let path = window.location.pathname; 
    let params=window.location.search;
   // let pagename=path && path.split("/").pop(); 
    //pagename=pagename || "Unknown";
    if (params) path=path+params;
    if (extraInfo) path=path+"|"+extraInfo;

    let requestPackage={
        p:path
    };

    return new Promise((resolve, reject) => {
        this.retrieveData(this.createUrl("pt"), requestPackage)
            .then(data => {
                
            }).catch(error => {
               
            });
    });

}

VcCommunicator.prototype.addHandler=function(type, handler) {
    var me=this;
    me.handlers=me.handlers || {};
    me.handlers[type]=(me.handlers[type] || []);
    me.handlers[type].push(handler)
}

VcCommunicator.prototype.dispatchEvent=function(type, ...params) {
    var me=this;
    me.handlers && me.handlers[type] && me.handlers[type].forEach(function(f) {
        f(...params);
    })
}
VcCommunicator.prototype.deleteCookie=function(cname) {
    this.setCookie(cname, null, -30);
}
VcCommunicator.prototype.setCookie=function(cname, cvalue, exdays) {
    if (!exdays) exdays=30;
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

VcCommunicator.prototype.getCookie=function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
};



VcCommunicator.prototype.retrieveData=function(url,data, contentType, method) {
    var me=this;
   
    method=method || 'POST';
    contentType=contentType || (method==="POST"?'application/x-www-form-urlencoded':'application/json');

    data=data || {};

    //prune nulls because they get passed as 'null' text
    Object.keys(data).forEach(function(d) {
        (data[d] === null || data[d] === undefined) && delete data[d];
    });

    //console.log("Retrieve data:"+me.sessionId)
    if (me.sessionId) {
        data.sessionId=me.sessionId;
    }

    let gtag=(me.gclid?me.gclid:"")+"||"+(me.gtagclient_id?me.gtagclient_id:"")+"||"+(me.gtagsession_id?me.gtagsession_id:"")+"||"+VcLocationsStorage.getCid();
    if (gtag) {
        data.ginfo=gtag;
    }



    if (contentType==='application/x-www-form-urlencoded') {
        data = new URLSearchParams(data);
    } else if (contentType==='multipart') {
        contentType=null
    } else if (contentType==='application/json') {
        data=JSON.stringify(data);
    } else {

    }

    if (method==="GET") {
        data = new URLSearchParams(data);
        url=url+"?"+data.toString();
        data=null;
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method, 
            mode: 'cors',
            cache: 'no-cache', 
            headers: {
            'Content-Type': contentType,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        }).then(response => {
            if (!response.ok) {
               // if (!response.ok) throw new Error("Error retrieving the data ("+response.status+")");
               throw response;
            }
            
            return response.json();
        }).then(response => {

            if (response.errorCode) {
                reject(response.message || "Unexpected error "+response.errorCode);
                return;
            }
           resolve(response);
        
        }).catch((errorResponse) => {
           // console.log(errorResponse) 
            if (errorResponse.text) {
                errorResponse.text().then( errorMessage => {
                    reject(errorMessage);
                })
            } else {
                reject("An error occurred retrieving the data");
            }  
        });
    });
   

}
/*
VcCommunicator.prototype.retrieveDataMultiPart=function(url, textInfo, text,callback) {
    if (!textInfo) textInfo={};
    textInfo=JSON.stringify(textInfo);

    var boundary = "VC_UPLOAD_BOUNDARY";
    while (text.indexOf (boundary)!==-1 || textInfo.indexOf (boundary)!==-1) {
        boundary = "VC_UPLOAD_BOUNDARY" + (new Date().getTime());
    }
   
    var buffer = "--"+boundary+"\r\n";
    buffer+="Content-Disposition: form-data; name=\"" + VcCommunicator.prototype.HTTP_REQUEST_MULTIPART_NAME_VC_UPLOAD_INFO + "\"\r\n\r\n";
    buffer+=textInfo+"\r\n";
    buffer+="--"+boundary+"\r\n";
    buffer+="Content-Disposition: form-data; name=\"" + VcCommunicator.prototype.HTTP_REQUEST_MULTIPART_NAME_VC_UPLOAD_CONTENT + "\"\r\n\r\n";
    buffer+=text+"\r\n";
    buffer+="--"+boundary+"--\r\n";



    var request = $.ajax({
        url: url,
        data: buffer,
        cache: false,
        contentType: "multipart/form-data; charset=UTF-8; boundary="+boundary,
        processData: false,
        method: "POST"
    });
    
    request.done(function( data ) {
        callback.done(data);
    });
    
    request.fail(function( jqXHR, textStatus ) {
        if (callback.fail) callback.fail(textStatus);
    });
}

VcCommunicator.prototype.processObject=function(metadata,data,callback) {
    var me=this;
    var url=this.createUrl("processobject", null);
    
    var textInfo = {
        metadata:metadata.toJson(),
        sessionId:me.sessionId
    };

    var dataText=(typeof data === 'string' || data instanceof String)?data:JSON.stringify(data);

    me.retrieveDataMultiPart(url, textInfo, dataText,{
        "done":function(data) {
            me.checkJobResponse(data, callback);
        },

        "fail":function(data) {
            callback.fail("Request failed: "+data);
        }
    
    });
}

VcCommunicator.prototype.checkJobResponse=function(response,callback) {
    var me=this;
    response=(typeof response === 'string' || response instanceof String)?JSON.parse(response):response;
    if (response.errorCode) {
        callback.fail(response.message || "Request failed", response.errorCode);
        return;
    }
    var metadata, requestMetadata, result;
    if (response.info) {
        metadata = new Metadata (response.info);
    }
    if (response.requestInfo){
        requestMetadata = new Metadata (response.requestInfo);
    }

    if (response.dataJson) {
        result=response.dataJson;
    } else if (response.dataTextCompressed)   {
        callback.fail("Data text compressed result not support", -999); 
    } else if (response.dataText) {
        result=response.dataText;
    }
    
    if (result) {
        callback.done(result,metadata, requestMetadata );
        return;
    }


    if (!response.status) {
        callback.fail("Unsupported response format", -999); 
        return;
    }
    if (VisualCrossingConstants.JOB_STATUS.isComplete(response.status)) {
        //callback.done(response.result);
        me.checkJobResponse(response.result, callback);
        return;
    }
    if (VisualCrossingConstants.JOB_STATUS.isError(response.status)) {
        callback.fail(response.info || "Request failed", -999); 
        return;
    }
    if (VisualCrossingConstants.JOB_STATUS.isExecuting(response.status)) {
        if (callback.update) callback.update(response.info); 
        setTimeout(function() {
            me.checkJobStatus(response.id, callback);
        }, 500);
        return;
    }

}


VcCommunicator.prototype.checkJobStatus=function(jobId, callback) {
    var me=this;

    var requestPackage=this.config.createsessionpkg || {};

    this.retrieveData(this.createUrl("getjobstatus",[jobId]), requestPackage, {
        "done":function(data) {
            me.checkJobResponse(data, callback);
        },

        "fail":function(data) {
            callback.fail("Check job status request failed");
        }
    
    });
}
*/

VcCommunicator.prototype.createUrl=function(method, pathparams, serverRoot) {
    serverRoot=serverRoot || this.config.serverroot;
    var url=serverRoot+method;
    if (pathparams) {
        pathparams.forEach(function(d) {
            url+="/"+d;
        });
    }
    return url;
}
VcCommunicator.prototype.serverinfo=function() {
    var me=this;
    return this.retrieveData(this.createUrl("serverinfo"), null);
}

VcCommunicator.prototype.waitForSession=function(callback) {
    var me=this;
    me.addHandler('createsession', function(sessionId,sessionState) {
        callback(me.sessionState); 
        return;
    });

    me.addHandler('createsessionfailed', function(error) {
        callback(null, error); 
    });

    


    if (me.sessionState) {
        callback(me.sessionState); 
        return;
    }
   
}
VcCommunicator.prototype.waitForTerminateSession=function(callback) {
    var me=this;

    me.addHandler('terminatesession', function() {
        callback();
        return;
    });
}
/*
VcCommunicator.prototype.waitForSession=function() {
    var me=this;

    return new Promise((resolve, reject) => {
        if (!me.sessionId) {
            reject("No existing session id"); 
            return;
        }


        if (me.sessionState) {
            resolve(me.sessionId, me.sessionState);
            return;
        }
        me.addHandler('createsession', function(sessionId,sessionState) {
            resolve(sessionState, sessionId);
            return;
        });

        me.addHandler('createsessionfailed', function(error) {
            reject(error); 
        });
    });
}
VcCommunicator.prototype.waitForTerminateSession=function() {
    var me=this;

    return new Promise((resolve, reject) => {
       
        me.addHandler('terminatesession', function() {
            resolve();
            return;
        });

    });
}
*/
VcCommunicator.prototype.createsession=function(username, password, recreateSession, existingSessionId) {
    var me=this;

    var requestPackage=this.config.createsessionpkg || {};

    if (!username) {
        requestPackage.existingSessionId=existingSessionId || me.sessionId;
        requestPackage.recreateSession=recreateSession;
    } else {
        requestPackage.userId=username;
        requestPackage.userName=username;
        requestPackage.pwd=password;
    }
    requestPackage.nostate=true;

    return new Promise((resolve, reject) => {
        this.retrieveData(this.createUrl("createsession"), requestPackage)
            .then(data => {
                if (data.errorCode || !data.sessionId) {
                    reject(data.message || "Log in failed");
                    return;
                }
                me.sessionId=data.sessionId;
                me.sessionState=data;
                me.setCookie('sessionId', data.sessionId,0, 60);
                me.dispatchEvent('createsession',me.sessionId,me.sessionState  );
                resolve(me.sessionId);
            }).catch(error => {
                me.dispatchEvent('createsessionfailed',error );
                reject(error); 
            });
    });

        
}

/*
VcCommunicator.prototype.changeuserpassword=function(config) {
    var me=this;

    return new Promise((resolve, reject) => {
        this.retrieveData(this.createUrl("changeuserpassword"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Password change failed");
                    return;
                }
                
                resolve(me.sessionId);
            }).catch(error => {
                reject(error); 
            });
    });

}
*/

VcCommunicator.prototype.signOut=function() {
    var me=this;
   
    me.sessionId=null;
    me.sessionState=null;
    me.deleteCookie('sessionId');
    me.dispatchEvent('terminatesession');
    return this.retrieveData(this.createUrl("terminatesession"),  {
        "targetSessionId":me.sessionId
    });

}
VcCommunicator.prototype.createTimelineRequest=function(defn, location, name, requireKey, contentType, forceGlobalKey) {
    var me=this;
    function encodeURIComponentIfNotNull(value) {
        return  value?encodeURIComponent(value):value;
    }

     
    location=encodeURIComponentIfNotNull(location);
    name=encodeURIComponentIfNotNull(name);
    var startdate=encodeURIComponentIfNotNull(defn.startdate);
    var enddate=encodeURIComponentIfNotNull(defn.enddate);

    var sessionId=!requireKey && me.sessionId;
    var key=(forceGlobalKey && VcGlobal.GLOBAL_KEY)||  defn.key || (!sessionId && me.sessionState && me.sessionState.apiKey);
    contentType=contentType || defn.contentType;

    var queryParams={
       
    }

    if (name) queryParams.locationNames=name;
    if (defn.unitGroup) queryParams.unitGroup=defn.unitGroup;
    if (defn.maxDistance>0) queryParams.maxDistance=defn.maxDistance;
    if (defn.elements) queryParams.elements=defn.elements;
    if (defn.include) queryParams.include=defn.include;
    if (defn.forecastDataset) queryParams.forecastDataset=defn.forecastDataset;
    
    if (sessionId) {
        queryParams.sessionId=sessionId;
    } else if (key) {
        queryParams.key=key;
    } else {
        queryParams.key=VcGlobal.GLOBAL_KEY;
    }


    if (defn.maxStations>0) queryParams.maxStations=defn.maxStations;
    if (defn.elevationDifference>0) queryParams.elevationDifference=defn.elevationDifference;
    if (defn.options) queryParams.options=defn.options;
    if (defn.lang) queryParams.lang=defn.lang;
    if (defn.iconSet) queryParams.iconSet=defn.iconSet;

    if (contentType) queryParams.contentType=contentType;
    if (defn.forecastBasisDate) queryParams.forecastBasisDate=defn.forecastBasisDate;
    if (defn.forecastBasisDay) queryParams.forecastBasisDay=defn.forecastBasisDay;
    		
    if (defn.degreeDayMethod) queryParams.degreeDayMethod=defn.degreeDayMethod;
    if (defn.degreeDayTempFix) queryParams.degreeDayTempFix=defn.degreeDayTempFix;
    if (defn.degreeDayTempMaxThreshold) queryParams.degreeDayTempMaxThreshold=defn.degreeDayTempMaxThreshold;
    if (defn.degreeDayTempBase) queryParams.degreeDayTempBase=defn.degreeDayTempBase;
    if (defn.degreeDayStartDate) queryParams.degreeDayStartDate=defn.degreeDayStartDate;
    if (defn.degreeDayInverse) queryParams.degreeDayInverse=defn.degreeDayInverse;
    var urlParams=new URLSearchParams(queryParams);

    var url=VcGlobal.SERVER_ROOT+"/VisualCrossingWebServices/rest/services/timeline/"+location;
    if (startdate) {
        url+="/"+startdate;
        if (enddate) {
            url+="/"+enddate;
        }
    }

    return url+"?"+urlParams; 
}
VcCommunicator.prototype.retrieveTimelineData=function(defn, location, name,  contentType, requireKey, forceGlobalKey) {
    var me=this;
    
    var request=me.createTimelineRequest(defn, location, name, requireKey, contentType, forceGlobalKey);



    return new Promise((resolve, reject) => {
        var isError=false;
        fetch(request)
            .then(response => {
                if (!response.ok) {
                    isError=true;
                    return response.text();
                }
                if (contentType==="json") {
                    return response.json();
                } else {
                    return response.text();
                }
               
            }).then(jsonOrText =>  {
                if (isError) {
                    reject(jsonOrText);
                }
                resolve(jsonOrText);
            }).catch(error =>  {
                reject(error);
            }
            );
    });

    return fetch(request)
        .then(response => {
            if (!response.ok) {
                //throw new Error("Error retrieving the data ("+response.status+")");
                return response.text();
            }
            
            return response.json();
        }); 


    /*, {
        method: 'GET', 
        mode: 'cors',
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
        'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(defn) 
    }
    /*.then(response => {
      
        resolve(response);
    }).catch((error) => {
       reject(error);
    });*/
    
}

/*
VcCommunicator.prototype.retrieveWeatherData=function(querytype,locations,params, callback) {
    var me=this;
    var tableId="WxSourceData";
    var table={
        "name" : tableId,
        "id" : tableId,
        "isPrimary" : true,
        "analyzeLevels" : false,
        "columns" : [],
        "rows" : [],
        layerDataContext:{
            "FieldJoins" : "{}",
            "useGeoJsonGeometry" : "true",
            "attributeId" : "name",
            "shapeType" : "1",
            "onDemandTileGeneration" : "true",
            "joinLayerColumns" : "address",
            "contextType" : "6",
            "attributeName" : "",
            "FieldValues" : "{}",
            "addressFields" : "address"
        }
    }
 
    table.columns.push( {
        "isKey" : true,
        "name" : "Name",
        "id" : "name",
        "type" : "string",
        "defaultNumberAggregateStyle" : -1,
        "format" : "!"							
    });
    table.columns.push( {
        "isKey" : false,
        "name" : "Address",
        "id" : "address",
        "type" : "string",
        "defaultNumberAggregateStyle" : -1,
        "format" : "!"							
    });


 

    locations.forEach(function(location) {
        table.rows.push([location.name, location.address]);
    });


    var weatherRequest=new Metadata();
    weatherRequest.addData(0, VisualCrossingConstants.DATA_PROVIDER_TYPE.JSONDATASOURCETABLE);
    weatherRequest.addData(1, VisualCrossingConstants.DATA_PROVIDER_TYPE.WEATHERJOINDATASOURCETABLE);

    weatherRequest.addDataProperty(1, VisualCrossingConstants.WEATHER.DATASET_TYPE, VisualCrossingConstants.WEATHER_DATASET_TYPE.goalTypeFromName(querytype));
    
    Object.keys(VisualCrossingConstants.WEATHER).forEach(function(d) {
        var prop=VisualCrossingConstants.WEATHER[d];
        if (params[prop]) {
            weatherRequest.addDataProperty(1,prop, params[prop]);
        }
    });
 
    
    me.processObject(weatherRequest,table, {
        "done":function(data) {
            data=VcUtils.transformDatasource(data);
            callback.done(data);
        },
        "update":function(data) {
            callback.update(data);
        },
        "fail":function(data) {
            callback.fail(data || "Weather data retrieval failed");
        }
    
    });
    
}
*/
