<template>
              
  <div class="d-flex align-items-center flex-wrap ">
        <i class="bi bi-stopwatch d-none d-sm-inline"></i> 
          
        <button class="dropdown-toggle btn text-primary px-1" type="button" id="dateSelectionTypeDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
          {{selectedDateSelectionTypeText}} 
        </button>
        <ul class="dropdown-menu" aria-labelledby="dateSelectionTypeDropdownButton">
          <template v-for="d in dateselectiontypes">
          <li v-if="!d.hidden"  @click="setDateSelectionType(d)"><a class="dropdown-item" href="#" >{{d.label}}</a></li>
          </template>
          <li v-for="p in periods"  @click="setDynamicPeriod(p)"><a class="dropdown-item" href="#" >{{p.label}}</a></li>
        </ul>
      <div  class="d-flex align-items-center">
        <DatePicker class="inline-block h-full" v-model="startdate">
          <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
              <button
                class="btn text-primary px-1"
                @click="togglePopover()"
              >
                <i class="bi-calendar"></i> {{inputValue || "Start"}}
              </button>
            </div>
          </template>
        </DatePicker>
        <div class="btn disabled px-0 ">
          <i class="bi bi-arrow-right"></i>
        </div>
        <DatePicker class="inline-block h-full" v-model="enddate">
          <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
              <button
                class="btn text-primary px-1"
                @click="togglePopover()"
                >
                <i class="bi-calendar"></i> {{inputValue || "End"}}
              </button>
            </div>
          </template>
        </DatePicker>
      </div>
  </div>

</template>

<script>
import {VcDateUtils} from '/../visualcrossing-core-js/src/vcdateutils';
import { Calendar, DatePicker } from 'v-calendar';
export default {
  name: 'DateRangeChooser',
  props: {
    sessionId:String,
    sessionState:Object,
    queryDefn:Object,
    queryData:Object,
  },
  emits: ['change:daterange'],
  data() {
    return {
      periods:[
            {id:"today",label:"today"},
            {id:"yesterday",label:"yesterday"},
            {id:"yeartodate",label:"yeartodate"},
            {id:"monthtodate",label:"monthtodate"},
            {id:"last7days",label:"last7days"},
            {id:"last30days",label:"last30days"},
            {id:"last24hours",label:"last24hours"},
            {id:"next7days",label:"next7days"},
            {id:"next30days",label:"next30days"},
            {id:"next24hours",label:"next24hours"},
      ],
      dateselectiontypes:[
         {id:"forecast",label:"Next 15 days"},
         //{id:"singledate",label:"Single date"},
         {id:"daterange",label:"Date range"},
         {id:"period",label:"Dynamic period", hidden:true},
         
      ],
      selectedDateSelectionType:null,
      localQueryDefn:null, //this.queryDefn && JSON.parse(JSON.stringify(this.queryDefn))
    }
  },
  methods:{
    setDateSelectionType(value) {
      var me=this;
      if (!me.localQueryDefn) return;

      if (!value) {
        if (!me.localQueryDefn.startdate) {
          value=me.dateselectiontypes[0]; 
        
        } else if (me.localQueryDefn.startdate && !me.localQueryDefn.enddate) {
          if (me.getPeriodFromId(me.localQueryDefn.startdate)) {
            value=me.dateselectiontypes[2]; 
          } else {
            value=me.dateselectiontypes[1]; 
          }
          
        } else if (me.localQueryDefn.startdate && me.localQueryDefn.enddate) {
          value=me.dateselectiontypes[1]; 
        }
      }

      me.selectedDateSelectionType=value;

      if (value.id==="forecast") {
        me.localQueryDefn.startdate=null;
        me.localQueryDefn.enddate=null;
         me.$emit("change:daterange", me.localQueryDefn);
      } else if (value.id==="singledate") {
        me.localQueryDefn.startdate=(me.getPeriodFromId(me.localQueryDefn.startdate) && null) || me.localQueryDefn.startdate;
        me.localQueryDefn.enddate=null;
      } else if (value.id==="daterange") {
        me.localQueryDefn.startdate=null;
        me.localQueryDefn.enddate=null;
      } else if (value.id==="period" && !me.getPeriodFromId(me.localQueryDefn.startdate)) {
        me.setDynamicPeriod(me.periods[0]);
      }
    },
    setDynamicPeriod(value) {
      var me=this;
      me.selectedDateSelectionType=me.dateselectiontypes[2];
      me.localQueryDefn.startdate=(value && value.id) || null;
      me.localQueryDefn.enddate=null;
      me.$emit("change:daterange", me.localQueryDefn);
    },
    getPeriodFromId(id) {
      return this.periods.reduce(function (a, d) {
        return a || d.id===id
      }, null);
    },
  },
  computed:{
    dynamicDateLabel() {
      var me=this;
      return me.localQueryDefn && (me.localQueryDefn.startdate || "Select period...");
    },
    selectedDateSelectionTypeText() {
      if (!this.selectedDateSelectionType) {
        this.setDateSelectionType(null);
      }
      if (!this.selectedDateSelectionType) return  "-";
      if (this.isDynamicPeriod) return this.dynamicDateLabel;
      return this.selectedDateSelectionType.label;
    },
    isDynamicOrCustom() {
      return this.isDynamicPeriod || this.isCustomRange;
    },
    isDynamicPeriod() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="period";
    },
    isCustomRange() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="custom";
    },
    isSingleDate() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="singledate";
    },
    isDateRange() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="daterange";
    },
    isForecast() {
      return !(this.isDynamicPeriod || this.isSingleDate || this.isDateRange);
    },
    startdate: {
      get: function () {
         var me=this;
        if (!me.localQueryDefn) return null;
        if (!me.localQueryDefn.startdate || me.isDynamicOrCustom) {
          var days=me.queryData && me.queryData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[0].datetime);
        }
        if (me.isDynamicPeriod) return me.localQueryDefn.startdate;
        return VcDateUtils.dateFromIsoLocal(me.localQueryDefn.startdate);
      },
      set: function (newValue) {
        var me=this;

        let currentStartDate=me.localQueryDefn.startdate && VcDateUtils.dateFromIsoLocal(me.localQueryDefn.startdate);

        
        me.localQueryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        var days=me.queryData && me.queryData.days;
        if (days  && !me.localQueryDefn.enddate) {
          me.localQueryDefn.enddate=days[days.length-1].datetime;
        }

        let currentEndDate=!me.isDynamicOrCustom && me.localQueryDefn.enddate && VcDateUtils.dateFromIsoLocal(me.localQueryDefn.enddate);

        if (currentStartDate) {
          let dayDelta=me.isMetered?-30:-365;

          let newTimeDelta=(newValue.getTime()-currentStartDate.getTime())/86400000;
          if (currentEndDate && currentEndDate.getTime()<newValue.getTime()) { 
            me.localQueryDefn.enddate=VcDateUtils.formatDateIso(newValue);
          } else if (newTimeDelta<dayDelta) {
            me.localQueryDefn.enddate=VcDateUtils.formatDateIso(newValue);
          }
        }

        me.selectedDateSelectionType=me.dateselectiontypes[1];


        me.$emit("change:daterange", me.localQueryDefn);
      }
    },
    enddate: {
      get: function () {
        var me=this;
        if (!me.localQueryDefn) return null;
        if (!me.localQueryDefn.enddate || me.isDynamicOrCustom) {
          var days=me.queryData && me.queryData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[days.length-1].datetime);
        }
        if (me.isDynamicPeriod) return null;
        return VcDateUtils.dateFromIsoLocal(me.localQueryDefn.enddate);
      },
      set: function (newValue) {
        var me=this;
        

        let currentStartDate=!me.isDynamicOrCustom && me.localQueryDefn.startdate && VcDateUtils.dateFromIsoLocal(me.localQueryDefn.startdate);

        var days=me.queryData && me.queryData.days;
        if (days && (me.isDynamicPeriod || !me.localQueryDefn.startdate)) {
          me.localQueryDefn.startdate=days[0].datetime;
        }

        me.localQueryDefn.enddate=VcDateUtils.formatDateIso(newValue);

        if (currentStartDate && currentStartDate.getTime()>newValue.getTime()) { 
          me.localQueryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        } 

        me.selectedDateSelectionType=me.dateselectiontypes[1];
        me.$emit("change:daterange", me.localQueryDefn);
      }
    },
  },
  watch:{
    queryDefn:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.localQueryDefn=newValue && JSON.parse(JSON.stringify(newValue));
        },
        deep: true
    },
  },
  mounted() {
    var me=this;
    
    me.localQueryDefn=me.queryDefn && JSON.parse(JSON.stringify(me.queryDefn));
    
     
  },
  updated() {
    var me=this;
  
  },
  components: {
    DatePicker,
    Calendar,
  }
}
</script>

<style scoped>

</style>
