<template>
 <div class="">
    <div v-if="errorStatus" class="alert alert-danger my-3" role="alert" v-html="errorStatus">
    </div>
    <div v-else-if="isLoading" class="d-flex align-items-center justify-content-center" style="min-height:300px">
        <div class="spinner-border mx-2" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Loading...
    </div>
    <div v-else-if="noData">
      <div v-if="errorStatus" class="alert alert-info my-3" role="alert">
        No data available.
      </div>
    </div>
    <div v-else-if="!selectedLocation" class="row justify-content-md-center">
      <div class="col-12 col-lg-8 ">
        <h5 class="mb-0">Please add a location to get started</h5>
        <div class="input-group">
          <div class="btn px-1 disabled" >
            <i class="bi bi-geo"></i>
          </div>
          <input v-model="newLocation" v-on:keyup.enter="setLocation()" class="form-control border-end-0  border-primary" type="text" placeholder="Enter a location" aria-describedby="locationHelpBlock">
          <span class="input-group-append">
              <button @click="setLocation()" class="btn btn-primary  border-start-0 border-primary ms-n1" :class="{disabled:!newLocation}" type="button" >
                  <i class="bi-search"></i>
              </button>
          </span>
        </div>
      </div>
    </div>
    <template v-else >
      <div class="row justify-content-md-center">
        
        <div class="row ">
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end mb-3 mb-md-0">
             
            <a href="#" class="dropdown-toggle" id="locationDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{selectedLocation.location}} <i class="bi bi-pencil"></i></a>
            <ul class="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
              <li><div class="fw-bold mt-3">Enter address or partial address:</div></li>
              <li> 
                <div class="d-flex align-items-center p-1">
                  <input v-model="newLocation" v-on:keyup.enter="setLocation()" class="form-control" type="text" placeholder="Enter a location">
                  <button @click="setLocation()" type="button" class="btn btn-sm btn-translucent-primary "> <i class="bi-search"></i></button>
                </div>
                <a class="ps-2 pt-0" href="#" @click.prevent.stop="findMyLocation()"><i class="bi bi-broadcast-pin"></i> Use your location</a>
              </li>
              <template v-if="hasLocationsHistory">
                <li><div class="fw-bold mt-3">History:</div></li>
                <li v-for="(loc,index) in locationsHistory"  @click="setLocation(loc)"><a class="dropdown-item" href="#" >{{loc.location}}</a></li>
              </template>
            </ul>
          </div>
         
          <DatePicker class="col-7 col-md-3 d-flex align-items-center justify-content-center" v-model="focusDate" @change="refresh()">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <button
                    class="btn text-primary px-1"
                    @click="togglePopover()"
                  >
                    {{inputValue || "Today"}}
                  </button>
                </div>
              </template>
          </DatePicker>
          <div class="col-5 col-md-3">
            <div class="btn-group ms-auto" role="group" aria-label="Solid button group">
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:queryDefn.unitGroup==='us'}" @click="setUnitGroup('us')">F</button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:queryDefn.unitGroup==='metric'}" @click="setUnitGroup('metric')">C</button>
            </div>

            <button type="button" class="btn text-primary btn-sm"  @click="copyLink()"><i class="bi bi-share"></i><span class="d-none  d-lg-inline"> Share </span></button>
          </div>
          
        </div>
      
        <!--
        <div class="row justify-content-center">
          <div class="display-1 col-12 col-md-6 text-center">{{formatElementValue(focusTemp,'temp')}}</div>
        </div>
        -->

        <div class="row justify-content-center mt-5">
          <h4 class="d-flex align-items-center justify-content-center">How does this compare to the last 10 years on this date?</h4>
          <div class="col-6 col-md-3 text-center">
            <h5 class="mb-0">Ranking</h5>
            <div class="display-5">{{rankingPosition}}</div>
            <div class="">{{rankingSubTitle}}</div>
          </div>
          <div class="col-6 col-md-3 text-center">
            <h5 class="mb-0">Warmest</h5>
            <div class="display-5">{{formatElementValue(warmestTemp[focusType],'temp')}}</div>
            <div class="">({{yearFromDateTime(warmestTemp.datetime)}})</div>
          </div>
          <div class="col-6 col-md-3 text-center">
            <h5 class="mb-0">Normal</h5>
            <div class="display-5">{{normalTemp}}</div>
          </div>
          <div class="col-6 col-md-3 text-center">
            <h5 class="mb-0">Coldest</h5>
            <div class="display-5">{{formatElementValue(coldestTemp[focusType],'temp')}}</div>
            <div class="">({{yearFromDateTime(coldestTemp.datetime)}})</div>
          </div>
        <!-- <div class="col-6col-md-3 text-center">{{historicalTemps}}</div>-->
        </div>
        <div class="row justify-content-md-center mt-5">
          <div class="col-12 col-lg-10">
            <div class="d-flex flex-wrap">
              <a class="btn btn-translucent-dark btn-xs rounded-pill fs-sm me-2 mb-2" @click="openDashboard('/how-hot-is-it/')"><i class="bi-search me-2"></i>How hot is it?</a> 
              <a class="btn btn-translucent-dark btn-xs rounded-pill fs-sm me-2 mb-2" @click="openDashboard('/how-cold-is-it/', true)"><i class="bi-search me-2"></i>How cold is it?</a> 
              <a class="btn btn-translucent-dark btn-xs rounded-pill fs-sm me-2 mb-2" @click="openDashboard('/weather-history/')"><i class="bi-search me-2"></i>Full History</a> 
              <a class="btn btn-translucent-dark btn-xs rounded-pill fs-sm me-2 mb-2" @click="openDashboard('/weather-forecast/')"><i class="bi-search me-2"></i>15-Day Forecast</a>
            </div>
          </div>
        </div>
      </div>
    </template>


    
    <CommonModals />
  </div>
</template>

<script>
import {VcLocationsStorage} from "/../visualcrossing-core-js/src/vclocationsstorage";
import "/../visualcrossing-core-js/src/vccommunicator_serveradmin";
import CommonModals from '/src/components/CommonModals'
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
import { Calendar, DatePicker } from 'v-calendar';
import {VcConversion} from "/../visualcrossing-core-js/src/vcconversion";
import {VcFindMyLocation} from '/../visualcrossing-core-js/src/vcfindmylocation';
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils';

export default {
  name: 'howisut',
  props: {
    
  },
  data() {
    return {
      noData:false,
      sessionId:null,
      sessionState:null,
      errorStatus:null,
      isLoading:false,
      selectedLocation:null,
      viewElement:null,
      rooturl:null,
      basePeriodLabel:null,
      newLocation:null,
      locationsHistory:null,
      showAllColumns:false,
      focusType:null,
      queryDefn:{
        startdate:null,
        enddate:null,
        include:"days,current,stats,obs",
        options:"",
        elements:"datetime,datetimeEpoch,temp,tempmax,tempmin",
        unitGroup:"us",
      },
      queryData:null,
      queryHistoryData:null,
      sortedHistoryDays:null,
      isLoadingData:false,
    }
  },
  methods:{
    yearFromDateTime(datetimetxt) {
      if (!datetimetxt) return "-";
      const datetime=VcDateUtils.dateFromIsoLocal(datetimetxt);
      if (!datetime) return "-";
      return datetime.getFullYear();
    },
    formatEpochTime(epochTime) {
      return VcDateUtils.formatDateTime2(epochTime);
    },
    format(value,decimals,separators) {
      decimals=(decimals>=0)?decimals:1;
      if (!Number.isFinite(value) || value===Number.MIN_VALUE || value===Number.MAX_VALUE) return "-";

      if (separators) {
        return value.toLocaleString('en-US', {maximumFractionDigits:decimals});
      }
      return value.toFixed(decimals);
    },
    formatElementValue(value, element) {
        var me=this;
        if (!element) {
          return me.format(value, 0, false);
        }
        value=Math.round(value)
        return VcConversion.formatValue(me.queryDefn.unitGroup, element, value, true);
        
    },
    refresh() {
      let me=this;
      

      window.location=me.createLink();

    },
    createLink() {
      let me=this;
      let focusDate="today";
      if (me.focusDate) {
        focusDate=new Date(me.focusDate.getTime());
   
        focusDate=VcDateUtils.formatDateIso(focusDate);
      }

      let location=me.selectedLocation && me.selectedLocation.location;

      return (me.rooturl || "") +location+"/"+me.queryDefn.unitGroup+"/"+focusDate;
    },
    openDashboard(dashboardPath, includeDate) {
      let me=this;
      let dateParam="";
      if (includeDate) {
        let focusDate="today";
        if (me.focusDate) {
          focusDate=new Date(me.focusDate.getTime());
    
          focusDate=VcDateUtils.formatDateIso(focusDate);
        }
        dateParam="/"+focusDate;
      }
      let location=me.selectedLocation && me.selectedLocation.location;

      window.location=dashboardPath+location+"/"+me.queryDefn.unitGroup+dateParam;
    },
    copyLink() {
      var me=this;
      var url=me.createLink();


      VcClipboardUtils.copyToClipboard(window.location.origin+url);
      VcCommonModals.showToast("Link copied", "The dashboard link been copied to the clipboard.")
    },
    refreshData(historyOffset) {
      let me=this;

      let hasHistoryOffset=historyOffset || (historyOffset===0);

      let queryDefn=JSON.parse(JSON.stringify(me.queryDefn));
      if (hasHistoryOffset) {
        if (historyOffset===0) {
          me.queryHistoryData=[];
        }
        let historyDate=new Date(me.focusDate.getTime());
        let currentYear=new Date().getFullYear()
        historyDate.setFullYear(currentYear-historyOffset)
        queryDefn.startdate=VcDateUtils.formatDateIso(historyDate);
      } else {
        if (!queryDefn.startdate) queryDefn.startdate="today";
        
        me.queryData=null;

       // me.queryHistoryData=[];
      }
     
      me.isLoadingData=true;
      vcCommunicator.retrieveTimelineData(queryDefn, me.selectedLocation.location, null, "json", true,false)
        .then(response => {
          if (hasHistoryOffset) {
            me.queryHistoryData[historyOffset]=response;

            if (historyOffset<10) {
              me.refreshData(historyOffset+1);
            } else {
              me.sortHistoryDays();
              me.isLoadingData=false;
            }
          } else {
            me.queryData=response;
            me.refreshData(0);

          }
          
        }).catch((error) => {
          me.isLoadingData=false;
          me.errorMessage=error;
      });
    },

   findMyLocation() {
      var me=this;
      var fml=VcFindMyLocation.findMyLocation(true);

      fml.then(location=> {
          //me.setLocation(location);
          me.newLocation=location;
        })
        .catch(error=>{
          console.log("Could not find location..."+error)
        });
    },
    setLocation(newLocation) {
      
      var me=this;
      if (newLocation) {
        me.selectedLocation=newLocation;
      } else {
        me.selectedLocation={
          location:me.newLocation,
          name:null,
        };
      }
      
      me.newLocation=null;
      me.saveLocation(me.selectedLocation);
      me.refresh();
    },
    saveLocation(newLocation) {
      var me=this;
      VcLocationsStorage.addLocation(newLocation);
      me.refreshLocationsHistory();
    },
    setUnitGroup(unitGroup) {
      var me=this;
      me.queryDefn.unitGroup=unitGroup
      //VcLocationsStorage.setUnitGroup(me.unitGroup);
      me.refresh();
    },
    refreshLocationsHistory() {
      this.locationsHistory=VcLocationsStorage.getLocations();
    },
    isValidQueryData(queryData) {
      return (queryData && queryData.days && queryData.days.length);
    },

    sortHistoryDays() {
      let me=this;
      if (!me.queryHistoryData || !me.queryHistoryData.length) return null;

      let days=me.queryHistoryData.map(d=>{
        return me.isValidQueryData(d) && d.days[0];
      });

      days.sort((a,b)=>b[me.focusType]-a[me.focusType])
      me.sortedHistoryDays=days;
    },
  },
  computed:{
    normalTemp() {
      let me=this;
      if (me.isLoadingData) return "-";
      let days=me.sortedHistoryDays;
      if (!days || !days.length) return "-";
      days.forEach(d=> {
        if (d[me.focusType]) {
          days.count=days.count && (days.count+1) || 1;
          days.sum=days.sum && (days.sum+d[me.focusType]) || d[me.focusType];
        }
      })
      return me.formatElementValue( days.sum/days.count,'temp'); 
    },
    focusTemp() {
      let me=this;
      if (!me.isValidQueryData(me.queryData)) return "-";
      return me.queryData.days[0][me.focusType];
    },
    warmestTemp() {
      let me=this;
      if (me.isLoadingData) return "-";
      let days=me.sortedHistoryDays;
      if (!days || !days.length) return "-";
      let day=days.reduce((a,d)=>{ return a || d}, null)
      return day || {};
    },
    coldestTemp() {
      let me=this;
      if (me.isLoadingData) return "-";
      let days=me.sortedHistoryDays;
      if (!days || !days.length) return "-";
      let day=days.reduce((a,d)=>{ return d || a}, null)
      return day || {};

    },
    historicalTemps() {
      let me=this;
   
      let days=me.sortedHistoryDays;
      if (!days || !days.length) return 0;

      return days.reduce((a,d)=>{
        if (!d) return a;
        if (a.length) a+=", ";
        return a+d[me.focusType];
      }, "")
    },
    rankingSubTitle() {
      let me=this;
      if (me.focusType==="tempmax") {
        return "Warmest";
      } else if (me.focusType==="tempmin") {
        return "Coldest";
      }
    },
    rankingPosition() {
      let me=this;
      if (me.isLoadingData) return "-";
      let days=me.sortedHistoryDays;
      if (!days || !days.length) return 0;
      let index=days.reduce((a,d,i)=> {
        if (!d || a!==-1) return a;
        if (d[me.focusType]<=me.focusTemp) return i;
        return a;
      }, -1)
      if (index<0) return "-";
      if (me.focusType==="tempmin") {
        index=days.length-index;
      } else {
        index++;
      }
      
      

      if (index==1) {
         return "1st";
      } else if (index==2) {
        return "2nd";
      } else if (index==3) {
        return "3rd";
      }
      return index+"th";
    },
    showDateRangeChooser() {
      return true;
    },
    hasLocationsHistory() {
      return this.locationsHistory && this.locationsHistory.length;
    },
    focusDate: {
      get: function () {
        var me=this;
        if (!me.queryDefn.startdate ) {
          var days=me.queryData && me.queryData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[0].datetime);
        }
     
        return VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);
      },
      set: function (newValue) {
        var me=this;
        me.queryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        me.queryDefn.enddate=null;
      }
    },
  },
  watch: {
    queryData:{
        handler:function(newValue,oldValue) {
          
        },
        deep: true
    },
   queryDefn:{
        handler:function(newValue,oldValue) {
          let me=this;
          if (!newValue || !oldValue) return;
          if (JSON.stringify(newValue)===JSON.stringify(oldValue)) return;
          console.log("queryDefn changed")
          me.refresh();
        },
        deep: true
    },
    focusDate:{
       handler:function(newValue,oldValue) {
          let me=this;
           if (!newValue || !oldValue) return;
          if (newValue.getTime()===oldValue.getTime()) return;
          console.log("focusDate changed")
          me.refresh();
        },
        deep: true
    },
  },
  unmounted() {
    var me=this;
  },
  mounted() {
    var me=this;
    
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    me.sessionState=vcCommunicator.sessionState;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
      me.sessionId=sessionId;
      me.sessionState=sessionState;
    
    
    });
     vcCommunicator.addHandler('terminatesession', function () {
       me.sessionId=null;
       me.sessionState=null;
    });

    const vcconfig=window.vcconfig;

    if (vcconfig) {
     // if (window.vcconfig.location==="<?php echo $location ?>") window.vcconfig.location="Herndon,VA";
     // if (window.vcconfig.unitGroup==="<?php echo $unitGroup ?>") window.vcconfig.unitGroup="us";
     // if (window.vcconfig.startdate==="<?php echo $startDate ?>") window.vcconfig.startdate="last15days";
     // if (window.vcconfig.enddate==="<?php echo $endDate ?>") window.vcconfig.enddate="";

      
        if (vcconfig.location) {
          me.selectedLocation={
            location:vcconfig.location,
            name:null,
          };
        }
        if (vcconfig.unitGroup) {
          me.queryDefn.unitGroup=vcconfig.unitGroup;
        }
        if (vcconfig.startdate) {
          me.startdate=window.vcconfig.startdate;
        }
        if (vcconfig.focusType) {
          me.focusType=window.vcconfig.focusType;
        }
        if (vcconfig.rooturl) {
          me.rooturl=window.vcconfig.rooturl;
        }
        
        if (vcconfig.queryData) {
          me.queryData=vcconfig.queryData;
          me.refreshData(0);
        }

    }
    
  },
  components: {
    DatePicker,
    CommonModals
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .old {
      font-style: italic;
      opacity: 0.9;
  }
  .table-striped tbody  .error {
    font-weight: bold;
  
  }
  .table-striped tbody .error td {
      background-color: rgb(245, 212, 212) !important;
  }

  .comparisonchart {
      background-color: #fff;
      position:relative;
  }

  .comparisonchart :deep() .modelline {
      stroke:rgb(81, 82, 81);
      fill:none;
  }
  

 .tooltip {
    background-color: white;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    transform: translate(-50%, 0%);
    pointer-events: none;
    line-height: 13px;
  }
  .tooltip .contents {
  
  }
  .tooltip .contents  :deep() .range {
    font-weight: bold;
    font-size: 1.1em;
  }

  .tooltip .contents  :deep() .result {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .tooltip * {
    text-align: center;
  }

</style>