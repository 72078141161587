

<script>

import {VcWidgetHelper} from './common_widget.js'

//import Prism from "../../../node_modules/prismjs/prism.js"
//import "../../../node_modules/prismjs/components/prism-json.js"
//import "../../../node_modules/prismjs/themes/prism-dark.css"

export default {
  name: 'api-results-widget',
  props:{
    weatherData:Object,
    parentData:Boolean,
  },
  data() {
    return {
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      dayIndex:0,
      hourIndex:null,
      period:"day",
      rooturl:null,
    }
  },
  methods:{
   
    refreshData() {
      var me=this;
      if (me.parentData) return;
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:me.forceRefresh,
                })
      .then(response => {
          me.wxdata=response.data;
          if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
          if (!me.location) me.location=me.wxdata.resolvedAddress;
      }).catch((error) => {
           me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    formatValue(value, element) {
      var me=this;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup );
    },
    formatTime(period) {
      return VcWidgetHelper.formatTime(period.datetimeEpoch, this.wxdata.timezone);
    },
    formatDate(period) {
      return VcWidgetHelper.formatDate(period.datetimeEpoch, this.wxdata.timezone);
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    tdValue(value) {
      if (!value) return value;
      if (Array.isArray(value) ) return value.join(",");
      return value;
    },
  },
  computed:{
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation(this.wxdata, this.location, this.name, );
    },
    hasError:function() {
      return this.error;
    },
    
    isTruncated() {
      var me=this;
      var wxcopy= me.parsedJson;
      return (wxcopy.days && wxcopy.days.length>1)
    },
    parsedJson() {
      var me=this;
      var wxcopy= JSON.parse(JSON.stringify(me.wxdata));

      return wxcopy;
    },
    resultViewData() {
      var me=this;
      var wxcopy= me.parsedJson;
      if (wxcopy.days && wxcopy.days.length>1) wxcopy.days=wxcopy.days.slice(0,1);
      wxcopy.days.forEach(function(d) {
        if (!d.hours) return;
        var i=d.hours.length/2;

        d.hours=d.hours && d.hours.slice(i,i+2)
      });
      return JSON.stringify(wxcopy, null, 1);
      //return Prism.highlight( JSON.stringify(wxcopy, null, 1), Prism.languages.json);
    },
    hasResultData() {
      return this.wxdata;
    },
   
    isEmptyData() {
      return !this.hasResultData;
    },
   
  },
  updated() {
    Prism.highlightAll();
  },
  mounted() {
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);
    me.refreshData();
    Prism.highlightAll();
  },
  components: {

  }
}
</script>
<template>

    <div class="d-flex flex-column">
      <div v-if="isEmptyData" class="flex-fill d-flex justify-content-center mt-5">
        <i class="bi bi-code-slash me-1"></i>There is no data in this selection
      </div>
      <div v-if="isTruncated" class="flex-fill d-flex justify-content-center mt-1">
        <i class="bi bi-exclamation-triangle me-1"></i>The JSON has been truncated to a single day preview.
      </div>
      <pre class="line-numbers"><code class="language-json" v-html="resultViewData"></code></pre>
      <div v-if="!hasResultData" class="flex-fill d-flex justify-content-center mt-5">
        <i class="bi bi-code-slash me-1"></i>Please run some data
      </div>
  </div>
</template>
<style scoped>
</style>
