
import './how-is-it.scss'

import "/src/main.js"
import "/src/accountcomponents.js"

import { createApp } from 'vue'
import HowIsItComponent from './HowIsIt'


(function(w,d) {

  window.addEventListener("load",function(event) {
    createApp(HowIsItComponent).mount("#howIsItComponent")
  },false);

})(window, document);