<template>
 <div class="bg-secondary rounded-3 " :class="{showwaitmask:showWaitMask}">
    <div class="row justify-content-md-center">
      <div class="col-12 col-xl-10 ">
        <!--
        <div class="d-flex  bg-white rounded-2 overflow-hidden mt-1">
          <div v-for="city in cities" class="d-none d-md-block" style="min-width:125px">
            <a href="#"  class="btn btn-sm text-primary text-nowrap">
              {{city.name}}
              <! --
              <span v-if="city.trend>=0" class="trendup"><i  class="bi bi-caret-up-fill "></i>{{Math.abs(city.trend)}}%</span>
              <span v-else class="trenddown"><i  class="bi bi-caret-down-fill "></i>{{Math.abs(city.trend)}}%</span>
              -- >
            </a>
          </div>
        </div>
        -->
        <div class="col-12 d-inline-flex align-items-center justify-content-center my-2 px-2">
          <h1 class="h3 mb-0">
           
            Winter Energy Calculator for
            <a href="#" class="dropdown-toggle" id="locationDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{selectedLocation.location}} <i class="bi bi-pencil"></i></a>
            <ul class="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
              <li><div class="fw-bold mt-3">Enter address or partial address:</div></li>
              <li> 
                <div class="d-flex align-items-center p-1">
                  <input v-model="newLocation" v-on:keyup.enter="setLocation()" class="form-control" type="text" placeholder="Enter a location">
                  <button @click="setLocation()" type="button" class="btn btn-sm btn-translucent-primary "> <i class="bi-search"></i></button>
                </div>
                <a class="ps-2 pt-0" href="#" @click.prevent.stop="findMyLocation()"><i class="bi bi-broadcast-pin"></i> Use your location</a>
              </li>
              <template v-if="hasLocationsHistory">
                <li><div class="fw-bold mt-3">History:</div></li>
                <li v-for="(loc,index) in locationsHistory"  @click="setLocation(loc)"><a class="dropdown-item" href="#" >{{loc.location}}</a></li>
              </template>
            </ul>

              
          </h1> 
          
          <div class="ms-auto">
            <div class="btn-group" role="group" aria-label="Solid button group">
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:queryDefn.unitGroup==='us'}" @click="setUnitGroup('us')">F</button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:queryDefn.unitGroup==='metric'}" @click="setUnitGroup('metric')">C</button>
            </div>
          </div>
        </div>
              
        <h2 class="h6">Learn how winter temperatures and high energy prices are driving heating costs</h2>
         

        <div class="position-relative overflow-auto" >
          <div v-if="isLoading" class="position-absolute top-0 end-0 m-2 spinner-border text-primary" role="status"  style="z-index:101"></div>
          <div class="position-absolute d-none d-md-block w-100 h-100"  style="z-index:10">
              <div class="weatherMap" > </div>
          </div>
          
          <div class="row justify-content-md-center mx-0 position-relative pe-none mb-3" style="z-index:100">

            <div class="col-12 col-md-10  col-lg-3 px-2 py-2">
              <div class="d-flex h-100 flex-column  align-items-center bg-white rounded-2  px-2 py-2 pe-auto">
                <div class="h6 mb-1 text-muted text-center w-100">
                  <i class="bi bi-thermometer-low"></i> 
                  Winter Heating
                  <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Winter Trend" data-bs-content="Shows how the currently selected winter heating requirements compares with the previous winter">
                    <i class="bi bi-question-circle" ></i>
                  </a>
                </div>
                  <div class="fs-2 text-nowrap waitmask" :class="{warmer:trend<0, cooler:trend>0}">
                    {{trendText}}
                  </div>
                  
                  <div class="fs-6 text-muted mt-auto waitmask">
                    <template v-if="trend>0">
                      <i class="bi bi-caret-down-fill"></i>
                      {{Math.abs(trend)}}% colder
                    </template>
                    <template v-else-if="trend<0">
                      <i class="bi bi-caret-up-fill"></i>
                      {{Math.abs(trend)}}% warmer
                    </template>
                  </div>
                  <div class="fs-xxs text-muted">{{currentSeasonLabel}} vs {{compareToLabel}}</div>
              </div>
            </div>

            <div  class="col-12 col-md-6  col-lg-3 px-2 py-2">
              <div class="d-flex h-100 flex-column  align-items-center bg-white rounded-2  px-2 py-2 pe-auto">
                <div class="h6 mb-1 text-muted text-center w-100">
                  <i class="bi bi-lightning-fill"></i> 
                  Usage
                   <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Estimated usage" data-bs-content="Shows how the estimated heating energy usage compares with the previous winter">
                    <i class="bi bi-question-circle" ></i>
                  </a>
                  </div>
                
                    <div v-if="isNaN(estimatedUsageChange)"  class="fs-1 text-nowrap waitmask">
                      -
                    </div>
                    <div v-else-if="estimatedUsageChange>=0"  class="fs-1 text-nowrap worse waitmask">
                      <i class="bi bi-caret-up-fill"></i>{{formatElementValue(estimatedUsageChange, '%')}}
                    </div>
                    <div v-else  class="fs-1 text-nowrap better waitmask">
                      <i class="bi bi-caret-down-fill"></i>{{formatElementValue(estimatedUsageChange, '%')}}
                    </div>
                    <div class="fs-6 text-muted mt-auto  waitmask">{{format(estimatedUsage,0,true)}} vs {{format(estimatedNormalUsage,0,true)}} {{energyUsageUnitLabel}}</div>   
                   <div class="fs-xxs text-muted">{{currentSeasonLabel}} vs {{compareToLabel}}</div>
                </div>
              </div>


            <div  class="col-12 col-md-6  col-lg-3 px-2 py-2">
               <div class="d-flex h-100 flex-column  align-items-center bg-white rounded-2  px-2 py-2 pe-auto">
                <div class="h6 mb-1 text-muted text-center w-100">
                  <i class="bi bi-currency-dollar"></i> 
                  Cost
                   <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Estimated cost" data-bs-content="Shows how the estimated heating energy cost compares with the previous winter">
                    <i class="bi bi-question-circle" ></i>
                  </a>
                </div>
                  <div v-if="isNaN(estimatedCostChange)"  class="fs-1 text-nowrap waitmask">
                      -
                  </div>
                  <div v-else-if="estimatedCostChange>=0" class="fs-1 text-nowrap  worse waitmask">
                    <i class="bi bi-caret-up-fill"></i>
                    {{formatElementValue(estimatedCostChange, '%')}}
                  </div>
                  <div v-else  class="fs-1 text-nowrap better waitmask">
                    <i class="bi bi-caret-down-fill"></i>
                      {{formatElementValue(estimatedCostChange, '%')}}
                  </div>
                  <div class="fs-6 text-muted mt-auto  waitmask" v-html="formatCurrency(estimatedCost)+' vs '+formatCurrency(estimatedNormalCost)"></div>   
                  <div class="fs-xxs text-muted">{{currentSeasonLabel}} vs {{compareToLabel}}</div>
                <!--</div>-->
              </div>
            </div>

            <div class="col-12 px-4 py-2 pe-auto">
              <div class="row justify-content-center align-items-center bg-white rounded-2 py-2 fs-6">
                <div class="col-12 col-lg-10 d-flex justify-content-center align-items-center flex-fill flex-column flex-lg-row ">
                  <div class="d-flex">
                    <div class="me-1">Winter: </div>
                    <a href="#" class="dropdown-toggle" id="seasonDropDownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
                      {{season?season.label:'Select winter'}}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="seasonDropDownMenu">
                      <li v-for="u in seasons"  @click="season=u"><a class="dropdown-item" href="#">{{u.label}}</a></li>
                    </ul>
                  </div>

                  <div class="d-flex ms-3 me-1">
                    <div class="me-1">Model: </div>

                    <a href="#" class="dropdown-toggle" id="energyModelDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"> {{energyUsageLabel}}</a>
                    <ul class="dropdown-menu p-1" aria-labelledby="energyModelDropdownMenuButton">
                      <li v-for="(preset,index) in availableModels" @click="selectedPreset=preset"><a v-if="preset" class="dropdown-item" href="#">{{preset.label}}</a></li>
                      <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#energyParamsModal">Create your own model</a></li>
                    </ul>
                  </div>

                  <div class="d-flex ms-3 me-1">
                  <div class="me-1">Compare to: </div>
                  <a href="#" class="dropdown-toggle" id="compareTypeDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{compareToLabel}}</a>
                  <ul class="dropdown-menu p-1" aria-labelledby="compareTypeDropdownMenuButton">
                    <li><a class="dropdown-item" href="#" @click="compareType='normal'">Normal</a></li>
                    <li><a class="dropdown-item" href="#" @click="compareType='previous'">{{compareSeason?compareSeason.label:'Previous winter'}}</a></li>
                  </ul>
                  </div>
                </div>
   

                <div class="col-3 col-lg-2">
                  <a class="btn btn-icon btn-s rounded-circle" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.visualcrossing.com%2Fwinter-energy-calculator&t=Winter%20Energy%20Calculator" title="Share on Facebook"><i class="bi-facebook"></i></a>
                  <a class="btn btn-icon btn-s rounded-circle" target="_blank" href="http://twitter.com/share?text=Check out the Winter Energy Usage Dashboard&url=https%3A%2F%2Fwww.visualcrossing.com%2Fwinter-energy-calculator&hashtags=winter,energy,heatingdegreedays" title="Share on Twitter"><i class="bi-twitter"></i></a>
                  <a class="btn btn-icon btn-s rounded-circle" target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.visualcrossing.com%2Fwinter-energy-calculator" title="Share on LinkedIn"><i class="bi-linkedin"></i></a>

                </div>

                <div class="col-12 d-flex justify-content-center align-items-center bg-white rounded-2  px-2 fs-sm">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#energyParamsModal">
                    <i class="bi-person"></i> Create a usage model using your own usage</a>
                </div>

              </div>
            
            </div>
           


            <div class="col-12 mb-3 d-inline-flex">
              <div class="bg-white rounded-2 mx-md-3 px-3 py-1 w-100 w-md-50 pe-auto chartsidebar" >
                <div class="d-flex">
                  <span class="h6 mb-1 text-muted ">Cost by Month</span>
                  

                  <div class="form-check ms-auto fs-sm text-muted me-3">
                    <input class="form-check-input" type="radio" v-model="costChartView" value="cost" id="cost" />  <label class="form-check-label" for="cost">Cost</label>
                  </div>
                  <div class="form-check fs-sm text-muted ">
                    <input class="form-check-input" type="radio" v-model="costChartView" value="usage" id="usage" /><label class="form-check-label" for="usage">Usage</label>
                  </div>

                  <!--
                    <div class="form-check form-check-inline ms-auto fs-sm text-muted ">
                    <input type="checkbox"  id="showUsage" v-model="showUsage" class="form-check-input"   />
                    <label class="form-check-label" for="showUsage">Usage</label>
                  </div>
                  <div class="form-check form-check-inline fs-sm text-muted ">
                    <input type="checkbox"  id="showCost" v-model="showCost" class="form-check-input"   />
                    <label class="form-check-label" for="showCost">Cost</label>
                  </div>
                  
                  <div class="form-check form-check-inline fs-sm text-muted ">
                    <input type="checkbox"  id="showTemp" v-model="showTemp" class="form-check-input"   />
                    <label class="form-check-label" for="showTemp">Temperature</label>
                  </div>
                  -->
                </div>
                <div class="chart chart-temp">
                  <div class="tooltip">
                    <div class="contents">-</div>
                  </div>
                  <!--<div class="legend" ></div>-->
                  <svg class="w-100"></svg>
                  <div class="d-flex align-items-center fs-sm legend">
                    <div class="ms-auto lastPeriod" style="width:15px;height:15px;margin-right:3px">&nbsp;</div>
                   {{compareToLabel}}
                    
                    <div class="ms-2 swatch" style=";width:15px;height:15px;margin-right:3px">&nbsp;</div>
                   {{currentSeasonLabel}}
                  </div>
                </div>
                
                <div class="d-flex mt-3 ">
                  <span class="h6 mb-1 text-muted ">Heating Degree Days</span>
                  <div class="form-check form-check-inline ms-auto fs-sm text-muted ">
                    <input type="checkbox"  id="cumulativeHdd" v-model="cumulativeHdd" class="form-check-input"   />
                    <label class="form-check-label" for="cumulativeHdd">Cumulative</label>
                  </div>
                </div>
                <div class="chart chart-hdd">
                  <div class="bg-white rounded-2 mx-3">
                    <div class="tooltip">
                      <div class="contents">-</div>
                    </div>
                    <div class="legend" ></div>
                    <svg class="w-100"></svg>
                  </div>
                </div>
              </div>

              <div class="d-none d-md-flex position-relative border rounded-2 mx-3 px-3 py-1 w-md-50 chartsidebar">
                <div class="position-absolute position-relative bottom-0 start-0 p-2 w-100">
                  <div class="d-flex fw-bold ">
                     <div v-for="(bin,index) in hddtilebins" class="flex-fill" :style="{'background-color':bin.color}">&nbsp;</div>
                     <div class="position-absolute start-0 ms-3">Warm Winter</div>
                     <div class="position-absolute end-0 me-3 ">Cold Winter</div>
                  </div>
                </div>
              </div>
            </div>

<!--
            <div class="col-12 mb-3">
              <div class="bg-white rounded-2 mx-3 px-3 py-1">
                <div class="d-flex">
                  <span class="h6 mb-1 text-muted ">Heating Degree Days by Day</span>
                  <div class="form-check form-check-inline ms-auto fs-sm text-muted ">
                    <input type="checkbox"  id="cumulativeHdd" v-model="cumulativeHdd" class="form-check-input"   />
                    <label class="form-check-label" for="cumulativeHdd">Cumulative</label>
                  </div>
                </div>
                <div class="chart chart-hdd">
                  <div class="bg-white rounded-2 mx-3">
                    <div class="tooltip">
                      <div class="contents">-</div>
                    </div>
                    <div class="legend" ></div>
                    <svg class="w-100 h-100"></svg>
                  </div>
                </div>
              </div>
            </div>
-->
          <!--
            <div class="col-12 col-md-12 mb-3">
              <div class="bg-white rounded-2 mx-3 px-3 py-1">
                <div class="h6 mb-1 text-muted">Global Map of Heating Degree Day</div>
                <div class="weatherMap" > </div>
              </div>
            </div>
          --> 
            <!--<div class="row mb-3">-->
              <div  class="col-12 col-md-6 col-lg-3 mb-3">
                <div class="d-flex flex-column  align-items-center bg-white rounded-2  px-2 py-2 pe-auto">
                  <div class="h6 mb-1 text-center text-muted  w-100">
                    Heating Degree Days
                    <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Total Heating Degree Days" data-bs-content="Number of Heating Degree Days in the currently selected winter compared with the previous winter">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
                
                  <div  class="fs-1 waitmask">{{formatElementValue(kpis.totalHeatingDays)}}</div>
                  <div class="fs-xs text-muted"> {{currentSeasonLabel}}</div>
                  <div class="fs-6 waitmask">{{formatElementValue(compareKpis.totalHeatingDays)}}</div>   
                  <div class="fs-xxs text-muted">{{compareToLabel}}</div>
                </div>
              </div>

              <div  class="col-12 col-md-6 col-lg-3 mb-3">
                <div class="d-flex flex-column  align-items-center bg-white rounded-2  px-2 py-2 pe-auto">
                  <div class="h6 mb-1 text-center text-muted  w-100">
                    Average Temp
                     <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Average Temperature" data-bs-content="Average temperature during the currently selected winter compared with the previous winter">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
                
                  <div  class="fs-1 waitmask">{{formatElementValue(kpis.meanTemp,'temp')}}</div>
                  <div class="fs-xs text-muted"> {{currentSeasonLabel}}</div>
                  <div class="fs-6 waitmask" >{{formatElementValue(compareKpis.meanTemp,'temp')}}</div>   
                  <div class="fs-xxs text-muted">{{compareToLabel}}</div>
                </div>
              </div>

              <div  class="col-12 col-md-6 col-lg-3 mb-3">
                <div class="d-flex flex-column  align-items-center bg-white rounded-2  px-2 py-2  pe-auto">
                 <div class="h6 mb-1 text-center text-muted  w-100">
                  Average Low Temp
                   <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Average Low Temperature" data-bs-content="Average low temperature during the currently selected winter compared with the previous winter">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
                
                  <div  class="fs-1 waitmask">{{formatElementValue(kpis.meanLow, 'temp')}}</div>
                  <div class="fs-xs text-muted"> {{currentSeasonLabel}}</div>
                  <div class="fs-6 waitmask">{{formatElementValue(compareKpis.meanLow, 'temp')}}</div>   
                  <div class="fs-xxs text-muted">{{compareToLabel}}</div>
                </div>
              </div>

              <div  class="col-12 col-md-6 col-lg-3 mb-3">
                <div class="d-flex flex-column  align-items-center bg-white rounded-2  px-2 py-2  pe-auto">
                  <div class="h6 mb-1 text-center text-muted  w-100">
                    Lowest Temp
                     <a href="#" class="text-muted fs-xs float-end" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Lowest Temperature" data-bs-content="The coldest temperature seen during the currently selected winter compared with the previous winter">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
                
                  <div  class="fs-1 waitmask">{{formatElementValue(kpis.minLow, 'temp')}}</div>
                  <div class="fs-xs text-muted"> {{currentSeasonLabel}}</div>
                  <div class="fs-6 waitmask">{{formatElementValue(compareKpis.minLow, 'temp')}}</div>   
                  <div class="fs-xxs text-muted">{{compareToLabel}}</div>
                </div>
              </div>
            <!--</div>-->
          </div>

        </div>
<!--
        <div class="mt-3">
          <h3>How to use the energy tracker</h3>
          <p>
            Aliquet nec ullamcorper sit amet risus nullam eget felis eget. Donec ac odio tempor orci. Integer feugiat scelerisque varius morbi enim nunc faucibus. 
            Ante metus dictum at tempor commodo ullamcorper a lacus vestibulum. Aliquam sem et tortor consequat id porta. Enim nec dui nunc mattis enim ut tellus. Nec sagittis aliquam malesuada bibendum arcu vitae elementum. 
            Mauris a diam maecenas sed enim ut sem. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Eget sit amet tellus cras adipiscing enim.
          </p>
          <p>
          </p> 
        </div>
-->
       
      </div>
    </div>

    <RegressionEditor :queryDefn="queryDefn" :selectedLocation="selectedLocation" :energyusagein="energyusage" @change:energyusage="updateUsageModel" />
    <!--
    <div class="modal fade" id="energyParamsModal" tabindex="-1" aria-labelledby="energyParamsModalLabel" aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl" >
        <div class="modal-content"  style="height:80vh;">
            <div class="modal-header">
              <h5 class="modal-title" id="energyParamsModalLabel">Energy Model Parameters</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div class="modal-body">
              <template v-if="modelView==='simple'">
                <p>
                  The Winter Energy Calculator is built using models to estimate the energy needed to heat a house. 
                  The calculator uses historical weather data to calculate the Heating Degree Days that a location experiences based on the daily average temperatures.
                  The energy usage model calculates the amount energy as a function of the heating degrees days. 
                </p>
               
                <h4>Create your own usage model</h4>
                <p>
                  If you have a record of your recent usage, you can teach the calculator to calculate your own person energy model.
                  Simply upload a your energy usage and we will calculate how your energy usage varies with your heating needs.
                  To get started, download your energy usage from your energy provider or your smart meter and we will guide you through the process. 
                </p>
                <p>
                  Once you are complete, you can choose to share your model with us so we can add your model to our system and help all our users.
                </p>
                <div>
                  <button type="button" class="btn btn-primary  ms-auto " @click="createCustomModel()">
                      <i class="bi bi-clipboard"></i> Get started building your model
                  </button>
                </div>
              </template>
              <template v-if="modelView==='custom'" class="row">
                <RegressionEditor :queryDefn="queryDefn" :selectedLocation="selectedLocation" :energyusage="energyusage" @change:energyusage="modelView='simple'" @cancel="modelView='simple'" class="col-12" />
              </template>
             
            </div>
          </div>
        </div>
      </div>
      -->

    <CommonModals />
  </div>
</template>

<script>
//import {VcStatistics} from "/../visualcrossing-core-js/src/vcstatistics";
import {VcLocationsStorage} from "/../visualcrossing-core-js/src/vclocationsstorage";
//import "/../visualcrossing-core-js/src/vccommunicator_serveradmin";
import CommonModals from '/src/components/CommonModals'
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
import DateRangeChooser from '/src/components/DateRangeChooser';
import {VcConversion} from "/../visualcrossing-core-js/src/vcconversion";
import {VcFindMyLocation} from '/../visualcrossing-core-js/src/vcfindmylocation';
import RegressionEditor from './RegressionEditor';
import {WinterTrackerHelper} from "./winter-tracker-helper";
import {VcWidgetHelper} from '/../bootstrap-vue-app/src/pages/wxwidgets/common_widget.js'
//import data_2022 from './data/2022.json';
//import data_2021 from './data/2021.json';
//import data_2020 from './data/2020.json';

export default {
  name: 'wintertracker',
  props: {
    
  },
  data() {
    return {
      YEARS:[2022,2021,2020,2019],
      MONTHS:[1,2,3,4,5,6,7,8,9,10,11,12],
      
      hddtilebins:[{"min":0.0,"max":381.0,"color":"#F98B66"},{"min":381.0,"max":761.9,"color":"#F29359"},{"min":761.9,"max":1142.9,"color":"#E89B4D"},{"min":1142.9,"max":1523.8,"color":"#DBA346"},{"min":1523.8,"max":1904.8,"color":"#CBAC43"},{"min":1904.8,"max":2285.7,"color":"#BAB347"},{"min":2285.7,"max":2666.7,"color":"#A7BA4F"},{"min":2666.7,"max":3047.6,"color":"#93C05D"},{"min":3047.6,"max":3428.6,"color":"#7FC56D"},{"min":3428.6,"max":3809.5,"color":"#69C980"},{"min":3809.5,"max":4190.5,"color":"#52CC95"},{"min":4190.5,"max":4571.4,"color":"#3CCEAA"},{"min":4571.4,"max":4952.4,"color":"#2BCEBE"},{"min":4952.4,"max":5333.3,"color":"#2DCED1"},{"min":5333.3,"max":5714.3,"color":"#44CDE1"},{"min":5714.3,"max":6095.2,"color":"#63CAEE"},{"min":6095.2,"max":6476.2,"color":"#83C6F6"},{"min":6476.2,"max":6857.1,"color":"#A4C0FA"},{"min":6857.1,"max":7238.1,"color":"#C3BAF9"},{"min":7238.1,"max":7619.0,"color":"#DFB3F3"},{"min":7619.0,"max":8000.0,"color":"#F8ABE8"}],
      selectedPreset:null,

      leafletMap:null,
      marker:null,
      sessionId:null,
      sessionState:null,
      errorStatus:null,
      isLoading:false,
      selectedLocation:{
          location:null,
          name:null,
        },
      //viewElement:null,
      basePeriodLabel:null,
      newLocation:null,
      newEnergyValue:null,
      locationsHistory:null,
      //showAllColumns:false,
      isNorthernHemisphere:true,
      season:null,
      //compareSeason:null,
      seasons:[],
      compareType:"previous",
      cities:[
        { 
          name:"London,UK",
          trend: "3"
        },
         { 
          name:"Paris, France",
          trend: "5"
        },
        { 
          name:"Oslo, Norway",
          trend: "-4"
        },
        { 
          name:"Rome,Italy",
          trend: "8"
        },
        { 
          name:"Berlin, Germany",
          trend: "1"
        },
       
        { 
          name:"Kyiv, Ukraine",
          trend: "-1"
        },
         { 
          name:"Washington, DC",
          trend: "-1"
        },
      ],
      
      energyusage:{
        label:"-",
        country:"-",
        unit:"-",
        m:3.5,
        c:-1100,
        baseUsage:12,
        cost:0.10330,
        balancePointTemp:0,
        isPreset:true,
      },  
     
      queryDefn:{
        startdate:null,
        enddate:null,
        include:"days,obs,fcst,stats",
        options:"betanormals",
        elements:"datetime,datetimeEpoch,temp,tempmax,tempmin,degreedays,accdegreedays",
        unitGroup:"us",
        degreeDayInverse:true,
        degreeDayTempBase:65,
      },
      queryData:null,
      compareQueryData:null,
      cumulativeHdd:false,
      costChartView:"cost",
      showUsage:false,
      showCost:false,
      showTemp:false,
    }
  },
  methods:{

    updateUsageModel(newModel) {
      let me=this;
      me.energyusage=newModel;
      if (newModel && !newModel.isPreset) {
        VcLocationsStorage.setValue("winterenergy", newModel);
      }
    },
    kwh2UiUnits(value) {
      let me=this;
      return WinterTrackerHelper.kWh2unit(value, me.energyusage.unit)
    },
    
   /*
    setEnergyUsage(energyusage) {
      let me=this;
      me.energyusage=energyusage;
    },*/
    formatEpochTime(epochTime) {
      return VcDateUtils.formatDateTime2(epochTime);
    },
    formatAny(value, element) {
      let me=this;
      if (element==="currency") {
        return me.formatCurrency(value);
      } else if (element==="usage") {
        return me.format(value,0,true)+ " "+me.energyusage.unit;
      }
      return me.formatElementValue(value, element);
    },
    formatCurrency(value) {
      let me=this;

      value=me.format(value, 0, true); //value<10?2:0
      if (!me.energyusage || !me.energyusage.currency) return value;
      return WinterTrackerHelper.currency2HtmlSymbol(me.energyusage.currency)+value;
    },
    format(value,decimals,separators) {
      decimals=(decimals>=1)?decimals:0;
      if (!Number.isFinite(value) || value===Number.MIN_VALUE || value===Number.MAX_VALUE) return "-";

      if (separators) {
        return value.toLocaleString('en-US', {maximumFractionDigits:decimals});
      }
      return value.toFixed(decimals);
    },
    formatElementValue(value, element) {
        var me=this;
        if (!element) {
          return me.format(value, 0, false);
        }
        return VcConversion.formatValue(me.queryDefn.unitGroup, element, value, true);
        
    },
    refreshData(comparePeriod) {
      let me=this;

      if (!me.selectedLocation || !me.selectedLocation.location) return;
      if (!me.season) return;

      if (!comparePeriod) {
        me.queryData=null;
        me.compareQueryData=null;
        me.refreshChart();
      }
      let startdate,enddate;
      //let season=me.season;
      if (comparePeriod) {
        
        //season=comparePeriod;
        startdate=comparePeriod.startdate;
        enddate=comparePeriod.enddate;
        //enddate=new Date(me.season.enddate.getFullYear()-1, me.season.enddate.getMonth(), me.season.enddate.getDate());
      } else {
        startdate=me.season.startdate;
        enddate=me.season.enddate;
      }
     
      me.queryDefn.startdate=VcDateUtils.formatDateIso(startdate);

      me.queryDefn.enddate=VcDateUtils.formatDateIso(enddate);

      me.queryDefn.key="H5PTTMBME9TW3AW9LF3JQ7LHJ";
      let queryDefn=JSON.parse(JSON.stringify(me.queryDefn));
      //me.queryData=null;
      me.isLoading=true;
      vcCommunicator.retrieveTimelineData(queryDefn, me.selectedLocation.location, null, "json", true,false)
        .then(response => {
           me.isLoading=false;
          me.processTimelineData(response,me, comparePeriod);
         
        })
        //.catch((error) => {
        //  me.errorMessage=error;
         // console.trace();
          //console.log("Error:"+error);
      //});
    },
    processTimelineData(response, me, comparePeriod) {
      

      if (comparePeriod) {
        me.compareQueryData=response;
        me.refreshSeasons(response);
        me.calculateKpis(response, me.queryData);
        me.refreshChart();
        // me.refreshMap(response);
      } else {
        me.isNorthernHemisphere=response.latitude>=0;
        if (!me.energyusage || me.energyusage.isPreset) {
          me.energyusage=WinterTrackerHelper.guessModelFromLatLon(response.latitude, response.longitude, WinterTrackerHelper.PRESETMODELS);
        }
        me.queryData=response;
        if (me.compareSeason) {
          me.refreshData(me.compareSeason);
        } else {
         
          me.convertNormalsToComparisonData(response);
        }
        me.refreshSeasons(response);
        me.calculateKpis(response);
        me.refreshChart();
        me.refreshMap(response);
      }

    },
    setDateRange(defn) {
      let me=this;
      console.log("Set date range:"+defn.startdate +"=> "+defn.enddate)
      me.queryDefn.startdate=defn.startdate;
      me.queryDefn.enddate=defn.enddate;
      me.refreshData();
    },
   findMyLocation(noGps) {
      var me=this;
      var fml=VcFindMyLocation.findMyLocation(noGps?false:true);

      fml.then(location=> {
          
        me.newLocation=location;
        me.setLocation();
      })
      .catch(error=>{
        console.log("Could not find location..."+error)
      });

    },
    setLocation(newLocation) {
      
      var me=this;
      if (newLocation) {
        me.selectedLocation=newLocation;
      } else {
        me.selectedLocation={
          location:me.newLocation,
          name:null,
        };
      }
      
      me.newLocation=null;
      me.saveLocation(me.selectedLocation);
      me.refreshData();
    },
    saveLocation(newLocation) {
      var me=this;
      VcLocationsStorage.addLocation(newLocation);
      me.refreshLocationsHistory();
    },
    setUnitGroup(unitGroup) {
      var me=this;
      me.queryDefn.unitGroup=unitGroup
      if (unitGroup==="us") {
        me.queryDefn.degreeDayTempBase=65;
      } else {
        me.queryDefn.degreeDayTempBase=18;
      }
      me.refreshData();
    },
    hasLocationsHistory() {
      return this.locationsHistory && this.locationsHistory.length;
    },
    refreshLocationsHistory() {
      this.locationsHistory=VcLocationsStorage.getLocations();
    },
    
    resetDateRange() {
      var me=this;
      me.season=me.seasons && me.seasons[0];
     
      /*
      var me=this;
      var now=new Date();
      var year=now.getFullYear();
      var month=now.getMonth();
      var date=now.getDate();
      var startYear=year;
      if (month<9) {
        startYear--;
      }

      var startDate=new Date(startYear,8,1);

      if (month>4 || (month===4 && date>1)) {
        month=4;
        date=1;
      } else {
        date+=7
      }
      var endDate=new Date(year,month,date);

      me.queryDefn.startdate=VcDateUtils.formatDateIso(startDate);
      me.queryDefn.enddate=VcDateUtils.formatDateIso(endDate);
      me.refreshData();
      */

    },
    convertNormalsToComparisonData(queryData) {
       let me=this;
       if (!queryData || !queryData.days) return [];
      let normalQueryData={
        days:[],
        kpis:{},
      }
      let accdegreedays=0;
      queryData.days.forEach(function(day, i) {
        let degreedays=Math.max(0, me.queryDefn.degreeDayTempBase-(day.normal.tempmax[1]+day.normal.tempmin[1])/2);
        accdegreedays+=degreedays;
        let newDay={
          datetime:day.datetime,
          datetimeEpoch:day.datetimeEpoch,
          tempmax: day.normal.tempmax[2],
          tempmin: day.normal.tempmin[0],
          temp:(day.normal.tempmax[1]+day.normal.tempmin[1])/2,
          degreedays:degreedays,
          accdegreedays:accdegreedays,
        }
        
        normalQueryData.days.push(newDay);
      });
     
      me.calculateKpis(normalQueryData);
      me.compareQueryData=normalQueryData;
    },
    calculateKpis(queryData, masterQueryData) {
      let me=this;
      if (!queryData || !queryData.days) return [];
      let dateTimeFilter=0;
      if (masterQueryData) {
        dateTimeFilter=masterQueryData.days[masterQueryData.days.length-1].datetimeEpoch-365*24*60*60;
      }
      WinterTrackerHelper.calculateMonthlyAggregates(queryData, me.queryDefn.unitGroup, me.energyusage,false, dateTimeFilter);


      let maxHigh=-Infinity, minLow=Infinity, sumLow=0, sumTemp=0; 

      let count=0; //, countNormal=0;

      let hddfirstDateTime=-1;
      let hddlastDateTime=0;


      queryData.days.forEach(function(day, i) {
        maxHigh=Math.max(maxHigh,day.tempmax);
        minLow=Math.min(minLow,day.tempmin);
        //sumHigh+=day.tempmax;
        sumLow+=day.tempmin;
        sumTemp+=day.temp;
        //sumDegreeDays+=day.degreedays;
        
        if (day.degreedays>me.heatingThreshold) {
          if (hddfirstDateTime===-1) {
            hddfirstDateTime=day.datetimeEpoch;
          }
          hddlastDateTime=day.datetimeEpoch;
        }

        count++;
      });

      
      var kpis={
        totalHeatingDays:0,
        totalHeatingUsage:0,
        totalHeatingCost:0
      };
      queryData.kpis=kpis;

      if (hddfirstDateTime>0 && hddlastDateTime>0) {
         kpis.hdddays=(hddlastDateTime-hddfirstDateTime)/86400;
      } else {
         kpis.hdddays=NaN;
      }

      kpis.hddstart=hddfirstDateTime>0?hddfirstDateTime:null;
      kpis.hddend=hddlastDateTime>0?hddlastDateTime:null;

      //kpis.totalHeatingDays=sumDegreeDays;


      kpis.minLow=minLow;
     

      kpis.meanTemp=sumTemp/count;
    

      kpis.meanLow=sumLow/count;
      

      Object.values(queryData.months).forEach(d=>{
        kpis.totalHeatingDays+=d.hdd;
        kpis.totalHeatingUsage+=d.usage;
        kpis.totalHeatingCost+=d.cost;
      });

    },


    refreshMap(queryData) {
      let me=this;
      if (!queryData ||!me.leafletMap) return;
      let lat=queryData.latitude;
      let lon=queryData.longitude;
      if (me.marker) {
        me.marker.unbindTooltip();
        me.marker.remove();
      }
      me.marker = L.marker([lat, lon],{}).addTo(me.leafletMap);
      me.marker.bindTooltip(queryData.resolvedAddress)
      //me.leafletMap.flyTo([lat, lon], 4, { 'paddingTopLeft': [800, 10]});
      const offsetX = document.querySelector('.chartsidebar').offsetWidth; 
      const offsetY = document.querySelector('.chartsidebar').offsetTop; 
      me.leafletMap.flyToBounds(new L.LatLngBounds(new L.LatLng(lat, lon), new L.LatLng(lat, lon)), { maxZoom:4, 'paddingTopLeft': [offsetX+10, offsetY/2]});
    },
    refreshChart() {
      let me=this;

      me.refreshHddChart();
      //me.refreshTempChart();
      me.refreshCostChart();
    },
    refreshHddChart() {
      let me=this;
      let queryData=me.queryData;
      
      if (!queryData) return;
      let compareQueryData=me.compareQueryData || { days:[], months:[]};


      let cumulativeHdd=me.cumulativeHdd;
      var svg = d3.select(".chart-hdd").select("svg");
        
      if (!svg || !svg.node()) return;

      var rect=svg.node().getBoundingClientRect();
  
      let days=queryData.days;
      let compareDays=compareQueryData && compareQueryData.days;
    
      let compareEpochOffset=0;
      if (compareDays && compareDays.length) {
        compareEpochOffset=days[0].datetimeEpoch-compareDays[0].datetimeEpoch;
      } else {
        compareDays=[];
      }
      let margin = {
          top: 10,
          right: 40,
          bottom: 20,
          left: 40
        },
        width = rect.width - margin.left - margin.right,
        height =rect.height - margin.top - margin.bottom;
      


      
/*
      let tempMin=d3.min(days, function(d) {return d3.min([d.tempmin, d.normal && d.normal.tempmin[0]]);});
      let tempMax=d3.max(days, function(d) {return d3.max([d.tempmax, d.normal && d.normal.tempmax[2]]);});
      
      let y1 = d3.scaleLinear()
         .range([height, 0])
         .domain(accdegreedaysextent);
*/

      /*
      let dateTimeExtent=d3.extent(days, function(d) {
        return d.datetimeEpoch*1000;
      });
      */

      let dateTimeExtent=[days[0].datetimeEpoch*1000, d3.max([days[days.length-1].datetimeEpoch*1000, compareDays.length && ((compareDays[compareDays.length-1].datetimeEpoch+compareEpochOffset)*1000)])];
      if (dateTimeExtent[1]-dateTimeExtent[0]<90*86400000) {
        dateTimeExtent[1]=dateTimeExtent[0]+90*86400000;
      }
     
      let bandwidth=width/((dateTimeExtent[1]-dateTimeExtent[0])/86400000);
      let buffer=Math.floor(bandwidth*0.5);
      

      //time axis
      let x = d3.scaleTime().range([0, width])
        .domain(dateTimeExtent);
    

      let y = d3.scaleLinear()
         .range([height, 0])
         .domain(d3.extent(days, function(d) {return d.temp;})).nice();

      let yddbasetemp=y(me.queryDefn.degreeDayTempBase);

      let y1 = d3.scaleLinear();
      if (cumulativeHdd) {
        let accdegreedaysmax=d3.max([0, days[days.length-1].accdegreedays, compareDays && compareDays[compareDays.length-1] && compareDays[compareDays.length-1].accdegreedays]);
      
        y1.range([height, 0])
          .domain([0, accdegreedaysmax]).nice();
      } else {
        y1.range([height, yddbasetemp])
          .domain([d3.max(days, function(d) {return d.degreedays;}),0]).nice();
      }
     


      let g;
      if (svg.select("g").empty())  {
          g = svg.append("g")
            .attr("clip-path","url(#chart-hdd)")
            .attr("class", "chartcontainer");
          g=g.append("g")
            .attr("class", "chart");
            
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
          
          svg.append("g")			
            .attr("class", "time-axis")
          svg
            .append("g")
            .attr("class", "y-axis")

          
          g.append("text")
            .attr("class", "y-axis-label")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("x", 5)
            .attr("y", margin.top)
            .html("Temp")

          g.append("text")
            .attr("class", "y1-axis-label")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("x", width-25)
            .attr("y", height-5)
            .html("HDD")

          svg
            .append("g")
            .attr("class", "y1-axis")

          svg.append("clipPath")
            .attr("id", "chart-hdd")
            .append("rect")
              .attr("class", "clippath");
        } else {
          g=svg.select(".chart");
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        }


        svg.select(".y-axis-label")
          .html(`Temp (${ VcConversion.getElementSymbol(me.queryDefn.unitGroup, "temp")})`);
         

          
        svg.select(".y1-axis-label")
           .attr("y", cumulativeHdd?margin.top:height-5);

        svg.select(".clippath")
            .attr("x",margin.left)
            .attr("y", margin.top)
            .attr("height",height)
            .attr("width",width)

        let u;

        if (cumulativeHdd) {

          
          let hddareaCompare = d3.area()
            .x(d => x((d.datetimeEpoch+compareEpochOffset)*1000)+buffer)
            .y0(d => y1(0))
            .y1(d => y1(d.accdegreedays))
            .curve(d3.curveMonotoneX);

          
          u=g.selectAll(".hddarea.lastPeriod")
            .data([compareDays]);
          
          u.enter()
            .append("path")
            .attr("class", "hddarea lastPeriod") 
            .merge(u)
            .datum(compareDays) 
            .attr("d", hddareaCompare); 



          let hddarea = d3.area()
            .x(d => x(d.datetimeEpoch*1000)+buffer)
            .y0(y1(0))
            .y1(d => y1(d.accdegreedays))
            .curve(d3.curveMonotoneX);


          u=g.selectAll(".hddarea.currentPeriod")
            .data([days]);
          
          u.enter()
            .append("path")
            .attr("class", "hddarea currentPeriod") 
            .merge(u)
            .datum(days) 
            .attr("d", hddarea); 


          g.selectAll(".warmarea").remove();
          g.selectAll(".hddbase").remove();
          g.selectAll(".hddbaselabel").remove();
        } else {
          let hddareaCompare = d3.area()
            .defined(function(d) { return d.temp<me.queryDefn.degreeDayTempBase;})
            .x(d => x((d.datetimeEpoch+compareEpochOffset)*1000)+buffer)
            .y0(d => y(d3.min([me.queryDefn.degreeDayTempBase, d.temp])))
            .y1(d => y(me.queryDefn.degreeDayTempBase))
            .curve(d3.curveMonotoneX);


          u=g.selectAll(".hddarea.lastPeriod")
            .data([compareDays]);
          
          u.enter()
            .append("path")
            .attr("class", "hddarea lastPeriod") 
            .merge(u)
            .datum(compareDays) 
            .attr("d", hddareaCompare); 



          let hddarea = d3.area()
            .defined(function(d) { return d.temp<me.queryDefn.degreeDayTempBase;})
            .x(d => x(d.datetimeEpoch*1000)+buffer)
            .y0(d => y(d3.min([me.queryDefn.degreeDayTempBase, d.temp])))
            .y1(d => y(me.queryDefn.degreeDayTempBase))
            .curve(d3.curveMonotoneX);


          u=g.selectAll(".hddarea.currentPeriod")
            .data([days]);
          
          u.enter()
            .append("path")
            .attr("class", "hddarea currentPeriod") 
            .merge(u)
            .datum(days) 
            .attr("d", hddarea); 

          let warmarea = d3.area()
            .defined(function(d) { return d.temp>=me.queryDefn.degreeDayTempBase;})
            .x(d => x(d.datetimeEpoch*1000)+buffer)
            .y0(d => y(me.queryDefn.degreeDayTempBase))
            .y1(d => y(d3.max([me.queryDefn.degreeDayTempBase, d.temp])))
            .curve(d3.curveMonotoneX);


          u=g.selectAll(".warmarea")
            .data([days]);
          
          u.enter()
            .append("path")
            .attr("class", "warmarea") 
            .merge(u)
            .datum(days) 
            .attr("d", warmarea); 


          u=g.selectAll(".hddbase")
            .data([me.queryDefn]);

          u.exit().remove();

          u.enter()
            .append("line")
              .attr("class", "hddbase")
            .merge(u)
              .attr("x1", function(d) { return 0; })
              .attr("y1", function(d) { return y(d.degreeDayTempBase); })
              .attr("x2", function(d) { return width; })
              .attr("y2", function(d) { return y(d.degreeDayTempBase); })

          u=g.selectAll(".hddbaselabel")
            .data(["No heating", "Heating"]);

          u.exit().remove();

          u.enter().append("text")
            .attr("class", "hddbaselabel")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
          .merge(u)
            .attr("x", 5)
            .attr("y", function(d,i) {return y(me.queryDefn.degreeDayTempBase)+i*15-5;})
            .html(function(d,i) {
              return d
            })

        }
       
      

        svg.select(".time-axis").attr("transform", "translate("+margin.left+","+(height+margin.top)+")")
            .call(d3.axisBottom(x).ticks(5)
               .tickFormat(function(d) {
                      return VcDateUtils.formatMonthShort(d);
                  })
            )

        svg.select(".y-axis").attr("transform", "translate("+margin.left+","+margin.top+")")
          .call(d3.axisLeft(y ).ticks(5));

        svg.select(".y1-axis").attr("transform", "translate("+(margin.left+width)+","+margin.top+")")
          .call(d3.axisRight(y1 ).ticks(5));

        let container = d3.select(".chart-hdd")
        let tooltip=container.select(".tooltip");
        me.setUpTooltip(svg,tooltip, function(event) {
          var x0 =(d3.pointer(event)[0]-margin.left);
          var y0 =(d3.pointer(event)[1]-margin.top);

          x0=Math.floor(x0);
          let datetime=x.invert(x0);
          let requiredDateTimeEpoch=3600;
          
          datetime.setHours(0);
          requiredDateTimeEpoch*=24;
          
          datetime.setMinutes(0, 0, 0);
          let dateTimeEpoch=datetime.getTime()/1000;
          let html="<div class='h6 text-nowrap'>"+VcDateUtils.formatDate(datetime)+"</div>";
        
          let day=days.reduce(function(a, d) {
            let dateTimeDelta=d.datetimeEpoch-dateTimeEpoch;

            return a || (dateTimeDelta>=0 && dateTimeDelta<requiredDateTimeEpoch && d);
          }, null);
        
          if (!day) return;


          html+=`<div class='text-nowrap'>Degree days: ${me.formatAny(day.degreedays)}</div>`;
          html+=`<div class='text-nowrap'>High: ${me.formatAny(day.tempmax, 'temp')}</div>`;
          html+=`<div class='text-nowrap'>Low: ${me.formatAny(day.tempmin, 'temp')}</div>`;
          html+=`<div class='text-nowrap'>Average: ${me.formatAny(day.temp, 'temp')}</div>`;

          y0=y0<height/2?height/2:0;
          me.showTooltip(x0+margin.left,y0, tooltip,html );

        
        });
    },
/*
    refreshTempChart() {
      let me=this;

      let queryData=me.queryData;
      
      if (!queryData) return;
      
      let compareQueryData=me.compareQueryData || { days:[], months:[]};
      let view=me.costChartView;

      //let showUsage=me.showUsage;
      //let showCost=me.showCost;
    
      var svg = d3.select(".chart-temp").select("svg");
        
      if (!svg || !svg.node()) return;

      var rect=svg.node().getBoundingClientRect();
  
      let monthIds=Object.keys(queryData.months);
      monthIds.sort((a,b)=>queryData.months[a].month-queryData.months[b].month);

      let months=[];

      monthIds.forEach(function(monthId) {
        months.push({
          month:queryData.months[monthId].month,
          thisPeriod:queryData.months[monthId],
          lastPeriod:compareQueryData.months[monthId]
        });
       
      });

      let periods=['lastPeriod' , 'thisPeriod'];

      let margin = {
          top: 10,
          right: 40,
          bottom: 20,
          left: 40
        },
        width = rect.width - margin.left - margin.right,
        height =rect.height - margin.top - margin.bottom;


      let x = d3.scaleBand().range ([0, width]).padding(0.4).domain(months.map(function(d) { return d.month; }));
      let x1 = d3.scaleBand().padding(0.05).domain(periods).rangeRound([0, x.bandwidth()]);

      let minTemp=Infinity, maxTemp=-Infinity, maxUsage=-Infinity, maxCost=-Infinity;;

      months.forEach(function(month) {
        minTemp=d3.min([minTemp, month['lastPeriod'] &&  month['lastPeriod'].temp,month['thisPeriod'] && month['thisPeriod'].temp]);
        maxTemp=d3.max([maxTemp, month['lastPeriod'] && month['lastPeriod'].temp,month['thisPeriod'] && month['thisPeriod'].temp]);
        maxUsage=d3.max([maxUsage,month['lastPeriod'] &&  month['lastPeriod'].usage,month['thisPeriod'] && month['thisPeriod'].usage]);
        maxCost=d3.max([maxCost, month['lastPeriod'] && month['lastPeriod'].cost,month['thisPeriod'] && month['thisPeriod'].cost]);
      });


      let y = d3.scaleLinear()
         .range([height, 0])
         .domain([minTemp, maxTemp]).nice();

      let y1 = d3.scaleLinear()
         .range([height, 0])
         .domain([0, maxUsage]).nice();

      let y2 = d3.scaleLinear()
         .range([height, 0])
         .domain([0, maxCost]).nice();

      let g, g1;
      if (svg.select("g").empty())  {
          let containerg = svg.append("g")
            .attr("clip-path","url(#chart-temp)")
            .attr("class", "chartcontainer");

          g=containerg.append("g")
            .attr("class", "chart");
            
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
          
          g1=containerg.append("g")
            .attr("class", "chart1");
            
          g1.attr("transform", "translate(" + margin.left + "," + margin.top + ")");


          svg.append("g")			
            .attr("class", "time-axis")
          svg
            .append("g")
            .attr("class", "y-axis")


          svg.append("clipPath")
            .attr("id", "chart-temp")
            .append("rect")
              .attr("class", "clippath");
        } else {
          g=svg.select(".chart");
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          g1=svg.select(".chart1");
          g1.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        }

        svg.select(".clippath")
            .attr("x",margin.left)
            .attr("y", margin.top)
            .attr("height",height)
            .attr("width",width)

        let u;


        u=g.selectAll(".tempbar")
         .data(months, d=> d.month);

        u.exit().remove();

        let u1=u.enter().append("g")
          .attr("class", "tempbar")
          .merge(u)
            .attr("transform", function(d) { return "translate(" + x(d.month) + ",0)"; })
              .selectAll("rect")
              .data(function(d) { 
                  return periods.map(function(period) { return {period: period, temp:d[period] && d[period].temp} }).filter(d=>d.period && d.temp); 
                })

        u1.enter()
          .append("rect")
          .merge(u1)
                  .classed("noheating", function(d) { return d.temp>=me.queryDefn.degreeDayTempBase })
                  .classed("lastPeriod", function(d) {return d.period==='lastPeriod'; })
                  .attr("x", function(d) { return x1(d.period); })
                  .attr("y", function(d) {
                     return y(d.temp); 
                    })
                  .attr("width", x1.bandwidth())
                  .attr("height", function(d) { return height - y(d.temp); });
          
        
        if (showUsage) {
          let usageLine=d3.line()
             // .defined(function(d) { return d['thisPeriod'].usage })
              .x(function(d) { return x(d.month)+x.bandwidth()/2 })
              .y(function(d) { return y1(d['thisPeriod'].usage) })
              .curve(d3.curveMonotoneX);
          
          u=g1.selectAll(".usageline")
            .data([months]);
            
          u.enter()
            .append("path")
            .attr("class", "usageline") 
            .merge(u)
            .datum(months) 
            .attr("d", usageLine); 
        } else {
          g1.selectAll(".usageline").remove();
        }

        if (showCost) {
          let costLine=d3.line()
              .x(function(d) { return x(d.month)+x.bandwidth()/2 })
              .y(function(d) { return y2(d['thisPeriod'].cost) })
              .curve(d3.curveMonotoneX);
          
          u=g1.selectAll(".costline")
            .data([months]);
            
          u.enter()
            .append("path")
            .attr("class", "costline") 
            .merge(u)
            .datum(months) 
            .attr("d", costLine); 
        } else {
          g1.selectAll(".costline").remove();
        }
        
        let monthId2Date=function(d) {
          let day=d%100;
          d=Math.floor(d/100);
          let month=d%100;

          let year= Math.floor(d/100);
          let date=new Date(year,month-1,day);
          return VcDateUtils.formatMonthShort(date);
        }
   
        svg.select(".time-axis").attr("transform", "translate("+margin.left+","+(height+margin.top)+")")
             .call(d3.axisBottom(x).ticks(5)
               .tickFormat(monthId2Date).ticks(6)
            );

        svg.select(".y-axis").attr("transform", "translate("+margin.left+","+margin.top+")")
          .call(d3.axisLeft(y ).ticks(6));

       

        let container = d3.select(".chart-temp")
        let tooltip=container.select(".tooltip");
        me.setUpTooltip(svg,tooltip, function(event) {
          let x0 =(d3.pointer(event)[0]-margin.left);
          let y0 =(d3.pointer(event)[1]-margin.top);

          x0=Math.floor(x0);

          let eachBand = x.step();
          let index = Math.floor((x0 / eachBand));

          let month=months[index];

          if (!month) return;
        
          let html="<div class='h6'>"+monthId2Date(month.month)+"</div>";
        
         
          
          html+=`<div class='text-nowrap'>Degree days: ${me.format(month['thisPeriod'].hdd)} vs ${me.format(month['lastPeriod'].hdd)} </div>`;
          html+=`<div class='text-nowrap'>High: ${me.format(month['thisPeriod'].tempmax)} vs ${me.format(month['lastPeriod'].tempmax)} </div>`;
          html+=`<div class='text-nowrap'>Low: ${me.format(month['thisPeriod'].tempmin)} vs ${me.format(month['lastPeriod'].tempmin)} </div>`;
          html+=`<div class='text-nowrap'>Average: ${me.format(month['thisPeriod'].temp)} vs ${me.format(month['lastPeriod'].temp)} </div>`;
          html+=`<div class='text-nowrap'>Usage: ${me.format(month['thisPeriod'].usage)} vs ${me.format(month['lastPeriod'].usage)} </div>`;
          html+=`<div class='text-nowrap'>Cost: ${me.formatCurrency(month['thisPeriod'].cost)} vs ${me.formatCurrency(month['lastPeriod'].cost)} </div>`;

          y0=y0<height/2?height/2:0;
          me.showTooltip(x0+margin.left,y0, tooltip,html );

        
        });
    },

*/
    refreshCostChart() {
      let me=this;

      let queryData=me.queryData;
      
      if (!queryData) return;
      
      let compareQueryData=me.compareQueryData || { days:[], months:[]};
      let view=me.costChartView;
      //let showUsage=me.showUsage;
      //let showCost=me.showCost;
      //let showTemp=me.showTemp;
      var svg = d3.select(".chart-temp").select("svg");
        
      if (!svg || !svg.node()) return;

      var rect=svg.node().getBoundingClientRect();
  
      let monthIds=Object.keys(queryData.months);
      let monthsList=queryData.months;
      let compareMonths=compareQueryData.allmonths || compareQueryData.months;
      let compareMonthIds=Object.keys(compareMonths);
      if (compareMonthIds && compareMonthIds.length>monthIds.length) {
        monthIds=compareMonthIds;
        monthsList=compareMonths;
       
      }
      monthIds.sort((a,b)=>monthsList[a].month-monthsList[b].month);

      let months=[];

      monthIds.forEach(function(monthId) {
        months.push({
          month:monthsList[monthId].month,
          thisPeriod:queryData.months[monthId],
          lastPeriod:compareMonths[monthId],

        });
       
      });

      let periods=['lastPeriod' , 'thisPeriod'];

      let margin = {
          top: 10,
          right: 40,
          bottom: 20,
          left: 40
        },
        width = rect.width - margin.left - margin.right,
        height =rect.height - margin.top - margin.bottom;


      let x = d3.scaleBand().range ([0, width]).padding(0.1).domain(months.map(function(d) { return d.month; }));
      let x1 = d3.scaleBand().padding(0.05).domain(periods).rangeRound([0, x.bandwidth()]);

      let minTemp=Infinity, maxTemp=-Infinity, maxUsage=-Infinity, maxCost=-Infinity;;

      months.forEach(function(month) {
        minTemp=d3.min([minTemp, month['lastPeriod'] &&  month['lastPeriod'].temp,month['thisPeriod'] && month['thisPeriod'].temp]);
        maxTemp=d3.max([maxTemp, month['lastPeriod'] && month['lastPeriod'].temp,month['thisPeriod'] && month['thisPeriod'].temp]);
        maxUsage=d3.max([maxUsage,month['lastPeriod'] &&  month['lastPeriod'].usage,month['thisPeriod'] && month['thisPeriod'].usage]);
        maxCost=d3.max([maxCost, month['lastPeriod'] && month['lastPeriod'].cost,month['thisPeriod'] && month['thisPeriod'].cost]);
      });

    
      let ytemp = d3.scaleLinear()
         .range([height, 0])
         .domain([minTemp, maxTemp]).nice();

      let yusage = d3.scaleLinear()
         .range([height, 0])
         .domain([0, maxUsage]).nice();

      let ycost = d3.scaleLinear()
         .range([height, 0])
         .domain([0, maxCost]).nice();

      let y=view==="cost"?ycost:view==="usage"?yusage:null;

      let g, g1;
      if (svg.select("g").empty())  {
          let containerg = svg.append("g")
            .attr("clip-path","url(#chart-temp)")
            .attr("class", "chartcontainer");

          g=containerg.append("g")
            .attr("class", "chart");
            
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
          
          g1=containerg.append("g")
            .attr("class", "chart1");
            
          g1.attr("transform", "translate(" + margin.left + "," + margin.top + ")");


          svg.append("g")			
            .attr("class", "time-axis")

          svg
            .append("g")
            .attr("class", "y-axis")


          g.append("text")
            .attr("class", "y-axis-label")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("x", 5)
            .attr("y", margin.top)
            .html("Cost")
          /*
           svg
            .append("g")
            .attr("class", "y2-axis")
            
            *//
          svg.append("clipPath")
            .attr("id", "chart-temp")
            .append("rect")
              .attr("class", "clippath");
        } else {
          g=svg.select(".chart");
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
/*
          g1=svg.select(".chart1");
          g1.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
          */
        }

        svg.select(".clippath")
            .attr("x",margin.left)
            .attr("y", margin.top)
            .attr("height",height)
            .attr("width",width)

        let u;

        if (view==="cost") {

          svg.select(".y-axis-label")
            .html(`Cost (${WinterTrackerHelper.currency2HtmlSymbol(me.energyusage.currency)})`);
        } else if (view==="usage") {
           svg.select(".y-axis-label")
            .html(`Usage (${me.energyusage.unit})`);
        }
        u=g.selectAll(".costbar")
         .data(months, d=> d.month);

        u.exit().remove();

        let u1=u.enter().append("g")
          .attr("class", "costbar")
          .merge(u)
            .attr("transform", function(d) { return "translate(" + x(d.month) + ",0)"; })
              .selectAll("rect")
              .data(function(d) { 
                  return periods.map(function(period) { 
                    return {
                      period: period, 
                      temp:d[period] && d[period].temp, 
                      cost:d[period] && d[period].cost,
                      usage:d[period] && d[period].usage,
                      ispartial:d[period] && d[period].ispartial,
                    } 
                  }); //.filter(d=>d.period && d.cost  && d.usage); ; 
                })

        u1.enter()
          .append("rect")
          .merge(u1)
                  //.classed("noheating", function(d) { return d.temp>=me.queryDefn.degreeDayTempBase })
                  .style("fill", view==="usage"?"#e6a91d":"#36b99f")
                  .style("stroke", view==="usage"?"#e6a91d":"#36b99f")
                  .classed("lastPeriod", function(d) {return d.period==='lastPeriod'; })
                  .classed("isPartial", function(d) {
                    return d.period==='thisPeriod' && d.ispartial; 
                    })
                  .attr("x", function(d) { return x1(d.period); })
                  .attr("y", function(d) {
                      let value=view==="cost"?d.cost:view==="usage"?d.usage:0;
                      if (isNaN(value)) return 0;
                      return y(value); 
                    })
                  .attr("width", x1.bandwidth())
                  .attr("height", function(d) { 
                    let value=view==="cost"?d.cost:view==="usage"?d.usage:0;
                    if (isNaN(value)) return 0;
                    return height - y(value); 
                  });
          
        d3.selectAll(".chart-temp .swatch").style("background-color", view==="usage"?"#e6a91d":"#36b99f")

        /*
        if (showUsage) {
          let usageLine=d3.line()
             // .defined(function(d) { return d['thisPeriod'].usage })
              .x(function(d) { return x(d.month)+x.bandwidth()/2 })
              .y(function(d) { return yusage(d['thisPeriod'].usage) })
              .curve(d3.curveMonotoneX);
          
          u=g1.selectAll(".usageline")
            .data([months]);
            
          u.enter()
            .append("path")
            .attr("class", "usageline") 
            .merge(u)
            .datum(months) 
            .attr("d", usageLine); 
        } else {
          g1.selectAll(".usageline").remove();
        }

        if (showCost) {
          let costLine=d3.line()
              .x(function(d) { return x(d.month)+x.bandwidth()/2 })
              .y(function(d) { return ycost(d['thisPeriod'].cost) })
              .curve(d3.curveMonotoneX);
          
          u=g1.selectAll(".costline")
            .data([months]);
            
          u.enter()
            .append("path")
            .attr("class", "costline") 
            .merge(u)
            .datum(months) 
            .attr("d", costLine); 
        } else {
          g1.selectAll(".costline").remove();
        }

        if (showTemp) {
          let tempLine=d3.line()
              .x(function(d) { return x(d.month)+x.bandwidth()/2 })
              .y(function(d) { return ytemp(d['thisPeriod'].temp) })
              .curve(d3.curveMonotoneX);
          
          u=g1.selectAll(".templine")
            .data([months]);
            
          u.enter()
            .append("path")
            .attr("class", "templine") 
            .merge(u)
            .datum(months) 
            .attr("d", tempLine); 
        } else {
          g1.selectAll(".templine").remove();
        }
        */
        let monthId2Date=function(d) {
          let day=d%100;
          d=Math.floor(d/100);
          let month=d%100;

          let year= Math.floor(d/100);
          let date=new Date(year,month-1,day);
          return VcDateUtils.formatMonthShort(date);
        }
        let comparisonHtml=me.comparisonHtml;
        svg.select(".time-axis").attr("transform", "translate("+margin.left+","+(height+margin.top)+")")
             .call(d3.axisBottom(x).ticks(5)
               .tickFormat(monthId2Date).ticks(6)
            );

        svg.select(".y-axis").attr("transform", "translate("+margin.left+","+margin.top+")")
          .call(d3.axisLeft(y ).ticks(6));
/*
        if (showTemp) {
          svg.select(".y2-axis").attr("transform", "translate("+(margin.left+width)+","+margin.top+")")
            .call(d3.axisRight(ytemp ).ticks(6));
        }
*/
        let container = d3.select(".chart-temp")
        let tooltip=container.select(".tooltip");
        me.setUpTooltip(svg,tooltip, function(event) {
          let x0 =(d3.pointer(event)[0]-margin.left);
          let y0 =(d3.pointer(event)[1]-margin.top);

          x0=Math.floor(x0);

          let eachBand = x.step();
          let index = Math.floor((x0 / eachBand));

          let month=months[index];

          if (!month) return;
        
          let html="<div class='h6'>"+monthId2Date(month.month)+"</div>";
        
          html+=`<div class='text-nowrap'>Cost: ${comparisonHtml(month, 'cost', 'currency')}  </div>`;
          html+=`<div class='text-nowrap'>Usage: ${comparisonHtml(month, 'usage', 'usage')}  </div>`;
          

          html+=`<div class='text-nowrap'>Degree days: ${comparisonHtml(month, 'hdd')} </div>`;
          html+=`<div class='text-nowrap'>High:  ${comparisonHtml(month, 'tempmax', 'temp')}  </div>`;
          html+=`<div class='text-nowrap'>Low:   ${comparisonHtml(month, 'tempmin', 'temp')}  </div>`;
          html+=`<div class='text-nowrap'>Average:   ${comparisonHtml(month, 'temp', 'temp')}  </div>`;


          y0=y0<height/2?height/2:0;
          me.showTooltip(x0+margin.left,y0, tooltip,html );

        
        });
    },
    comparisonHtml(month,property, element) {
      let me=this;
      return `${me.safeMonthValue(month, 'thisPeriod',property, element)} vs ${me.safeMonthValue(month, 'lastPeriod', property, element)}`;
    },
    safeMonthValue(month, period, property, element) {
      let me=this;
      if (!month || !month[period] || (!month[period][property] && month[period][property]!==0)) return "-";

      return me.formatAny(month[period][property], element);
    },
   
     setUpTooltip(svg, tooltip, showHandler) {
      var me=this;
     

      
      svg.on("mousemove", function(event) {
          showHandler(event);
        })
        .on("touchstart", function(event) {
          showHandler(event);
          event.preventDefault();
        })
       svg.on("mouseout", function() {
          me.hideTooltip(tooltip);
        })
        .on("touchend", function(event) {
          me.hideTooltip(tooltip);
        })
    },

    showTooltip(focusX, focusY, tooltip, html) {
        if (!html) html="";

       
       
        tooltip.style("visibility", "visible")
                .style("opacity", "1")
                .style("left", (focusX) + "px")
                .style("top",(focusY+5) + "px");
                       
         tooltip=tooltip.select(".contents")
         tooltip.html(html);    

         

        return tooltip;
    },

    hideTooltip(tooltip) {
      tooltip.style("visibility", "hidden")
    },
    refreshSeasons() {
      let me=this;
      let isNorthernHemisphere=me.isNorthernHemisphere;

      if (me.seasons && me.seasons[0] && me.seasons.isNorthernHemisphere===isNorthernHemisphere) {
        return;
      }
      
      
      let now=new Date();
      let year=now.getFullYear();
      let month=now.getMonth();
      let date=now.getDate();
      
      let startMonth; 
      let endMonth;
      let seasons=[];
      me.season=null;
      seasons.isNorthernHemisphere=isNorthernHemisphere;


      let seasonStartYear;
      if (isNorthernHemisphere) {
        startMonth=7; 
        endMonth=7;

        if (month>=startMonth-1) {
          seasonStartYear=year;
        } else {
          seasonStartYear=year-1;
        }

        for (let startYear=seasonStartYear;startYear>=seasonStartYear-10;startYear--) {
          let endDate=new Date(startYear+1,endMonth-1,1);
          let isCurrentSeason=false;
          if (endDate.getTime()>now.getTime()) {
            endDate=new Date(now.getTime()+7*86400000);
            isCurrentSeason=true;
          }
          seasons.push({
            label:startYear+"-"+(startYear+1-2000),
            startdate:new Date(startYear,startMonth-1,1),
            enddate:endDate,
            year:startYear,
            isCurrentSeason:isCurrentSeason,
          })
        }

      } else {
        startMonth=1; 
        endMonth=1;
         if (month>=startMonth-1) {
          seasonStartYear=year;
        } else {
          seasonStartYear=year-1;
        }
        for (let startYear=seasonStartYear;startYear>=seasonStartYear-10;startYear--) {
          let endDate=new Date(startYear,endMonth,1);
          if (endDate.getTime()>now.getTime()) {
            endDate=new Date(now.getTime()+7*86400000);
          }
          seasons.push({
            label:startYear,
            startdate:new Date(startYear,startMonth-1,1),
            enddate:endDate
          })
        }


      }
      if (!me.season) me.season=seasons[0];
      me.seasons=seasons;

    
    },
    renderMap(retries) {
      var me=this;
      var containerElement=me.$el.parentElement;
      var mapElement=containerElement.querySelector(".weatherMap");

      if (!mapElement) {
        retries=retries || 0;
        if (retries<5) {
          me.$nextTick(
            function() {
              me.renderMap(retries++);
            }
          )
        } else {
          console.error("Could not find map element after 5 tries");
        }
        return;
      }

      if (!me.leafletMap) {
        me.leafletMap = new L.Map(mapElement, {
          fadeAnimation:false,
          minZoom: 1,
          maxZoom: 10,
          center: [37.8, -20], zoom:3
        });

        /*
        const backgroundLayer=new L.TileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", 
        {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
           
        });
        

        me.leafletMap.addLayer(backgroundLayer);
        */
         
        //me.wxlayerGroup = L.layerGroup().addTo(me.leafletMap);
        //L.DomUtil.addClass(backgroundLayer.getContainer(), "greyscale");
        /*
        var CartoDB_VoyagerNoLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 20
        });
        me.leafletMap.addLayer(CartoDB_VoyagerNoLabels);
*/
/*
        var Stamen_TonerBackground = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.{ext}', {
            attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            subdomains: 'abcd',
              opacity:0.4,
            minZoom: 0,
            maxZoom: 20,
            ext: 'png'
          });
        me.leafletMap.addLayer(Stamen_TonerBackground);
*/
         var CartoDB_PositronNoLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 20
        });

        me.leafletMap.addLayer(CartoDB_PositronNoLabels);
        
        let tileServer= "https://weather1.visualcrossing.com"; //"http://localhost:10081"; //"
        const hddLayer=new L.TileLayer(`${tileServer}/VisualCrossingWebServices/rest/services/retrievetile/hdd/{z}/{x}/{y}/202101010000?key=ZMM2U9XUSJ6UV37L4L49NQACY&model=hddsummary_location`, 
            {
                opacity:0.6,
               // attribution: '&copy; Visual Crossing Corporation'
            });

        me.leafletMap.addLayer(hddLayer);

        var CartoDB_VoyagerOnlyLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20
          });

        me.leafletMap.addLayer(CartoDB_VoyagerOnlyLabels);
/*
var Stamen_TerrainLabels = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}{r}.{ext}', {
	attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	subdomains: 'abcd',
	minZoom: 0,
	maxZoom: 18,
	ext: 'png'
});
me.leafletMap.addLayer(Stamen_TerrainLabels);

*/
        me.leafletMap.on('click', function (e) {
          //  me.updateWxDetail(e);
        });


      }

    
    },


  },
  computed: {
    presetLabel() {
      let me=this;
      return (me.preset && me.preset.label) || "-";
    },
    availableModels() {
      let models=WinterTrackerHelper.PRESETMODELS;
      if (VcLocationsStorage.hasValue("winterenergy")) {
        let storedModel=VcLocationsStorage.getValue("winterenergy");

        if (storedModel) models.push(storedModel);
      }
      return models;
    },
    presetModels() {
      return WinterTrackerHelper.PRESETMODELS;
    },
    energyUsageLabel() {
      let me=this;
      return (me.energyusage && me.energyusage.label) || "-";
    },
    energyUsageUnitLabel() {
      let me=this;
      return (me.energyusage && me.energyusage.unit) || "-";
    },
    comparisonLabel() {
      let me=this;
      if (me.season && me.season.isCurrentSeason) {
        return `${currentSeasonLabel} vs ${compareToLabel}`;
      } else {
        return "This winter vs last winter to date";
      }
    },
    
    isCurrentSeason() {
      let me=this;
      return me.season && me.season.isCurrentSeason;
    },
    currentSeasonLabel() {
      let me=this;
      if (me.isCurrentSeason) return "This winter to date";
      return me.season?me.season.label:'Current winter'
    },
    /*
    compareToLabel() {
      let me=this;
      return me.compareSeason?me.compareSeason.label:'Previous winter'
    },
    */
    compareToLabel() {
      let me=this;
      switch (me.compareType) {
        case "normal": return "Normal";
        case "previous": {
          if (me.isCurrentSeason) return "Last winter to date";
          return me.compareSeason?me.compareSeason.label:'Previous winter';
        } 
        default: return "-";
      }
    },
    showWaitMask() {
      let me=this;
      return me.isLoading || !me.selectedLocation || !me.selectedLocation.location; 
    },
    validTrend() {
      let me=this;
      return me.kpis.totalHeatingDays>=250;
    },
    trend() {
      let me=this;
      if (!me.validTrend) {
        return NaN;
      }
      let hddDelta=me.kpis.totalHeatingDays-me.compareKpis.totalHeatingDays;

      let hddDeltaPercent=me.kpis.totalHeatingDays>200?100*hddDelta/me.compareKpis.totalHeatingDays:0;
      return Math.round(hddDeltaPercent);
    },
    trendText() {
      let me=this;
      if (!me.validTrend) {
        return "Few cold days";
      } else if (me.trend>10) {
        return "Much colder";
      } else if (me.trend>2.5) {
       return "Colder";
      } else if (me.trend<-10) {
         return "Much warmer";
      } else if (me.trend<-2.5) {
         return "Warmer";
      } else {
         return "Similar";
      }
    },

    kpis() {
      let me=this;
      return (me.queryData && me.queryData.kpis) || {};
    },
    compareKpis() {
      let me=this;
       return (me.compareQueryData && me.compareQueryData.kpis) || {};
    },

     estimatedCostChange() {
      let me=this;
      return Math.round(100*(me.estimatedCost/me.estimatedNormalCost))-100;
    },
  
    estimatedNormalCost() {
      let me=this;
      return me.compareKpis.totalHeatingCost;
      //if (!me.energyusage.cost) return 0;
      //return me.estimatedNormalUsage*me.energyusage.cost;
    },
    estimatedCost() {
      let me=this;
      return me.kpis.totalHeatingCost;
      //return me.estimatedUsage*me.energyusage.cost;
    },
    estimatedNormalUsage() {
      let me=this;
      return me.compareKpis.totalHeatingUsage;
      //let hdds=me.compareKpis.totalHeatingDays;
     // return WinterTrackerHelper.hdd2Energy(hdds, me.queryDefn.unitGroup, me.energyusage);
    },
   
    estimatedUsage() {
      let me=this;
      return me.kpis.totalHeatingUsage;
      //let hdds=me.kpis.totalHeatingDays;
     // return WinterTrackerHelper.hdd2Energy(hdds, me.queryDefn.unitGroup, me.energyusage);
    },
  
    estimatedUsageChange() {
      let me=this;
      return Math.round(100*(me.estimatedUsage/me.estimatedNormalUsage))-100;
    },

    heatingThreshold() {
      var me=this;
      return me.queryDefn.unitGroup==="us"?10:5.67;
    },
    /*
    orderedKpis() {
      var me=this;

      let kpiKeys=Object.keys(me.kpis);

      let kpis=kpiKeys.map(function(d) {
        return me.kpis[d];
      });
      return kpis.sort(function(a,b) {
        return a.order-b.order;
      })
    },*/
    showDateRangeChooser() {
      return true;
    },
    
    compareSeason() {
      let me=this;
      if (me.compareType==="normal") {
        return null;
      }

      let index=me.seasons.indexOf(me.season);
      if (index!==-1 && index<me.seasons.length-1) {
       return me.seasons[index+1];
      } else {
       return null;
      }
    },
  },
  updated() {
    //console.log("Updated");
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return  bootstrap.Popover.getOrCreateInstance(popoverTriggerEl)
    });
  },
  watch: {
    cumulativeHdd() {
      let me=this;
      me.refreshHddChart();
    },
    costChartView() {
      let me=this;
      me.refreshChart();
    },
    showUsage() {
      let me=this;
      me.refreshChart();
    },
    showCost() {
      let me=this;
      me.refreshChart();
    },
    showTemp() {
      let me=this;
      me.refreshChart();
    },
    selectedPreset(){
      let me=this;
      me.energyusage=JSON.parse(JSON.stringify(me.selectedPreset));
      me.refreshChart();
    },
    compareSeason: {
      handler:function(newValue) {
        let me=this;
        if (!newValue) {
          me.convertNormalsToComparisonData(me.queryData);
        }

       //me.refreshData();
      },
      deep: true
    },
    /*
    compareType:{
      handler:function(newValue) {
        let me=this;
        if (newValue==="normal") {
          me.compareSeason=null;
          me.convertNormalsToComparisonData(me.queryData);
          return;
        }

        let index=me.seasons.indexOf(me.season);
        if (index!==-1 && index<me.seasons.length-1) {
          me.compareSeason=me.seasons[index+1];
        } else {
          me.compareSeason=null;
        }
       
     
        me.refreshData();
      },
      deep: true
    },
    */
    season:{
      handler:function(newValue,oldValue) {
        let me=this;

        if (newValue && !oldValue || newValue.label!==oldValue.label) {
          me.refreshData();
        }
      },
      deep: true
    },
    energyusage: {
      handler:function(newValue,oldValue) {
        let me=this;
        me.calculateKpis(me.compareQueryData, me.queryData);
        me.calculateKpis(me.queryData);
        me.refreshChart();
          //this.refreshChart();
         
        },
        deep: true
    },
    queryData:{
        handler:function(newValue,oldValue) {
          //this.refreshSeasons();
          //this.calculateKpis();
          //this.refreshChart();
         
        },
        deep: true
    },

    
  },
  
  created() {
    var me=this;
    me.onresize=VcWidgetHelper.debounce(function () {
         me.refreshChart();
    }, 250);

    window.addEventListener("resize", me.onresize);
  },
  destroyed() {
    window.removeEventListener("resize", me.onresize);
  },
  unmounted() {
    let me=this;
  },
  mounted() {
    let me=this;
   
    if (VcLocationsStorage.hasValue("winterenergy")) {
      me.energyusage=VcLocationsStorage.getValue("winterenergy");
    }

    me.renderMap();
    me.refreshSeasons();
   
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    me.sessionState=vcCommunicator.sessionState;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
      me.sessionId=sessionId;
      me.sessionState=sessionState;
    
    
    });
     vcCommunicator.addHandler('terminatesession', function () {
       me.sessionId=null;
       me.sessionState=null;
    });

    me.refreshLocationsHistory();

    let lastLocation=me.hasLocationsHistory() && me.locationsHistory[0];
    let savedUnitGroup=VcLocationsStorage.getUnitGroup();
    let launchLocation=null;
   

    if (window.vcconfig) {
      if (window.vcconfig.location==="<?php echo $location ?>") window.vcconfig.location="Herndon,VA";
      if (window.vcconfig.unitGroup==="<?php echo $unitGroup ?>") window.vcconfig.unitGroup="us";
    

      if (!window.vcconfig.isDefault || !lastLocation) {
        if (window.vcconfig.location) {
         launchLocation={
            location:window.vcconfig.location,
            name:null
          }
          
        }
        if (window.vcconfig.unitGroup) {
          me.unitGroup=window.vcconfig.unitGroup || savedUnitGroup
          VcLocationsStorage.setUnitGroup(me.unitGroup);
        }

       
      }
    }
    
    launchLocation=launchLocation || lastLocation;
    if (launchLocation) {
        me.setLocation(launchLocation);
    } else {
       me.findMyLocation(true);
    }
    
    
  },
  components: {
    DateRangeChooser,
    CommonModals,
    RegressionEditor
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .warmer {
    color: #e6a91d;
  }

  .cooler {
    color: #75aaf0;
  }

  .better {
    color: #36b99f;
  }

  .worse {
    color: #e16153;
  }

  .weatherMap {
  /*  height: calc(50vh) !important;*/
  height:100%;
  }
  .trendup {
    color:#36b99f;
  }
  .trenddown {
    color:e16153;
  }
  .old {
      font-style: italic;
      opacity: 0.9;
  }
  .table-striped tbody  .error {
    font-weight: bold;
  
  }
  .table-striped tbody .error td {
      background-color: rgb(245, 212, 212) !important;
  }

  .chart {
      /*background-color: #fff;*/
      position:relative;
  }

  .chart-temp svg {
      min-height:250px
  }

  .chart :deep() .usageline {
      stroke:rgb(221, 47, 16);
      fill:none;
  }
  .chart :deep() .costline {
      stroke:rgb(231, 169, 158);
      stroke-dasharray: 5;
      fill:none;
  }

  .chart :deep() .templine {
      stroke:rgb(81, 82, 81);
      fill:none;
  }
  .chart :deep() .accddline {
      stroke:rgb(81, 82, 81);
      fill:none;
  }
  .chart :deep() .normalsArea {
      stroke:#6b6767;
      fill:#d6d3d3;
  }
   .chart :deep() .hddarea {
      stroke:#75aaf0;
      fill:#75aaf0;
  }
  .chart :deep() .warmarea {
    stroke: #e6a91d;
    fill: #e6a91d;
    opacity: 0.3;
  }
  
  .chart :deep() .tempbar {
    background-color:#6b6767;
	  fill: #75aaf0;
    stroke: #75aaf0;
	}

  .chart :deep() .costbar {
    background-color:#6b6767;
    fill-opacity: 0.8;
	  /*fill: #36b99f;
    stroke: #36b99f;*/
	}

  .chart :deep() .noheating {
    stroke: #e6a91d;
    fill: #e6a91d;
	}
  .chart :deep() .lastPeriod {
    opacity: 0.3;
    stroke: #6b6767 !important;
    fill:#6b6767 !important;
    background-color:#6b6767;
  }

  .chart :deep() .isPartial {
    opacity:0.4;
      /*stroke-dasharray: 5 3;
      fill-opacity: 1.0;
      stroke: #6b6767 !important;
      fill:#6b6767 !important;*/
  }

  .chart :deep() .tempdot {
    background-color:#6b6767;
	  fill: #6b6767;
    stroke: #d6d3d3;
    opacity: 0.3;
    /*stroke-linecap: round;*/
	}
  

 

  .chart :deep() .hddbase {
    background-color:#6b6767;
	  fill: #6b6767;
    stroke: #6b6767;
    stroke-dasharray: 5;
	}
 .tooltip {
    background-color: white;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    transform: translate(-50%, 0%);
    pointer-events: none;
    line-height: 13px;
  }
  .tooltip .contents {
  
  }
  .tooltip * {
    text-align: center;
  }

  :deep() .greyscale {
    filter: grayscale(1) brightness(75%);
    -webkit-filter: grayscale(1) brightness(75%);
  }
  
  .showwaitmask .waitmask, .showwaitmask .waitmaskchildren div {
    color:#e7e3e3 !important;
    min-width: 50px;
    margin:1px;
    background-color:#e7e3e3 !important;
  }



/*
  .monthlyusagechart {
      background-color: #fff;
      position:relative;
  }

  .monthlyusagechart :deep() .hddpoint {
    fill: #d85b1c;
    stroke: #d85b1c;
	}
  .monthlyusagechart :deep() .hddpoint.notheating  {
    fill: #918f8e;
    stroke: #918f8e;
	}
  .monthlyusagechart :deep() .temppoint {
    fill: none;
    stroke: #1f1cd8;
	}

  .monthlyusagechart :deep() .bestfitline {
     background-color:#6b6767;
	  fill: #6b6767;
    stroke: #6b6767;
    stroke-dasharray: 5;
  }
  */
</style>