export var VcMetadataUtils = (function () {
    const ACCESS_CONTROL_TYPE={
      READ:1,
      WRITE:2,
      DENY:4,
      READWRITE:3
    }
    return {
      hasWriteAccess:function(metadata) {
        return metadata && 
          (metadata.resolvedAccess & ACCESS_CONTROL_TYPE.WRITE);vcc
  
      },
      hasReadAccess:function(metadata) {
        return metadata && 
          (metadata.resolvedAccess & ACCESS_CONTROL_TYPE.READ);
      },
      getTagPrefixValue:function(metadata, tagPrefix) {
        var me=this;
        var values=me.getTagPrefixValues(metadata, tagPrefix);
        if (values && values.length>0) return values[0];
        return null;
      },
      getTagPrefixValues:function(metadata, tagPrefix) {

        if (!tagPrefix.endsWith(": ")) tagPrefix+=": ";
        var prefixLength=tagPrefix.length;

        if (!metadata || !metadata.tags) return null;
         
        
        var matches=metadata.tags.reduce(function(a,d) {
            if (d && d.startsWith(tagPrefix)) {
                d=d.substring(prefixLength);
                a.push(d);
            }
            return a;
        }, []);
        return matches;
      },
      getSafeResultTime(result) {
        return (result && result.dateTime && result.dateTime.getTime()) || 0;
      },
      jobStatusText(status) {
        switch (status) {
          case 0: return "Unknown";
          case 1: return "Queued";
          case 2: return "Executing";
          case 3: return "Complete";
          case 4: return "Error";
          case 5: return "Cancelled";
          default: return "? " +status;
        }
    },
    }
}());