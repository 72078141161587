export var VcTaxIds=VcTaxIds || {}

VcTaxIds.TAX_IDS=[["AU","au_abn","Australian Business Number (AU ABN)","12345678912"],
        ["AU","au_arn","Australian Taxation Office Reference Number","123456789123"],
        ["AT","eu_vat","European VAT number","ATU12345678"],
        ["BE","eu_vat","European VAT number","BE0123456789"],
        ["BR","br_cnpj","Brazilian CNPJ number","01.234.456/5432-10"],
        ["BR","br_cpf","Brazilian CPF number","123.456.789-87"],
        ["BG","bg_uic","Bulgaria Unified Identification Code","123456789"],
        ["BG","eu_vat","European VAT number","BG0123456789"],
        ["CA","ca_bn","Canadian BN","123456789"],
        ["CA","ca_gst_hst","Canadian GST/HST number","123456789RT0002"],
        ["CA","ca_pst_bc","Canadian PST number (British Columbia)","PST-1234-5678"],
        ["CA","ca_pst_mb","Canadian PST number (Manitoba)","123456-7"],
        ["CA","ca_pst_sk","Canadian PST number (Saskatchewan)","1234567"],
        ["CA","ca_qst","Canadian QST number (Québec)","1234567890TQ1234"],
        ["CL","cl_tin","Chilean TIN","12.345.678-K"],
        ["HR","eu_vat","European VAT number","HR12345678912"],
        ["CY","eu_vat","European VAT number","CY12345678Z"],
        ["CZ","eu_vat","European VAT number","CZ1234567890"],
        ["DK","eu_vat","European VAT number","DK12345678"],
        ["EG","eg_tin","Egyptian Tax Identification Number","123456789"],
        ["EE","eu_vat","European VAT number","EE123456789"],
        ["EU","eu_oss_vat","European One Stop Shop VAT number for non-Union scheme","EU123456789"],
        ["FI","eu_vat","European VAT number","FI12345678"],
        ["FR","eu_vat","European VAT number","FRAB123456789"],
        ["GE","ge_vat","Georgian VAT","123456789"],
        ["DE","eu_vat","European VAT number","DE123456789"],
        ["GR","eu_vat","European VAT number","EL123456789"],
        ["HK","hk_br","Hong Kong BR number","12345678"],
        ["HU","eu_vat","European VAT number","HU12345678912"],
        ["HU","hu_tin","Hungary tax number (adószám)","12345678-1-23"],
        ["IS","is_vat","Icelandic VAT","123456"],
        ["IN","in_gst","Indian GST number","12ABCDE3456FGZH"],
        ["ID","id_npwp","Indonesian NPWP number","12.345.678.9-012.345"],
        ["IE","eu_vat","European VAT number","IE1234567AB"],
        ["IL","il_vat","Israel VAT","000012345"],
        ["IT","eu_vat","European VAT number","IT12345678912"],
        ["JP","jp_cn","Japanese Corporate Number (*Hōjin Bangō*)","1234567891234"],
        ["JP","jp_rn","Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)","12345"],
        ["JP","jp_trn","Japanese Tax Registration Number (*Tōroku Bangō*)","T1234567891234"],
        ["KE","ke_pin","Kenya Revenue Authority Personal Identification Number","P000111111A"],
        ["LV","eu_vat","European VAT number","LV12345678912"],
        ["LI","li_uid","Liechtensteinian UID number","CHE123456789"],
        ["LT","eu_vat","European VAT number","LT123456789123"],
        ["LU","eu_vat","European VAT number","LU12345678"],
        ["MY","my_frp","Malaysian FRP number","12345678"],
        ["MY","my_itn","Malaysian ITN","C 1234567890"],
        ["MY","my_sst","Malaysian SST number","A12-3456-78912345"],
        ["MT","eu_vat","European VAT number","MT12345678"],
        ["MX","mx_rfc","Mexican RFC number","ABC010203AB9"],
        ["NL","eu_vat","European VAT number","NL123456789B12"],
        ["NZ","nz_gst","New Zealand GST number","123456789"],
        ["NO","no_vat","Norwegian VAT number","123456789MVA"],
        ["PH","ph_tin","Philippines Tax Identification Number","123456789012"],
        ["PL","eu_vat","European VAT number","PL1234567890"],
        ["PT","eu_vat","European VAT number","PT123456789"],
        ["RO","eu_vat","European VAT number","RO1234567891"],
        ["RU","ru_inn","Russian INN","1234567891"],
        ["RU","ru_kpp","Russian KPP","123456789"],
        ["SA","sa_vat","Saudi Arabia VAT","123456789012345"],
        ["SG","sg_gst","Singaporean GST","M12345678X"],
        ["SG","sg_uen","Singaporean UEN","123456789F"],
        ["SK","eu_vat","European VAT number","SK1234567891"],
        ["SL","eu_vat","European VAT number","SI12345678"],
        ["SL","si_tin","Slovenia tax number (davčna številka)","12345678"],
        ["SA","za_vat","South African VAT number","4123456789"],
        ["KR","kr_brn","Korean BRN","123-45-67890"],
        ["ES","es_cif","Spanish NIF number (previously Spanish CIF number)","A12345678"],
        ["ES","eu_vat","European VAT number","ESA1234567Z"],
        ["SE","eu_vat","European VAT number","SE123456789123"],
        ["CH","ch_vat","Switzerland VAT number","CHE-123.456.789 MWST"],
        ["TW","tw_vat","Taiwanese VAT","12345678"],
        ["TH","th_vat","Thai VAT","1234567891234"],
        ["TR","tr_tin","Turkish Tax Identification Number","0123456789"],
        ["UA","ua_vat","Ukrainian VAT","123456789"],
        ["AE","ae_trn","United Arab Emirates TRN","123456789012345"],
        ["GB","eu_vat","Northern Ireland VAT number","XI123456789"],
        ["GB","gb_vat","United Kingdom VAT number","GB123456789"],
        ["US","us_ein","United States EIN","12-3456789"]]
