<template>
  <div class="">
    <div v-if="hasSession" class=" text-nowrap">
      <a class="btn btn-sm text-primary d-inline-flex align-items-center" href="#signoutModal" data-bs-toggle="modal" title="Sign out">
        <i class="fs-5 bi bi-box-arrow-right me-lg-1"></i><span class="d-none d-lg-inline"> Sign out</span>
        </a>
      <a class="btn btn-primary btn-sm rounded-pill  d-inline-flex align-items-center" href="/account"  title="Your Account">
      <i class="fs-5 bi bi-person me-lg-1"></i><span class="d-none d-lg-inline">Account</span>
      </a>
    </div>
    <div v-else class="text-nowrap">
      <a class="btn btn-sm text-primary px-2 d-inline-flex align-items-center" href="#signinModal" data-bs-toggle="modal"  title="Sign in">
        <i class="fs-5 bi bi-box-arrow-in-right me-lg-1"></i><span class="d-none d-lg-inline"> Sign in</span>
      </a>
      <a class="btn btn-sm btn-primary rounded-pill  d-inline-flex align-items-center"  href="/sign-up"  title="Sign up for an account">
      <i class="fs-5 bi bi-person me-lg-1"></i><span class="d-none d-lg-inline"> Sign up</span>
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'login',
  props: {
    msg: String
  },
  data() {
    return {
     // username:null,
      //password:null,
      sessionId:null,
     // errorTitle:null,
     // errorDetails:null
    }
  },
  methods:{
    /*
    showError(title, details) {
      var me=this;
      me.errorTitle=title; 
      me.errorDetails=details;
      var myModalEl=me.$el.querySelector(".errorModel");
      var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
      modal.show();
    },
    signIn() {
      var me=this;
      
      vcCommunicator.createsession(me.username,me.password)
        .then(function (response) {
          console.log("Successful login");
          me.sessionId=vcCommunicator.sessionId;

        })
        .catch((error) => {
          console.log("Failed login");

          me.sessionId=null;
          me.showError("An error occurred during sign in", error || "An unknown problem occurred")
        });
      me.password=null;
    },
    signOut() {
      var me=this;
        vcCommunicator.signOut().then(function (response) {
    
        me.sessionId=vcCommunicator.sessionId;
      }).catch((error) => {
        console.log("signOut Failed:"+error);
        me.sessionId=null;
      });
    }*/
  },
  computed:{
    hasSession() {
      return this.sessionId;
    }
  },
  mounted() {
    var me=this;
    
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
       me.sessionId=sessionId;
    });
     vcCommunicator.addHandler('terminatesession', function () {
       me.sessionId=null;
    });
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
