<template>
  <div class="fs-sm" :class="widgetcolumns">
    <div v-if="!hasData" class="nodata  col-12" >
        No data available
      </div>
      <div v-if="hasError" class="haserror  col-12" >
        {{error}}
      </div>
  <!--<nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-today-tab" data-bs-toggle="tab" data-bs-target="#nav-today" type="button" role="tab" aria-controls="nav-today" aria-selected="true">Today</a>
        <a class="nav-link" id="nav-weekend-tab" data-bs-toggle="tab" data-bs-target="#nav-weekend" type="button" role="tab" aria-controls="nav-weekend" aria-selected="false">Weekend</a>
        <a class="nav-link" id="nav-daily-tab" data-bs-toggle="tab" data-bs-target="#nav-daily" type="button" role="tab" aria-controls="nav-daily" aria-selected="false">Daily</a>
      </div>
    </nav>
    -->
    <div class="tab-content" id="nav-tabContent" style="overflow-y:auto;overflow-x:clip">
      <div class="tab-pane show active" id="nav-today">
        <div v-if="showCurrent" class="flex-fill  rounded-3 bg-secondary mb-3 row">
          <div class="col-12 fs-xxs">Currently</div>
          <div class="row col-12 col-md-6">
            <div class="col-2 col-md-2 col-lg-2 d-flex flex-row align-items-center justify-content-start ps-md-5">
              <div class="d-flex flex-column">
                <div class="icon me-2"><div class="" :class="currentConditions.icon"></div></div>
                <div class="fs-xxs">{{formatTime(currentConditions)}}</div>
              </div>
            <!-- <div class="">Currently</div>-->
            </div>


            <div class="col-2 d-flex flex-column align-items-center justify-content-center">
              <div class="justify-content-center" v-html="formattedValue(currentConditions,'temp')"></div>
              <div v-if="currentConditions.feelslike!==currentConditions.temp" class="fs-xxs justify-content-center">Feels:<span v-html="formattedValue(currentConditions,'feelslike')" /></div>
            </div>
            <div class="col-1 fs-xs d-flex flex-column align-items-center justify-content-center">
              <div class="fs-xxs opacity-75"><i class="bi-droplet" > </i></div>
              <div class="d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(currentConditions,'precip')}}</div>
              <div v-if="currentConditions.precipremote" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-broadcast" ></i>{{formattedValue(currentConditions,'precipremote', false, 'precip')}}</div>
              <div v-if="currentConditions.snow" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(currentConditions,'snow')}}</div>
            </div>
            <div class="col-1 fs-xs d-flex flex-column align-items-center justify-content-center">
              <div class="fs-xxs opacity-75">UVI</div>
              <div class="" v-html="formattedValue(currentConditions,'uvindex2')"></div>
            </div>

            
            <div class="col-2 d-flex flex-column align-items-center justify-content-center">
              <div class="fs-xxs opacity-75">AQI</div>
              <div class="d-none d-md-block d-flex justify-content-center">{{formattedValue(currentConditions,'aqi')}}</div>
              <div class="d-block d-md-none d-flex justify-content-center">{{formattedValue(currentConditions,'aqi',true)}}</div>
              <div v-if="currentConditions.aqius>50" class="d-none d-md-block fs-xxs">{{formattedValue(currentConditions,'aqielement')}}</div>
            </div>

            

            <div class="col-2 fs-xs d-flex flex-column align-items-center justify-content-center">
              <div class="fs-xxs opacity-75">Wind</div>
              <div class="d-flex justify-content-center" :title="formattedValue(currentConditions,'wind')">
                <div class="position-relative"  style="width:15px" >
                  <div class="position-absolute"  :style="'transform:rotate('+Math.round(currentConditions.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
                </div>
                <span class="" v-html="formattedValue(currentConditions,'windspeed', true)" />
              </div>
              <!--<div class="fs-xxs" v-html="formattedValue(currentConditions,'pressure')"></div>-->
            </div>

            <!--
            <div v-if="nearbyRain" class="col-12 col-md-3 fs-xs text-center">
              {{nearbyRain}}
            </div>
            -->

          </div>

          <div class="col-12 col-md-6 position-relative">
            <div class="position-absolute fs-xs" style="z-index:50">{{nearbyRainWithNone}}</div>
            <div class="col-12 chartcontainer reflectivitycontainer">
              <svg class="chart" />
              <div class="periods periods overflow-hidden">
                <template v-for="(period,index) in minutely">
                  <div  class="period">
                    <div class="time">{{formatTime(period)}}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showAlerts" class="accordion my-3">
          <!--<h5>Active Alerts</h5>-->
          <div v-for="(a,i) in alerts" class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#alertdetail'+i" >
              {{a.event}}
              </button>
            </div>
            <div class="collapse" :id="'alertdetail'+i">
              <div class="card card-body">
                <h6> {{a.headline}}</h6>
                <p class="desc text-wrap" v-html="alertDesc(a)"></p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="today" class="rounded-3 bg-secondary">
          
          <DayWidget :days="days" :day="today" :timezone="wxdata.timezone" :unitGroup="unitGroup" />
        </div>
<!--
        <div v-if="showCurrent" class="section mt-3">
          <h5>{{nearbyRainWithNone}}</h5>
          <div class="col-12 accordion my-3 chartcontainer reflectivitycontainer">
            <svg class="chart" />
            <div class="periods">
              <template v-for="(period,index) in minutely">
                <div  class="period">
                  <div class="time">{{formatTime(period)}}</div>
                </div>
              </template>
            </div>
          </div>
     -->

        


        <div  class="col-12 mt-3  mx-1 ">
          <div class="weatherMap rounded" ></div>
        </div>
        <div class="rounded-3 bg-secondary mt-3  mx-1 ">
          <DayWidget v-for="(day,dayIndex) in days" :days="days" :day="day" :timezone="wxdata.timezone" :unitGroup="unitGroup" />
            
          
        </div>
        
      </div>
    <!--
      <div class="tab-pane" id="nav-weekend">
        <div class="rounded-3 bg-secondary">
           <DayWidget v-for="(day,dayIndex) in daysWeekend" :days="days" :day="day" :timezone="wxdata.timezone" :unitGroup="unitGroup" />

        </div>
     
      </div>
      <div class="tab-pane" id="nav-daily">
        <div class="rounded-3 bg-secondary">
          <DayWidget v-for="(day,dayIndex) in days" :days="days" :day="day" :timezone="wxdata.timezone" :unitGroup="unitGroup" />
            
          
        </div>
      </div>
    -->

      <div class="tab-pane" id="nav-hourly">
         <!--<div v-for="(day,i) in days" class="row" :id="'hourDay'+i">-->
         
          <div v-if="selectedDay" class="row">
              <div class="col-11 h6">{{formatDate(selectedDay)}}</div>
              <button v-if="previousTab" class="col-1 btn btn-sm text-primary px-1 py-0" type="button"  @click="back()"><i class="bi-arrow-up" ></i> </button>
              <div v-for="(hour,i) in selectedDay.hours" class="row  pb-1 mb-1 fs-xs border-bottom border-dark-subtle">
                
                <div class="col-2 d-flex flex-column align-items-center justify-content-center">
                  <div class="icon me-2"><div class="" :class="hour.icon"></div></div>
                  <div class="fs-xxs">{{formatTime(hour)}}</div>
                </div>
                <div class="col-10 row">
                  <div class="col-4 col-md-2 text-center">
                    <div class="" v-html="formattedValue(hour,'temp')"></div>
                    <div v-if="hour.feelslike!==hour.temp" class="fs-xxs">Feels:<span v-html="formattedValue(hour,'feelslike')" /></div>
                </div>
                
                  
                <div class="col-4 col-md-2 text-center">
                  <div class="fs-xxs opacity-75">Precip</div>
                  <div class="">{{formattedValue(hour,'precipprob')}}</div>
                  <div v-if="hour.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(hour,'precip')}}</div>
                  <div v-if="hour.snow" class="fs-xxs  d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(hour,'snow')}}</div>
                </div>
                <div class="col-4 col-md-1 fs-xs text-center">
                  <div class="fs-xxs opacity-75">UVI</div>
                  <div class="" v-html="formattedValue(hour,'uvindex2')"></div>
                </div>

                  

                <div class="col-4 col-md-2 text-center">
                  <div class="fs-xxs opacity-75">AQI</div>
                  <div class="d-none d-md-block">{{formattedValue(hour,'aqi')}}</div>
                  <div class="d-block d-md-none">{{formattedValue(hour,'aqi',true)}}</div>
                  <div v-if="hour.aqius>50" class="d-none d-md-block fs-xxs">{{formattedValue(hour,'aqielement')}}</div>
                </div>
                  
                <div class="col-4 col-md-2 text-center">
                  <div class="fs-xxs opacity-75">Wind</div>
                  <div class="d-flex justify-content-center" :title="formattedValue(hour,'wind')">
                    <div class="position-relative"  style="width:15px" >
                      <div class="position-absolute"  :style="'transform:rotate('+Math.round(hour.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
                    </div>
                    <span class="" v-html="formattedValue(hour,'windspeed',true)" />
                  </div>
                  <div class="d-none d-md-block fs-xxs" v-html="formattedValue(hour,'pressure')"></div>
                </div>
                <div class="col-4 col-md-2  text-center">
                  <div class="fs-xxs opacity-75">Humidity</div>
                  <div class="" v-html="formattedValue(hour,'humidity')"></div>
                  <div class="d-none d-md-block fs-xxs" v-html="formattedValue(hour,'dew')"></div>
                </div>
                
              </div>
                  
            </div>
          </div>
          
            
      </div>

    </div>

<!--
  <div class="modal" tabindex="-1"  id="dayDetailModal"  aria-labelledby="dayDetailModalLabel"  data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dayDetailModalLabel">{{formatDate(selectedDay)}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="selectedDay" class="overflow-scroll" style="height:80vh">
         <div class="row d-md-none pb-1 mb-1 border-bottom border-primary-subtle">
              <div class="col-12 my-1  text-center" v-html="selectedDay.description" />
              <div class="col-3 col-md-2 text-center">
                <div class="fs-xxs opacity-75">AQI</div>
                <div class="d-none d-md-block">{{formattedValue(selectedDay,'aqi')}}</div>
                <div class="d-block d-md-none">{{formattedValue(selectedDay,'aqi',true)}}</div>
                <div class="d-none d-md-block fs-xxs">{{formattedValue(selectedDay,'aqielement')}}</div>
              </div>
              
              <div class="col-3 col-md-2 text-center">
                <div class="fs-xxs opacity-75">Wind</div>
                <div class="d-flex justify-content-center" :title="formattedValue(selectedDay,'wind')">
                  <div class="position-relative"  style="width:15px" >
                    <div class="position-absolute"  :style="'transform:rotate('+Math.round(selectedDay.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
                  </div>
                  <span class="" v-html="formattedValue(selectedDay,'windspeed', true)" />
                </div>
                <div class="fs-xxs" v-html="formattedValue(selectedDay,'pressure')"></div>
              </div>
              <div class="col-3 col-md-1 text-center">
                <div class="fs-xxs opacity-75">Humidity</div>
                <div class="" v-html="formattedValue(selectedDay,'humidity')"></div>
                <div class="fs-xxs" v-html="formattedValue(selectedDay,'dew')"></div>
              </div>
              
              <div class="col-3 col-md-2 text-center">
                <div class="fs-xxs opacity-75">Sunrise/set</div>
                <div class="d-flex d-flex justify-content-center"><i class="bi-sun me-1" ></i>{{formatTime(selectedDay,'sunriseEpoch')}}</div>
                <div class="d-flex d-flex justify-content-center"><i class="bi-moon me-1" ></i>{{formatTime(selectedDay,'sunsetEpoch')}}</div>
              </div>
              

          </div>
          <div  v-for="(hour,i) in selectedDay.hours" class="row ms-1 ms-md-3 pb-1 mb-1 fs-xs border-bottom border-dark-subtle">
                
                <div class="col-2 d-flex flex-column align-items-center justify-content-center">
                  <div class="icon me-2"><div class="" :class="hour.icon"></div></div>
                  <div class="fs-xxs">{{formatTime(hour)}}</div>
                </div>
                <div class="col-10 row">
                  <div class="col-4 col-md-2 text-center">
                    <div class="" v-html="formattedValue(hour,'temp')"></div>
                    <div v-if="hour.feelslike!==hour.temp" class="fs-xxs">Feels:<span v-html="formattedValue(hour,'feelslike')" /></div>
                  </div>
                
                  
                  <div class="col-4 col-md-2 text-center">
                    <div class="fs-xxs opacity-75">Precip</div>
                    <div class="">{{formattedValue(hour,'precipprob')}}</div>
                    <div v-if="hour.precip" class="fs-xxs d-flex justify-content-center"><i class="d-none d-sm-block  bi-droplet" ></i>{{formattedValue(hour,'precip')}}</div>
                    <div v-if="hour.snow" class="fs-xxs  d-flex justify-content-center"><i class="d-none d-sm-block bi-snow" ></i>{{formattedValue(hour,'snow')}}</div>
                  </div>
                  <div class="col-4 col-md-1 fs-xs text-center">
                    <div class="fs-xxs opacity-75">UVI</div>
                    <div class="" v-html="formattedValue(hour,'uvindex2')"></div>
                  </div>

                  

                  <div class="col-4 col-md-2 text-center">
                    <div class="fs-xxs opacity-75">AQI</div>
                    <div class="d-none d-md-block">{{formattedValue(hour,'aqi')}}</div>
                    <div class="d-block d-md-none">{{formattedValue(hour,'aqi',true)}}</div>
                    <div v-if="hour.aqius>50" class="d-none d-md-block fs-xxs">{{formattedValue(hour,'aqielement')}}</div>
                  </div>
                  
                  <div class="col-4 col-md-2 text-center">
                    <div class="fs-xxs opacity-75">Wind</div>
                    <div class="d-flex justify-content-center" :title="formattedValue(hour,'wind')">
                      <div class="position-relative"  style="width:15px" >
                        <div class="position-absolute"  :style="'transform:rotate('+Math.round(hour.winddir)+'deg)'"><i class="bi-arrow-down" ></i></div>
                      </div>
                      <span class="" v-html="formattedValue(hour,'windspeed',true)" />
                    </div>
                    <div class="d-none d-md-block fs-xxs" v-html="formattedValue(hour,'pressure')"></div>
                  </div>
                  <div class="col-4 col-md-2  text-center">
                    <div class="fs-xxs opacity-75">Humidity</div>
                    <div class="" v-html="formattedValue(hour,'humidity')"></div>
                    <div class="d-none d-md-block fs-xxs" v-html="formattedValue(hour,'dew')"></div>
                  </div>
                
                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
       
      </div>
    </div>
  </div>
 
  </div> -->
  </div>
</template>
<script>
import '/src/pages/weather-map/WxTileLayer.js';
import '/src/pages/weather-map/WxLegend.js';
import {VcWidgetHelper} from './common_widget.js'
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils'
import {VcGlobal} from "/../visualcrossing-core-js/src/vcglobal_conn";
const loadD3 = () => import('d3')
import DayWidget from './day-widget.vue'
export default {
  name: 'day-summary-widget2',
  props:{
    weatherData:Object,
    parentData:Boolean,
    config:Object,
    newLocation:String,
  },
  emits: ['update:location'],
  data() {
    return {
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      options:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      viewincludes:null,
      summarykpis:[],
      dayIndex:0,
      endDayIndex:0,
      hourIndex:null,
      period:"day",
      rooturl:null,
      widgetcolumncount:1,
      showMapFullScreen:false,
      previousTab:null,
      selectedDay:null,
      updateMapTimeLine:null,
    }
  },
  methods:{

    isExpanded(day){
      let me=this;
      return day===me.selectedDay;
    },
    selectDay(day) {
      let me=this;
      me.selectedDay=me.isExpanded(day)?null:day;
    },

    back() {
      var me=this;
      if (!me.previousTab) return;

      var triggerEl = document.querySelector(`#nav-${me.previousTab}-tab`)
      var tab = bootstrap.Tab.getOrCreateInstance(triggerEl);
      tab.show();
      window.scrollTo(0, 0);

    },
    showHourly(day, previousTab) {
      var me=this;
      me.previousTab=previousTab;
      me.selectedDay=day;
      /*
      let targetElementId=`hourDay${dayIndex}`;
      let targetElement=document.getElementById(targetElementId);
      if (!targetElement) {
        return;

      }
      */
      var triggerEl = document.querySelector('#nav-hourly-tab')
      var tab = bootstrap.Tab.getOrCreateInstance(triggerEl);
      tab.show();

      //targetElement.scrollIntoView();
    },
    refreshData(forceRefresh) {
      var me=this;
      if (me.parentData) {
        return;
      }
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    options:me.options,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:forceRefresh || me.forceRefresh,
                })
      .then(response => {
          me.wxdata=response.data;
          if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
          if (!me.location) me.location=me.wxdata.resolvedAddress;
          me.updateMap();
          me.renderCharts();
      }).catch((error) => {
           me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    formatNormalValue(period, element, index) {
        let me=this;
        if (!period || !period.normal) return "?";
        let normals;
        if (element==="rainsnow") {
          if (period.snow>0) {
            normals= period.normal["snow"];
          } else {
            normals= period.normal["precip"];
          }
        } else {
          normals=period.normal[element];
        }

        return VcWidgetHelper.formatElementValue(normals[index], element,me.unitGroup,null,"",false,true );
    },
    formattedValue(period, element, shortForm, formattElement) {
        var me=this;
        formattElement=formattElement || element;
        if (element==="rainsnow") {
          if (period.snow>0) return me.formatValue(period.snow, "snow");
          return me.formatValue(period.precip, "precip");
        }
        if (element==="aqi") return me.formattedAqi(period,shortForm);
        if (element==="aqielement") return me.formattedAqiElement(period);
        if (element==="wind") return me.formattedWind(period);
        if (element==="moonphase") return me.formattedMoonPhase(period);
        if (element==="sunriseset") return me.formattedSunriseset(period);
        return me.formatValue(period[element], formattElement, shortForm);
    },
    formattedAqi(period,shortForm) {
      let aqi=period.aqius;
      if (!aqi && aqi!==0) return "-";
      return `${VcWidgetHelper.getAqiText(aqi,shortForm)} (${this.formatValue(aqi, "aqius")})`
    },
    formattedAqiElement(period) {
      let aqielement=period.aqielement;
      if (!aqielement) return "-";
      return `${VcWidgetHelper.getAqiElementText(aqielement)}`; // (${this.formatValue(aqi, "aqius")})
    },
    formattedWind(period) {
      return VcWidgetHelper.getCompassDirection(period.winddir)+" "+
      this.formatValue(period.windspeed, "wind");
    },
    formattedMoonPhase(period) {
      return VcWidgetHelper.getMoonPhaseDesc(period.moonphase);
    },
    formattedSunriseset(period) {
      return  this.formatValue(period.sunrise)+"-"+this.formatValue(period.sunset);
    },
    formatValue(value, element, shortForm) {
      var me=this;
      const fixed=shortForm?0:null;
      const emptyvalue=null, unicode=null;
      const noSymbol=shortForm;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup,   fixed, emptyvalue,  unicode, noSymbol );
    },
    formatTime(period, property) {
      if (!period) return "-";
      const value=period && property && period[property]? period[property]:period.datetimeEpoch;
      return VcWidgetHelper.formatTime(value, this.wxdata.timezone);
    },
    formatDate(period,shortForm) {
      if (!period) return "-";

      if (VcWidgetHelper.isYesterday(period.datetimeEpoch, this.wxdata.timezone)) return "Yesterday";
      if (VcWidgetHelper.isToday(period.datetimeEpoch, this.wxdata.timezone)) return "Today";


      if (shortForm)  return VcWidgetHelper.formatDateVeryShort(period.datetimeEpoch, this.wxdata.timezone);
      return VcWidgetHelper.formatDateShort(period.datetimeEpoch, this.wxdata.timezone);
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    alertDesc(alert) {
      var desc= alert && alert.description;
      //desc=desc.replace(/(?:\r\n|\r|\n)/g, '<br>');
      desc=desc.replace(/(\*)/g, '<br>');
      return desc;
    },
    updateMap() {
      let me=this;
      if (!me.leafletMap) return;

      if (!me.wxdata) return;
      let zoom=me.leafletMap.getZoom();
      if (zoom<7) {
        zoom=7;
      }

      me.leafletMap.setView([me.wxdata.latitude, me.wxdata.longitude], zoom);
      if (me.circleMarker) me.circleMarker.remove();
      me.circleMarker = L.circleMarker([me.wxdata.latitude, me.wxdata.longitude], {
            color: 'blue',
            fillColor: 'blue',
            fillOpacity: 0.5,
            radius: 3,
            weight:1,
        }).addTo(me.leafletMap);


    },
    
    renderMap(retries) {
      var me=this;
      var containerElement=me.$el.parentElement;
      var mapElement=containerElement.querySelector(".weatherMap");

      if (!mapElement) {
        retries=retries || 0;
        if (retries<5) {
          me.$nextTick(
            function() {
              me.renderMap(retries++);
            }
          )
        } else {
          console.error("Could not find map element after 5 tries");
        }
        return;
      }


      if (!L) {
        console.error("No Leaflet found");
        return;
      }
      if (!me.leafletMap) {
        me.leafletMap = new L.Map(mapElement, {
          fadeAnimation:false,
          minZoom: 1,
          maxZoom: 16,
          center: [45, -103], 
          zoom:4,
          dateTime:null,
          dateTimeInterval:10,
        });

        const resizeObserver = new ResizeObserver(() => {
          me.leafletMap.invalidateSize();
        });

        resizeObserver.observe(mapElement);


        let legend= new L.Control.WxLegend({ position: "bottomleft", showDateTimeControl:true }).addTo(me.leafletMap);
        /*
        var Stamen_TonerBackground = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.{ext}', {
           // attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            subdomains: 'abcd',
              opacity:0.4,
            minZoom: 0,
            maxZoom: 20,
            ext: 'png'
          });


        me.leafletMap.addLayer(Stamen_TonerBackground);

        */
        var CartoDB_PositronNoLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 20
        });

        me.leafletMap.addLayer(CartoDB_PositronNoLabels);

        let forceRefresh=true;
       

        const reflectivityLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            model:"weatherRadarSubhourly_location",
            element: "reflectivity",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:10,
           // datetime:tileDateTime,
          });

        me.leafletMap.addLayer(reflectivityLayer);

       

        me.leafletMap.addLayer(reflectivityLayer);
        
        const forecastreflectivityLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            model:"weatherRadarSubhourly_location",
            element: "forecastreflectivity",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:10,
           // datetime:tileDateTime,
          });

         const relectivityHrrrForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            model:"hrrrsubhourly_location",
            element: "reflectivity",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:15,
           // datetime:tileDateTime,
          });

         const relectivityIcond2ForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            model:"icond2_location",
            element: "reflectivity",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:15,
           // datetime:tileDateTime,
          });

        // me.leafletMap.addLayer(forecastreflectivityLayer);
        const precipLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            model:"weatherRadarHourly_location",
            element: "precip",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
             dateTimeInterval:60,
          });

          const tempHrrrForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            
            model:"hrrrsubhourly_location",
            element: "temp",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:15,
          });

          const tempIcond2ForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            
            model:"icond2_location",
            element: "temp",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:60,
          });

          const precipHrrrForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            
            model:"hrrrsubhourly_location",
            element: "precip",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:15,
          });
          const precipIcond2ForecastLayer=new L.TileLayer.WxTileLayer({
            opacity:0.6,
            level:0,
            
            model:"icond2_location",
            element: "precip",
            forceRefresh:forceRefresh,
            key:VcGlobal.MAP_TILE_KEY,
            unitGroup:me.unitGroup,
            dateTimeInterval:15,
          });
         //me.leafletMap.addLayer(preciptypeLayer);
       

        me.wxLayers = {
              "Radar": reflectivityLayer,
              "Forecast Radar": forecastreflectivityLayer,
              
              "HRRR Radar": relectivityHrrrForecastLayer,
              "ICOND2 Radar": relectivityIcond2ForecastLayer,
              "Precip Amount": precipLayer,
              "HRRR Precip": precipHrrrForecastLayer,
              "ICOND2 Precip": precipIcond2ForecastLayer,
              "HRRR Temp": tempHrrrForecastLayer,
              "ICOND2 Temp": tempIcond2ForecastLayer,
        };

       

        var layerControl = L.control.layers(me.wxLayers).addTo(me.leafletMap);

        var CartoDB_VoyagerOnlyLabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20
          });

        me.leafletMap.addLayer(CartoDB_VoyagerOnlyLabels);


        me.leafletMap.on('contextmenu', function (e) {
          let contextMenu=L.DomUtil.create('div','d-flex flex-column');

          let copyLatLong=L.DomUtil.create('a','nav-link',contextMenu);
          let openLocation=L.DomUtil.create('a','nav-link',contextMenu);
          copyLatLong.innerText="Copy Lat,Lon";
          openLocation.innerText="Show ";

          let popup = L.popup({closeButton:false})
            .setLatLng(e.latlng)
            .setContent(contextMenu)
           
          let location=(Math.round(e.latlng.lat*10000)/10000)+","+(Math.round(e.latlng.lng*10000)/10000);
           
          
          L.DomEvent.on(copyLatLong, "click", function(e) {
            VcClipboardUtils.copyToClipboard(location);
            popup.setContent("Copied!")
            setTimeout(function() {me.leafletMap.closePopup(popup)}, 2000);
          });

          L.DomEvent.on(openLocation, "click", function(e) {
             me.$emit('update:location', location);
             setTimeout(function() {me.leafletMap.closePopup(popup)}, 1000);
          });




        
          popup.openOn(me.leafletMap);

        });
    
        me.leafletMap.on("datetimeupdated", function(d) {
          me.updateMapTimeLine(me.leafletMap.options.dateTime);
        })

        me.leafletMap.on('click', function (e) {
          //  me.updateWxDetail(e);
        });

        me.leafletMap.on('dblclick', function (e) {
          if (!e || !e.originalEvent || !e.originalEvent.ctrlKey) return;
          let location=(Math.round(e.latlng.lat*10000)/10000)+","+(Math.round(e.latlng.lng*10000)/10000);
          me.newLocation=location;
          me.setLocation();
          L.DomEvent.stopPropagation(e);
        });
      }

    
    },

    renderCharts() {
      console.log("renderCharts");
      var me=this;

      //window.addEventListener("resize", renderCharts);

      loadD3().then(d3=>{

        if (!window.d3) window.d3=d3;
        
        var reflectivitycontainer = d3.select(this.$el).select(".reflectivitycontainer");

        me.renderReflectivityChart(reflectivitycontainer);



      })

     
      
    },

    renderReflectivityChart(container) {

      console.log("renderReflectivityChart");
      var me=this;
      var data=me.minutely;

      if (!data) {
        console.log("no data");
        return;
      }
      
      const current=new Date();
      var currentTzoffset=current.getTimezoneOffset()*60000;

      function toLocalDateInstance(epochTime, tzoffset) {
        tzoffset=(tzoffset?tzoffset:me.wxdata.tzoffset);
        return new Date(epochTime*1000+tzoffset*3600000+currentTzoffset);
      }


      var currentLocal=toLocalDateInstance(current.getTime()/1000);

    

      data.reduce(function(a,d) {
            d.datetimeInstance=toLocalDateInstance(d.datetimeEpoch, d.tzoffset); //new Date(d.datetimeEpoch*1000+(d.tzoffset?d.tzoffset:me.wxdata.tzoffset)*3600000+tzoffset);
            return a;
        }, {
           
      });


      var chartContainer=container.select(".chart");

      if (chartContainer.empty()) {

        return;
      }

      var rect=chartContainer.node().getBoundingClientRect();
      var margin = {top: 0, right: 0, bottom:20, left:0} ; 
      var width = rect.width-(margin.left+margin.right);
      var height = rect.height-(margin.top+margin.bottom);


      var minWidth=1;
      var timeextent=d3.extent(data, function(d) { return d.datetimeInstance; });

      var periodCount=data.length; //me.isHourly?d3.timeHour.count(timeextent[0],timeextent[1]):d3.timeDay.count(timeextent[0],timeextent[1]);
      console.log("periodCount3="+periodCount);
      if (!periodCount) {
         return;
      }
      var periodWidth=width/(periodCount+1);
      if (periodWidth<minWidth) periodWidth=minWidth;
      var summaryPeriodInterval=Math.round(minWidth/periodWidth);
      //if the forecast periods (days or hours) will be too small, set them to a minimum size
      //in this case the periods will be draggable to view periods out of view
      //if (periodWidth<minWidth) {
      //    periodWidth=minWidth;
     // }

      width=periodWidth*(periodCount);
      //chartContainer.style("width",(width+periodWidth)+"px")

      container.selectAll(".period")
        .style("visibility", function (d,i) {
          return (i%summaryPeriodInterval===0)?"visible":"hidden";
        })
        .style("left",  function (d,i) {
          return (i*periodWidth)+"px";
        })
        .style("width", periodWidth+"px")
      //set up the x axis representing time
      var x = d3.scaleTime().range([0, width]);
      x.domain(timeextent);
      
      var chartDrawing;
      if (chartContainer.select("g").empty()) {
          var chartRoot=chartContainer; 

          chartRoot.append("clipPath")
              .attr("id", "clippath")   
              .append("rect")
                  .attr("x",0)
                  .attr("y",0)
                  .attr("width",0)
                  .attr("height",0)


          chartRoot=chartRoot.append("g")
              .attr("transform",
                  "translate(" + margin.left + "," + margin.top + ")")
              .attr("clip-path","url(#clippath)")

          
          chartDrawing=chartRoot.append("g")
              .attr("class", "container")
          
          let timeannotations=chartDrawing.append("g")
              .attr("class", "timeannotations")

          timeannotations.append("line")
              .attr("class", "nowline")

           timeannotations.append("line")
              .attr("class", "maptimeline")

          //future.append("rect")


          chartDrawing.append("path")
              .attr("class", "line")

          chartDrawing.append("path")
              .attr("class", "line2")
        
          
          /*
          chartDrawing.append("path")
              .attr("class", "precipline")

          chartDrawing.append("path")
              .attr("class", "precipprobline")
          
          chartDrawing.append("g")
              .attr("class", 'bars')


          */
          chartDrawing.append("linearGradient")
              .attr("id", "reflectivity-gradient")
              .attr("gradientUnits", "userSpaceOnUse")
        
          chartDrawing.append("g")			
              .attr("class", "grid")

          
      } else {
          chartDrawing=chartContainer.select(".container");
      }

      chartContainer.select("#clippath rect")
          .attr("x",-margin.left)
          .attr("width", (width+periodWidth))
          .attr("height", rect.height);

      chartDrawing.attr("transform", null);

      //set up vertical y scales for the temperature and precipitation fields
      var y = d3.scaleLinear().range([height, 0]);
      
      y.domain([0, d3.max(data, function(d) { return Math.max(d.reflectivity, d.reflectivity2)+5 })]);
      

      //var yprecip = d3.scaleLinear().range([height, height*0.6]);
      //var yprecipprob = d3.scaleLinear().range([height, height*0.6]);
      //area creation function for the temperature 

      let reflectivityarea = d3.area()
          .x(function(d, i) {
             return x(d3.timeMinute.offset( d.datetimeInstance,7.5));
          })
          .y0(height)
          .y1(function(d) { return y(d.reflectivity || 0); })
          .curve(d3.curveCatmullRom);
    
      let reflectivity2area=d3.area()
           
              .x(function(d, i) {
                return x(d3.timeMinute.offset( d.datetimeInstance,7.5));
              })
              .y0(height)
              .y1(function(d) { return y(d.reflectivity2 || 0); })
              .curve(d3.curveCatmullRom) ;

      
      //create a gradient that maps the colors to particular temperature
      //in this case we will use the turbo colors from d3 and interpolate them between 255 and 311K (approx 0-100F)
      var colorStopCount=12;


      var minReflectivity=y.domain()[0], maxReflectivity=y.domain()[1];

      var reflectivityInterval= (maxReflectivity-minReflectivity)/(colorStopCount+1);
      var colors=[];


      for (var i=0;i<=colorStopCount;i++) {
          colors.push({offset: i*(100/colorStopCount)+"%", color:VcWidgetHelper.dbzColor(minReflectivity+i*reflectivityInterval)});
        
      }

  
      //create the gradient stops based on the above stop array
      var stopsContainer=chartDrawing.select("#reflectivity-gradient").attr("x1", 0).attr("y1", y(y.domain()[0]))
              .attr("x2", 0).attr("y2", y(y.domain()[1]))
              .selectAll("stop")
              .data(colors, function(d) {return d.color})
      stopsContainer.exit().remove();
      stopsContainer=stopsContainer.merge(stopsContainer.enter().append("stop"))
      
      stopsContainer.attr("offset", function(d) { return d.offset; })
              .attr("stop-color", function(d) { return d.color; })
              .attr("stop-opacity", function(d,i) { return 0.5+0.5*i/colors.length; });
      


      //populate the area chart for the temperature view
      chartDrawing.select(".line")
          .data([data])
          //.transition()
          .attr("d", reflectivityarea);


      chartDrawing.select(".line2")
          .data([data])
          //.transition()
          .attr("d", reflectivity2area);

      /*
      chartDrawing.select(".precipline")
          .data([data])
          //.transition()
          .attr("d", preciparea);
      
      chartDrawing.select(".precipprobline")
          .data([data])
          //.transition()
          .attr("d", precipprobarea);
      */
      //draw vertical lines at the boundaries of each period
      chartDrawing.select(".grid")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x)
             .ticks(d3.timeHour.every(width>1000?1:3))
             // .ticks(periodCount)
              .tickSize(-10)
              .tickFormat(d3.timeFormat("%H"))
          )

      let nowx=x(currentLocal);

      /*
      chartDrawing.select(".future")
         .attr("transform", "translate("+nowx+",0)")
         .select("rect")
          .attr("width", (width-nowx))
          .attr("height", height);
      */
      chartDrawing.select(".timeannotations .nowline")
        .attr("x1", nowx)  
        .attr("y1", 20)
        .attr("x2", nowx)  
        .attr("y2", height);

      me.updateMapTimeLine=function(datetime) {
        datetime=toLocalDateInstance(datetime.getTime()/1000);
        let datetimex=x(datetime);
        chartDrawing.select(".timeannotations .maptimeline")
        .attr("x1", datetimex)  
        .attr("y1", 20)
        .attr("x2", datetimex)  
        .attr("y2", height);
      }

    },
    
  },
  computed:{
    
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation(this.wxdata, this.location, this.name, );
    },
    hasError:function() {
      return this.error;
    },
    hasData:function() {
      return this.focusDays;
    },
    currentConditions() {
      var me=this;
      return me.wxdata && me.wxdata.currentConditions;
    },
    showCurrent:function() {
      return this.currentConditions;
    },
    showPeriods:function() {
      return this.dayIndex>=0;
    },
    days1:function() {
      let me=this;
      return me.days && me.days.length>=3 && me.days.slice(0,1);
    },
    days1to3:function() {
      let me=this;
      return me.days && me.days.length>=3 && me.days.slice(0,3);
    },
    
    daysWeekend:function() {
      let me=this;
      return me.days && me.days.length>=3 && me.days.filter((d, i)=> 
        {
          let dow=new Date(d.datetimeEpoch*1000).getDay()
          return i<6 && (dow===0 || dow===6);
        });
    },


    days:function() {
      var me=this;
      if (!me.wxdata || !me.wxdata.days || !me.wxdata.days.length) return null;
      return me.wxdata.days
    },
    
    focusDays:function() {
      var me=this;
  
      if (!me.wxdata || !me.wxdata.days) return null;
      if (me.dayIndex || me.dayIndex===0) {
        if (me.endDayIndex>me.dayIndex) {
           return me.wxdata.days.slice(me.dayIndex, me.endDayIndex+1)
        } else {
           return [me.wxdata.days[me.dayIndex]];
        }
      }
      return me.wxdata.days;
    },
    showAlerts:function() {
      return this.alerts && this.alerts.length!==0;
    },
    alerts:function() {
        var me=this;
        return me.wxdata && me.wxdata.alerts;
    },
    minutely:function() {
        var me=this;
        let minutes=[];
        if (!me.wxdata || !me.wxdata.days) return [];
        let nowEpoch=new Date().getTime()/1000;
        let startTime=nowEpoch-12*3600;
        let endTime=nowEpoch+12*3600;
        me.wxdata.days.forEach(day=> {
          day.hours && day.hours.forEach((hour)=>{
            hour.minutes &&  hour.minutes.forEach((minute)=>{
              if (minute && minute.datetimeEpoch>=startTime && minute.datetimeEpoch<=endTime ) {
                minutes.push(minute);
              }
            });
          })
        });
        return minutes;
        /*
        return today.hours.reduce(function(a, d) {
          if (d && d.minutes) {
            a=a.concat(d.minutes);
          }
          return a;
        }, []);

        
        let today=me.today;

        if (!today || !today.hours) return null;

        return today.hours.reduce(function(a, d) {
          if (d && d.minutes) {
            a=a.concat(d.minutes);
          }
          return a;
        }, []);
        */
    },
    today:function() {
      var me=this;

      return me.wxdata && me.wxdata.days && me.wxdata.days.reduce((a,d)=> {
        if  (VcWidgetHelper.isToday(d.datetimeEpoch, me.wxdata.timezone))  return d;
        return a;
      }, null);

    },
    rainInVicinity:function() {
      var me=this;
      let lastNextRain=me.lastNextRain;
      return lastNextRain && (lastNextRain.latest || lastNextRain.next);
    },
   
    nowEpoch:function() {
      var me=this;
      let lastNextRain=me.lastNextRain;
      return lastNextRain && lastNextRain.nowEpoch;
    },
    lastNextRain:function() {
      var me=this;
      if (!me.minutely) return null;
      const nowEpoch=new Date().getTime()/1000;
      let latestPrecip=me.minutely.reduce((a,d)=>{
        if (d.datetimeEpoch>nowEpoch) return a;
        if (d.reflectivity>0) return d;
        return a;
      }, null);

    
      let nextPrecip=me.minutely.reduce((a,d)=>{
        if (d.datetimeEpoch<nowEpoch) return a;
        return a || (d.reflectivity>0 && d);
      }, null);

      let maxFuturePrecip=me.minutely.reduce((a,d)=>{
        if (d.datetimeEpoch<nowEpoch) return a;
        if (d.reflectivity>0 && (!a || d.reflectivity>a.reflectivity)) return d;
        return a;
      }, null);

      if (latestPrecip) {
        latestPrecip.minsAgo=Math.round((nowEpoch-latestPrecip.datetimeEpoch)/60);
      }

      if (nextPrecip) {
        nextPrecip.minsUntil=Math.round((nextPrecip.datetimeEpoch-nowEpoch)/60);
      }

      return {
        latest:latestPrecip,
        next:nextPrecip,
        maxFuture:maxFuturePrecip,
        nowEpoch:nowEpoch,
      }
    },
    nearbyRainWithNone:function() {
      var me=this;
      return (me.nearbyRain && me.nearbyRain) || "No rain nearby";
    },
    nearbyRain:function() {
      var me=this;
      let lastNextRain=me.lastNextRain;
      if (!lastNextRain || (!lastNextRain.latest && !lastNextRain.next)) return null;
      if (lastNextRain.latest && !lastNextRain.next) return me.lastRain;
      if (!lastNextRain.latest && lastNextRain.next) return me.nextRain;

      if (lastNextRain.latest.minsAgo<15 && lastNextRain.next.minsUntil<15) {
         return "Rain continuing";
      }

      return me.lastRain+". "+me.nextRain;
     
    },
    lastRain:function() {
      var me=this;
      

      let lastNextRain=me.lastNextRain;
      let latestPrecip=lastNextRain && lastNextRain.latest;
      
      if (!latestPrecip) return "No recent precip";

      //let nowEpoch=lastNextRain.nowEpoch;
     // const minsAgo=Math.round((nowEpoch-latestPrecip.datetimeEpoch)/60);
      let period;
      if (latestPrecip.minsAgo<10) {
        period="currently falling";
      } else {
        period=`${latestPrecip.minsAgo} minutes ago`
      }

      if (latestPrecip.reflectivity>40) {
        return `Heavy rain ${period}`;
      } else if (latestPrecip.reflectivity>20) {
        return `Moderate rain ${period}`;
      } else if (latestPrecip.reflectivity>0) {
        return `Light rain ${period}`;
      }
    },
    nextRain:function() {
      var me=this;
      if (!me.minutely) return null;

      let lastNextRain=me.lastNextRain;
      let nextPrecip=lastNextRain && lastNextRain.next;
      let maxFuture=lastNextRain && lastNextRain.maxFuture;

      if (!nextPrecip) return "No upcoming precip";

     // let nowEpoch=lastNextRain.nowEpoch;
      //const minsUntil=Math.round((nextPrecip.datetimeEpoch-nowEpoch)/60);
      let period;
      if (nextPrecip.minsUntil<10) {
        period="in any moment";
      } else {
        period=`in ${nextPrecip.minsUntil} minutes`
      }
      let output;
      if (nextPrecip.reflectivity>50) {
        output= `Torrential rain ${period}`;
      } else if (nextPrecip.reflectivity>30) {
        output= `Heavy rain ${period}`;
      } else if (nextPrecip.reflectivity>20) {
        output= `Moderate rain ${period}`;
      } else if (nextPrecip.reflectivity>0) {
        output= `Light rain ${period}`;
      }
      if (maxFuture.reflectivity>nextPrecip.reflectivity*2) {
        output+= ` becoming a lot heavier`;
      } else if (maxFuture.reflectivity>nextPrecip.reflectivity) {
        output+= ` becoming heavier`;
      } else if (maxFuture.reflectivity<nextPrecip.reflectivity) {
        output+= ` becoming lighter`;
      }

      return output;
    },
    summarykpicount:function() {
        return this.summarykpis?this.summarykpis.length:0;
    },
    showSummary() {
      return this.doShow('icon') || this.summarykpicount
    },
    summaryCssClasses() {
      if (this.doShow('icon')) return 'summarykpis'+this.summarykpicount
      return 'summarykpis'+this.summarykpicount+" noicon"; 
    },
    widgetcolumns() {
     
      var preferredColumns=this.widgetcolumncount;
      // console.log("columns:"+preferredColumns)
      if (!preferredColumns || preferredColumns<2) return "onecols";
      if (preferredColumns==2) return "twocols";
      if (preferredColumns==3) return "threecols";
      if (preferredColumns==4) return "fourcols";
      return "onecols";
    }



  },
  watch: {
    /*
    newLocation:{
        handler:function(newValue,oldValue) {
          var me=this;
          if (!newValue) return;
          me.location=newValue;
          me.refreshData(true);
        },
        deep: true
    },*/
    config:{
        handler:function(newValue,oldValue) {
          var me=this;
          var containerElement=me.$el.parentElement;
          VcWidgetHelper.readElementConfig(containerElement, me);
          me.refreshData(true);
        },
        deep: true
    },
  },
  mounted() {
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);

    me.renderMap();
    me.refreshData();
    
    me.onresize=VcWidgetHelper.debounce(function () {
        me.renderCharts();
    }, 250);

    window.addEventListener("resize", me.onresize);
  },
   components: {
    DayWidget,
    //LocationEditor
  }
}
</script>

<style scoped>

.expanded .fs-xs {
   /*font-size:1rem  !important;*/
}

.expanded {
  margin:10px 0px 10px 0px !important;
  font-size:1.5em  !important;
}
.expanded .expandable {
  display:block !important;
}

.weatherMap {
   /* height: calc(50vh) !important;*/
    min-height:50vh;
    height:100%;
}

.fullscreen {
  height: 100svh  !important; /*quick hack because 100% hidden by mobile browser toolbar */
  width: 100vw !important;
  position: fixed;
  top: 0vh;
  right: 0;
  left: 0;
  z-index: 1030;
  margin: 0 !important;
  padding: 0px;
  background: #ffffffd1;
}
:deep() .mapexpand {
  display:block;
}
:deep() .mapcollapse {
  display:none;
}
.fullscreen :deep() .mapexpand {
  display:none;
}

.fullscreen :deep() .mapcollapse {
  display:block;
}

:deep() .mapplay {
  display:block;
}
:deep() .mappause {
  display:none;
}
.isplaying :deep() .mapplay {
  display:none;
}

.isplaying :deep() .mappause {
  display:block;
}

:deep() .icon  > div {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
    overflow: hidden;
}



.chartcontainer {
    grid-column: 1/-1;
    min-height:150px;
    display:flex;
    flex-direction: column;
    position:relative;
  }

  .chartcontainer svg {
    flex:1;
  }

  .chartcontainer :deep() .axis  {
    font-size:0.6em;
  }

  .chartcontainer :deep() .axis line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }
  .chartcontainer :deep() .axis  path {
    stroke-width: 0;
  }

  .chartcontainer {
    position:relative;
    min-height: 125px;
    font-size: 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .chartcontainer .chart {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .chartcontainer .chart :deep()  .line {
      fill: url(#reflectivity-gradient);
      stroke-width: 05px;
  }
  .chartcontainer .chart :deep()  .line2 {
      fill: url(#reflectivity-gradient);
      fill-opacity: 0.2;
      stroke: rgb(68, 68, 68);
      stroke-width: 1px;
      stroke-opacity: 0.5;
      

  }
  .chartcontainer .chart :deep()  .precipline {
      fill: #045a8d;
     stroke-width: 2px;
  }
  .chartcontainer .chart :deep()  .precipprobline {
      fill: #7cb5d6;
      stroke-width: 2px;
  }
  .chartcontainer .chart :deep() .grid line {
    stroke: darkgray;
    shape-rendering: crispEdges;
  }

  .chartcontainer .periods {
    position: absolute;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:flex;
    flex-direction: row;
    height: 100%;
  }
  .chartcontainer .periods .period {
    position: absolute;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    /* grid-gap: 1px; */
    cursor: grab;
    height: 100%;
    color:#8e8b8b;
    font-size: 1.1em;
    white-space: nowrap;
    cursor:pointer;
  }

  .chartcontainer .periods .period .date {
    font-size: 0.8em;
  }
  .chartcontainer .periods .period .dow {
    font-weight:bold;
  }
  .chartcontainer .periods .period .time {
   
  }
  .chartcontainer .periods .period.hour {
    font-size: 0.8em;
    cursor:default;
  }
		

  .chartcontainer .periods .period.conditions {
    display:none;
    color:#8e8b8b;
    font-size: 0.9em;
  }

  .chartcontainer .periods .period  .maxt {
    font-weight: bold;
  }
  .chartcontainer .periods .period  .mint {
    font-size: 0.9em;
    color:#8e8b8b;
  }
  
  .chartcontainer .periods .period  .hidden {
    visibility: hidden;
    display:none;
  }
  .chartcontainer .periods .period .precip {
    margin-top: auto;
    color: #163753;
    font-size: 0.9em;
  }
  .chartcontainer .chart :deep()  .future  {
      fill: #ebeaea;
      stroke-width: 2px;
  }
  .chartcontainer .chart :deep()  .nowline {
    stroke: #e33939;
  }

  .chartcontainer .chart :deep()  .maptimeline {
    stroke: #302f2f;
  }

  .chartcontainer .chart :deep()  .future .title {
    text-anchor: end;
    alignment-baseline: hanging;
    font-size: 0.6em;
  }
/*
  .kpi {
    display: grid;
    gap: 0px;
    justify-items: center;
  }
  
  .alerts {
    margin-top:15px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 10px;
  }
  .alerts .event {

  }
  .alerts .desc {
    font-size:0.6em;
    width:100%;
  }
  .current {
    grid-column: 1/-1;
  }

  .maingrid :deep() .normals {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: .5em;
    row-gap: 0px;
    column-gap: 5px;
    align-items: center;
    justify-items: center;
   }

 
  .maingrid :deep() .kpi.summary {
    grid-column: span 1;
    line-height: 20px;
    font-size:1.2em; 
    font-weight: 400;
  }
  .maingrid :deep() .kpi {
    grid-column: span 1;
  }
  
  .maingrid .period {
    grid-column: 1/-1;
  }

  .maingrid .summary {
    grid-column: 1/-1;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    align-items: center;
    justify-items: center;
    margin-top:10px;
    margin-bottom:10px;
  }
  .summary .icon {
    grid-column: 1/1;
  }

  .summary.summarykpis0 .icon {
    grid-column: 1/-1;
  }

  .summary.summarykpis1 {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis1.noicon {
    grid-template-columns: repeat(1, 1fr);
  }
  .summary.summarykpis2 {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis2.noicon {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis3 {
    grid-template-columns: repeat(4, 1fr);
  }
  .summary.summarykpis3.noicon {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis4 {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis4.noicon {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis4 .icon {
    grid-column: 1/1;
    grid-row: span 2;
  }


  .maingrid .description {
    grid-column: 1/-1;
    white-space: normal;
  }
  .maingrid .details {
    margin-top:10px;
    border-top:1px solid #929292;
    padding-top:10px;
    grid-column: 1/-1;
    font-size: 0.6em;
    color:#929292;

  }
 
  .maingrid .moonphase {
     grid-column: 1/-1;
  }
  

  @media only screen and (min-width: 800px) {
    .maingrid .sunrise, .maingrid .sunset {
      grid-column: span 2;
    }
    .maingrid .moonphase {
      grid-column: span 2;
    }
  }

*/
 

</style>
