<template>
              
    <div class="">

    

      <div class="d-flex align-items-center justify-content-start">
         <button class="dropdown-toggle badge btn text-primary my-2 px-3 ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
          Sort by: {{sortby}}
         </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="sortby='name'">Name</a></li>
              <li><a class="dropdown-item" href="#" @click="sortby='latest'">Latest</a></li>
              <li><a class="dropdown-item" href="#" @click="sortby='oldest'">Oldest</a></li>
            </ul>
                   
        <button type="button" class="btn text-primary btn-sm  p-1 fs-5 "  @click="loadAvailableTasks()"><i class="bi-arrow-repeat"></i></button>
        
      </div>
      <div v-if="isLoading" class="d-flex align-items-center justify-content-center" style="min-height:300px">
        <div class="spinner-border mx-2" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Loading...
      </div>
      <div v-else-if="!hasAvailableTasks" style="min-height:300px">No data definitions found.</div>
      <div v-else class="overflow-auto" style="min-height:300px;max-height:600px">
        <ul class="list-group">
          
          <div  v-for="(task, i) in availableTasks" class="list-group-item d-flex" >
              <div class="d-flex flex-wrap w-100 justify-content-between">
               
                <button v-if="hasWriteAccess(task)" class="btn btn-sm text-primary px-1" type="button" @click="selectTaskDefn(task)" >
                  <i class="bi-table text-muted me-2"></i>
                  {{task.name}}
                </button>
                 <button  v-else class="btn btn-sm text-primary px-1" type="button" disabled="true" >
                  <i class="bi-table text-muted me-2"></i>
                  {{task.name}}
                </button>
               
                <button v-if="hasSchedule(task)" type="button" class="btn text-primary btn-sm p-1 fs-5" :disabled="!hasWriteAccess(task)" @click="selectTaskDefn(task, 'export')" ><i class="bi-clock p-1"></i></button>
                <!--<button v-if="hasWriteAccess(task)" type="button" class="btn text-primary btn-sm p-1 fs-5" @click="selectTaskDefn(task)"><i class="bi bi-pencil"></i></button>-->
                <button v-if="hasWriteAccess(task)" type="button" class="btn text-primary btn-sm p-1 fs-5" @click="deleteMetadata(task)"><i class="bi bi-trash"></i></button>
                
                <span class="ms-auto">&nbsp;</span>
                <template v-if="task.instance">
                  <div v-if="task.instance.status===1" class="d-flex fst-italic fs-sm align-items-center">
                    Queued... 
                  </div>
                  <div v-if="task.instance.status===2" class="d-flex fst-italic fs-sm align-items-center">
                    <div v-if="task.instance.completed>0 && task.instance.total>1">Completed {{task.instance.completed}} of {{task.instance.total}}</div>
                    <div v-else>Running...</div>
                  </div>

                  <div v-if="task.instance.status===4" class="d-flex  flex-column fst-italic fs-sm align-items-center justify-content-end ">
                    Last execution failed 
                    <button type="button" class="btn text-primary btn-sm fs-sm" @click="showTaskResultInfo(task)" title="Result information" >
                      More info
                    </button> 
                  </div>

                  <template v-if="task.instance.status===3">
                 
                    <div class="d-flex align-items-center justify-content-start flex-column ">
                        <div class="btn-group ms-auto" role="group" aria-label="View options">
                          
                          <button type="button" class="btn text-primary btn-sm px-1 fs-5" @click="downloadResult( task)"
                            title="Download data" >
                            <i class="bi bi-download"></i>
                            </button> 

                          <button type="button" class="btn text-primary btn-sm px-1 fs-5" @click="copyLink(task, false)"
                            title="Copy link" >
                            <i class="bi bi-link"></i>
                            </button> 

                          <button type="button" class="btn text-primary btn-sm px-1 fs-5" @click="copyLink(task, true)"
                            title="Copy link to zip" >
                          <i class="bi bi-file-earmark-zip"></i>
                          </button> 

                          <button type="button" class="btn text-primary btn-sm px-1 fs-5" @click="showTaskResultInfo(task)"
                            title="Result information" >
                          <i class="bi bi-info"></i>
                          </button> 
                         
                        </div>
                        <div class="form-text fs-sm mt-0">{{formatDateTime(task.results[0].dateTime)}}</div>
                    </div>
                  
                  
                  </template>
                  <div v-if="task.instance.status===5" class="d-flex fst-italic fs-sm align-items-center">
                    Last result expired 
                  </div>
                </template>
                
                <div v-else class="d-flex fst-italic fs-sm align-items-center">
                    No result available
                </div>
               
              </div>
              <div v-if="task.desc" class="d-flex flex-wrap w-100 justify-content-between">
                <div class="fs-sm text-muted">{{task.desc || "No description"}}</div>
              </div>
            </div>
          
        </ul>
      </div>

      <div class="modal fade" id="resultInfoModel" tabindex="-1" aria-labelledby="resultInfoModelLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="resultInfoModelLabel">Result information</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body overflow-auto">
              <template v-if="selectedTaskResultDefn && selectedTask">
                <h5>{{selectedTask.name}}</h5>
                <div>Id: {{selectedTask.id}}</div>
                <div>Start time: {{formatDateTime(selectedTaskResultDefn.dateTime)}}</div>
                <div>Finish time: {{formatDateTime(selectedTaskResultDefn.endDatetime)}}</div>
                <div>Expiration time: {{formatDateTime(selectedTaskResultDefn.expirationDateTime)}}</div>
                <div>Locations: {{selectedTaskResultDefn.total}}</div>
                <div>Successes: {{selectedTaskResultDefn.successful}}</div>
                <div>Failures: {{selectedTaskResultDefn.failed}}</div>
                <!--<div>Result is error: {{selectedTaskResultDefn.error}}</div>-->
                <template v-if="selectedTaskResultDefn.info">
                  <h6>Warnings</h6>
                  <div>
                    {{selectedTaskResultDefn.info}}
                  </div>
                  <!--<div v-for="(warning,index) in selectedTaskResultDefn.warnings">
                    {{warning}}
                  </div>-->
                </template>
                <!--<div>{{JSON.stringify(selectedTaskResultDefn)}}</div>-->
              </template>
              <div v-else>No result information found</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>

</template>

<script>
//import {VcCommunicator} from "/../visualcrossing-core-js/src/commonlib";
import "/../visualcrossing-core-js/src/vccommunicator_metadata";
import {VcMetadataUtils} from "/../visualcrossing-core-js/src/vcmetadatautils";
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils';
//import ConfirmationModal from "./CommonModals.vue";
export default {
  name: 'BulkDefinitionsList',
  props: {
    sessionId:String,
    sessionState:Object,
  },
   emits: ['select:task'],
  data() {
    return {
      taskList:[],
      resultList:[],
      isLoading:false,
      selectedTask:null,
      confirmPackage: {
        title:"",
        content:"",
        show:false,
        handle:null
      },
      view:"tasklist",
      selectedTaskResultDefn:null,
      selectedTask:null,
      sortby:"latest"
    }
  },
  methods:{
    jobStatus(status) {
      return VcMetadataUtils.jobStatusText(status);
    },
    formatDateTime(epochSecs) {
      return VcDateUtils.formatDateTime2(epochSecs,null, {
        year:'numeric',
        month: 'long', 
        day:'numeric',
        hour: 'numeric', 
        minute: 'numeric',  
        hour12: false
        
      })
    },
    selectTaskDefn(metadata, panelView) {
      var me=this;
      vcCommunicator.loadBulkSourceDefn(metadata.id)
        .then(function (taskDefn) {
          me.$emit("select:task", metadata, taskDefn, panelView);
        }).catch((error) => {
          console.log("selectTaskDefn failed:"+error);
        });
    },

    showTaskResultInfo(taskMetadata) {
      var me=this;
       me.selectedTask=taskMetadata;
       me.selectedTaskResultDefn= me.selectedTask.instance;
       var target=me.$el && me.$el.querySelector("#resultInfoModel");
       var modal =target &&  bootstrap.Modal.getOrCreateInstance(target);
       modal && modal.show();
      
    },

    downloadResult(task, downloadAsZip) {
      task=task || getTaskDefn(result);
      var request=vcCommunicator.constructBulkWeatherDataRequest(task, true, downloadAsZip);
      window.open(request);
    },

    copyLink:function(task, downloadAsZip) {
      var request=vcCommunicator.constructBulkWeatherDataRequest(task, true, downloadAsZip);
      VcClipboardUtils.copyToClipboard(request)
      VcCommonModals.showToast("Link copied", "The result URL link been copied to the clipboard")
    },

    hasWriteAccess(metadata) {
        return VcMetadataUtils.hasWriteAccess(metadata);
    },
    hasSchedule(metadata) {
      return VcMetadataUtils.getTagPrefixValue(metadata, "Schedule");
    },
    deleteMetadata(object) {
      console.log('deleteMetadata')
      var me=this;

      window.VcCommonModals.showConfirm("Confirm delete", "Are you sure you want to delete this object?")
        .then(function(id) {
          vcCommunicator.deleteMetadata(object).then(function (results) {
            me.loadAvailableTasks();
          }).catch((error) => {
            console.log("Delete object failed:"+error);
            me.loadAvailableTasks();
          });
          
        }).catch((error) => {
          console.log("User said no to delete")
        });


    },
    loadAvailableTasks() {
      var me=this;
      me.isLoading=true;
      vcCommunicator.resultsByJobPackage().then(function (results) {
       
        me.taskList=results;
        me.isLoading=false;

      }).catch((error) => {
        console.log("Failed loadAvailableTasks:"+error);
         me.isLoading=false;
      });


    },
    getTaskDefn(result) {
      var me=this;
      return me.taskList.reduce(function(a, taskResults) {
        return a || (taskResults.jobPackage && taskResults.jobPackage.id===result.taskId && taskResults.jobPackage);
      }), null;
    },
  },
  computed:{
    hasAvailableTasks() {
      return this.availableTasks && this.availableTasks.length>0;
    },
    
    availableTasks:function() {
        var me=this;
        if (!me.taskList || !me.resultList) return null;

        var tasks= me.taskList.reduce(function(a, taskResults) {
          if (!taskResults || !taskResults.jobPackage || (!me.hasWriteAccess(taskResults.jobPackage) )) return a; //&& (!taskResults.results || !taskResults.results.length)
          a.push(taskResults.jobPackage);
          return a;
        }, []);

        if (me.sortby==="name") {
           return tasks.sort(function(a,b) {
                if (a && b && a.name) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
                
              });
          
        } else if (me.sortby==="latest") {
            return tasks.sort(function(a,b) {
                var atime=(a  && a.results && a.results[0] && a.results[0].dateTime && a.results[0].dateTime.getTime()) || 0;
                var btime=(b  && b.results && b.results[0] && b.results[0].dateTime && b.results[0].dateTime.getTime()) || 0;
                return btime-atime;
              });
        } else if (me.sortby==="oldest") {
            return tasks.sort(function(a,b) {
                var atime=(a && a.results && a.results[0] && a.results[0].dateTime.getTime()) || 0;
                var btime=(b && b.results && b.results[0] && b.results[0].dateTime.getTime()) || 0;
                return atime-btime;
              });
        }


      },
    
  },
  watch:{
    
  },
  mounted() {
    var me=this;
    me.loadAvailableTasks();
  },
  updated() {
    var me=this;
    //me.loadAvailableTasks();
  },
  components: {
   // ConfirmationModal,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
