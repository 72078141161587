<template>
  <div class=" d-flex flex-column">

    <div  class="d-flex align-items-center my-2">
      <div v-if="!locations.length"  class="text-primary h6 m-0">Please add or import a location</div>
      <div v-if="locations.length===1"  class="text-primary h6 m-0">You currently have one location</div>
      <div v-if="locations.length>1"  class="text-primary h6 m-0">You currently have {{locations.length}} locations</div>
      <!--<span class="badge bg-success ms-auto">{{locations.length}} locations</span>-->
      <button v-if="locations.length" class="btn btn-sm text-primary ms-auto" type="button" @click="clearLocations()">Clear All</button>
    </div>

    <div v-if="locations.length>5" class="d-flex">
      <div class="input-group input-group-sm w-lg-50">
        <span class="input-group-text text-muted">
          <i class="bi bi-search"></i>
        </span>
        <input type="text" class="form-control"   placeholder="Search locations" v-model="searchText"  >
        <!--<button class="btn btn-outline-primary" type="button"  title="Search locations" :disabled="!searchText" ><i class="bi bi-search" ></i></button>-->
      </div>
    </div>
    <ol v-if="locations.length" class="list-group mb-3 overflow-auto">
      <li v-for="location in pageLocations" class="list-group-item d-flex justify-content-between align-items-start py-1 fs-sm">
        <div class="ms-1 me-auto">
          <div class=""><a href="#"  @click="selectLocation(location)">{{location.location}}</a></div>
          <span v-if="location.name">{{location.name}}</span>
        </div>
        <button type="button" class="btn-close" aria-label="Remove"  @click="removeLocation(location)" title="Remove location"></button>
      </li> 
    </ol> 
      <div v-if="hasPages" >
        <ul class="pagination fs-xs mt-1">
          <li class="page-item"  :class="{disabled: 0===pageIndex}">
            <a class="page-link" @click="pageIndex=0" href="#" aria-label="Start">
              <span aria-hidden="true">&lt;&lt;</span>
            </a>
          </li>
            <li class="page-item"  :class="{disabled: 0===pageIndex}">
            <a class="page-link" @click="pageIndex--" href="#" aria-label="Previous">
              <span aria-hidden="true">&lt;</span>
            </a>
          </li>
          <li v-for="page in visiblePages" class="page-item" :class="{active: page===pageIndex}"><a class="page-link" href="#" @click="pageIndex=page">{{page+1}}</a></li>
          
          <li class="page-item"   :class="{disabled: pageCount-1===pageIndex}">
            <a class="page-link" @click="pageIndex++"  href="#" aria-label="Next">
              <span aria-hidden="true">&gt;</span>
            </a>
          </li>
          <li class="page-item"    :class="{disabled: pageCount-1===pageIndex}">
            <a class="page-link" @click="pageIndex=pageCount-1" href="#" aria-label="Last">
              <span aria-hidden="true">&gt;&gt;</span>
            </a>
          </li>
        </ul>
      </div>
   <!--  <div class="d-flex">
       <div class="h6 mt-3">Add a location </div>
     </div>
   --->
    <div class="row ">

      <div class="col-12 col-md-6">
         <div class="input-group input-group-sm">
          <input type="text" class="form-control"  v-on:keyup.enter="addLocation()" aria-describedby="locationHelpBlock" placeholder="Add a location" v-model="newLocation"  >
          <button class="btn btn-primary" type="button" @click="addLocation()" title="Add location" :disabled="!newLocation" ><i class="bi bi-plus-circle" ></i></button>
        </div>
      </div>
     
      <button  type="button" class="btn btn-sm text-primary col-12 col-md-6"  data-bs-toggle="modal" data-bs-target="#locationsImportModal">
         <i class="bi bi-file-earmark"></i>Import from file or clipboard
      </button>

      <div id="locationHelpBlock" class="form-text col-12">
          Locations can be addresses, partial addresses or latitude,longitude values.
      </div>

    </div>
   
      
    <!--  <button class="btn btn-primary btn-sm ms-auto" :class="{disabled:!newLocation}" :disabled="!newLocation" type="button" @click="addLocation()">Add location</button>-->
    
 

    <div class="modal fade"  id="locationsImportModal" tabindex="-1" aria-labelledby="locationsImportModalLabel" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl" >
        <div class="modal-content"  style="height:80vh;">
          <div class="modal-header">
            <h5 class="modal-title" id="locationsImportModalLabel">Import locations from file or clipboard</h5>
            <!--<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>-->
          </div>
          <div class="modal-body overflow-auto">
             
            <div class="steps mb-1 mb-lg-3">
      <a href="#" class="step " :class="[hasCompletedImportStep('rawdata')?['pe-auto','active']:['pe-none'] ]" @click="setImportStep('rawdata')">
        <div class="step-progress">
          <span class="step-progress-start"></span>
          <span class="step-progress-end"></span>
          <span class="step-number">1</span>
        </div>
        <div class="step-label">Enter Data</div>
      </a>
      <a href="#" class="step pe-none" :class="[hasCompletedImportStep('reviewparse')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('reviewparse')">
        <div class="step-progress">
          <span class="step-progress-start"></span>
          <span class="step-progress-end"></span>
          <span class="step-number">2</span>
        </div>
        <div class="step-label">Review data</div>
      </a>
      <a href="#" class="step pe-none" :class="[hasCompletedImportStep('locationfields')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('locationfields')">
        <div class="step-progress">
          <span class="step-progress-start"></span>
          <span class="step-progress-end"></span>
          <span class="step-number">3</span>
        </div>
        <div class="step-label">Specify locations</div>
      </a>
      <a href="#" class="step pe-none" :class="[hasCompletedImportStep('verifylocations')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('verifylocations')">
        <div class="step-progress">
          <span class="step-progress-start"></span>
          <span class="step-progress-end"></span>
          <span class="step-number">4</span>
        </div>
        <div class="step-label">Finish import</div>
      </a>
    </div>
    
    <template v-if="importStep==='rawdata'">

      <div v-if="bulkMode=='file'" class="mb-3">
          <label for="formFile" class="form-label fs-sm">Provide data in CSV format that includes address or latitude,longitude columns</label>
          <input class="form-control" type="file" id="formFile" accept=".csv" @change="addTextFile($event)">
          <div class="col-12 d-flex">
            <button  type="button" class="btn btn-sm text-primary ms-auto px-0"  @click="bulkMode='clipboard'" :class="{disabled:false}">
              <i class="bi bi-clipboard"></i>Import from clipboard
            </button>
          </div>
      </div>
      <div class="pasteTextInput"  v-if="bulkMode=='clipboard'">
          <label for="exampleFormControlTextarea1" class="form-label">Provide data in CSV format that includes address or latitude,longitude columns</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="rawData" placeholder="Paste data here"></textarea>
          <div class="col-12 d-flex">
            <button  type="button" class="btn btn-sm text-primary  ms-auto px-0"  @click="bulkMode='file'" :class="{disabled:false}">
              <i class="bi bi-file-earmark"></i>Import from file
            </button>
          </div>
      </div>

    </template>
     
    <template v-if="importStep==='reviewparse'">
      <!--<p>Assign columns to the location and name.</p> -->
      <div class="table-responsive">
        <table class="table table-sm fs-sm">
          <thead>
            <tr>  
              <th v-for="(col,index) in columns">
                <div class="">
                  <div>{{col}}</div>
                  <!--
                  <select class=" form-select-sm" id="select-input" v-model="columns[index].type">
                    <option>Assign column...</option>
                    <option v-for="(type,index2) in columnTypes" >{{type}}</option>
                  </select>
                  -->
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(record,index) in previewRecords">
              <tr>
                  <td v-for="(v,index2) in record" scope="col" class="text-nowrap px-2 py-1" >{{v}}</td>
              </tr>
            </template>
          </tbody>
            
        </table>
      </div>
      <div v-if="parsingError" class="col-12 d-flex">
        {{parsingError}}
      </div>
      <div v-if="rawData" class="col-12 d-flex">
        <div v-if="totalRecords>=0" class="form-text mt-0 mb-1 ">
            {{totalRecords}} total rows.
        </div>
        <div class="form-check form-check-inline ms-auto">
          <input type="checkbox"  id="hasheaders" v-model="dataHasHeaders" class="form-check-input"   />
          <label class="form-check-label" for="hasheaders">My data has headers</label>
        </div>
      </div>

      


    


    </template>
    <template v-if="importStep==='locationfields'">
      <!--
      <div class="form-label fs-sm">Select the fields to create a valid location such as address, partial address or latitude,longitude value</div>

      <div class="d-flex ">
        <button v-for="(field,index) in locationFields" type="locationFields" class="btn btn-secondary btn-xs  rounded-pill me-3" @click="locationFields.splice(index,1)">
          {{field}}
          <i class="bi bi-trash"></i>
          
        </button>
        
        <button class="dropdown-toggle  btn btn-primary btn-xs" type="button" id="chooseFieldDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
          <span class="">Add column</span>
        </button>
          <ul class="dropdown-menu" aria-labelledby="chooseFieldDropdownButton">
            <li v-for="(col,index) in columns"  @click="locationFields.push(col.id)"><a class="dropdown-item" href="#"><span class="">{{col.id}}</span></a></li>
        </ul>
      </div>

      <template v-if="resolvedLocationsPreview.length">
        <div class="table-responsive>
        <table class="table table-striped table-sm fs-sm">
          <thead>
            <tr>  
              <th>Location preview</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="(preview,index) in resolvedLocationsPreview">
                <td v-html="preview"></td>
            </tr>
          </tbody>
            
        </table>
      </div>

      </template>
      <div class="d-flex">
        <button  type="button" class="btn btn-sm btn-secondary ms-auto me-2"  @click="cancelImportLocations()">
          Cancel
        </button>
        <button class="btn btn-primary btn-sm" :class="{disabled:!rawData}" :disabled="!locationFields.length" type="button" @click="setImportStep('verifylocations')">Continue</button>
      </div>
      -->
      <div class="mb-2">
        <div class="form-label">My data contains</div>
        <div class="d-flex">
          <div class="form-check me-3">
            <input class="form-check-input" type="radio" v-model="locationType" value="address" id="address" />  <label class="form-check-label" for="address">Address or partial address</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="locationType" value="latlon" id="latlon" /><label class="form-check-label" for="latlon">Latitude and longitude</label>
          </div>
         </div>
      </div>
      <div>
       <div class="row mb-3">
          <div v-for="field in selectedLocationFields" class="col-12 mb-0" :class="field.class">
            <label class="form-label fs-xs mb-0" for="select-input">{{field.name}}</label> 
            <div class="">
              <select class="form-select  form-select-sm" id="select-input"  v-model="field.col">
                <option value="">&nbsp;</option>
                <option v-for="(col,index2) in columns" :value="col">{{col}}</option>
              </select> 
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="field in nameFields" class="col-12 mb-1" :class="field.class">
            <label class="form-label mb-0" for="select-input">{{field.name}}</label> 
            <div class="">
              <select class="form-select  form-select-sm" id="select-input"  v-model="field.col">
                <option value="">&nbsp;</option>
                <option v-for="(col,index2) in columns" :value="col">{{col}}</option>
              </select> 
            </div>
          </div>
        </div>
        <template v-if="hasPreview">
          <div class="table-responsive">
            <table class="table table-striped table-sm fs-sm">
              <thead>
                <tr>  
                  <th>Location</th> <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="(record,index) in previewRecords">
                    <td>{{getRecordLocation(record,"-")}}</td><td>{{getRecordName(record,"-")}}</td>
                </tr>
              </tbody>
                
            </table>
          </div>
        </template>
        <!--
          <ol v-if="locations.length" class="list-group mb-3 overflow-auto" style="min-height:150px">
            <li v-for="field in selectedLocationFields" class="list-group-item p-0">
              <label class="form-label fs-sm" for="select-input">{{field.name}}</label> 
              <select class="form-select  form-select-sm" id="select-input"  v-model="field.col">
                <option value="">&nbsp;</option>
                <option v-for="(col,index2) in columns" :value="col">{{col}}</option>
              </select>
            </li> 
          </ol> 
          -->
      </div>
    </template>
    <template v-if="importStep==='verifylocations'">
     
      <div class="p-lg-5">
         <div class="d-flex align-items-center ">
          <div class="text-primary h6 mb-3">Your locations are ready to import</div>
        </div>
        <p class="">
          <span class="">{{totalRecords-badTotalRecords}} locations will be imported. </span>
          <span v-if="badTotalRecords>1" class="fw-bold text-danger">There are {{badTotalRecords}} records with an invalid location.</span>
          <span v-if="badTotalRecords===1" class="fw-bold text-danger">There is one record with an invalid location.</span>
        </p>
        <p>
           To download weather data for all locations, create the query in the query builder and hit 'download' to download the data as CSV, Excel or JSON format.
        </p>
        
        <div class="form-check form-check-inline ">
          <input type="checkbox"  id="clearOnImport" v-model="clearOnImport" class="form-check-input"   />
          <label class="form-check-label" for="clearOnImport">Remove any existing locations</label>
        </div>
<!--
        <div class="form-check form-check-inline ">
          <input type="checkbox"  id="cleanLocations" v-model="cleanLocations" class="form-check-input"   />
          <label class="form-check-label" for="cleanLocations">Remove duplicates, empty values etc</label>
        </div>
        -->
      </div>
     
      
     </template>

          </div>
          <div class="modal-footer">
            <div  class="d-flex">
              <button type="button" class="btn btn-sm btn-secondary ms-auto me-2"  @click="cancelImportLocations()" data-bs-dismiss="modal" >Cancel</button>
              <button v-if="importStep==='rawdata'" class="btn btn-primary btn-sm " :class="{disabled:!rawData }" :disabled="!rawData " type="button" @click="setImportStep('reviewparse')">Continue</button>
              <button v-if="importStep==='reviewparse'" class="btn btn-primary btn-sm" :class="{disabled:!rawData || !totalRecords}" :disabled="!rawData || !totalRecords" type="button" @click="setImportStep('locationfields')">Continue</button>
              <button v-if="importStep==='locationfields'" class="btn btn-primary btn-sm" type="button" :disabled="!hasLocationFields"  @click="setImportStep('verifylocations')">Continue</button>
              <button v-if="importStep==='verifylocations'" class="btn btn-primary btn-sm" :class="{disabled:!rawData}" :disabled="!resolvedRecords.length" type="button"  @click="addImportedLocations()" data-bs-dismiss="modal">Import locations</button>
            </div>
          </div>
        </div>
      </div>
    </div>


 
  
    
  </div>   
 

</template>

<script>
import '/src/VcUtils'
import { parse } from 'csv-parse';
import Template from './Template.vue';

export default {
  name: 'locationsEditor',
  props: {
    parentLocations: Object,  
    subscriptionParams: Object,
  },
  emits: ['locationsChanged','selectLocation'],
  data() {
    return {
      locationType:"address",
      locations: this.parentLocations,
      selectedLocation:null,
      newLocation:null,
      searchText:null,
      newName:null,
      entryMode:"single",
      bulkMode:"file",
      importStep:"rawdata",
      rawData:null,
      records:null,
      parsingError:null,
      dataHasHeaders:true,
      locationFields:[],
      concatenator:",",
      clearOnImport:true,
      cleanLocations:true,
      pageSize:5,
      pageIndex:0,
      columns:[],
      addressFields:[{name:"Address",col:null,class:"col-md-6" },
                      {name:"Address 2",col:null,class:"col-md-6"}, 
                      {name:"City",col:null,class:"col-md-4"}, 
                      {name:"State or County",col:null,class:"col-md-4"}, 
                      {name:"Zip or postal code",col:null,class:"col-md-4"},
                      {name:"Country",col:null,class:"col-md-5"}],

      latlonFields:[{name:"Latitude",col:null,class:"col-md-4"}, {name:"Longitude",col:null,class:"col-md-4"}, {name:"Latitude,Longitude",col:null,class:"col-md-6"}],
      nameFields:[{name:"Name (optional)",col:null,class:"col-md-6"}]
    }
  },
  methods:{
    

     /* defaultValue=defaultValue || null;
      var sortedColumns=me.columns.filter(function(d) {
        return d && d.type && d.type!=="Name";
      });
      return sortedColumns && sortedColumns.length;
      
    },*/
    getRecordLocation(record, defaultValue) {
      var me=this;
      if (!record) return null;
      var location= me.selectedLocationFields.reduce(function(a,d) {
                  if (!d || !d.col) return a;
                  
                  var colIndex=me.columns.indexOf(d.col);

                  return (record[colIndex] && (a && a+me.concatenator+record[colIndex] || record[colIndex])) || a;
              },"");

      return location || defaultValue;
    },
    getRecordName(record,defaultValue) {
      var me=this;
      if (!record) return null;
      defaultValue=defaultValue || null;
      var name= me.nameFields.reduce(function(a,d) {
                    if (!d || !d.col) return a;
                    
                    var colIndex=me.columns.indexOf(d.col);

                    return (record[colIndex] && (a && a+me.concatenator+record[colIndex] || record[colIndex])) || a;
                },"");

      return name || defaultValue;

    },
    refreshColumns() {
      var me=this;
      if (!me.records || !me.records.length) {
        me.columns= [];
        return;
      } 
      var idlist;
      if (me.dataHasHeaders) {
        idlist= me.records[0];
      } else {
        var colcount=me.records.reduce(function(a, d) {
          return (d && d.length>a)?d.length:a;
        },0);

        idlist= Array.from({ length: colcount},function(a,i) {return "Col "+i});
      }

      me.columns= (idlist && idlist.map(function(id) {
        return id;
        /*return {
          id:id,
          type:null
        }*/
      }) ) || [];
    },
    cancelImportLocations() {
      var me=this;
      me.rawData=null;
      me.records=null,
      me.locationFields=[]
      me.entryMode='single';
      me.bulkMode="file";
      me.importStep="rawdata";
      me.columns=[];
    },

    addImportedLocations() {
      var me=this;
      if (me.clearOnImport) me.locations.length=0;
      me.resolvedRecords.forEach(function(d) {
        if (!d) return;
        var location=me.getRecordLocation(d);
        if (!location) return;
        me.locations.push({
          location:location,
          name:me.getRecordName(d),
        });
      });
      me.cancelImportLocations();
      me.$emit('locationsChanged', me.locations);
      me.entryMode='single';
    },
    hasCompletedImportStep(step) {
      var me=this;
      var steps=["rawdata","reviewparse","locationfields","verifylocations"];
      var currentIndex=steps.indexOf(me.importStep);
      return currentIndex>=0 && steps.indexOf(step)<=currentIndex;
    },
    setImportStep(step) {
       var me=this;
       me.importStep=step;

    },
    resolvedLocationName(location) {
      if (!location) return "<No location>";
      if (location.name) return location.name;
      if (location.location) return location.location;
      return  "<No location>";
    },
    isSelectedLocation(location) {
      return location===this.selectedLocation;
    },
    selectLocation(location) {
       this.$emit('selectLocation', location);
    },
    removeLocation(location) {
      var me=this;
      VcUtils.removeArrayItem(me.locations,location);
      me.$emit('locationsChanged', me.locations);
    },
    clearLocations(location) {
      var me=this;
      me.locations.length=0;
      me.$emit('locationsChanged', me.locations);
    },
    addLocation(location, name) {
      var me=this;
      if (me.newLocation) location=me.newLocation;
      if (me.newName) name=me.newName;
      me.newLocation=null;
      me.newName=null;
      me.locations.push({
          location:location,
          name:name
      });

      me.$emit('locationsChanged', me.locations);
    },

     addTextFile:function(event) {
      var me=this;
      var input = event.target;

      me.readFile(input.files[0]);
    },

    readFile:function(file) {
      var me=this;
      var reader = new FileReader();
      reader.onload = function(){
        me.rawData = reader.result;
        if (me.rawData) {
          me.setImportStep("reviewparse");
        }
      };
      console.log("Loading text...");
      reader.readAsText(file);
    },
  },
  computed:{
    
    hasLocationFields() {
      var me=this;
      return me.selectedLocationFields.reduce(function(a,d) {
                  return a || (d && d.col);
              },false);

    },
    selectedLocationFields() {
       var me=this;
       console.log("selectedLocationFields")
       if (me.locationType==='address') return me.addressFields;
       if (me.locationType==='latlon') return me.latlonFields;
       if (me.locationType==='name') return me.nameFields;
       return [];
    },
    dirtyDetected() {
      var me=this;
      if (!me.resolvedRecords || !me.resolvedRecords.length) return "None detected";
      var rawCount=me.resolvedRecords.length;
      var unique={};
      var dirtyCount= me.resolvedRecords.reduce(function(a, d) {
      
        if (!d || unique[d]) {
          return a+1;
        } else {
          unique[d]=1;
          return a;
        }
      },0);
      return (dirtyCount && (dirtyCount+" detected")) || "None detected";
    },
  
    hasCreateBulk() {
      return this.subscriptionParams  && this.subscriptionParams.createBulk;
    },
    pageCount() {
      var me=this;
      return Math.ceil(me.filteredLocations.length/me.pageSize);
    },
    hasPages() {
      return this.pageCount>1;
    },
    pageLocations() {
      var me=this;

      if (!me.filteredLocations || !me.hasPages) return me.filteredLocations;
      return me.filteredLocations.slice(me.pageIndex*me.pageSize, (me.pageIndex+1)*me.pageSize)
    },
    minPage() {
      return Math.floor(this.pageIndex/5)*5;
    },
    maxPage() {
       return Math.min(Math.ceil((this.pageIndex+1)/5)*5, this.pageCount-1);
    },
    visiblePages() {
      var output=[];
      for (var i=this.minPage;i<=this.maxPage;i++) {
        output.push(i);
      }
      return output;
    },
    filteredLocations() {
      var me=this;
      if (!me.locations || !me.locations.length || !me.searchText) return me.locations;
      var searchText=me.searchText.toLowerCase();
      return  me.locations.filter(function(d) {
        return d && d.location && d.location.toLowerCase().indexOf(searchText)!==-1;
      });
    },


    isValidNewLocation() {
      return this.newLocation;
    },
    resolvedRecords() {
      var me=this;
      if (!me.records || !me.records.length) return [];
       return me.dataHasHeaders?me.records.slice(1):me.records;
    },
    previewRecords() {
      var me=this;
      if (!me.resolvedRecords || !me.resolvedRecords.length) return [];
      return me.resolvedRecords.slice(0,3);
    },
    hasPreview() {
      var me=this;
       if (!me.previewRecords || !me.previewRecords.length) return false;
       return me.previewRecords.reduce(function(a, d) {
         return a || me.getRecordLocation(d);
       },false);
    },
    totalRecords() {
      var me=this;
      if (!me.resolvedRecords || !me.resolvedRecords.length) return 0;
      return me.resolvedRecords.length;
    },
    badTotalRecords() {
      var me=this;
      return me.resolvedRecords.reduce(function(a, d) {
        if (!d) return a+1;
        var location=me.getRecordLocation(d);
        if (!location) return a+1;
        return a;
      }, 0);
    },
    locationFieldIndices() {
       var me=this;
       if (!me.locationFields || !me.locationFields.length) return [];
       return me.locationFields.map(function(d) {
         return me.columns.indexOf(d);
       })
    },
    
/*
    resolvedLocationsPreview() {
      var me=this;
      if (!me.resolvedLocations || !me.resolvedLocations.length || !me.locationFields.length) return [];
      return me.resolvedLocations.slice(0,5);
    },

    resolvedLocations() {
      var me=this;
      
      if (!me.resolvedRecords || !me.resolvedRecords.length || !me.locationFields) return [];
      
      var locations= me.resolvedRecords.map(function(d,i) {
          
          return me.locationFieldIndices.reduce(function(a,j) {
            
            return (d[j] && (a && a+me.concatenator+d[j] || d[j])) || a;
          },"");

        });
      if (me.cleanLocations) {
        var unique={};
        locations=locations.filter(function(d) {
          var r= d && (!unique[d] )
          unique[d]=true;
          return r;
        })
      }
      return locations;
      
    }*/
  },
  mounted() {
   
  },
  watch: {
    records:function() {
      var me=this;
      me.refreshColumns();
    },
    dataHasHeaders:function() {
      var me=this;
      me.refreshColumns();
    },
    rawData:function(rawData){
      var me=this;
     
      if (!rawData) {
        me.records=[];
        me.parsingError="No raw data available";
        return;
      }
      me.parsingError=null;
      me.locationFields=[];
      parse(rawData, {
       // comment: '#',
        //relax_column_count: true
      }, function(err, records){
        me.parsingError=err;
        me.records=records;
      });
    },
    parentLocations: {
     immediate: true,
     handler: function(parentLocations){
      if(parentLocations){
        this.locations = this.parentLocations;
      }
     }
    }
  },
  components: {
    
  },
}
    Template
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
