import { VcCommunicator } from "./vccommunicator";
//import {VcMetadataUtils} from "/../visualcrossing-core-js/src/vcmetadatautils";
//import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
console.log("VcCommunicatorServerAdmin class")



VcCommunicator.prototype.loaderstatus=function() {
    var me=this;
    var config={
       
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("loaderstatus"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "loaderstatus failed");
                    return;
                }

               

                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.serverstatus=function(serverroot) {
    var me=this;
    var config={
        "detailed":true
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("serverstatus",null, serverroot), config,null,"GET")
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "serverstatus failed");
                    return;
                }

               

                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.executereport=function(reportId,config) {
    var me=this;
    config=config || {}
    config["reportId"]=reportId;

    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("executereport",null), config,null)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "executereport failed");
                    return;
                }

                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
}
VcCommunicator.prototype.getreports=function() {
    var me=this;
    var config={
     
    }
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("getreports",null), config,null)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "getreports failed");
                    return;
                }

                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });

}


VcCommunicator.prototype.serverlog=function(serverroot) {
    var me=this;
    var config={
        
    }

    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("serverlog",null, serverroot), config,null)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "serverlog failed");
                    return;
                }

                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });

}

VcCommunicator.prototype.monitor=function(sinceSeconds, serverroot) {
    var me=this;
    var config={
        sinceSeconds:sinceSeconds,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("monitor",null, serverroot), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "monitor failed");
                    return;
                }

             
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}


VcCommunicator.prototype.generateNewApiKey=function(userMdId) {
    var me=this;
    var config={
        id:userMdId,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("generatenewapikey"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "generatenewapikey failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}


VcCommunicator.prototype.setUserEnabled=function(userMdId,enabled) {
    var me=this;
    var config={
        id:userMdId,
        enabled:enabled,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("setuserenabled"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "setuserenabled failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}
VcCommunicator.prototype.setUserExecutionStatus=function(userMdId,status) {
    var me=this;
    var config={
        id:userMdId,
        status:status,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("setuserexecutionstatus"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "setuserexecutionstatus failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.setUserUsername=function(userMdId,newEmail) {
    var me=this;
    var config={
        id:userMdId,
        newEmail:newEmail,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("setuserusername"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "setuserusername failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.getUserDetails=function(criteria) {
    var me=this;
    var config={
        criteria:criteria,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("getuserdetails"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "getuserdetails failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}
VcCommunicator.prototype.getAllPlans=function() {
    var me=this;
    var config={
       
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("getallplans"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "getallplans failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.getAllProperties=function() {
    var me=this;
    var config={
       
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("getallplanproperties"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "getallplanproperties failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
} 

VcCommunicator.prototype.setUserPlans=function(masterid, plans) {
    var me=this;
    var config={
        masterid:masterid,
        plans:plans,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("setuserplans"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "setuserplans failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.getPlanDefinition=function(planId, isUserPlan) {
    var me=this;
    var config={
        planId:planId,
        isUserPlan:isUserPlan,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("getplandefn"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "getplandefn failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}

VcCommunicator.prototype.savePlanDefinition=function(plan, isUserPlan) {
    var me=this;
    var config={
        sesssion:me.sessionId,
        plan:plan,
        isUserPlan:isUserPlan,
    };
    
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("saveplandefn"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "saveplandefn failed");
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}