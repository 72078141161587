import { VcCommunicator } from "../../../../visualcrossing-core-js/src/vccommunicator";
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";

export var WinterTrackerHelper = (function () {
    
  return {
    
    USAGEUNITS:[
      {
        id:"kwh",
        label:"kWh"
      },
      {
        id:"m3",
        label:"m3 (gas)"
      },
      {
        id:"therm",
        label:"US Therm"
      },
      {
        id:"btu",
        label:"BTU"
      },
    ],

    kWh2unit(usage, unit) {
      if (unit && unit.id) unit=unit.id;
      if (unit==="m3") {
        return usage/10.55; //https://learnmetrics.com/m3-gas-to-kwh/
      } else if (unit==="therm") {
        return usage/29.3001; //https://www.metric-conversions.org/energy-and-power/therms-us-to-kilowatt-hours.htm
      } else if (unit==="btu") {
        return usage*3412.14; 
      }
      return usage;
    }, 

    unit2kWh(usage, unit) {
      if (unit && unit.id) unit=unit.id;
      if (unit==="m3") {
        return usage*10.55; //https://learnmetrics.com/m3-gas-to-kwh/
      } else if (unit==="therm") {
        return usage*29.3001; //https://www.metric-conversions.org/energy-and-power/therms-us-to-kilowatt-hours.htm
      } else if (unit==="btu") {
        return usage/3412.14; 
      }
      return usage;
    },  

    guessModelFromLatLon(lat,lon, models) {
      let bestModel=models.reduce((a,d)=>{
       
        return a || (d.bounds && L.latLngBounds(d.bounds).contains([lat, lon]) && d);
      },null);
      
      return bestModel || models[models.length-1];

    },

    currency2HtmlSymbol(currency) {
      switch (currency) {
        case 'usd': return "$";
        case 'gbp': return "&pound;";
        case 'eur': return "&euro;";
        default: return "";
      }
    },
    forceMetricHdd(hdd, unitGroup) {
      if (unitGroup==="us") {
        return hdd*5/9;
      }
      return hdd;
    },
    hdd2Energy(hdd, unitGroup, energyusage) {
      let energymodel=energyusage;
      hdd=WinterTrackerHelper.forceMetricHdd(hdd, unitGroup);
      let b=-energymodel.m*energymodel.c;
      let energy= Math.max(0,energymodel.m*hdd+b); //K->kWh
      return energy;
      //return WinterTrackerHelper.kWh2unit(energy, energyusage.unit);
    },
    date2Key(date) {
        return date.getFullYear()*10000+(date.getMonth()+1)*100+date.getDate();
    },
    getMonthsArray(months) {
      months=months.months || months;

      let monthIds=Object.keys(months);
      monthIds.sort((a,b)=>a-b);

      return monthIds.map(d=>months[d]);
    },
    calculateMonthlyAggregates(queryData, unitGroup, energyusage, useFullMonthIds, maxDateTime, monthProperty) {
      
      if (maxDateTime) {
        //add an unfiltered months for use by charts
        this.calculateMonthlyAggregates(queryData, unitGroup, energyusage, useFullMonthIds, 0, 'allmonths');
      }

      monthProperty=monthProperty || "months";
      let months={};
      let days=queryData.days;

      if (maxDateTime) {
       days=days.filter(d=>d.donotaggregate=d.datetimeEpoch<maxDateTime);
      }
      const lastDayOfMonth=[31,28,31,30,31,30,31,31,30,31,30,31];
      days.forEach(function(day, i) {
        let date=VcDateUtils.dateFromIso(day.datetime);
        let dayOfMonth=date.getDate();
        date.setDate(1);

        let fullMonthId=WinterTrackerHelper.date2Key(date);
        let monthId=useFullMonthIds?fullMonthId:date.getMonth();
        

        if (months[monthId]) {
          months[monthId].hdd+=day.degreedays;
          months[monthId].temp+=day.temp;
          months[monthId].tempmax=Math.max( months[monthId].tempmax, day.temp);
          months[monthId].tempmin=Math.min( months[monthId].tempmin, day.temp);
          months[monthId].count++;
          months[monthId].donotaggregate=months[monthId].donotaggregate || day.donotaggregate;
          months[monthId].maxDate=Math.max(months[monthId].maxDate, dayOfMonth);
         /// months[monthId].ispartial=date.getDate()<lastDayOfMonth[date.getMonth()];
        } else {
          months[monthId]={
            month:fullMonthId,
            hdd:day.degreedays,
            tempmax:day.temp,
            tempmin:day.temp,
            temp:day.temp,
            count:1,
            usage:0,
            cost:0,
            maxDate:0,
            ispartial:false,
          }
        }
      });

      Object.keys(months).forEach(function(k) {
        let d=months[k];
        d.ispartial=d.maxDate<lastDayOfMonth[k];
        d.temp=d.temp/d.count;
        if (energyusage) {
          let monthId=d.month && Math.floor(d.month/100);
          d.usage=WinterTrackerHelper.hdd2Energy(d.hdd, unitGroup, energyusage) 
         
          if (energyusage.monthlycosts && energyusage.monthlycosts[monthId]) {
            d.cost=Math.max(0,d.usage*energyusage.monthlycosts[monthId]);
          } else {
            d.cost=Math.max(0,d.usage*energyusage.cost);
          }
          //convert to UI units now?
          d.usage=WinterTrackerHelper.kWh2unit(d.usage, energyusage.unit)

        }
      });
      
      queryData[monthProperty]=months;
    },

    saveEnergyModel(model) {
      var me=this;
      
      
      return new Promise((resolve, reject) => {
        var vcCommunicator=window.vcCommunicator;

        if (!vcCommunicator || !model)  {
          reject("No model provided"); 
          return;
        }
        

        let url=vcCommunicator.createUrl("saveenergymodel", null, "https://weather1.visualcrossing.com/VisualCrossingWebServices/rest/services/");

        //model=JSON.stringify(model);

        vcCommunicator.retrieveData(url, model, 'application/json', 'POST')
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || `saveenergymodel failed ${data.errorCode}`);
                    return;
                }
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
      
    },


    PRESETMODELS:[
      {
        label:"Typical household, UK",
        country:"UK",
        bounds:[[49.959999905, -7.57216793459 ],[58.6350001085, 1.68153079591]],
        currency:"gbp",
        unit:"kwh",
        m:6.81654747930318,
        b:0,
        c:1.9763187921218444,
        baseUsage:12,
        cost:0.10330,
        isPreset:true,
        monthlycosts:{
         '201506':0.014801997262808,
          '201507':0.0148361268391575,
          '201508':0.0135460288531438,
          '201509':0.014006778133863,
          '201510':0.0135016604038894,
          '201511':0.0122525179094952,
          '201512':0.0116142948317583,
          '201601':0.0109385292200368,
          '201602':0.0101228323452821,
          '201603':0.0101023545994724,
          '201604':0.010047747277313,
          '201605':0.0103788041679039,
          '201606':0.0116723151115525,
          '201607':0.0117064446879021,
          '201608':0.0103617393797291,
          '201609':0.0096791478527377,
          '201610':0.0146176975505203,
          '201611':0.0164231521394125,
          '201612':0.0158804918754543,
          '201701':0.0182217808130348,
          '201702':0.017409496895915,
          '201703':0.0139999522185931,
          '201704':0.0135596806836837,
          '201705':0.0134095105477456,
          '201706':0.0118907444001898,
          '201707':0.0123583195961789,
          '201708':0.0145289606520114,
          '201709':0.0156996051208016,
          '201710':0.0154948276627042,
          '201711':0.0180716106770967,
          '201712':0.0198941300541636,
          '201801':0.0172149583107225,
          '201802':0.0201227982157057,
          '201803':0.0219453175927727,
          '201804':0.0173856061924703,
          '201805':0.0189692185350903,
          '201806':0.018761028119358,
          '201807':0.0197712635793052,
          '201808':0.0214436128204341,
          '201809':0.0251603236849021,
          '201810':0.0227064071453681,
          '201811':0.0219692082962174,
          '201812':0.0217541919652151,
          '201901':0.0196961785113361,
          '201902':0.0160716175030119,
          '201903':0.0134163364630155,
          '201904':0.0119385258070792,
          '201905':0.0107269258466695,
          '201906':0.00954262954733943,
          '201907':0.0101023545994724,
          '201908':0.00947095743700533,
          '201909':0.00848802563813775,
          '201910':0.00875423633366439,
          '201911':0.012982890843376,
          '201912':0.0109692458387514,
          '202001':0.00952215180152969,
          '202002':0.00802045044214866,
          '202003':0.00782249889932116,
          '202004':0.00469281674806571,
          '202005':0.00395561789891502,
          '202006':0.00449827816287316,
          '202007':0.00451875590868291,
          '202008':0.00690100033788281,
          '202009':0.0102047433285211,
          '202010':0.0130477370384401,
          '202011':0.012822481834533,
          '202012':0.015593803434118,
          '202101':0.0201398630038805,
          '202102':0.0156791273749919,
          '202103':0.0153446575267661,
          '202104':0.0186927689666588,
          '202105':0.0222524837799188,
          '202106':0.0245767079293245,
          '202107':0.0310169589864881,
          '202108':0.0375561858150655,
          '202109':0.055324043262651,
          '202110':0.0729553824048382,
          '202111':0.0686311650813478,
          '202112':0.0924775000767915,
          '202201':0.0695390118122464,
          '202202':0.0640851055115853,
          '202203':0.107020112559343,
          '202204':0.0635253804594524,
          '202205':0.0359418568537309,
          '202206':0.0510203036849704,
          '202207':0.0635253804594524,
          '202208':0.0359418568537309,
          '202209':0.0510203036849704,
          '202210':0.10330,
          '202211':0.10330,
          '202212':0.10330,
        },
        balancePointTemp:0,
      },  
      
      {
        label:"Typical household, EU",
        country:"EU",
        bounds:[[30,-33],[68, 41]],
        currency:"eur",
        unit:"kwh",
        m:6.81654747930318,
        b:0,
        c:1.9763187921218444,
        baseUsage:12,
        cost:0.10330,
        isPreset:true,
        monthlycosts:{
          '201901':0.0669,
          '201902':0.0669,
          '201903':0.0669,
          '201904':0.0669,
          '201905':0.0669,
          '201906':0.0669,
          '201907':0.0720,
          '201908':0.0720,
          '201909':0.0720,
          '201910':0.0720,
          '201911':0.0720,
          '201912':0.0720,
          '202001':0.0645,
          '202002':0.0645,
          '202003':0.0645,
          '202004':0.0645,
          '202005':0.0645,
          '202006':0.0645,
          '202007':0.0698,
          '202008':0.0698,
          '202009':0.0698,
          '202010':0.0698,
          '202011':0.0698,
          '202012':0.0698,
          '202101':0.0638,
          '202102':0.0638,
          '202103':0.0638,
          '202104':0.0638,
          '202105':0.0638,
          '202106':0.0638,
          '202107':0.0782,
          '202108':0.0782,
          '202109':0.0782,
          '202110':0.0782,
          '202111':0.0782,
          '202112':0.0782,
          '202201':0.0782,
          '202202':0.0782,
          '202203':0.0782,
          '202204':0.0782,
          '202205':0.0782,
          '202206':0.0782,
          '202207':0.0782,
          '202208':0.0782,
          '202209':0.0782,
          '202210':0.0782,
          '202211':0.0782,
          '202212':0.0782,
        },
        balancePointTemp:0,
      }, 
      {
        label:"Typical household, USA",
        country:"USA",
        bounds:[[-90, -180, ],[90, 179.9]],
        currency:"usd",
        unit:"therm",
        m:6.949861302071842,
        b:0,
        c:34.228464685668925,
        baseUsage:11,
        cost:0.0776,
        isPreset:true,
        monthlycosts:{
          '201001':0.036,
          '201002':0.0365,
          '201003':0.0375,
          '201004':0.0408,
          '201005':0.0448,
          '201006':0.0507,
          '201007':0.0553,
          '201008':0.0568,
          '201009':0.0533,
          '201010':0.0456,
          '201011':0.0372,
          '201012':0.0341,
          '201101':0.0338,
          '201102':0.0346,
          '201103':0.0356,
          '201104':0.0385,
          '201105':0.0427,
          '201106':0.0502,
          '201107':0.0551,
          '201108':0.0569,
          '201109':0.0533,
          '201110':0.0438,
          '201111':0.0368,
          '201112':0.0335,
          '201201':0.0328,
          '201202':0.0323,
          '201203':0.0355,
          '201204':0.0373,
          '201205':0.043,
          '201206':0.0484,
          '201207':0.0516,
          '201208':0.054,
          '201209':0.0502,
          '201210':0.0399,
          '201211':0.0341,
          '201212':0.0334,
          '201301':0.0312,
          '201302':0.0315,
          '201303':0.0319,
          '201304':0.0356,
          '201305':0.043,
          '201306':0.0513,
          '201307':0.0556,
          '201308':0.0561,
          '201309':0.0535,
          '201310':0.0422,
          '201311':0.0343,
          '201312':0.0312,
          '201401':0.0316,
          '201402':0.0333,
          '201403':0.0365,
          '201404':0.0401,
          '201405':0.0464,
          '201406':0.055,
          '201407':0.0588,
          '201408':0.0594,
          '201409':0.0555,
          '201410':0.0447,
          '201411':0.0348,
          '201412':0.0342,
          '201501':0.0324,
          '201502':0.031,
          '201503':0.0317,
          '201504':0.0356,
          '201505':0.0434,
          '201506':0.0514,
          '201507':0.0555,
          '201508':0.0576,
          '201509':0.056,
          '201510':0.043,
          '201511':0.0342,
          '201512':0.0316,
          '201601':0.0283,
          '201602':0.0285,
          '201603':0.0314,
          '201604':0.0329,
          '201605':0.0396,
          '201606':0.0492,
          '201607':0.0565,
          '201608':0.0601,
          '201609':0.0573,
          '201610':0.0469,
          '201611':0.0367,
          '201612':0.0309,
          '201701':0.0318,
          '201702':0.0342,
          '201703':0.0336,
          '201704':0.0387,
          '201705':0.0452,
          '201706':0.0548,
          '201707':0.0609,
          '201708':0.0622,
          '201709':0.0577,
          '201710':0.0457,
          '201711':0.0346,
          '201712':0.0317,
          '201801':0.0304,
          '201802':0.0329,
          '201803':0.0333,
          '201804':0.0343,
          '201805':0.0461,
          '201806':0.0562,
          '201807':0.0609,
          '201808':0.0633,
          '201809':0.0588,
          '201810':0.0417,
          '201811':0.0321,
          '201812':0.0328,
          '201901':0.0319,
          '201902':0.0321,
          '201903':0.0321,
          '201904':0.037,
          '201905':0.0435,
          '201906':0.0532,
          '201907':0.0605,
          '201908':0.0627,
          '201909':0.0601,
          '201910':0.0427,
          '201911':0.0318,
          '201912':0.0317,
          '202001':0.0322,
          '202002':0.0314,
          '202003':0.0334,
          '202004':0.0356,
          '202005':0.0402,
          '202006':0.0523,
          '202007':0.0597,
          '202008':0.0623,
          '202009':0.0575,
          '202010':0.0418,
          '202011':0.0375,
          '202012':0.0333,
          '202101':0.033,
          '202102':0.0318,
          '202103':0.0359,
          '202104':0.0418,
          '202105':0.0482,
          '202106':0.0605,
          '202107':0.068,
          '202108':0.0716,
          '202109':0.0691,
          '202110':0.0597,
          '202111':0.0454,
          '202112':0.0448,
          '202201':0.0411,
          '202202':0.0415,
          '202203':0.0443,
          '202204':0.0466,
          '202205':0.0599,
          '202206':0.0776,
        },
        balancePointTemp:0,
      },  
    
    ],
  }
}());