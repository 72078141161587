<template>
  <div>
    <div v-if="errorStatus" class="alert alert-danger my-3" role="alert" v-html="errorStatus"></div>
    <div v-else-if="isLoading" class="d-flex align-items-center justify-content-center" style="min-height:300px">
        <div class="spinner-border mx-2" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Loading...
    </div>
    <div v-else-if="noData">
      <div v-if="errorStatus" class="alert alert-info my-3" role="alert">
        No usage data available.
      </div>
    </div>
    <div v-else-if="!selectedLocation" class="row justify-content-md-center">
      <div class="col-12 col-lg-8 ">
        <h5>Please add a location to get started</h5>
        <div class="input-group">
          <div class="btn px-1 disabled" >
            <i class="bi bi-geo"></i>
          </div>
          <input v-model="newLocation" v-on:keyup.enter="setLocation()" class="form-control border-end-0  border-primary" type="text" placeholder="Enter a location" aria-describedby="locationHelpBlock">
          <span class="input-group-append">
              <button @click="setLocation()" class="btn btn-primary  border-start-0 border-primary ms-n1" :class="{disabled:!newLocation}" type="button" >
                  <i class="bi-search"></i>
              </button>
          </span>
        </div>
      </div>
    </div>
    <div v-else class="row justify-content-md-center">
      <div class="col-12 col-lg-10 ">
        <div class="d-flex align-items-center">
          <h3 class="h3"><template v-if="basePeriodLabel">{{basePeriodLabel}} for </template>
            <a href="#" class="dropdown-toggle" id="locationDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{selectedLocation.location}} <i class="bi bi-pencil"></i></a>
            <ul class="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
              <li><div class="fw-bold mt-3">Enter address or partial address:</div></li>
              <li> 
                <div class="d-flex align-items-center p-1">
                  <input v-model="newLocation" v-on:keyup.enter="setLocation()" class="form-control" type="text" placeholder="Enter a location">
                  <button @click="setLocation()" type="button" class="btn btn-sm btn-translucent-primary "> <i class="bi-search"></i></button>
                </div>
                <a class="ps-2 pt-0" href="#" @click.prevent.stop="findMyLocation()"><i class="bi bi-broadcast-pin"></i> Use your location</a>
              </li>
              <template v-if="hasLocationsHistory">
                <li><div class="fw-bold mt-3">History:</div></li>
                <li v-for="(loc,index) in locationsHistory"  @click="setLocation(loc)"><a class="dropdown-item" href="#" >{{loc.location}}</a></li>
              </template>
              </ul>
              <DateRangeChooser v-if="showDateRangeChooser" :queryDefn="selectedQueryDefn" :queryData="selectedQueryData" @change:daterange="setDateRange" />
          </h3> 
          <div class="btn-group  ms-auto" role="group" aria-label="Solid button group">
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:unitGroup==='us'}" @click="setUnitGroup('us')">F</button>
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:unitGroup==='metric'}" @click="setUnitGroup('metric')">C</button>
          </div>
        </div>
        <div v-if="showApiControls" class="d-flex align-items-center justify-content-end">
          <button type="button" class="btn text-primary btn-sm"  @click="showApiQueries()"><i class="bi bi-code-slash"></i><span class="d-none  d-lg-inline"> API</span></button>
          <button type="button" class="btn text-primary btn-sm"  @click="refreshPage(null, '/weather/weather-data-services')"><i class="bi bi-download"></i><span class="d-none  d-lg-inline"> Download </span></button>
          <button type="button" class="btn text-primary btn-sm"  @click="copyLink()"><i class="bi bi-share"></i><span class="d-none  d-lg-inline"> Share </span></button>
        </div>
        <div v-if="dataStoredError" class="alert alert-danger my-3" role="alert" v-html="dataStoredError"></div>
        <template v-else-if="view==='dailysummary'">
          <DaySummaryWidget :config="todayDaySummaryConfig"/>
          <MultiPeriodChartWidget :config="todayMultiPeriodConfig" />

          <h3 class="mt-3">{{basePeriodLabel}}'s detailed weather information for {{selectedLocation.location}}</h3> 
          <DaySummaryWidget  :config="todayDetailedDaySummaryConfig"/>

          <h3 class="mt-3">Last 10 days weather data for  {{selectedLocation.location}}</h3> 

          <MultiPeriodChartWidget  :config="last10DaysMultiPeriodConfig" />
        </template>
         <template v-else-if="view==='forecast'">
           <h3 class="mt-3">Currently</h3> 
          <div class="row">
            <DaySummaryWidget class="" :config="forecastCurrentSummaryConfig"/>
          </div>
          <h3 class="mt-3">3 Day outlook</h3> 
          <div class="row">
            <DaySummaryWidget class="" :config="forecastDaySummaryConfig"/>
          </div>
          <h3 class="mt-3">Hourly forecast</h3> 
          <MultiPeriodChartWidget :config="forecastDaySummaryConfigHourly" />

          <h3 class="mt-3">Long term outlook</h3> 
          <MultiPeriodChartWidget :config="forecastMultiPeriodConfig" />

        </template>


        <template v-else-if="view==='history'">
          <div class="row justify-content-center mt-3 mb-3">
            <div v-for="kpi in kpiIds" class="col-4 col-md-2 border-1 shadow-sm py-3 rounded-3">
              <div class="text-muted fs-xs">{{summmaryKpis[kpi].label}}</div>
              <div class="fs-2 text-center">{{summmaryKpis[kpi].fvalue}}</div>
            </div>
          </div>
          <MultiPeriodChartWidget  :config="historyMultiPeriodConfig" />

          <h3 class="mt-3">Location and station map</h3> 
          <StationInfoWidget :config="historyMultiPeriodConfig" />
        </template>
         <template v-else>
          Unexpected view requested
        </template>
      </div>
    </div>
   

  <div class="modal fade" id="apiQueryEditorModal" tabindex="-1" aria-labelledby="apiQueryEditorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="apiQueryEditorModalLabel">Weather API Queries</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
              <p>This page is populated using the <a href="/weather-api">Visual Crossing Weather API</a> using the following queries. 
              To build queries for yourself, head over to our <a href="/weather/weather-data-services">Weather Query Builder</a> page. Also see our <a href="/resources/">API docs</a> 
               </p>
              <div class="d-flex mt-3 align-items-center ">
                <div class="fw-bold">Available queries</div>
                 <button class="dropdown-toggle  btn text-primary px-1" type="button" id="queryDefnDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                  {{selectedQueryDefnLabel}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="queryDefnDropdownButton">
                  <li v-for="u in queryDefinitions"  @click="selectedQueryDefn=u"><a class="dropdown-item" href="#" v-html="u.id"></a></li>
                </ul>
              </div>
              <ApiQuery v-if="selectedQueryDefn" :sessionState="sessionState" :queryDefinition="selectedQueryDefn"></ApiQuery>
              <div v-else>Please select a query definition</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>


     <CommonModals />

  </div>
 
</template>

<script>
//import { Calendar, DatePicker } from 'v-calendar';
import CommonModals from '/src/components/CommonModals'
import "/../visualcrossing-core-js/src/vccommunicator_account";
import {VcLocationsStorage} from "/../visualcrossing-core-js/src/vclocationsstorage";
//import {VcPrettyNumberFormatter} from '/../visualcrossing-core-js/src/vcprettynumberformatter';
//import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
//import {VcGlobal} from '/../visualcrossing-core-js/src/vcglobal_conn';
//import '/src/VcUtils'
import ApiQuery from '/src/components/ApiQuery';
import DateRangeChooser from '/src/components/DateRangeChooser';
import MultiPeriodChartWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/multi-period-chart-widget';
import DaySummaryWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/day-summary-widget';
import StationInfoWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/station-info-widget';
import '/../bootstrap-vue-app/src/pages/wxwidgets/wxwidgets.scss';
import {VcWidgetHelper} from '/../bootstrap-vue-app/src/pages/wxwidgets/common_widget.js'
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils';
import {VcFindMyLocation} from '/../visualcrossing-core-js/src/vcfindmylocation';
import {VcDateUtils} from '/../visualcrossing-core-js/src/vcdateutils';
import {VcTimezone} from '/../visualcrossing-core-js/src/vctimezone';


export default {
  name: 'weathersummary',
  props: {
    
  },
  data() {
    return {
      view:'dailysummary',
      sessionId:null,
      sessionState:null,
      errorStatus:null,
      isLoading:true,
      selectedLocation:null,
      unitGroup:null,
      rooturl:null,
      startdate:null,
      basePeriodLabel:null,
      newLocation:null,
      locationsHistory:null,
      queryDefinitions:[],
      selectedQueryDefn:null,
      selectedQueryData:null,
      showApiControls:true,
      dataStoredError:null,
    }
  },
  methods:{
    showApiQueries() {
      var me=this;
      me.refreshQueryDefns();
      //if (me.queryDefinitions && me.queryDefinitions.length) me.selectedQueryDefn=me.queryDefinitions[0];
      var target=this.$el && this.$el.querySelector("#apiQueryEditorModal");
      var modal = bootstrap.Modal.getOrCreateInstance(target);
      modal.show();
    },
    setDateRange(defn) {
      console.log("Set date range:"+defn.startdate +"=> "+defn.enddate)
      this.refreshPage(defn);
    },
    findMyLocation() {
      var me=this;
      var fml=VcFindMyLocation.findMyLocation(true);

      fml.then(location=> {
          //me.setLocation(location);
          me.newLocation=location;
        })
        .catch(error=>{
          console.log("Could not find location..."+error)
        });
    },
    setLocation(newLocation) {
      
      var me=this;
      if (newLocation && newLocation.location) {
        me.selectedLocation=newLocation;
      } else if (newLocation) {
        me.selectedLocation={
          location:newLocation,
          name:null,
        };
      } else {
        me.selectedLocation={
          location:me.newLocation,
          name:null,
        };
      }
      
      me.newLocation=null;
      me.saveLocation(me.selectedLocation);
      me.refreshPage();
  
    },
    saveLocation(newLocation) {
      var me=this;
      VcLocationsStorage.addLocation(newLocation);
      this.refreshLocationsHistory();
    },
    setUnitGroup(unitGroup) {
      var me=this;
      me.unitGroup=unitGroup
      VcLocationsStorage.setUnitGroup(me.unitGroup);
      me.refreshPage();
    },
    createLink(defn, rooturl) {
      var me=this;
     
      if (!me.selectedLocation) return;
      rooturl=rooturl || me.rooturl;
      var unitGroup=me.unitGroup || "us";
      if (me.showDateRangeChooser) defn=defn || me.selectedQueryDefn;
      var url=(rooturl || "") +"/"+me.selectedLocation.location;
      url+="/"+unitGroup;
      if (defn && defn.startdate) {
        url+="/"+defn.startdate;
        if (defn.enddate) url+="/"+defn.enddate;
      }
      return url;
    },
    copyLink(defn, rooturl) {
      var me=this;
      var url=me.createLink(defn, rooturl);
      VcClipboardUtils.copyToClipboard(window.location.origin+url);
      VcCommonModals.showToast("Link copied", "The dashboard link been copied to the clipboard.")
    },

    refreshPage(defn, rooturl) {
      var me=this;
      var url=me.createLink(defn, rooturl);
      window.location=url;
    },
    refreshLocationsHistory() {
      console.log("refreshLocationsHistory")
      this.locationsHistory=VcLocationsStorage.getLocations();
    },
    refreshQueryDefns() {
      var me=this;
      var queryDefns=VcWidgetHelper.queryDefinitions;
      if (!queryDefns) {
        me.queryDefinitions=[];
        return;
      }
      me.queryDefinitions=[];
      Object.values(queryDefns).forEach(function(defn) {
        if (defn) me.queryDefinitions.push(defn);
      });
      if (me.queryDefinitions && me.queryDefinitions.length) {
        me.selectedQueryDefn=me.queryDefinitions[0];
        me.selectedQueryData=VcWidgetHelper.getDataset(me.selectedQueryDefn.id);
      }
    },

    formatTime(date) {
     // var datetz=VcTimezone.toTz(date, 'utc', true);
      return VcDateUtils.formatDateCustom(date,"HHmm");
    },
    formatDateIso(dateTime) {
      return VcDateUtils.formatDateTime2(dateTime);
    },
  },
  computed:{
    summmaryKpis() {
      var me=this;
      return VcWidgetHelper.calcSummaryKpis(me.selectedQueryData, me.unitGroup);

    },
    kpiIds() {
      var kpis=this.summmaryKpis;
      return kpis && Object.keys(kpis);
    },
    showDateRangeChooser() {
      return this.view==="history"; // && this.selectedQueryDefn;
    },
    selectedQueryDefnLabel() {
      var queryDefn=this.selectedQueryDefn;
      if (queryDefn) return queryDefn.id;
      return "-";
    },
    hasLocationsHistory() {
      return this.locationsHistory && this.locationsHistory.length;
    },

   
  
    todayDaySummaryConfig() {
      console.log("todayDaySummaryConfig")
      return {
          id:this.startdate,
          startdate:this.startdate,
          location:this.selectedLocation.location,
          // dayindex:"0",
          viewincludes:'date,icon,desc,periods',
          include:"fcst,obs,histfcst,stats,days,hours",
          summarykpis:'tempmax,tempmin,rainsnow,precipprob',
          unitgroup:this.unitGroup,
          };
    },
    todayMultiPeriodConfig() {
      return {
            id:this.startdate,
            startdate:this.startdate,
            location:this.selectedLocation.location,
           // dayindex:"0",
            period:"hours",
            include:"fcst,obs,histfcst,stats,days,hours",
					  viewincludes:'periodsummary',
            unitgroup:this.unitGroup,
           };
    },
    todayDetailedDaySummaryConfig() {
      return {
          id:this.startdate,
          startdate:this.startdate,
          location:this.selectedLocation.location,
          // dayindex:"0",
          include:"fcst,obs,histfcst,stats,days,hours",
          viewincludes:'date,temp,feekslike,precip,snow,wind,humidity,pressure,solar,astro,periods',
          unitgroup:this.unitGroup,
        };
    },
    historyMultiPeriodConfig() {
      return {
         id:"history",
          location:this.selectedLocation.location,
          startdate:this.startdate || "last15days",
          enddate:this.enddate,
          include:"fcst,obs,histfcst,stats",
          viewincludes:'temp,precip,wind,degreedays',
          options:"",
          elements:"datetime,datetimeEpoch,temp,tempmax,tempmin,precip,windspeed,windgust,feelslike,feelslikemax,feelslikemin,pressure,stations,degreedays,accdegreedays",
          unitgroup:this.unitGroup,
          };
    },
    last10DaysMultiPeriodConfig() {
      return {
         id:"10dayobs",
           location:this.selectedLocation.location,
            startdate:"last10days",
            enddate:"",
            include:"fcst,obs,histfcst,stats",
					  viewincludes:'temp,precip,wind',
            unitgroup:this.unitGroup,
           };
    },

    forecastMultiPeriodConfig() {
      return {
            id:"forecast",
            startdate:null,
            location:this.selectedLocation.location,
           // dayindex:"0",
            period:"days",
            include:"fcst,obs,histfcst,stats,days,hours,current,alerts",
					  viewincludes:'periodsummary',
            unitgroup:this.unitGroup,
           }
    },
    forecastDaySummaryConfig() {
      return {
          id:"forecast",
          startdate:null,
          location:this.selectedLocation.location,
          dayindex:0,
          enddayindex:2,
          columncount:3,
          viewincludes:'date,icon,desc',
          include:"fcst,obs,histfcst,stats,days,hours,current,alerts",
          summarykpis:'tempmax,tempmin,rainsnow,precipprob',
          unitgroup:this.unitGroup,
          };
    },
    forecastDaySummaryConfigHourly() {
      return {
          id:"forecast",
          startdate:null,
          location:this.selectedLocation.location,
          /*dayindex:0,
          enddayindex:0,*/
          maxDayIndex:1,
          period:"hours",
          include:"fcst,obs,histfcst,stats,days,hours,current,alerts",
				  viewincludes:'periodsummary',
          unitgroup:this.unitGroup,
          };
    },
    forecastCurrentSummaryConfig() {
      return {
          id:"forecast",
          startdate:null,
          location:this.selectedLocation.location,
          dayindex:-1,
          viewincludes:'current,alerts,date,icon,desc,astro',
          include:"fcst,obs,histfcst,stats,days,hours,current,alerts",
          options:"beta",
          summarykpis:'temp,feelslike,precip,wind',
          unitgroup:this.unitGroup,
          };
    },

   forecastMultiPeriodConfig2() {
      return {
            id:"forecast2",
            startdate:null,
            location:this.selectedLocation.location,
            options:"beta",            
            period:"days",
            include:"fcst,days,hours,current,alerts",
					  viewincludes:'periodsummary',
            unitgroup:this.unitGroup,
           }
    },
    forecastDaySummaryConfig2() {
      return {
          id:"forecast2",
          startdate:null,
          location:this.selectedLocation.location,
          dayindex:0,
          enddayindex:7,
          columncount:1,
          viewincludes:'date,icon,desc',
          include:"days,hours,current,alerts",
          options:"",
          summarykpis:'tempmax,tempmin,rainsnow,precipprob,wind,pressure,sunrise,sunset,uvindex2',
          unitgroup:this.unitGroup,
          };
    },

    noData() {
      return false;
    },
    hasSession() {
      return this.sessionId;
    },

  },
  watch: {

    /*
    queryDefinitions:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.selectedQueryDefn=me.queryDefinitions[0];
        },
        deep: true
    },*/
  },
  updated() {
   
  },
  unmounted() {
    var me=this;
  },
  beforeMount() {

  },
  mounted() {
    var me=this;

    //console.log("mounting...")
    var containerElement=me.$el.parentElement;
    me.view=containerElement.getAttribute('data-view') || "dailysummary"
   

    me.refreshLocationsHistory();

    var lastLocation=me.hasLocationsHistory && me.locationsHistory[0];
    var savedUnitGroup=VcLocationsStorage.getUnitGroup();
    
    var unitGroup;
    var startdate=containerElement.getAttribute('data-baseperiod');
    var enddate=null;

    if (window.vcconfig) {
      if (window.vcconfig.location==="<?php echo $location ?>") window.vcconfig.location="Herndon,VA";
      if (window.vcconfig.unitGroup==="<?php echo $unitGroup ?>") window.vcconfig.unitGroup="us";
      if (window.vcconfig.startdate==="<?php echo $startDate ?>") window.vcconfig.startdate="today";
      if (window.vcconfig.enddate==="<?php echo $endDate ?>") window.vcconfig.enddate="";

      if (!window.vcconfig.isDefault || !lastLocation) {
        if (window.vcconfig.location) {
          me.selectedLocation={
            location:window.vcconfig.location,
            name:null
          }
          me.saveLocation(me.selectedLocation);
          me.refreshLocationsHistory();
         
        }
        if (window.vcconfig.unitGroup) {
          unitGroup=window.vcconfig.unitGroup;
          if (unitGroup) VcLocationsStorage.setUnitGroup(unitGroup);
        }

        if (window.vcconfig.startdate) {
          startdate=window.vcconfig.startdate;
        }

        if (window.vcconfig.enddate) {
          enddate=window.vcconfig.enddate;
        }
      }
    }
    
    me.selectedLocation=me.selectedLocation || lastLocation;
    me.unitGroup=unitGroup || savedUnitGroup || "us";
    me.startdate=startdate;
    me.enddate=enddate;


    me.basePeriodLabel=containerElement.getAttribute('data-baseperiodlabel') 

    const showapicontrols=containerElement.getAttribute('data-showapicontrols');
    if (showapicontrols) {
      me.showApiControls=showapicontrols==="true";
    }
    
    me.rooturl=containerElement.getAttribute('data-rooturl') 
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    me.sessionState=vcCommunicator.sessionState;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
      me.sessionId=sessionId;
      me.sessionState=sessionState;
     
      
    });
     vcCommunicator.addHandler('terminatesession', function () {
       me.sessionId=null;
    });

    VcWidgetHelper.addHandler("datastored", function (id, defn, data) {
      me.selectedQueryDefn=defn;
      me.selectedQueryData=data && data.result==="success" && data.data;
      me.dataStoredError=null;
    })
      
    VcWidgetHelper.addHandler("datastorederror", function (id, defn,msg) {
      me.selectedQueryDefn=defn;
      me.dataStoredError=msg;
    })
    me.isLoading=false;
    
  },
  components: {
    ApiQuery,
    MultiPeriodChartWidget,
    DaySummaryWidget,
    StationInfoWidget,
    DateRangeChooser,
    CommonModals
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>