
import './weather-last-weekend.scss'
import "/src/main.js"
import "/src/accountcomponents.js"


import { createApp } from 'vue'
import WeatherSummaryComponent from '/src/components/WeatherSummary'


(function(w,d) {

  window.addEventListener("load",function(event) {
    createApp(WeatherSummaryComponent).mount("#weatherSummary")
  },false);

})(window, document);