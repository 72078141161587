

export var VcDataProcessing = (function () {
    const DEFAULT_COLS= ['name','datetime', 'tempmax', 'tempmin', 'temp', 'degreedays', 'accdegreedays','feelslikemax', 'feelslikemin', 'feelslike', 'dew', 'humidity', 'precip', 'precipprob', 'precipcover', 'preciptype', 'snow', 'snowdepth', 'windgust', 'windspeed', 'winddir', 'pressure', 'cloudcover', 'visibility', 'solarradiation', 'solarenergy', 'uvindex', 'severerisk', 'sunrise',  'sunset',  'moonphase', 'conditions', 'description', 'icon', 'stations', 'source']
     
    return {
        gridFromResult:function(wxdata,view) {
           // console.log("gridFromResult");
            if (view==="days") {
                return wxdata.days
            } else if (view==="hours") {
            return wxdata.days.reduce(function(a, d) {
                if (d && d.hours) {
                    d.hours[0].daydatetime=d.datetime;
                    return a.concat(d.hours);
                }
                return a;
            }, []);
            } else if (view==="current") {
                return (wxdata.currentConditions && [wxdata.currentConditions]) || [];
            } else if (view==="alerts") {
            return wxdata.alerts || [];
            } else if (view==="events") {
                return wxdata.days.reduce(function(a, d) {
                    if (d && d.events) {
                    return a.concat(d.events);
                    }
                    return a;
                }, []);
            } else if (view==="stations") {
            var stations=wxdata.stations || {};
            return Object.values(stations);
            } else if (view==="info") {
            var info=[];
            
            info.push( Object.keys(wxdata).reduce(function(a, d) {
                if (typeof wxdata[d] === 'object') return a;
                a[d]=wxdata[d];
                return a;
            }, {}));
        
            return info;
            }
        },
        columnHeaders(visibleCols, data, view) {
            //console.log("columnHeaders");
            if (!data || data.length===0) return [];
            visibleCols=visibleCols || DEFAULT_COLS;
            //var visibleCols=this.currentCols || this.defaultCols;

            var iterateProps=function(a, d) {
                for (var p in d) {
                    if (p==='hours') continue;
                    if (p && a.indexOf(p)===-1) a.push(p);
                }
            }

            var allheaders= (data && data.reduce(function (a, d) {
                iterateProps(a,d)
                
                return a;
            },[] )) || [];
            
            if (['days','hours','subhourly','current'].indexOf(view)===-1) return allheaders;
            
            allheaders=allheaders.concat(['name','address','resolvedAddress','latitude','longitude']);
            
            return allheaders.filter(function(d) {
                return visibleCols.indexOf(d)!==-1;
            });

        }
    }
}());


