
export var VcLocationsStorage = (function () {
    const MAX_LOCATIONS=10;
    const DEFAULT_STATE={
        locations:[],
        unitGroup:"metric",
        cid:null,
        required:{
            necessary:true,
            functional:false,
            analysis:false,
            ads:false
        }
    };

	var storage=window && window.localStorage;
    var vcstate=DEFAULT_STATE;
    
    var processCookieValues=function() {
        window.zE && window.zE('webWidget', 'updateSettings', {
            cookies: hasFunctionalCookies()
        });
        if (window.gtag) {
            gtag('consent', 'update', {
                'ad_storage': hasAdsCookies()?'granted':'denied',
                'analytics_storage': hasAnalysisCookies()?'granted':'denied'
            });
        }
    }

    var _saveState=function() {
        if (!storage) return;

        

        try {
            var state;
            if (hasFunctionalCookies()) {
                state=vcstate;
            } else {
                state={
                    required:vcstate.required
                };
            }
            storage.vcstate=JSON.stringify(state);
        } catch (e) {
            console.log("Error storing locations");
        }
        processCookieValues();
    }
    var _restoreState=function() {
        console.log("restore state 1...")
        if (!localStorage) {
            console.log("no local")
            vcstate=JSON.parse(JSON.stringify(DEFAULT_STATE));
        } else {
            console.log("with local")
            vcstate=(localStorage.vcstate && JSON.parse(localStorage.vcstate)) || JSON.parse(JSON.stringify(DEFAULT_STATE));
           
        }
        if (!vcstate.required) {
            console.log("Adding required property")
            vcstate.required=JSON.parse(JSON.stringify(DEFAULT_STATE.required));
        }
        if (!vcstate.cid) {
            vcstate.cid=Math.floor(Math.random() * Date.now()).toString(16);
            _saveState();
        }

        processCookieValues();
        console.log("restored state... with state:"+(vcstate?true:false))
    }

    var _addLocation=function(newLocation,name) {
        if (!location) throw "Invalid location";
        
        var locations=vcstate.locations || [];
        locations.unshift(newLocation);

        var dups={};
        locations=locations.filter(function(d) {
            if (dups[d.location]) return false;
            dups[d.location]=true;
            return true;
        });

        
        if (locations.length>MAX_LOCATIONS) locations=locations.slice(0, MAX_LOCATIONS);
        vcstate.locations=locations;
        _saveState();
    }

    var _removeLocation=function(locationOrIndex) {
        var locations=vcstate.locations;
        if (Number.isInteger(value)) {
            locations=locations.slice(location, 1);
        } else if (location.hasOwnProperty('location') ) {
            locations=locations.filter(function(d) {
                return d.vclocation!==locationOrIndex.vclocation;
            });
        } else {
            locations=locations.filter(function(d) {
                return d.vclocation!==locationOrIndex;
            });
        }

        vcstate.locations=locations;
        _saveState();
    }
/*
    var _clearAll=function() {
        vcstate.locations=[];
        _saveState();
    }
*/
    var hasFunctionalCookies=function() {
        return vcstate && vcstate.required && vcstate.required.functional;
    }
    var hasAnalysisCookies=function() {
        return vcstate && vcstate.required && vcstate.required.analysis;
    }
    var hasAdsCookies=function() {
        return vcstate && vcstate.required && vcstate.required.ads;
    }
    var _setUnitGroup=function(unitGroup) {
        vcstate.unitGroup=unitGroup;
        _saveState();
    }
    var _getUnitGroup=function() {
        return vcstate.unitGroup;
    }

    var _setValue=function(name, value, required) {
        if (required) {
            vcstate.required[name]=value;
        } else {
            vcstate[name]=value;
        }
        
        _saveState();
    }
    var _getValue=function(name) {
        return vcstate[name] || (vcstate.required && vcstate.required[name]);
    }
    var _hasValue=function(name) {
        return vcstate[name]!==null;
    }


    var _enableAllCookies=function() {
        if (window["gtag"]) {
            gtag('event', 'enableAllCookies', { method : 'Vc' });
          }

        _setValue("hasSetCookies", 1, true);
        for (const [key, value] of Object.entries(DEFAULT_STATE.required)) {
            vcstate.required[key]=true
        }
       _saveState();
    }
    var _disableAllCookies=function() {
        if (window["gtag"]) {
            gtag('event', 'disableAllCookies', { method : 'Vc' });
        }
        _setValue("hasSetCookies", 1, true);
        for (const [key, value] of Object.entries(DEFAULT_STATE.required)) {
            vcstate.required[key]=value
        }

        //vcstate.required=JSON.parse(JSON.stringify(DEFAULT_STATE.required));
       _saveState();
    }
    var _setAllCookies=function(value) {
        if (window["gtag"]) {
            gtag('event', 'setAllCookies', { method : 'Vc' });
        }
        _setValue("hasSetCookies", 1, true);
        vcstate.required=value;
       _saveState(true);
    }
    var _resetAllCookies=function() {
        vcstate={};
        vcstate.required=JSON.parse(JSON.stringify(DEFAULT_STATE.required));
        _saveState();
    }
   var _getCookieValue=function(name) {
       return vcstate.required && vcstate.required[name];
   }

   var _getAllCookies=function() {
       if (!vcstate.required) {
        vcstate.required=JSON.parse(JSON.stringify(DEFAULT_STATE.required));
       }
       return vcstate.required;
   }
  

    _restoreState();

    return {
        hasStorage:function() {
          return storage;
        },
        getLocations() {
            return vcstate.locations && vcstate.locations.map(function(d) {return d;});
        },
        getCid() {
            return vcstate.cid;
        },
        addLocation:_addLocation,
        removeLocation:_removeLocation,
        //clearAll:_clearAll,
        setUnitGroup:_setUnitGroup,
        getUnitGroup:_getUnitGroup,
        getCookieValue:_getCookieValue,
        enableAllCookies:_enableAllCookies,
        disableAllCookies:_disableAllCookies,
        resetAllCookies:_resetAllCookies,
        getAllCookies:_getAllCookies,
        setAllCookies:_setAllCookies,
        setValue:_setValue,
        getValue:_getValue,
        hasValue:_hasValue,
    }
}());
