
//import moment from "moment"
import dayjs from "dayjs"
//import utc  from "dayjs/plugin/utc"
//import timezone from "dayjs/plugin/timezone"
import customParseFormat from "dayjs/plugin/customParseFormat"

export var VcDateUtils = (function () {
    dayjs.extend(customParseFormat);
    
    return {
      formatDateTime2:function(epochSecsOrDate,tz, options) {
        if (epochSecsOrDate===null || epochSecsOrDate===0) {
          return "-";
        }

        var date=(epochSecsOrDate instanceof Date)?epochSecsOrDate:new Date(epochSecsOrDate*1000);
        var options = options || {
          month: 'short', 
           day:'numeric',
           hour: 'numeric', 
           minute: 'numeric',  
           /*hour12: false,*/
           hourCycle: 'h23',
         };
         if (tz) options.timeZone=tz;
         try {
            return (new Intl.DateTimeFormat('en-US', options).format(date));
         } catch(e) {
            console.error("Error formatting date with epoch sec value:"+epochSecsOrDate);
            return "-";
         }
     },
     formatTime:function(epochSecs,tz) {
        return this.formatDateTime2(epochSecs,tz, {
            hour: 'numeric', 
            minute: 'numeric',  
            hour12: false
          });
     },
     formatDate:function(epochSecs,tz) {
        return this.formatDateTime2(epochSecs,tz,{
          year: 'numeric', 
            month: 'short', 
            day:'numeric'
          });
     },
     formatDateShort:function(epochSecs,tz) {
      return this.formatDateTime2(epochSecs,tz,{
          month: 'short', 
          day:'numeric'
        });
      },
      formatMonthShort:function(epochSecs,tz) {
        return this.formatDateTime2(epochSecs,tz,{
            month: 'short', 
          
          });
        },
     formatDateIso:function(epochSecs,tz) {
        var date;
        if (epochSecs instanceof Date) {
            date=epochSecs;
        } else {
            date=new Date(epochSecs*1000);
        }

        
        var options = {
            timeZone: tz
          };
         
          try {
             return (new Intl.DateTimeFormat('sv', options).format(date));
          } catch(e) {
             console.error("Error formatting date with epoch sec value:"+epochSecs);
             return "?";
          }
      },
      dateFromIso:function(dateString) {
        if (!dateString) return null;
        return dayjs(dateString).toDate();
       
      },
      dateFromIsoLocal:function(dateString) {
       
        if (!dateString) return null;

        return dayjs(dateString).toDate();

        
      },
      toIsoString(date) {
        return dayjs(date).toISOString() 
      },
      formatDateCustom(date, format) {
        return dayjs(date).format(format) 
      },
      truncateToHour(date, offset) {
        offset=offset || 0;
        return new Date(Math.floor(date.getTime()/3600000)*3600000+offset*3600000);
      },
      truncateToMinute(date, offset) {
        offset=offset || 0;
        return new Date(Math.floor(date.getTime()/60000)*60000+offset*60000);
      },
      dateFromString(dateString, formats) {
        if (!dateString) return null;
        dateString=dateString.trim();
        let day;
        if (!formats)  {
          day=dayjs(dateString);
        }  else {
          day=dayjs(dateString, formats);
        }
        if (!day || !day.isValid()) return null;

        return day.toDate();
      },

      addDays(date, days) {
        const dateCopy = new Date(date);
        dateCopy.setDate(date.getDate() + days);
        return dateCopy;
      },
    }
}());
