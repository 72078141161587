export var VcFindMyLocation = (function () {
    

    return {

      lookupLocation:function() {
        return new Promise((resolve, reject) => {

          
          fetch('https://api.ipdata.co/?api-key=2a3ba97e6c19d40545bdd9942b0d1cd06da89daeedadf02b3ed83929', {
            method: 'GET', 
            mode: 'cors',
            cache: 'no-cache', 
            headers: {
            
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'strict-origin-when-cross-origin', 
          }).then(response => {
              if (!response.ok) {
                throw response;
              }
              
              return response.json();
          }).then(response => {

            var pieces=[];
            if (response.city) pieces.push(response.city);
            
            if (response.region_code) {
              pieces.push(response.region_code);
            } else if(response.region) {
              pieces.push(response.region);
            }
            if (response.country_code) {
              pieces.push(response.country_code);
            } else if(response.country_name) {
              pieces.push(response.country_name);
            }
            
            var address="";
            
            for (var i=0;i<pieces.length;i++) {
              if (address.length>0) address+=", ";
              address+=pieces[i];
            }

            resolve({
              lat:response.latitude,
              lon:response.longitude,
              address:address,
            });
          
          }).catch((errorResponse) => {
              if (errorResponse.text) {
                  errorResponse.text().then( errorMessage => {
                      reject(errorMessage);
                  })
              } else {
                  reject("An error occurred retrieving the data");
              }  
          });
        });
      },

      findMyLocation:function(useGps) {
        return new Promise((resolve, reject) => {
          if (useGps) {
            var options = {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            };
            navigator.geolocation.getCurrentPosition(function(pos) {
              pos=pos && pos.coords;
              if (!pos || !pos.latitude || !pos.longitude) {
                reject("Invalid response");
                return;
              }
              resolve(pos.latitude.toFixed(4)+","+pos.longitude.toFixed(4));
            }, 
            function() {
              populateLocationFromMyLocation(false)
                .then(response=> {
                  resolve(response);
                })
                .fail(response=>{
                  reject(response)
                });

            }, options)
          }
          
          fetch('https://api.ipdata.co/?api-key=2a3ba97e6c19d40545bdd9942b0d1cd06da89daeedadf02b3ed83929', {
            method: 'GET', 
            mode: 'cors',
            cache: 'no-cache', 
            headers: {
            //'Content-Type': 'application/json'
            // 'Content-Type': contentType,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(postdata) // body data type must match "Content-Type" header
            // body: data
          }).then(response => {
              if (!response.ok) {
                throw response;
              }
              
              return response.json();
          }).then(response => {

            var pieces=[];
            if (response.city) pieces.push(response.city);
            
            if (response.region_code) {
              pieces.push(response.region_code);
            } else if(response.region) {
              pieces.push(response.region);
            }
            if (response.country_code) {
              pieces.push(response.country_code);
            } else if(response.country_name) {
              pieces.push(response.country_name);
            }
            
            var address="";
            
            for (var i=0;i<pieces.length;i++) {
              if (address.length>0) address+=", ";
              address+=pieces[i];
            }

            resolve(address);
          
          }).catch((errorResponse) => {
              //console.log(errorResponse) 
              if (errorResponse.text) {
                  errorResponse.text().then( errorMessage => {
                      reject(errorMessage);
                  })
              } else {
                  reject("An error occurred retrieving the data");
              }  
          });
        });
      }
    }
}());