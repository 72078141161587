<template>
  <div>
    <div class="modal fade confirm" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered" modal-xl>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmationModalLabel">{{confirmPackage.title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="confirmPackage.handle('cancel')"></button>
          </div>
          <form>
            <div class="modal-body" v-html="confirmPackage.content"></div>
            <div class="modal-footer">
              <button v-if="showCancelButton" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal" @click="confirmPackage.handle('cancel')">{{confirmPackage.cancelLabel}}</button>
              <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" @click="confirmPackage.handle('ok')">{{confirmPackage.okLabel}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="modal fade error" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered" modal-xl>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="errorModalLabel">{{errorPackage.title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="errorPackage.handle('cancel')"></button>
          </div>
          <form>
            <div class="modal-body" v-html="errorPackage.content"></div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal" @click="errorPackage.handle('cancel')">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>

   <div class="toast-container fixed-top start-50 translate-middle-x mt-5" id="toastPlacement">
      <div class="toast simpletoast bg-primary text-white" data-bs-delay="2500">
        <div class="d-flex">
          <div class="toast-body" v-html="toastPackage.content"></div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonModals',
  props: {
   
  },
  //emits: ['click','cancel','ok'],
  data() {
    return {
      confirmPackage: {
        title:"",
        content:"",
        show:false,
        handle:null,
        okLabel:null,
        cancelLabel:null
      },
      errorPackage: {
        title:"",
        content:"",
        show:false,
        handle:null
      },
      toastPackage: {
        title:"",
        content:"",
        show:false,
        handle:null
      }
      
    }
  },
  methods:{


    injectGlobalMethods() {
      var me=this;
      console.log("Injecting common modals")
      window.VcCommonModals=(function() {

          return {
            //showCommonModal: function(config) {
            //  return me.showModal(config);
            //},
            showAlert(title,content, okLabel) {
              return me.showAlert(title, content, okLabel);
            },
            showConfirm(title,content, okLabel,cancelLabel ) {
              return me.showConfirm(title, content, okLabel,cancelLabel);
            },
            showError(title,content) {
              return me.showError(title, content);
            },
            showToast(title,content) {
              return me.showToast(title, content);
            }
          }
      })();

    },

    showAlert(title,content, okLabel) {
      var me=this;
      return new Promise((resolve, reject) => {
        me.confirmPackage.title=title;
        me.confirmPackage.content=content;
        me.confirmPackage.okLabel=okLabel || "Ok";
        me.confirmPackage.cancelLabel=null;
        me.confirmPackage.show=true;
        me.confirmPackage.handle=function(id) {
          me.confirmPackage.show=false;
          if (id==='cancel') {
            reject(id);
          } else {
            resolve(id);
          }
        }
      });
    },

    showConfirm(title,content, okLabel,cancelLabel) {
      var me=this;
      return new Promise((resolve, reject) => {
        me.confirmPackage.title=title;
        me.confirmPackage.content=content;
        me.confirmPackage.okLabel=okLabel || "Ok";
        me.confirmPackage.cancelLabel=cancelLabel || "Cancel";
        me.confirmPackage.show=true;
        me.confirmPackage.handle=function(id) {
          me.confirmPackage.show=false;
          if (id==='cancel') {
            reject(id);
          } else {
            resolve(id);
          }
        }
      });
    },
    showToast(title,content) {
      var me=this;
      me.toastPackage.title=title;
      me.toastPackage.content=content;
      me.toastPackage.show=true;
      me.toastPackage.handle=function(id) {
        me.toastPackage.show=false;
      }
    },
    showError(title,content) {
      var me=this;
      me.errorPackage.title=title;
      me.errorPackage.content=content;
      me.errorPackage.show=true;
      me.errorPackage.handle=function(id) {
        me.errorPackage.show=false;
      }
    },
    /*
    showModal(config) {
      if (config.id==="confirm") {
        this.showConfirm(config);
      } else if (config.id==="error") {
        this.showError(config);
      } else {
        console.log("Unknown modal requested:"+id);
      }
    },
    */
    setToastState(selector, show) {
      var me=this;
      var myToastEl = this.$el && this.$el.querySelector(selector);
      var myToast = bootstrap.Toast.getOrCreateInstance(myToastEl);
      myToastEl.addEventListener('hidden.bs.toast', function () {
        me.toastPackage.show=false;
      })
      if (show) {
        myToast.show();
      } else { 
        myToast.hide();
      } 
    },
    setModalState(selector, show) {
      var target=this.$el && this.$el.querySelector(selector);
      var modal = bootstrap.Modal.getOrCreateInstance(target);
      if (show) {
        modal.show();
      } else { 
        modal.hide();
      } 
    }
  /*
    buttonClick(id) {
      this.setModalState(false)
      if (id==="ok") {
        this.$emit("ok");
      } else if (id==="cancel") {
        this.$emit("cancel");
      } else {
        this.$emit('click',id);
      }
      
    },
   
  */
  },
  computed:{
    showCancelButton() {
      return this.confirmPackage && this.confirmPackage.cancelLabel;
    }
  },
  watch: {
    "confirmPackage.show":function(newValue) {
      this.setModalState(".confirm", newValue);
    },
    "errorPackage.show":function(newValue) {
      this.setModalState(".error", newValue);
    },
    "toastPackage.show":function(newValue) {
      this.setToastState(".simpletoast", newValue);
    }
  },
  mounted() {
    this.injectGlobalMethods();
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>