
import Prism from "/node_modules/prismjs/prism.js"
Prism.manual = true;

export var VcQueryLanguageGenerator = (function () {
	const availableLangs= [
        {
          id:"http",
          label:"Http",
          class:"none"
        },
        {
          id:"curl",
          label:"cURL",
          class:"none"
        },
        {
          id:"javascript",
          label:"JavaScript"
        },
        {
          id:"java",
          label:"Java"
        },
        {
          id:"python",
          label:"Python"
        },
        {
          id:"python_requests",
          label:"Python (Requests library)"
        },
      ]
   

    var _prepareQuery=function(defn,key, location, name, contentType ) {
    
      function encodeURIComponentIfNotNull(value) {
          return  value?encodeURIComponent(value):value;
      }

      location=location || defn.location;
      name=name || defn.name;

      location=encodeURIComponentIfNotNull(location);
      name=encodeURIComponentIfNotNull(name);

      var startdate=encodeURIComponentIfNotNull(defn.startdate);
      var enddate=encodeURIComponentIfNotNull(defn.enddate);

      
      key=key ||defn.key || "YOUR_API_KEY";
      contentType=contentType || defn.contentType;

      var query={
        basePath:"https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services",
        pathParams:"",
        params:{}
      }
      var queryParams=query.params;
      if (name) queryParams.locationNames=name;
      if (defn.unitGroup) queryParams.unitGroup=defn.unitGroup;
      if (defn.maxDistance>0) queryParams.maxDistance=defn.maxDistance;
      if (defn.elements) queryParams.elements=defn.elements;
      if (defn.include) queryParams.include=defn.include;
      
      
      queryParams.key=key;


      if (defn.maxStations>0) queryParams.maxStations=defn.maxStations;
      if (defn.elevationDifference>0) queryParams.elevationDifference=defn.elevationDifference;
      if (defn.options) queryParams.options=defn.options;
      if (defn.lang) queryParams.lang=defn.lang;
      if (defn.iconSet) queryParams.iconSet=defn.iconSet;

      if (contentType) queryParams.contentType=contentType;
      if (defn.forecastBasisDate) queryParams.forecastBasisDate=defn.forecastBasisDate;
      if (defn.forecastBasisDay) queryParams.forecastBasisDay=defn.forecastBasisDay;
          
      if (defn.degreeDayMethod) queryParams.degreeDayMethod=defn.degreeDayMethod;
      if (defn.degreeDayTempFix) queryParams.degreeDayTempFix=defn.degreeDayTempFix;
      if (defn.degreeDayTempMaxThreshold) queryParams.degreeDayTempMaxThreshold=defn.degreeDayTempMaxThreshold;
      if (defn.degreeDayTempBase) queryParams.degreeDayTempBase=defn.degreeDayTempBase;
      if (defn.degreeDayStartDate) queryParams.degreeDayStartDate=defn.degreeDayStartDate;
      if (defn.degreeDayInverse) queryParams.degreeDayInverse=defn.degreeDayInverse;
      query.pathParams="/timeline/"+location;
      if (startdate) {
        query.pathParams+="/"+startdate;
          if (enddate) {
            query.pathParams+="/"+enddate;
          }
      }
      
      return query;
    }
    /*
    var _safePrismHighlight=function(text,grammar,language) {

      if (!grammar) {
        console.log(`Couln't fine language grammar ${language}`);

        //return Prism.highlight(text, Prism.languages.javascript, "javascript"); 
      }
      return text;
      //return Prism.highlight(text, grammar, language);
    }
    */
    var _genHttp=function( query) {
      var raw= query.basePath+""+
          query.pathParams+"?"+
          new URLSearchParams(query.params);
      return raw;
      //return _safePrismHighlight(raw, Prism.languages.http, "http");
    }

    var _genJsFetch=function(query) {

      var raw= 
`fetch("${query.basePath+query.pathParams+"?"+ new URLSearchParams(query.params)}", {
  "method": "GET",
  "headers": {
  }
  })
.then(response => {
  console.log(response);
})
.catch(err => {
  console.error(err);
});
`;

      return raw;
      //return _safePrismHighlight(raw, Prism.languages.javascript, "javascript");
    }
    var _genJavaNetHttp=function(query) {
      var raw= 
`HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("${query.basePath+query.pathParams+"?"+ new URLSearchParams(query.params)}"))
    .method("GET", HttpRequest.BodyPublishers.noBody()).build();
HttpResponse<String> response = HttpClient.newHttpClient()
    .send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`;
      return raw;
      //return _safePrismHighlight(raw, Prism.languages.java, "java");
    }
    var _genCurlGet=function(query) {
      var raw= `curl -X POST  "${query.basePath+query.pathParams+"?"+ new URLSearchParams(query.params)}"`;
      return raw;
      //return _safePrismHighlight(raw, Prism.languages.java, "java");
    }


    var _genPython=function(query, contentType) {

      contentType=contentType || "json";

      let parserCode;
      let importCode;
      if (contentType==="csv") {
        parserCode=`
  # Parse the results as CSV
  CSVText = csv.reader(codecs.iterdecode(ResultBytes, 'utf-8'))
        `;

        importCode=`
import csv
import codecs
        `;

      } else if (contentType==="json") {
        parserCode=`
  # Parse the results as JSON
  jsonData = json.load(ResultBytes)
        `;

        importCode=`
import json
                `;

      }  else {
        parserCode=`
  # Choose CSV or JSON to see sample parsing   
        `;
        importCode=``;
      }

      var raw= 
`
import urllib.request
import sys
${importCode}
try: 
  ResultBytes = urllib.request.urlopen("${query.basePath+query.pathParams+"?"+ new URLSearchParams(query.params)}")
  ${parserCode}
except urllib.error.HTTPError  as e:
  ErrorInfo= e.read().decode() 
  print('Error code: ', e.code, ErrorInfo)
  sys.exit()
except  urllib.error.URLError as e:
  ErrorInfo= e.read().decode() 
  print('Error code: ', e.code,ErrorInfo)
  sys.exit()`;
      return raw;
     
    }
    

    var _genPythonRequests=function(query, contentType) {

      contentType=contentType || "json";

      let parserCode;
      let importCode;
      if (contentType==="csv") {
        parserCode=`
# Parse the results as CSV
CSVText = csv.reader(response.text.splitlines(), delimiter=',',quotechar='"')
        `;

        importCode=`
import csv
import codecs
        `;

      } else if (contentType==="json") {
        parserCode=`
# Parse the results as JSON
jsonData = response.json()
        `;

        importCode=`
import json
                `;

      }  else {
        parserCode=`
# Choose CSV or JSON to see sample parsing   
        `;
        importCode=``;
      }

      var raw= 
`
import requests
import sys
${importCode}

response = requests.request("GET", "${query.basePath+query.pathParams+"?"+ new URLSearchParams(query.params)}")
if response.status_code!=200:
  print('Unexpected Status code: ', response.status_code)
  sys.exit()  

${parserCode}
`;
      return raw;
     
    }

    return {
        generateCode:function(lang, queryDefinition, key) {
            //console.log("Gen code for "+lang)
            var query=_prepareQuery(queryDefinition, key);
            let contentType=queryDefinition.contentType;
            if (lang==="http") {
              return _genHttp(query, contentType);
            } else if (lang==="javascript") {
              return _genJsFetch(query, contentType);
            } else if (lang==="java") {
              return _genJavaNetHttp(query, contentType);
            } else if (lang==="curl") {
              return _genCurlGet(query, contentType);
            } else if (lang==="python") {
              return _genPython(query, contentType);
            } else if (lang==="python_requests") {
              return _genPythonRequests(query, contentType);
            } else {
              return lang="?";
            }
        },
        LANGUAGES:availableLangs
    }
}());
