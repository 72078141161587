

<script>

import {VcWidgetHelper} from './common_widget.js'
import KpiWidget from './kpiwidget.vue'
//import LocationEditor from './locationeditor.vue'
export default {
  name: 'day-summary-widget',
  props:{
    weatherData:Object,
    parentData:Boolean,
    config:Object,
  },
  data() {
    return {
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      options:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      viewincludes:null,
      summarykpis:[],
      dayIndex:0,
      endDayIndex:0,
      hourIndex:null,
      period:"day",
      rooturl:null,
      widgetcolumncount:1,
    }
  },
  methods:{
   
    refreshData() {
      var me=this;
      if (me.parentData) {
        return;
      }
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    options:me.options,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:me.forceRefresh,
                })
      .then(response => {
          me.wxdata=response.data;
          if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
          if (!me.location) me.location=me.wxdata.resolvedAddress;
      }).catch((error) => {
          me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    formatValue(value, element) {
      var me=this;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup );
    },
    formatTime(period) {
      return VcWidgetHelper.formatTime(period.datetimeEpoch, this.wxdata.timezone);
    },
    formatDate(period) {
      return VcWidgetHelper.formatDate(period.datetimeEpoch, this.wxdata.timezone);
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    alertDesc(alert) {
      var desc= alert && alert.description;
      desc=desc.replace(/(?:\r\n|\r|\n)/g, '<br>');

      return desc;
    }
  },
  computed:{
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation(this.wxdata, this.location, this.name, );
    },
    hasError:function() {
      return this.error;
    },
    hasData:function() {
      return this.focusDays;
    },
    currentConditions() {
      var me=this;
      return me.wxdata && me.wxdata.currentConditions;
    },
    showCurrent:function() {
      return this.doShow('current') && this.currentConditions;
    },
    showPeriods:function() {
      return this.doShow('periods') || this.dayIndex>=0;
    },
    focusDays:function() {
      var me=this;
      //console.log("focusDays")
      if (!me.wxdata || !me.wxdata.days) return null;
      if (me.dayIndex || me.dayIndex===0) {
        if (me.endDayIndex>me.dayIndex) {
           return me.wxdata.days.slice(me.dayIndex, me.endDayIndex+1)
        } else {
           return [me.wxdata.days[me.dayIndex]];
        }
      }
      return me.wxdata.days;
    },
    showAlerts:function() {
      return this.doShow('alerts') && this.alerts && this.alerts.length!==0;
    },
    alerts:function() {
        var me=this;
        return me.wxdata && me.wxdata.alerts;
    },
    summarykpicount:function() {
        return this.summarykpis?this.summarykpis.length:0;
    },
    showSummary() {
      return this.doShow('icon') || this.summarykpicount
    },
    summaryCssClasses() {
      if (this.doShow('icon')) return 'summarykpis'+this.summarykpicount
      return 'summarykpis'+this.summarykpicount+" noicon"; 
    },
    widgetcolumns() {
     
      var preferredColumns=this.widgetcolumncount;
      // console.log("columns:"+preferredColumns)
      if (!preferredColumns || preferredColumns<2) return "onecols";
      if (preferredColumns==2) return "twocols";
      if (preferredColumns==3) return "threecols";
      if (preferredColumns==4) return "fourcols";
      return "onecols";
    }
  },
  watch: {
    config:{
        handler:function(newValue,oldValue) {
          var me=this;
         
          me.refreshData();
        },
        deep: true
    },
  },
  mounted() {
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);
    me.refreshData();
  },
   components: {
    KpiWidget,
    //LocationEditor
  }
}
</script>
<template>
  <div class="widget" :class="widgetcolumns">
    <!--<LocationEditor v-if="doShow('locationeditor')" :location="location" :unitGroup="unitGroup" :rooturl="rooturl"  />-->
    <div v-if="error">{{error}}</div>
    <div v-if="showCurrent" class="current maingrid">
      <div v-if="doShow('date')" class="period">{{formatTime(currentConditions)}}</div>
      <div v-if="showSummary" class="summary" :class="summaryCssClasses">
        <div v-if="doShow('icon')" class="icon" ><div :class="currentConditions.icon"></div></div>
        <KpiWidget class="summary" v-for="e in summarykpis" :value="currentConditions" :element="e"  :unitGroup="unitGroup" />
      </div>
      <template v-if="doShow('astro')">
          <KpiWidget :value="currentConditions" element="sunrise" :unitGroup="unitGroup" />
          <KpiWidget :value="currentConditions" element="sunset" :unitGroup="unitGroup" />
          <KpiWidget :value="currentConditions" element="moonphase" class="moonphase" :unitGroup="unitGroup" />
        </template>
    </div>
    <div v-if="showAlerts" class="alerts ">
        <div class="">Alerts</div>
        <div v-for="a in alerts">
          <div class="event">{{a.event}}</div>
          <p  class="desc text-wrap" v-html="alertDesc(a)"></p>
        </div>
    </div>
    <div v-if="showPeriods" v-for="focusDay in focusDays" class="maingrid">
       <div v-if="!hasData" class="nodata" >
        No data available
      </div>
      <div v-if="hasError" class="haserror" >
        {{error}}
      </div>
      <template v-if="hasData">
        <div v-if="doShow('location')" class="location">{{adjustedLocation}}</div>
       
        <div v-if="doShow('date')" class="period">{{formatDate(focusDay)}}</div>
        <div v-if="showSummary" class="summary" :class="summaryCssClasses">
          <div v-if="doShow('icon')" class="icon" ><div :class="focusDay.icon"></div></div>
          <KpiWidget class="summary" v-for="e in summarykpis" :value="focusDay" :element="e"  :unitGroup="unitGroup" />
        </div>
        <template v-if="doShow('desc')">
          <div class="description">{{focusDay.description}}</div>
        </template>
        <!--<div class="details">&nbsp;</div>-->
        <template v-if="doShow('temp')">
          <KpiWidget :value="focusDay" element="tempmax" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="tempmin" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('feekslike')">
          <KpiWidget :value="focusDay" element="feelslikemax" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="feelslikemin" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('precip')">
          <KpiWidget :value="focusDay" element="precip" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('rainsnow')">
          <KpiWidget :value="focusDay" element="rainsnow" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('precipprob')">
          <KpiWidget :value="focusDay" element="precipprob" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('snow')">
          <KpiWidget :value="focusDay" element="snow" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="snowdepth" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('wind')">
          <KpiWidget :value="focusDay" element="wind" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="windgust" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('humidity')">
          <KpiWidget :value="focusDay" element="humidity" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="dew"  :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('pressure')">
          <KpiWidget :value="focusDay" element="pressure" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="cloudcover"  :unitGroup="unitGroup" />
        </template>
         <template v-if="doShow('solar')">
          <KpiWidget :value="focusDay" element="solarradiation" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="uvindex" :unitGroup="unitGroup" />
        </template>
        <template v-if="doShow('astro')">
          <KpiWidget :value="focusDay" element="sunrise" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="sunset" :unitGroup="unitGroup" />
          <KpiWidget :value="focusDay" element="moonphase" class="moonphase" :unitGroup="unitGroup" />
        </template>
      </template>
    </div>
  </div>
</template>
<style scoped>

  .kpi {
    display: grid;
    gap: 0px;
    justify-items: center;
  }
  
  .alerts {
    margin-top:15px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 10px;
  }
  .alerts .event {

  }
  .alerts .desc {
    font-size:0.6em;
    width:100%;
  }
  .current {
    grid-column: 1/-1;
  }

  .maingrid :deep() .normals {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: .5em;
    row-gap: 0px;
    column-gap: 5px;
    align-items: center;
    justify-items: center;
   }

 
  .maingrid :deep() .kpi.summary {
    grid-column: span 1;
    line-height: 20px;
    font-size:1.2em; 
    font-weight: 400;
  }
  .maingrid :deep() .kpi {
    grid-column: span 1;
  }
  
  .maingrid .period {
    grid-column: 1/-1;
  }

  .maingrid .summary {
    grid-column: 1/-1;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    align-items: center;
    justify-items: center;
    margin-top:10px;
    margin-bottom:10px;
  }
  .summary .icon {
    grid-column: 1/1;
  }

  .summary.summarykpis0 .icon {
    grid-column: 1/-1;
  }

  .summary.summarykpis1 {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis1.noicon {
    grid-template-columns: repeat(1, 1fr);
  }
  .summary.summarykpis2 {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis2.noicon {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis3 {
    grid-template-columns: repeat(4, 1fr);
  }
  .summary.summarykpis3.noicon {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis4 {
    grid-template-columns: repeat(3, 1fr);
  }
  .summary.summarykpis4.noicon {
    grid-template-columns: repeat(2, 1fr);
  }
  .summary.summarykpis4 .icon {
    grid-column: 1/1;
    grid-row: span 2;
  }


  .maingrid .description {
    grid-column: 1/-1;
    white-space: normal;
  }
  .maingrid .details {
    margin-top:10px;
    border-top:1px solid #929292;
    padding-top:10px;
    grid-column: 1/-1;
    font-size: 0.6em;
    color:#929292;

  }
 
  .maingrid .moonphase {
     grid-column: 1/-1;
  }
  

  @media only screen and (min-width: 800px) {
    .maingrid .sunrise, .maingrid .sunset {
      grid-column: span 2;
    }
    .maingrid .moonphase {
      grid-column: span 2;
    }
  }


 

</style>
