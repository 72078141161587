<template>
  
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  data() {
    return {
      
    }
  },
  methods:{
   
  },
  computed:{
    
  },
  mounted() {
   
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
