<template>
  <div class="kpi">
    <div v-if="subtitle" class="subtitle">{{subtitle}}</div>
    <div v-html="formattedValue"></div>
    <div v-if="normals" class="normals">
      <div v-html="formatNormalValue(0)"></div>
      <div v-html="formatNormalValue(1)"></div>
      <div v-html="formatNormalValue(2)"></div>
      <div class="subtitle">Min</div>
      <div class="subtitle">Mean</div>
      <div class="subtitle">Max</div>
    </div>
  </div>
</template>

<script>
import {VcWidgetHelper} from './common_widget.js'
export default {
  name: 'kipwidget',
  props: {
    element: String,
    title: String,
    value: Object,
    unitGroup: String,
    showTitle: {
      type:Boolean,
      default:true,
    }
  },
  methods: {
      formatNormalValue(index) {
        var me=this;
        return VcWidgetHelper.formatElementValue(me.normals[index], me.element,me.unitGroup,null,"",false,true );
      },
      formatValue(value,element) {
        var me=this;
        return VcWidgetHelper.formatElementValue(value,element || me.element,me.unitGroup );
      },
      formattedWind(period) {
        return VcWidgetHelper.getCompassDirection(period.winddir)+" "+
        this.formatValue(period.windspeed, "wind");
      },
      formattedMoonPhase(period) {
        return VcWidgetHelper.getMoonPhaseDesc(period.moonphase);
      },
      formattedSunriseset(period) {
        return  this.formatValue(period.sunrise)+"-"+this.formatValue(period.sunset);
      },
  },
  computed: {
    formattedValue() {
        var me=this;
        if (!me.value) return "No data";
        if (me.element==="rainsnow") {
          if (me.value.snow>0) return me.formatValue(me.value.snow, "snow");
          return me.formatValue(me.value.precip, "precip");
        }
        if (me.element==="wind") return me.formattedWind(me.value);
        if (me.element==="moonphase") return me.formattedMoonPhase(me.value);
        if (me.element==="sunriseset") return me.formattedSunriseset(me.value);
        return me.formatValue(me.value[me.element]);
    },
    subtitle() {
      var me=this;
      if (!me.showTitle) return null;
      if (me.element==="rainsnow") {
        if (me.value.snow>0) return VcWidgetHelper.getElementTitle("snow");;
        return VcWidgetHelper.getElementTitle("precip");;
      }
      return  VcWidgetHelper.getElementTitle(this.element);
    },
    normals() {
      var me=this;
      if (me.element==="rainsnow") {
        if (me.value.snow>0) return me.value && me.value.normal && me.value.normal["snow"];
        return me.value && me.value.normal && me.value.normal["precip"];
      }
      return me.value && me.value.normal && me.value.normal[me.element];
    },
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
