export var VcJobStatus = (function () {
    const STATUS={
        UNKNOWN:0,
        QUEUED:1,
        EXECUTING:2,
        COMPLETE:3,
        ERROR:4,
        CANCELLED:5,
        IDLE:6,
    }
    function isError(status) {
        return status===this.STATUS.ERROR;
    }
    function isComplete(status) {
        return status===this.STATUS.COMPLETE;
    }
    function isCancelled(status) {
        return status===this.STATUS.CANCELLED;
    }
    function isFinishedState(status) {
        return status==this.STATUS.ERROR || status==this.STATUS.COMPLETE || status==this.STATUS.CANCELLED;
    }
    function isExecuting(status) {
        return status==this.STATUS.QUEUED || status==this.STATUS.EXECUTING;
    }
    function toText(status) {
        switch (status) {
            case this.STATUS.UNKNOWN: return "Unknown";
            case this.STATUS.QUEUED: return "Queued";
            case this.STATUS.EXECUTING: return "Executing";
            case this.STATUS.COMPLETE: return "Complete";
            case this.STATUS.ERROR: return "Error";
            case this.STATUS.CANCELLED: return "Cancelled";
            default: return "? " +status;
        }
    }
    return {
        STATUS:STATUS,
        isError:isError,
        isComplete:isComplete,
        isCancelled:isCancelled,
        isFinishedState:isFinishedState,
        isExecuting:isExecuting,
        toText:toText, 
    }
}());
