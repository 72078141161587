
<script>

import {VcWidgetHelper} from './common_widget.js'
//import  { MapContainer, TileLayer, Marker, Popup } from  'leaflet';

//const loadLeaflet = () => import('leaflet')

//import 'leaflet/dist/leaflet.css';
import {VcConversion} from '/../visualcrossing-core-js/src/vcconversion'
export default {
  name: 'simple-map-widget',
  props:{
    weatherData:Object,
    parentData:Boolean,
    views:Array,
    selectedPeriod:String,
    config:Object,
  },
  data() {
    return {
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      viewincludes:this.views,
      onresize:null,
      dayIndex:null,
      hourIndex:null,
      period:this.selectedPeriod || "day",
      stationsMap:null,
    }
  },
  methods:{
   
    refreshData() {
      var me=this;
      if (me.parentData) return;
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:me.forceRefresh
                })
      .then(response => {
        var data=response.data;
       
        VcWidgetHelper.processData(data);  
        me.wxdata=data;
        if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
        

        if (  me.wxdata && me.wxdata.days && me.wxdata.days.length<4 && me.wxdata.days[0] && me.wxdata.days[0].hours) {
          me.period="hours";
        }



      }).catch((error) => {
          me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    showPrecip(value) {
      return value && (value.precip>0 && value.source==="obs") || (value.precipprob>30);
    },
    formatPrecip(value) {
      var me=this;
      if (value.source==="obs") {
        return me.formatValue(value.precip,"precip");
      }
      return me.formatValue(value.precipprob,"precipprob");
    },
    formatValue(value, element) {
      var me=this;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup );
    },
    formatTime(period) {
      if (!period) return "-";
      if (typeof period==="number") {
        return VcWidgetHelper.formatTime(period, this.wxdata.timezone);
      } else {
        return VcWidgetHelper.formatTime(period.datetimeEpoch, this.wxdata.timezone);
      }
    },
    formatDateShort(period) {
      if (!period) return "-";
      if (typeof period==="number") {
        return VcWidgetHelper.formatDateShort(period, this.wxdata.timezone);
      } else {
        return VcWidgetHelper.formatDateShort(period.datetimeEpoch, this.wxdata.timezone);
      }
      
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    renderMap() {
      //console.log("renderMap")
      var me=this;
      var containerElement=me.$el.parentElement;
      var mapElement=containerElement.querySelector(".stationsMap");

      if (!me.stationsMap) {
        me.stationsMap = new L.Map(mapElement, {center: [37.8, -96.9], zoom: 4})
        .addLayer(new L.TileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", 
        {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }));
      }


           
      if (!me.stationsMap) return;



      if (me.stationsMap.pointsLayer) {
          me.stationsMap.pointsLayer.clearLayers();
      }
      
      var data= me.wxdata;
      if (!data) return null;
      //if (!data || !me.stations) return null;

      var pointLayers=[];

     
      if (me.stations) {
        var stations=me.stations;
        stations.forEach(function (station) {
            var stationPoint=L.circleMarker([station.latitude, 
                station.longitude], {
                color: 'blue',
                fillColor: '#0000ff',
                fillOpacity: 0.6,
                radius: 10
            });
            var name=station.name || "";
            var id=station.id;
            if (name && id && name!==id) {
                stationPoint.bindTooltip("<b>Weather Station</b><br/>"+name+"<br/>"+id);
            } else {
                stationPoint.bindTooltip("<b>Weather Station</b><br/>"+name);
            }
            
            pointLayers.push(stationPoint);
        });
      }
      var focusPoint=L.circleMarker([data.latitude, data.longitude], {
          color: 'red',
          fillColor: '#ff0000',
          fillOpacity: 0.8,
          radius: 10,
          zIndexOffset:1000
      });
      focusPoint.bindTooltip("<b>"+data.address+"</b>", {  })

      pointLayers.push(focusPoint)

      
      if (!me.stationsMap.pointsLayer) {
          me.stationsMap.pointsLayer = L.featureGroup(pointLayers);
          me.stationsMap.pointsLayer.addTo(me.stationsMap);
      } else {
          pointLayers.forEach(function(d) {
              me.stationsMap.pointsLayer.addLayer(d);
          });
      }

      me.stationsMap.fitBounds(me.stationsMap.pointsLayer.getBounds(), {maxZoom:10});
      

    },
    formatDistance(value) {
      return Math.round(VcConversion.convertValue(value, "m", this.distanceUnit))
    }
  },
  computed:{
    stations() {
      var me=this;
      var stations=me.wxdata && me.wxdata.stations  && Object.values(me.wxdata.stations);
      stations=stations && stations.sort(function(a,b) {
              return a.distance - b.distance;
      })
      return stations;
    },
    periods() {
      var me=this;
      if (!me.wxdata || !me.wxdata.days) return null;
      if (me.period==="hours") {
        return me.wxdata.days.reduce(function(a,d,i) {
          if ((me.dayIndex || me.dayIndex===0) && i!==me.dayIndex) return a;
          if (!d.hours) return a;
          return a.concat(d.hours);
        }, [])
      }

      return me.wxdata.days;
    },
    isHourly() {
      return this.period==='hours';
    },
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation( this.location, this.name);
    },
    hasError:function() {
      return this.error;
    },
    hasData:function() {
      return this.focusDay;
    },
    focusDay:function() {
      var me=this;
      return me.wxdata && me.wxdata.days && me.wxdata.days[0];
    },
    showAlerts:function() {
      return this.alerts && this.alerts.length!==0;
    },
    alerts:function() {
        var me=this;
        return me.wxdata && me.wxdata.alerts;
    },
    summarykpicount:function() {
        return this.summarykpis?this.summarykpis.length:0;
    },
    showSummary() {
      return this.doShow('icon') || this.summarykpicount
    },
    summaryCssClasses() {
      if (this.doShow('icon')) return 'summarykpis'+this.summarykpicount
      return 'summarykpis'+this.summarykpicount+" noicon"; 
    },
    hasNormals() {
      return VcWidgetHelper.hasNormals(this.wxdata);
    },
    
     distanceUnit() {
        return VcConversion.getQuantityUnit(this.unitGroup || "us", "distance");
      },
      distanceUnitLabel() {
        return VcConversion.formatValueFromUnit(null,this.distanceUnit);
      },
  },
  created() {
    var me=this;
    me.onresize=VcWidgetHelper.debounce(function () {
        me.renderMap();
    }, 250);

    window.addEventListener("resize", me.onresize);
  },
  destroyed() {
    window.removeEventListener("resize", me.onresize);
  },
  watch: {
    weatherData:function(newValue,oldValue) {
      var me=this;
      me.wxdata=newValue;
      me.renderMap();
    },
    selectedPeriod:function(newValue) {
      this.period=newValue;
      this.renderMap();
    }
  },
  mounted() {
    //console.log("mounted")
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);
    me.refreshData();
  },
  updated: function() {
    var me=this;
    me.renderMap();
  }
}
</script>


<template>
   <div class="widget twocols">
   <!-- <div v-if="!hasData" class="nodata" >
    No data available
    </div>
    <div v-if="hasError" class="haserror" >
      {{error}}
    </div>
    <template v-if="hasData"> -->
      <div class="stationsMap" >

      </div>

      <table class="table table-striped fs-xs">
          <thead>
             <tr><th>Station</th><th>Id</th><th>Distance ({{distanceUnitLabel}})</th><th>Latitude</th><th>Longitude</th></tr>
          </thead>
          <tbody>
            <tr v-for="station in stations" >
              <td >
                {{station.name}}
              </td>
              <td >
                {{station.id}}
              </td>
              <td >
                {{formatDistance(station.distance)}}
              </td>
              <td >
                {{station.latitude}}
              </td>
              <td >
                {{station.longitude}}
              </td>
            </tr>
          </tbody>
      </table>

    <!--</template>-->
  </div>
</template>


<style scoped>
  .stationsMap {
    min-height:400px;
  /*  grid-column: 1/-1;
    min-height:400px;
    display:flex;
    flex-direction: column;*/
  }
  .stationsTable {
    overflow-x: auto;
  }
  .stationsTable table {
    font-size:14px;
    height:100%;
    width:100%;
  }
  .stationsTable tbody {
     
  }
  .stationsTable td, .stationsTable th {
    border: 1px solid #ddd;
    padding: 4px;
  }

  .stationsTable tr:nth-child(even){background-color: #f2f2f2;}

  .stationsTable tr:hover {background-color: #ddd;}

  .stationsTable th {
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: left;
    background-color: #00628b;
    color: white;
  }



</style>
