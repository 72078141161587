import {VcDateUtils} from '/../visualcrossing-core-js/src/vcdateutils';
import {VcTimezone} from '/../visualcrossing-core-js/src/vctimezone';
import {VcGlobal} from "/../visualcrossing-core-js/src/vcglobal_conn";

L.TileLayer.WxTileLayer = L && L.TileLayer.extend({
    //_legend:null,
    initialize: function(options) {
        let me=this;
        
        L.setOptions(me, options);
        me.retrieveBins();

        
    },
    onAdd: function(map) {
        let me=this;
        map.on("datetimeupdated", function() {
            me.redraw();
        })
        L.TileLayer.prototype.onAdd.call(this, map);
        me.retrieveBins();
    },
    onRemove: function(map) {
        L.TileLayer.prototype.onRemove.call(this, map);
    },
    tileDateTime(date) {
        var datetz=VcTimezone.toTz(date, 'utc', true);
        return VcDateUtils.formatDateCustom(datetz,"YYYYMMDDHHmm");
    },
    setOptions: function (options, refreshOnly) {
        L.setOptions(this, options);
        let me=this;
        me.retrieveBins();
        me.setOpacity(options.opacity);
        
        if (refreshOnly) {
            return me.refresh();
        } else {
            return me.redraw();
        }
        
    },
    getOptions : function() {
        return this.options;
    },
    _refreshTileUrl: function(tile, url) {
        //use a image in background, so that only replace the actual tile, once image is loaded in cache!
        var img = new Image();
        img.onload = function() {
                L.Util.requestAnimFrame(function() {
                         tile.el.src = url;
                });
        }
        img.src = url;
    },
    refresh: function() {
                //prevent _tileOnLoad/_tileReady re-triggering a opacity animation
                if (this._map) {
                    var wasAnimated = this._map._fadeAnimated;
                    this._map._fadeAnimated = false;
                }
                for (var key in this._tiles) {
                        tile = this._tiles[key];
                        if (tile.current && tile.active) {
                                var oldsrc = tile.el.src;
                                var newsrc = this.getTileUrl(tile.coords);
                                if (oldsrc != newsrc) {
                                        //L.DomEvent.off(tile, 'load', this._tileOnLoad); ... this doesnt work!

                                        this._refreshTileUrl(tile,newsrc);
                                }
                        }
                }

                if (this._map && wasAnimated)
                        setTimeout(function() { map._fadeAnimated = wasAnimated; }, 5000);
    },
    /*
    onRemove: function(map) {
        L.DomUtil.remove(this._container);
        let me=this;
        if (me._legend) {
            me._legend.remove();
            me._legend=null;
        }
    },
    renderLegend() {
        let me=this;

        if (!me._legend) {
            me._legend = L.control({ position: "bottomleft" });

            me._legend.onAdd = function(map) {
                let div = L.DomUtil.create("div", "legend");
                div.innerHTML += "<h4>LEGEND!</h4>";

                return div;
            };
        
            me._legend.addTo(me._map);
        }
    },*/
    retrieveBins() {
        let me=this;
        let url=me.getTileBinsUrl();
        fetch(url, {
            "method": "GET",
            "headers": {
            }
            })
            .then(response => {
                if (!response.ok) {
                    
                    return response.text();
                }
                return response.json();
                
               
            }).then(jsonOrText =>  {
                let items=Object.keys(jsonOrText).map(function(d) {
                    return jsonOrText[d];
                })
                //console.log("Items", items);
                me.fire('legendchanged',{items:items} )
            }).catch(err => {
                console.error(err);
            })
    },

    tileServer:VcGlobal.TILE_SERVER_ROOT, //""https://weather1.visualcrossing.com", //
    getTileBinsUrl: function(cords) {
        let me=this;

        return `${VcGlobal.TILE_SERVER_ROOT}/VisualCrossingWebServices/rest/services/tilebins/${me.options.element}?key=${me.options.key}&unitGroup=${me.options.unitGroup}`;
    },
    getTileUrl: function(coords) {
        let me=this;
        let time=new Date().getTime();
        let dateTimeFormatted=me.tileDateTime(me._map.options.dateTime);
        return `${me.tileServer}/VisualCrossingWebServices/rest/services/retrievetile/${me.options.element}/${coords.z}/${coords.x}/${coords.y}/${dateTimeFormatted}?key=${me.options.key}&model=${me.options.model}&altitude=${me.options.level || 0}&refresh=${me.options.forceRefresh}&nocache=${time}`;
    },
    getAttribution: function() {
        return "<a href='https://www.visualcrossing.com'>Visual Crossing</a>"
    }
});