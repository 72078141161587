import {VcCommunicator} from "../../visualcrossing-core-js/src/vccommunicator";
import {VcGlobal} from "../../visualcrossing-core-js/src/vcglobal_conn";




window.vcCommunicator=new VcCommunicator({
    "serverroot":VcGlobal.SERVER_ROOT+"/VisualCrossingWebServices/rest/services/",
    "createsessionpkg":{
      requiredStripeProductId: "prod_ElEHd1q0NaQXG8",
      installId: "VCWebsite"
    }
});



