<template>
               
    <div class="row">




    <div class="col-md-8 signup card card-body border-0 shadow-sm p-4 mb-4">
      <div v-if="!hasSession" class="alert alert-secondary">
        <i class="bi bi-lock"></i> Downloading data requires an account. To continue, please <a class="alert-link"   href="#signinModal" data-bs-toggle="modal"> sign in</a> your account.
      </div>

      <div v-if="hasSession && !locationCount" class="alert alert-primary" role="alert">
        <i class="bi bi-pin"></i> Please return to explore mode and enter some locations to continue.
      </div>

    

      <div class="" :class="{'opacity-40':isMissingReqs, 'pe-none':isMissingReqs}" id="basic-info">
          <h6 class="h6 mb-1">{{currentStep.label}}</h6>
          <template v-if="step==='chooseformat'">
            <ContentTypeChooser :queryDefinition="queryDefinition" :disabled="showProcessing || showResult" />
           <!-- <div>Include: {{localPackageDefn.params.include}}</div>-->
            <h6 class="h6 mt-3 mb-1">Dataset record count information</h6>
            <div >
              Location count: {{locationCount}}, Estimated record count: {{queryCostDetails}}
            </div>
            <div v-if="isMetered" class="">
              Used record cost: {{currentDailyCost}}. Free record count remaining: {{remainingFreeDailyCost}}
            </div>

            <div class="form-text">
              <a href="/resources/documentation/weather-data/what-exactly-is-a-weather-record/">What exactly is a weather record?</a>.
            </div>


            <div v-if="exceedsLimit" class="alert alert-danger mt-3" role="alert" v-html="exceedsLimitMsg"></div>

            <div v-if="largeDataset" class="alert alert-warning  mt-3" role="alert">
              This is a large dataset and may take multiple hours to run. Please track your query progress in <button class="btn btn-sm text-primary px-1 py-0" type="button" @click="openMyDatasets()">My Datasets</button> where you can download the results when they are finished.
            </div>

            <button  type="button" class="d-block btn btn-primary btn-sm mt-3"  @click="step='choosename';hasChosenFormat=true;" >
                Continue
            </button>

          </template>
          <template v-else>
            <div >
              <strong>Content type:</strong> {{contentType}}, <strong>Output sections:</strong> {{dataIncludes}}
            </div>
            <div >
              <strong>Location count:</strong> {{locationCount}}, <strong>Estimated record count:</strong> {{queryCostDetails}}
            </div>
          </template>

          <template v-if="step==='choosename'">
            
            <div class="input-group align-items-center  mt-3">
              <a href="#" @click="modifyMetadata()">{{currentName}}</a>
              <button type="button" class="btn text-primary btn-sm p-1 fs-6"  @click="modifyMetadata()"><i class="bi-pencil"></i></button>
            </div>
            <div class="form-text mb-3">
              The name will be used in the <button class="btn btn-sm text-primary m-0 p-0 border-0 align-top" type="button" @click="openMyDatasets()">My Datasets</button> and for the file names.
            </div>
            <button type="button" class="d-block btn btn-primary btn-sm  mt-3"  @click="step='showsubmit';hasChosenName=true;" >
                Continue
            </button>
          </template>



          <template v-if="step==='showsubmit'">
            <div >
              {{currentName}}
            </div>


            <div v-if="showProcessing" class="mt-3" >
              <div class="d-flex align-items-center">
                <div class="spinner-border mx-2 me-3 " role="status" aria-hidden="true"></div>
                <strong>{{processingStatusText}}</strong>
              </div>
              <div v-if="queueText" class="form-label">
                {{queueText}}
              </div>
            </div>
            
            <div v-else-if="showResultError"  class="mt-3">
              <div class="alert alert-primary mt-3">
                <div>Your job did not complete successfully</div>
                <div>{{info}}</div>
              </div>
            </div>
            <div v-else-if="showResult"  class="mt-3">
              <button  type="button" class="d-block btn btn-primary btn-sm mx-1"  @click="downloadResult( metadata)" :disabled="scheduleMode" >
                  <i class="bi-download p-1"></i><span class="d-none d-lg-inline">Download</span>
              </button>
              <div v-if="failed" class="alert alert-primary mt-3">
                <div>{{failed}} of your {{total}} locations did not complete successfully. </div>
                <div>{{info}}</div>
              </div>
              <div v-if="metadata && metadata.id">
                <h6  class="h6 mt-4 mb-2">Links to your dataset</h6>
                

              <!--
                <div v-if="!taskResult" class="alert alert-primary" role="alert">
                  These links will be available when the dataset is populated with data.
                </div>
              -->

                <div >
                  <button v-if="!requiresZipResult" type="button" class="btn btn-outline-primary btn-sm" @click="copyLink(textResultsUri)"  ><i class="bi bi-clipboard"></i> {{contentType}} file</button> 
                  <button type="button" class="btn btn-outline-primary btn-sm ms-3" @click="copyLink(zipResultsUri)"  ><i class="bi bi-file-earmark-zip"></i> Zip file</button>
                </div>
                <div class="form-text mb-3">
                    Use these links to import data into databases and tools such as Microsoft Excel and Google Sheets.
                </div>
              </div>
            </div>
            <div v-else-if="!hasSchedule" class="mt-3 d-flex align-items-center justify-content-start">
              <div>
              
                <button type="button" class="btn btn-primary btn-sm"  @click="submit(true)" :disabled="exceedsLimit"  >
                  <span class="">Run Query</span>
                </button>
                <button  type="button" class="btn text-primary btn-sm"   @click="createSchedule()">
                  <i class="bi-clock p-1"></i><span class="">Set up schedule</span>
                </button>
                <div class="form-text">
                  Results are available for at least 24 hours. Click on <button class="btn btn-sm text-primary px-1 py-0" type="button" @click="openMyDatasets()">My Datasets</button> to access your recent datasets.
                </div>
              </div>
            </div>

            <template v-else-if="bulkAllowSubscriptions">    
              <!--<h6 class="h6 mt-3 mb-2">Daily run time</h6>-->

              <div class="text-muted fs-sm mt-3">
                  <span>Hour of day to run dataset </span>  
              </div>
              <div class="d-inline-flex align-items-center">
                  <select v-model="scheduleHour" class="form-select form-select-sm w-auto" id="select-input">
                    <option  v-for="h in scheduleHours" :value="h"  v-html="h"></option>
                  </select>
                  <span class="mx-2">:00</span>
              </div>
              <div class="form-text">
                 Jobs are executed at
                 <span v-if="runTime">{{runTime}}</span> Coordinated Universal Time (UTC) time.
                 
                 Time changes such as daylight savings time may cause the local time to vary.
              </div>

              <div  class="d-flex align-items-center mt-3" >
              
                
                <!--
                <button  type="button" class="btn btn-primary btn-sm me-1" :disabled="exceedsLimit || !bulkAllowSubscriptions" @click="submit(true)" data-bs-target="#processingModal">
                    <span class="">Save &amp; Run Query</span>
                </button>
                -->
                <button  type="button" class="btn btn-primary btn-sm" :disabled="exceedsLimit || !bulkAllowSubscriptions" @click="submit(false)">
                    <i class="bi-clock p-1"></i><span class="">Save Schedule</span>
                </button>
                <button  type="button" class="btn text-primary btn-sm"  @click="clearSchedule()">
                  Remove Schedule
                </button>

                
              
              </div>
              
              <div class="form-text d-inline-flex align-items-center">
                Results are available for at least 24 hours. Click on   <button class="btn btn-sm text-primary px-1 py-0" type="button" @click="openMyDatasets()">My Datasets</button>  to access your recent datasets.
              </div>
            </template>
            <template v-else>
              <div class="alert alert-primary" role="alert">
                  <i class="bi bi-lock"></i> Your subscription does not allow scheduled datasets. See our <a class="alert-link"   href="/weather-data-editions" target="_blank"> plans</a> for more details.
              </div>
              <div  class="mt-2">
                <button  type="button" class="btn-primary btn-sm"   @click="clearSchedule()">
                  <i class="bi-trash p-1"></i><span class="">Remove schedule</span>
                </button>
              </div>
            </template>
          </template>
      
        </div>
    </div>

    <aside class="col-md-3 d-none d-md-block">
      <div class="sticky-top">
        <h6 class="mt-3 mb-2">{{completePercent}}% completed</h6>
        <div class="progress mb-4" style="height: .25rem;">
          <div class="progress-bar bg-warning" role="progressbar" :style="{width: completePercent+'%'}" :aria-valuenow="completePercent" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <ul class="list-unstyled">
            <li v-for="(p,index) in progress" class="d-flex align-items-center" :class="{'d-none':!showProgressStep(p)}">
              <i v-if="p.complete" class="fs-2 bi-check text-success"></i>
              <i v-else class="fs-2 bi-x text-danger"></i>
              {{p.label}}
            </li>
        </ul>
      </div>
    </aside>

              
    
    <div class="modal fade" id="saveConfirmModal" tabindex="-1" aria-labelledby="saveConfirmModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered" modal-xl>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveConfirmModalLabel">Data data definition saved</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
                
              <div class="d-flex align-items-center justify-content-center">
                <strong>Your data definition has been saved successfully.</strong>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>


    <MetadataInfoComponent :metadata="metadata" :show="showMetadataInfo" @cancel="onMetadataInfoSubmit=null" @submit:metadata="setMetadataInfo"></MetadataInfoComponent>
    </div>

</template>

<script>

//import '/src/VcUtils'
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils'
import MetadataInfoComponent from "/src/components/MetadataInfo";
import "/../visualcrossing-core-js/src/vccommunicator_metadata";
import "/../visualcrossing-core-js/src/vccommunicator_bulkdata";
import "/../visualcrossing-core-js/src/vccommunicator_account";
import ContentTypeChooser  from '/src/components/ContentTypeChooser'
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils';
import {VcMetadataUtils} from "/../visualcrossing-core-js/src/vcmetadatautils";
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
import {VcJobStatus} from  '/../visualcrossing-core-js/src/vcjobstatus';

//import {VcDownload } from '/../visualcrossing-core-js/src/vcdownload'
export default {
  name: 'Export',
  props: {
    data:Object,
    packageDefn: Object, 
    //locations:Array, 
    sessionId:String,
    sessionState:Object,
    metadataProp:Object,
    queryCost:Number,
    queryCostHasHours:Boolean,
    suggestedView:String,
  },
  emits: ['change:metadata', 'openmydatasets', 'exportcomplete'],
  data() {
    return {
     localPackageDefn:this.createLocalPackageDefn(),

      metadata:this.metadataProp || {
        id:null,
        name:null,
        desc:null
      },
      showMetadataInfo:false,
      newNotificationAddress:null,
      scheduleMode:this.localPackageDefn && this.localPackageDefn.runTimes && this.localPackageDefn.runTimes.length,
      onMetadataInfoSubmit:null,
     // taskResult:null,
      taskInstance:null,
      isRefreshingResults:false,
      showSubmitImportant:false,
      existingJobPackages:[],
      latestResult:null,
      usageInfo:null,
      step:'chooseformat',
      progress:[
        {
          label:"Data format and section",
          complete:false,
          runonceonly:false,
        },
        {
          label:"Dataset name",
          complete:false,
          runonceonly:false,
        },
        {
          label:"Submit or schedule",
          complete:false,
          runonceonly:false,
        },
        {
          label:"Processing",
          complete:false,
          runonceonly:true,
        },
        {
          label:"Download",
          complete:false,
          runonceonly:true,
        },
        
      ],
      hasChosenFormat:false,
      hasChosenName:false,
      hasSubmitted:false,
      hasProcessed:false,
      hasDownload:false,
      //resultData:this.data,
      //isRefreshing:false,
      //errorMessage:null,
      //exportLocation:this.selectedLocation
    }
  },
  methods:{
    showProgressStep(step) {
      var me=this;
      return !step.runonceonly || (step.runonceonly && !me.hasSchedule);
    },
    copyLink(request) {
      VcClipboardUtils.copyToClipboard(request)
      VcCommonModals.showToast("Link copied", "The result URL link been copied to the clipboard")
    },
    refreshUsageInfo() {
      var me=this;
     // if (!me.hasSession) return;
     
      vcCommunicator.usagesummary(true,false,false)
        .then(function (result) {
          
          me.usageInfo=result;
         
        }).catch((error) => {
          console.log("Error loading usage info")
        });
    },
  
    createLocalPackageDefn() {
      var me=this;
      let newPackageDefn= me.packageDefn && JSON.parse(JSON.stringify(me.packageDefn));

      newPackageDefn.params.include=VcListUtils.processValidTableIncludeList(newPackageDefn.params.include, me.suggestedView || "days")

      /*
      if (me.suggestedView) {
        //if (!VcListUtils.hasAnyValueInList(newPackageDefn.params.include, ['days','hours','current','events','alerts'] )) {
          newPackageDefn.params.include=VcListUtils.setValueInList(newPackageDefn.params.include, me.suggestedView, true, false);
        //}
      }
      */
      return newPackageDefn;
    },
    downloadResult(task, downloadAsZip) {
      var me=this;
      if (window["gtag"]) {
        gtag('event', 'export_download', { method : 'Vc' });
      }
      me.hasDownload=true;
     
      var request=vcCommunicator.constructBulkWeatherDataRequest(task, true, downloadAsZip);
      window.open(request);
    },

    openMyDatasets() {
      this.$emit("openmydatasets");
    },
    modifyMetadata() {
      var me=this;
      //me.onMetadataInfoSubmit=function() {
      //  me.submit(runOnce, true);
     // }
      if (!me.metadata.name) me.metadata.name=me.defaultName;
      me.showMetadataInfo=true;
    },
    submit(runOnce, silent, loadedExistingDefns) {
      var me=this;

     

      me.metadata.name= me.metadata.name || me.defaultName;
     
      me.showSubmitImportant=false;
      me.localPackageDefn.enabled=true;
      me.localPackageDefn.runOnce=runOnce?true:false;

      if (window["gtag"]) {
        if (runOnce) {
          gtag('event', 'export_submit', { method : 'Vc' });
        } else {
          gtag('event', 'export_schedule', { method : 'Vc' });
        }
      }

    

      if (!loadedExistingDefns) {

        vcCommunicator.searchMetadata(null,"JobPackage") //
          .then(function (results) {
            me.existingJobPackages=results && results.result;
            me.submit(runOnce, silent, true);
          })
          .catch((error) => {
            console.log("Failed refreshExistingPackageDefns:"+error);
             me.submit(runOnce, silent, true);
          });

        return;
      }


      if (!me.metadata.id) {
        var existingObject=me.getExistingPackageByName(me.metadata.name);
        if (existingObject) {
          window.VcCommonModals.showConfirm("Confirm overwrite", `There is an existing defintion with name '${me.metadata.name}'. Would you like to overwrite it?`)
            .then(function(id) {
              me.metadata=existingObject;
              me.submit(runOnce, silent);
            }).catch((error) => {
              console.log("User said no to delete")
            });
          return;

        }
      }

      me.hasSubmitted=true;


      var waitForResult=runOnce;
      
      me.latestResult=null;
      //me.taskResult=null;
      vcCommunicator.saveBulkSourceDefn(me.metadata.id,me.metadata.name,me.metadata.desc,me.localPackageDefn).then(function (result) {
        if (result && result.metadata) {
          me.metadata=result.metadata;
          
          if (!runOnce) {
            me.hasProcessed=true;
            me.hasDownload=true;
            me.$emit("exportcomplete");
          }

          //AMW - people seem confused when we set the overall session metadata in this flow
          //me.$emit("change:metadata", me.metadata);

          if (!waitForResult) {
            var target=me.$el && me.$el.querySelector("#saveConfirmModal");
            var modal = bootstrap.Modal.getOrCreateInstance(target);
            modal.show();
            return;
          }

          if (waitForResult && result.results) {
            result.results.forEach(result => {
              if (VcMetadataUtils.getSafeResultTime(me.latestResult)<VcMetadataUtils.getSafeResultTime(result)) {
                me.latestResult=result;
              }
            });
           
          } 

          me.isRefreshingResults=true;
         
        }


      }).catch((error) => {
        console.log("Failed saveBulkSourceDefn:"+error);
        
        window.VcCommonModals.showError("Error saving export information", "There was a problem exporting the data definition. <br/>"+error);
        
      });
    },
   // getSafeResultTime(result) {
    //  return (result && result.dateTime && result.dateTime.getTime()) || 0;
    //},
    getExistingPackageByName(name) {
      var me=this;
      if (!me.existingJobPackages) return null;
      name=(name && name.toLowerCase());
      return me.existingJobPackages.reduce(function(a,d) {
        return a || (d && d.name && d.name.toLowerCase() === name && d);
      }, null);
    },
    /*
    refreshExistingPackageDefns() {
        var me=this;
        if (!me.sessionState) return;
        vcCommunicator.searchMetadata(null,"JobPackage") //
          .then(function (results) {
            me.existingJobPackages=results && results.result;
          })
          .catch((error) => {
            console.log("Failed refreshExistingPackageDefns:"+error);
            
          });
    },*/
    delayedRefreshPackageResults() {
      var me=this;
      setTimeout(function() {
            me.refreshPackageResults()
          }, 2500);
    },
    refreshPackageResults() {
      var me=this;
      if (!me.metadata || !me.metadata.id) {
        console.log("No md for refreshPackageResults");
        return;
      }
      vcCommunicator.resultsByJobPackage(me.metadata.id)
        .then(function (packageResults) {
          if (packageResults && packageResults[0] && packageResults[0].instance) {
            me.taskInstance=packageResults[0].instance;
          } else {
            me.taskInstance=null;
          }

          /*
          if (packageResults && packageResults[0] && packageResults[0].results && packageResults[0].results[0]) {

            var latestResult=packageResults[0].results.reduce(function (a, result) {
              //result.runTime=VcMetadataUtils.getTagPrefixValue(result, "RunTime");
              //result.dateTime=VcDateUtils.dateFromIso(result.runTime);
              return VcMetadataUtils.getSafeResultTime(a)<VcMetadataUtils.getSafeResultTime(result)?result:a;
            }, null);

 

            if (VcMetadataUtils.getSafeResultTime(me.latestResult)<VcMetadataUtils.getSafeResultTime(latestResult)) {
              //console.log(`Latest result is more recent ${VcMetadataUtils.getSafeResultTime(me.latestResult)} vs ${VcMetadataUtils.getSafeResultTime(latestResult)}`)
              me.taskResult=latestResult;
             //me.downloadResult( me.taskResult,  me.metadata);
              me.isRefreshingResults=false;

              setTimeout(function() {
                me.refreshUsageInfo();
              }, 2500);
             
            } else {
              //console.log(`Latest result wasn't more recent ${VcMetadataUtils.getSafeResultTime(me.latestResult)} vs ${VcMetadataUtils.getSafeResultTime(latestResult)}`)
              me.taskResult=null;
            }
          } else {
            me.taskResult=null;
          }
          */
          me.isRefreshingResults=me.isRefreshingResults && !me.taskInstance || !VcJobStatus.isFinishedState(me.taskInstance.status);

          
          if (me.isRefreshingResults) {
            me.delayedRefreshPackageResults();
          } else {
            me.hasProcessed=true;
            me.$emit("exportcomplete");
          }
        }).catch((error) => {
          console.log("Failed resultsByJobPackage:"+error);
          me.isRefreshingResults=me.isRefreshingResults && !me.taskInstance || !VcJobStatus.isFinishedState(me.taskInstance.status);
          if (me.isRefreshingResults) {
            me.delayedRefreshPackageResults();
          }
        });
    },

    setMetadataInfo(metadata) {
      var me=this;
      me.showMetadataInfo=false;
      if (me.metadata && me.metadata.id) {
          window.VcCommonModals.showConfirm("Change existing object?", `Would you like create create a new definition or rename the exiting definition?'`, "Rename existing", "Create new")
            .then(function(id) {
              me.metadata=metadata;
              vcCommunicator.saveMetadata(me.metadata.id,me.metadata.type,me.metadata.name,me.metadata.desc, null);

            }).catch((error) => {
              me.metadata={
                id:null,
                name:metadata.name,
                desc:metadata.desc
              }
            });
          return;

      }
      me.metadata=metadata;
     
     // if (this.onMetadataInfoSubmit) this.onMetadataInfoSubmit();
    },
    removeNotificationAddress(v) {
      var me=this;
      if (!v || !me.localPackageDefn.notificationAddresses) return;
      me.localPackageDefn.notificationAddresses=me.localPackageDefn.notificationAddresses.filter(function(d) {
        return d!==v;
      });
    },
    addNotificationAddress() {
      var me=this;
      if (!me.newNotificationAddress) return;
      me.localPackageDefn.notificationAddresses.push(me.newNotificationAddress);
      me.localPackageDefn.enableNotifications=true;
      me.newNotificationAddress=null;
    },
  
    clearSchedule() {
      var me=this;
      me.localPackageDefn.runTimes=null;
    },
    createSchedule() {
      var me=this;
      me.localPackageDefn.runTimes=["12:00"];
    }
  },
  computed:{

    currentStep() {
      var me=this;
      let currentStep=me.progress.reduce(function (a,d) {
        return a || (!d.complete && d);
      },null);

      //all steps completed
      if (!currentStep) {
       return "Download";
      }
      return currentStep || {
        label:"Unknown"
      }
    },

    completePercent() {
      var me=this;
      me.progress[0].complete=me.hasChosenFormat;
      me.progress[1].complete=me.hasChosenName;
      me.progress[2].complete=me.hasSubmitted;
      me.progress[3].complete=me.hasProcessed;
      me.progress[4].complete=me.hasDownload;
      
      let activeCount=me.progress.reduce(function (a,d) {
        return a+(me.showProgressStep(d)?1:0) ;
      },0);

      return Math.ceil(100*me.progress.reduce(function (a,d) {
        return a+((d.complete && 1) || 0) ;
      },0)/activeCount);
      
    },
    contentType() {
      var me=this;
      return me.queryDefinition.contentType;
    },
    dataIncludes() {
      var me=this;
      return me.queryDefinition.include;
    },
    requiresZipResult() {
      var me=this;
      return me.locationCount>1 && me.queryDefinition.contentType!=="csv";
    },
    textResultsUri() {
      var me=this;

      return vcCommunicator.constructBulkWeatherDataRequest(me.metadata, true, false);
    },
    zipResultsUri() {
      var me=this;
      return vcCommunicator.constructBulkWeatherDataRequest(me.metadata, true, true);
    },
    subscriptionParams() {
      return this.sessionState && this.sessionState.subscriptionParams;
    },
    freeDailyCost:function() {
      return this.subscriptionParams && this.subscriptionParams.freeDailyCost;
    },
    maxDailyCost() {
      return this.subscriptionParams  && this.subscriptionParams.maxDailyCost;
    },
    maxCount() {
      return this.subscriptionParams  && this.subscriptionParams.maxCount;
    },
    isMetered() {
      return this.subscriptionParams && this.subscriptionParams.usageType==="metered";
    },
    isMeteredNoPaymentSrc() {
      return  this.subscriptionParams && this.subscriptionParams.isMeteredNoPaymentSrc===1
    },
    bulkAllowSubscriptions() {
      return  this.subscriptionParams && this.subscriptionParams.bulkAllowSubscriptions===1
    },
    bulkMaxCost() {
      return this.subscriptionParams  && this.subscriptionParams.bulkMaxCost;
    },
    bulkMaxLocations() {
      return this.subscriptionParams  && this.subscriptionParams.bulkMaxLocations;
    },
    estimateQueryCost() {
      var me=this;
      console.log(`estimateQueryCost...${me.queryCost} .`)
      var estimatedCost=me.queryCost || 0;
      if (me.queryCostHasHours && !me.exportHasHours) estimatedCost= Math.ceil(estimatedCost/24);
      estimatedCost=estimatedCost*me.locationCount;
      return estimatedCost;
    },
    queryCostDetails() {
      var me=this;
      if (!me.estimateQueryCost) return "-";
      if (me.isMetered && !me.isMeteredNoPaymentSrc) {
        return `${me.estimateQueryCost} ($${(me.estimateQueryCost*0.0001).toFixed(4)})`;
      } else {
         return `${me.estimateQueryCost}`;
      }
    },
    currentDailyCost() {
      var me=this;
      if (!me.usageInfo || !me.usageInfo.currentPeriodUsage) return 0;
      return me.usageInfo.currentPeriodUsage.cost;
    },
    remainingFreeDailyCost() {
      var me=this;
      var remaining= (me.freeDailyCost-me.currentDailyCost);
      if (remaining<0) remaining=0;
      return remaining;
    },
    exceedsLimitMsg() {
      var me=this;
      //console.log("refreshQueryLimits")
      var exceedsLimitMsg=null;
      if (me.estimateQueryCost>me.maxDailyCost) {
        exceedsLimitMsg=`This data cannot be downloaded because the query record cost of ${me.estimateQueryCost} exceeds your daily limit of ${me.maxDailyCost}.`;
      } else if (me.estimateQueryCost>me.maxCount && me.isMeteredNoPaymentSrc) {
        exceedsLimitMsg=`This data cannot be downloaded because the query record cost of ${me.estimateQueryCost} exceeds your query limit of ${me.maxCount}.`;
      } else if (me.estimateQueryCost>me.remainingFreeDailyCost && me.isMeteredNoPaymentSrc) {
        exceedsLimitMsg=`This data cannot be downloaded because the query record cost of ${me.estimateQueryCost} exceeds your remaining free cost of  ${me.remainingFreeDailyCost}.`;
      } else if (me.locationCount>me.bulkMaxLocations) {
        exceedsLimitMsg=`This data cannot be downloaded because you have too many (${me.locationCount}) locations. Your subscription allows a maximum of  ${me.bulkMaxLocations} locations.`;
      } else if (me.estimateQueryCost>me.bulkMaxCost) {
        exceedsLimitMsg=`This data cannot be downloaded because the query record cost of ${me.estimateQueryCost} exceeds maximum download cost of ${me.bulkMaxCost}.`;
      }


      if (exceedsLimitMsg && me.isMeteredNoPaymentSrc) {
         exceedsLimitMsg+=" Please add a credit card to increase your limits. For more information, please see <a target='_blank' href='/weather-data-editions'>pricing information</a>.";
      }
      return exceedsLimitMsg;
    },
    exportHasHours() {
       return this.queryDefinition && (!this.queryDefinition.include || (this.queryDefinition.include && VcListUtils.hasValueInList(this.queryDefinition.include, 'hours')));
    },
    /*
    processingLabel() {
      if (this.showProcessing) return "We are retrieving your data!";
      if (this.showResult) return "Your data is ready!";
      return "Please close and try again";
    },
    */
    hasNoNotifications() {
      var me=this;
      return !me.localPackageDefn.enableNotifications || !me.localPackageDefn.notificationAddresses  || !me.localPackageDefn.notificationAddresses.length;
    },
    showSubmit() {
      var me=this;
      return (me.showSubmitImportant || (!me.showProcessing && !me.showResult ));
    },
    showProcessing() {
      var me=this;
      return me.isRefreshingResults;
    },

    failed() {
      var me=this;
      if (!me.taskInstance) return 0;
      return me.taskInstance.failed;
    },
    successful() {
      var me=this;
      if (!me.taskInstance) return 0;
      return me.taskInstance.successful;
    },
    total() {
      var me=this;
      if (!me.taskInstance) return 0;
      return me.taskInstance.total;
    },
    completed() {
      var me=this;
      if (!me.taskInstance) return 0;
      return me.taskInstance.completed;
    },
    info() {
      var me=this;
      return me.taskInstance && me.taskInstance.info;
    },
    processingStatusText() {
      var me=this;
      if (!me.taskInstance) return "Submitting...";
      if (me.taskInstance.status===1) return "Queued";
      if (me.taskInstance.status===2) {
        if (me.taskInstance.completed>1 && me.taskInstance.total>1) {
          return `Running ${me.taskInstance.completed} of ${me.taskInstance.total}`;
        } else {
          return "Running";
        }
      }
      return null;
    },
    queueText() {
      var me=this;
      if (!me.taskInstance || me.taskInstance.status>1) return null;
      if (me.taskInstance.queuedJobs) return `${me.taskInstance.queuedJobs} jobs in queue`;
      return null;
    },
    showResult() {
      var me=this;
      //return !me.showSubmitImportant && me.taskResult;
      return !me.showSubmitImportant && me.taskInstance && me.taskInstance.status===3;
    },
    showResultError() {
      var me=this;
      return me.taskInstance && me.taskInstance.status===4;
    },
    currentName() {
      return (this.metadata && this.metadata.name) || this.defaultName;
    },
    locationCount() {
      var me=this;
      return (me.localPackageDefn && me.localPackageDefn.locations  && me.localPackageDefn.locations.length) || 0;
    },
    defaultName() {
      var me=this;
      if (!me.queryDefinition || !me.localPackageDefn.locations || !me.localPackageDefn.locations.length) return null;
      var name=me.localPackageDefn.locations[0].name || me.localPackageDefn.locations[0].location;
      name=(name.length<25 && name) || (name.substring(0,25)+"...");
      if (me.localPackageDefn.locations>1) name=name+" "+me.localPackageDefn.locations.length+" more";
      if (me.queryDefinition.startdate) name=name+" "+me.queryDefinition.startdate;
      if (me.queryDefinition.enddate)  name=name+" to "+me.queryDefinition.enddate;
      return name;
    },
    exceedsLimit() {
      return this.hasSession && this.exceedsLimitMsg;
    },
    largeDataset() {
      return this.hasSession && !this.exceedsLimit && this.estimateQueryCost>250000;
    },
    isMissingReqs() {
      return !this.hasSession || !this.locationCount ;
    },
    scheduleHours() {
      return Array.from({ length: 25},function(a,i) {return i===0?"":(i-1).toString().padStart(2, 0);});
    },
    scheduleMins() {
      return Array.from({ length: 4},function(a,i) {return i*15});
    },
    hasSchedule() {
      var me=this;
      return me.localPackageDefn.runTimes &&  me.localPackageDefn.runTimes[0];
    },
    runTime() {
      var me=this;
      let time=me.localPackageDefn.runTimes && me.localPackageDefn.runTimes[0];
      return time;
    },
    scheduleHour: {
        get: function () {
          var time=this.scheduleTime;
          var hour=time && +time[0];
          if (!hour && hour!==0) {
            return "";
          }
          hour=+hour;
          var date=new Date();
          date.setUTCHours(hour);
          
          return date.getHours().toString().padStart(2, 0);//yikes!
        },
        set: function (newValue) {
          var me=this;
          console.log(`New hour ${newValue}`)
          var time=me.scheduleTime;
          if (!newValue && newValue!==0) {
            me.localPackageDefn.runTimes=null;
            return;
          }
          
          newValue=+newValue;
          var date=new Date();
          date.setHours(newValue);
          newValue=date.getUTCHours();

          if (!me.localPackageDefn.runTimes) me.localPackageDefn.runTimes=[];
          if (time && time[1]) {
            me.localPackageDefn.runTimes[0]=newValue+":"+time[1];
          } else {
            me.localPackageDefn.runTimes[0]=newValue+":00";
          }
        }
    },
    scheduleMin: {
        get: function () {
          var time=this.scheduleTime;
          return (time && +time[1]) || 0;
        },
        set: function (newValue) {
           var me=this;
          var time=me.scheduleTime;
           if (!me.localPackageDefn.runTimes) me.localPackageDefn.runTimes=[];
          if (time && time[0]) {
            me.localPackageDefn.runTimes[0]=time[0]+":"+newValue;
          } else {
            me.localPackageDefn.runTimes[0]="00:"+newValue;
          }
        }
    },
    scheduleTime() {
      var time=this.localPackageDefn.runTimes && this.localPackageDefn.runTimes[0];
      return time && time.split(":");
    },
    queryDefinition() {
      return this.localPackageDefn && this.localPackageDefn.params;
    },


    locationCount() {
      return (this.localPackageDefn.locations && this.localPackageDefn.locations.length) || 0;
    },
    hasSession() {
      return this.sessionState;
    },
    /*
    resolvedQueryDefinition() {
      var me=this;
      if (!me.queryDefinition) return null;
      var result=JSON.parse(JSON.stringify(me.queryDefinition));
      result.include=me.include || result.include;
      result.goal="timeline";
      return result;
    },
    */
  },
  watch:{
    metadataProp:{
        handler:function(newValue,oldValue) {
          this.metadata=newValue || {
            id:null,
            name:null,
            desc:null
          }
        },
        deep: true
    },
    packageDefn:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.localPackageDefn=me.createLocalPackageDefn();
          console.log(me.localPackageDefn)
        },
        deep: true
    },
    suggestedView(newValue) {
      var me=this;
      me.localPackageDefn=me.createLocalPackageDefn();
      console.log(me.localPackageDefn)
    },
    sessionState(newValue) {
      var me=this;
     // if (newValue) me.refreshExistingPackageDefns();
    },
    isRefreshingResults(newValue) {
      if (newValue) {
        this.delayedRefreshPackageResults();
      }
    },
    
  },
  mounted() {
    var me=this;
    //me.refreshExistingPackageDefns();
    me.refreshUsageInfo();
  },
  updated() {

  },
  unmounted() {
    this.isRefreshingResults=false;
  },
  components: {
    MetadataInfoComponent,
    ContentTypeChooser
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
