<template>
  <div class="d-flex flex-column mt-5 pt-5 mb-5">
    <div class="mb-3  d-flex align-items-end justify-content-between">
      <h1 class="mb-0 h3">Weather Query Builder <span v-if="taskName"> - {{taskName}}</span> </h1>
<!--
      <div class="btn-group ms-auto" role="group" aria-label="View options">
          <button type="button" class="btn btn-sm btn-outline-primary" :class="{active:showWizard}"  @click="toggleWizard(true)"   >
            <i class="bi-download"></i><span class="d-none d-lg-inline"> Guided download</span>
          </button>
          
          <button type="button" class="btn btn-sm btn-outline-primary" :class="{active:!showWizard}" @click="toggleWizard(false)" >
            <i class="bi-compass"></i><span class="d-none d-lg-inline"> Manual Explore</span>
          </button>
      </div>
   
      -->
    <a href="/legacy/weather/weather-data-services" class="btn btn-sm text-primary" >
            <i class="bi-bandaid"></i><span class="d-none d-md-inline"> Legacy version</span>
      </a>
    </div>

    <div class="mb-3  d-flex align-items-end justify-content-around">
      <div class="btn-group" role="group" aria-label="View options">
          <button type="button" class="btn btn-sm btn-outline-primary" :class="{active:showWizard}"  @click="toggleWizard(true)"   >
            <i class="bi-download"></i><span class="d-none d-lg-inline"> Guided Data Download</span>
          </button>
          
          <button type="button" class="btn btn-sm btn-outline-primary" :class="{active:!showWizard}" @click="toggleWizard(false)" >
            <i class="bi-compass"></i><span class="d-none d-lg-inline"> Manual Explore</span>
          </button>
      </div>
    </div>



<!--
    <div class="d-flex align-items-center"   >
          <div class="h4">{{taskName}}</div>
         <a href="/legacy/weather/weather-data-services" class="btn btn-sm text-primary ms-auto" >
            <i class="bi-bandaid"></i><span class="d-none d-md-inline"> Legacy version</span>
          </a>
          <button v-if="panelView!=='datasets'" type="button" class="btn btn-sm text-primary "  @click="panelView='datasets'" :class="{disabled:!hasSession}">
            <i class="bi-grid-3x3"></i><span class="d-none d-md-inline"> My Datasets</span>
          </button>
          <button v-else type="button" class="btn btn-sm text-primary  active" @click="panelView='explore'" >
            <i class="bi-x-circle"></i><span class="d-none d-md-inline"> Close</span>
          </button>
     
    </div>
    -->


    

    <div v-if="!showWizard"  class="d-flex justify-content-between align-items-center mb-2">
      <div  class="btn-group ms-auto" role="group" aria-label="View options">
          <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:panelView==='locations'}"  @click="panelView='locations'" >
            <i class="bi-geo"></i><span class="d-none d-lg-inline"> Locations</span>
          </button>

          <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:panelView==='explore'}" @click="panelView='explore'" >
            <i class="bi-search"></i><span class="d-none d-lg-inline"> View data</span>
          </button>

          <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:panelView==='export',disabled:!hasSession}" @click="panelView='export'" >
            <i class="bi-download"></i><span class="d-none d-lg-inline"> Download</span>
          </button>
         
          <button  type="button" class="btn btn-outline-primary btn-sm" :class="{active:panelView==='datasets',disabled:!hasSession}" @click="panelView='datasets'">
              <i class="bi-grid-3x3"></i><span class="d-none d-md-inline"> My Datasets</span>
          </button>
      </div>
  </div>
<div v-if="showWizard" class="row bg-secondary py-3 px-0 mb-1" data-simplebar >
    <!--    <div class="col-12 d-flex">
          <button class="btn-close ms-auto" type="button" @click="finishWizard()"></button>
        </div>-->
       <div class="steps">
          <div v-for="(step,index) in wizardSteps" class="step " :class="[hasCompletedWizardStep(step)?['pe-auto','active']:[''] ]" @click="setWizardStep(step)">
            <div class="step-progress">
              <span class="step-progress-start"></span>
              <span class="step-progress-end"></span>
              <span class="step-number">{{index+1}}</span>
            </div>
            <div class="step-label">
              <h6 class="mb-2 pb-1">{{step.label}}</h6>
        
            </div>
          </div>
         <!--
          <div class="step " :class="[hasCompletedWizardStep('locations')?['pe-auto','active']:[''] ]" @click="panelView='locations'">
            <div class="step-progress">
              <span class="step-progress-start"></span>
              <span class="step-progress-end"></span>
              <span class="step-number">1</span>
            </div>
            <div class="step-label">
              <h6 class="mb-2 pb-1">Enter locations</h6>
        
            </div>
          </div>
          <div class="step " :class="[hasCompletedWizardStep('explore')?['pe-auto','active']:[''] ]" @click="panelView='explore'">
            <div class="step-progress">
              <span class="step-progress-start"></span>
              <span class="step-progress-end"></span>
              <span class="step-number">2</span>
            </div>
            <div class="step-label">
              <h6 class="mb-2 pb-1">Browse data</h6>
           
            </div>
          </div>
          <div class="step "  :class="[hasCompletedWizardStep('export')?['pe-auto','active']:[''] ]" @click="panelView='export'">
            <div class="step-progress">
              <span class="step-progress-start"></span>
              <span class="step-progress-end"></span>
              <span class="step-number">3</span>
            </div>
            <div class="step-label">
              <h6 class="mb-2 pb-1">Download or schedule</h6>
            
            </div>
          </div>

          <div class="step"  :class="[hasCompletedWizardStep('datasets')?['pe-auto','active']:[''] ]" @click="panelView='datasets'">
            <div class="step-progress">
              <span class="step-progress-start"></span>
              <span class="step-progress-end"></span>
              <span class="step-number">4</span>
            </div>
            <div class="step-label">
              <h6 class="mb-2 pb-1">Saved datasets</h6>
            
            </div>
          </div>
         -->
        </div>
         
      </div>
    
    
    <div  class="position-relative " >


        <div v-if="!hasSession && isContainerInited" class="alert alert-primary my-3" role="alert">
          <i class="bi bi-lock"></i>You aren't signed into an account. Some features will be restricted. <a  class="alert-link"   href="#signinModal" data-bs-toggle="modal"> Sign in</a>.
        </div>
      
        <template v-if="panelView==='explore'">
            <div v-if="validLocation && !requirePreconfig" class="row mt-0 mb-0 bg-primary text-light rounded-top">
              
              <div class="col-12 col-xs-12 col-sm-12 col-lg-4">
                  <template v-if="showLocationDropdown" >
                    <div class="d-flex align-items-center justify-content-start">
                      <button class="btn btn-outline-primary text-light  px-1" type="button" @click="showLocationEdit()">
                        <i class="bi bi-pencil"></i>
                      </button>
                      <button class="btn dropdown-toggle btn-outline-primary text-truncate text-light  px-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {{selectedLocationText}}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li v-for="(location,index) in previewLocations"><a class="dropdown-item" href="#" @click="changeSelectedLocation(index)">{{getLocationText(location)}}</a></li>
                        <li v-if="hasMoreLocations"><a class="dropdown-item" href="#"  @click.prevent.stop="panelView='locations'">+{{locations.length-previewLocations.length}} more</a></li>

                        <li class="mt-3 ps-2"><a class="" href="#"   @click.prevent.stop="panelView='locations'" ><i class="bi bi-geo"></i> Add a location</a></li>
                        <li class="mt-3 ps-2"   @click.prevent.stop="panelView='locations'"><a class="" href="#" ><i class="bi bi-file-earmark"></i> Import locations</a></li>
                      
                        <template v-if="hasLocationsHistory">
                          <!--<li class="fw-bold mt-3 ps-2">Recently viewed</li>-->
                          <li> <a class="dropdown-item mt-3 " href="#" >Recently viewed <i class="bi-chevron-right fs-sm ms-2"></i></a>
                            <ul class="dropdown-menu dropdown-submenu">
                              <li v-for="(loc,index) in locationsHistory"  @click.prevent.stop="addLocation(loc)"><a class="dropdown-item " href="#" >{{getLocationText(loc)}}</a></li>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </div>

                  </template>

                  <template v-if="showLocationAdd">
                    <div class="d-flex align-items-center">
                      <div class="form-group  m-2 p-0">
                        <input v-model="newLocation" v-on:keyup.enter="addLocation()" class="form-control" type="text" placeholder="Enter a location">
                        <button @click="addLocation()" type="button" class="btn btn-translucent-primary "> <i class="bi-search"></i></button>
                      </div>
                    </div>
                  </template>
                  <div id="locationHelpBlock" class="form-text text-light mt-0 mb-1 opacity-70 ">
                    Addresses, partial addresses or lat,lon
                    <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Locations" data-bs-content="Enter one or more locations using addresses, partial addresses or lat,lon.">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
              </div>
              <div  class="col-12 col-xs-12 col-sm-9 col-lg-6" >
                  <div class="d-flex align-items-center flex-wrap ">
                        <i class="bi bi-stopwatch d-none d-sm-inline"></i> 
                          
                        <button class="dropdown-toggle btn text-primary text-light px-1" type="button" id="dateSelectionTypeDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                          {{selectedDateSelectionTypeText}} 
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dateSelectionTypeDropdownButton">
                          <template v-for="d in dateselectiontypes">
                            <li v-if="!d.hidden"  @click="setDateSelectionType(d)"><a class="dropdown-item" href="#" >{{d.label}}</a></li>
                          </template>
                          <li> <a class="dropdown-item" href="#" >Dynamic periods <i class="bi-chevron-right fs-sm ms-2"></i></a>
                            <ul class="dropdown-menu dropdown-submenu">
                              <li v-for="p in periods"  @click="setDynamicPeriod(p)"><a class="dropdown-item" href="#" >{{p.label}}</a></li>
                            </ul>
                          </li>
                        
                        </ul>
                      <div  class="d-flex align-items-center">
                        <DatePicker class="inline-block h-full" v-model="startdate">
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <button
                                class="btn text-primary text-light px-1"
                                @click="togglePopover()"
                              >
                                <i class="bi-calendar"></i> {{inputValue || "Start"}}
                              </button>
                            </div>
                          </template>
                        </DatePicker>
                        <div class="btn disabled px-0  text-light">
                          <i class="bi bi-arrow-right"></i>
                        </div>
                        <DatePicker class="inline-block h-full" v-model="enddate">
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <button
                                class="btn text-primary text-light px-1"
                                @click="togglePopover()"
                                >
                                <i class="bi-calendar"></i> {{inputValue || "End"}}
                              </button>
                            </div>
                          </template>
                        </DatePicker>
                      </div>
                  </div>

                  <div id="dateHelpBlock" class="form-text text-light opacity-70 mt-0 mb-1" >
                    History or forecast data
                    <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Dates" data-bs-content="Retrieve weather forecast or historical weather data  by choosing a date range.">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                  </div>
                
              </div>

              <div v-if="validLocation" class="col-12 col-xs-6 col-sm-3 col-lg-2 " >
                <div class="d-flex align-items-center">

                  <button class="dropdown-toggle  btn text-primary text-light px-1" type="button" id="unitGroupDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                    <span class="d-none d-md-inline" v-html="getUnitGroupLabel(queryDefn.unitGroup, false)" />
                    <span class="d-inline d-md-none" v-html="getUnitGroupLabel(queryDefn.unitGroup, true)" />
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="unitGroupDropdownButton">
                    <li v-for="u in unitGroups"  @click="setUnitGroup(u.id)"><a class="dropdown-item" href="#"><span class="d-none d-md-inline" v-html="u.label"></span><span class="d-inline d-md-none" v-html="u.shortlabel"></span></a></li>
                  </ul>
                </div>
                <div id="locationHelpBlock" class="form-text text-light opacity-70 mt-0 mb-1">
                  Data units
                  <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom" title="Unit Group" data-bs-content="Select the unit grouping such as celcius, fahrenheit, kilometers and miles.">
                      <i class="bi bi-question-circle" ></i>
                    </a>
                </div>
              </div>
            
          </div>

          <div v-if="validLocation && showWizard && requirePreconfig" class="row mt-0 mb-0 bg-primary text-light rounded-top py-2">
            <div class="fw-bold my-2">Configure weather data query</div>
          </div>


          <div v-if="validLocation && !showWizard" class="row bg-secondary rounded-bottom">
            <div class="col-12 col-sm-6 col-lg-10 px-0">
              <div class="form-text">Query options</div>
              <div class=" d-flex flex-wrap align-items-center  justify-content-start">
                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='datasections'"><i class="bi-grid-1x2" ></i> Data sections</button>
                
                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='elements'"><i class="bi-ui-checks-grid" ></i> Weather elements</button>

                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='degreedays'"><i class="bi-tree" ></i> Degree days</button>

                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='energy'"><i class="bi-sun" ></i> Wind &amp; solar</button>

                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='agri'"><i class="bi-flower3" ></i> Agriculture</button>

                <button class="btn btn-sm text-primary px-1 py-0" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='stationoptions'"><i class="bi-three-dots-vertical" ></i> Weather stations</button>


              
              </div>
            </div>
          </div>



          

          <div v-if="validLocation && !showWizard" class="d-flex mt-3 mb-2 align-items-center justify-content-center" :class="{ 'opacity-25':!resultData}">
          
            <div class="btn-group" role="group" aria-label="View options">
              

              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='api'}" @click="setView('api')"><i class="bi bi-code-slash"></i><span class="d-none  d-lg-block"> API</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='grid'}" @click="setView('grid')"><i class="bi bi-grid-3x3"></i><span class="d-none  d-lg-block"> Grid</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='chart'}" @click="setView('chart')"><i class="bi bi-bar-chart-line"></i><span class="d-none  d-lg-block"> Chart</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='raw'}" @click="setView('raw')"><i class="bi bi-file-binary"></i><span class="d-none  d-lg-block"> JSON</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='csv'}" @click="setView('csv')"><i class="bi bi-file-earmark-spreadsheet"></i><span class="d-none  d-lg-block"> CSV</span></button>
            
            </div>

          </div>

          <div v-if="validLocation && showWizard && !requirePreconfig" class="d-flex mt-3 mb-2 align-items-center justify-content-center" :class="{ 'opacity-25':!resultData}">
          
            <div class="btn-group" role="group" aria-label="View options">
              

              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='grid'}" @click="setView('grid')"><i class="bi bi-grid-3x3"></i><span class="d-none  d-lg-block"> Grid</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='raw'}" @click="setView('raw')"><i class="bi bi-file-binary"></i><span class="d-none  d-lg-block"> JSON</span></button>
              <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='csv'}" @click="setView('csv')"><i class="bi bi-file-earmark-spreadsheet"></i><span class="d-none  d-lg-block"> CSV</span></button>
            </div>

          </div>


          <div v-if="errorMessage" class="alert alert-primary my-3" role="alert">
            <i class="bi bi-exclamation-diamond"></i> {{errorMessage}}.
          </div>
          <div v-else-if="requirePreconfig" class="my-3">
            <h6>Please select a date or date range</h6>
            <div>You can use a single date, or a date range, in the past or future. You can also use the dynamic date values such as 'yesterday' and 'next7days' to create a dynamic date range</div>
            <div class="d-flex align-items-center flex-wrap ">
                  <i class="bi bi-stopwatch d-none d-sm-inline"></i> 
                    
                  <button class="dropdown-toggle btn text-primary  px-1" type="button" id="dateSelectionTypeDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                    {{selectedDateSelectionTypeText}} 
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dateSelectionTypeDropdownButton">
                   <template v-for="d in dateselectiontypes">
                          <li v-if="!d.hidden"  @click="setDateSelectionType(d)"><a class="dropdown-item" href="#" >{{d.label}}</a></li>
                          </template>
                          <li> <a class="dropdown-item" href="#" >Dynamic periods <i class="bi-chevron-right fs-sm ms-2"></i></a>
                            <ul class="dropdown-menu dropdown-submenu">
                              <li v-for="p in periods"  @click="setDynamicPeriod(p)"><a class="dropdown-item" href="#" >{{p.label}}</a></li>
                            </ul>
                          </li>
                  </ul>
                  
                <div  v-if="!isDynamicPeriod" class="d-flex align-items-center">
                  <DatePicker class="inline-block h-full" v-model="startdate">
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="flex items-center">
                        <button
                          class="btn text-primary  px-1"
                          @click="togglePopover()"
                        >
                          <i class="bi-calendar"></i> {{inputValue || "Start"}}
                        </button>
                      </div>
                    </template>
                  </DatePicker>
                  <div class="btn disabled px-0"  >
                    <i class="bi bi-arrow-right"></i>
                  </div>
                  <DatePicker class="inline-block h-full" v-model="enddate">
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="flex items-center">
                        <button
                          class="btn text-primary  px-1"
                          @click="togglePopover()"
                          >
                          <i class="bi-calendar"></i> {{inputValue || "End"}}
                        </button>
                      </div>
                    </template>
                  </DatePicker>
                </div>
            </div>
            <h6>Units</h6>
            <div>Choose the unit group that you prefer</div>
            <div class="d-flex align-items-center">

              <button class="dropdown-toggle  btn text-primary px-1" type="button" id="unitGroupDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                <span class="d-none d-md-inline" v-html="getUnitGroupLabel(queryDefn.unitGroup, false)" />
                <span class="d-inline d-md-none" v-html="getUnitGroupLabel(queryDefn.unitGroup, true)" />
              </button>
              <ul class="dropdown-menu" aria-labelledby="unitGroupDropdownButton">
                <li v-for="u in unitGroups"  @click="setUnitGroup(u.id)"><a class="dropdown-item" href="#"><span class="d-none d-md-inline" v-html="u.label"></span><span class="d-inline d-md-none" v-html="u.shortlabel"></span></a></li>
              </ul>
            </div>
             <h6>Advanced options</h6>
              <div>Advanced options allow you to customize the columns, data sources and other items.</div>
              <div class="d-flex align-items-center">
                <button class="btn text-primary px-1" type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" ><i class="bi-grid-1x2" ></i> Open advanced options</button>
              </div>
          </div>
          <div v-else-if="!resultData" class="flex-fill d-flex justify-content-center mt-5">
            <div v-if="hasNoLocations" class="">
              <h5>Please add a location to get started</h5>
            
                <div class="input-group">
                  <div class="btn px-1 disabled" >
                    <i class="bi bi-geo"></i>
                  </div>
                  <input v-model="newLocation" v-on:keyup.enter="addLocation()" class="form-control border-end-0  border-primary" type="text" placeholder="Enter a location" aria-describedby="locationHelpBlock">
                  <span class="input-group-append">
                      <button @click="addLocation()" class="btn btn-primary  border-start-0 border-primary ms-n1" :class="{disabled:!newLocation}" type="button" >
                          <i class="bi-search"></i>
                      </button>
                  </span>
                </div>
                <div id="locationHelpBlock" class="form-text  my-1">
                  Addresses, partial addresses or latitude,longitude values.
                </div>
            
            </div>
            <div v-else class="">
              <div v-if="validLocation">No data available. Please check your location and date selection.</div>
              <div v-else>No data available. No valid location</div>
            </div>
          
          </div>
        
          <template v-if="validLocation && !errorMessage && resultData">
            <GridWidget v-if="view==='grid'" :initialView="gridView" :weather-data="resultData" :parent-data="true" :blurAboveIndex="blurAboveIndex?6:0" :query-defn="queryDefn" :hasSession="hasSession" @update:view="setGridView" @update:costsaver="changeCostSaver"  @update:panel="setPanelView" class="flex-fill"></GridWidget>
            <MultiPeriodChartWidget v-if="view==='chart'" :weather-data="resultData" :parent-data="true" :views="['temp','precip','wind']" :selected-period="showChartAsHours?'hours':'days'" :query-defn="queryDefn"></MultiPeriodChartWidget>
            <ApiResultsWidget v-if="view==='raw'" :weather-data="resultData"  :parent-data="true" :blurAboveIndex="blurAboveIndex?1:0" class="flex-fill"></ApiResultsWidget>
            <ApiQuery v-if="view==='api'" :sessionState="sessionState" :queryDefinition="queryDefn" :location="selectedLocation"></ApiQuery>
            <CsvViewer v-if="view==='csv'" :sessionState="sessionState" :queryDefinition="queryDefn" :location="selectedLocation" :costSaverMode="costSaverMode" class="flex-fill"></CsvViewer>
          </template>
        
        </template>
      </div>
      <template v-if="panelView==='locations'">
        <section class="" style="min-height:50vh">
          <div class="row mt-0 mb-0 bg-primary text-light rounded-top py-2">
            <div class="fw-bold my-2">Manage locations</div>
            <!--<div id="locationHelpBlock" class="form-text text-light mt-0 mb-1 opacity-70 ">
              Addresses, partial addresses or lat,lon
              <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Locations" data-bs-content="Enter one or more locations using addresses, partial addresses or lat,lon.">
                <i class="bi bi-question-circle" ></i>
              </a>
            </div>-->
          </div>
          <!--<div class="d-flex justify-content-between align-items-center"><h3 class="div mb-4">Locations</h3> <button type="button" class="btn-close" @click="panelView='explore'"></button></div>-->
          <LocationsEditor  :parentLocations="locations" :subscriptionParams="subscriptionParams" @locationsChanged="handleLocationsChanged" @selectLocation="handleSelectLocation" ></LocationsEditor>
        </section>
      </template>
      <template v-if="panelView==='export'"  style="min-height:50vh">
        <section class="">
          <div class="row mt-0 mb-2 bg-primary text-light rounded-top py-2">
          
            <div class="fw-bold my-2"> Download or schedule data</div>
            <div id="locationHelpBlock" class="form-text text-light mt-0 mb-1 opacity-70 ">
              Download your data as CSV, Excel or JSON files
              <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Download data" data-bs-content="Download your data as CSV, Excel or JSON files.">
                <i class="bi bi-question-circle" ></i>
              </a>
            </div>
          </div>
          <!--<div class="d-flex justify-content-between align-items-center"><h3 class="h4 mb-4">Download</h3> <button type="button" class="btn-close" @click="panelView='explore'"></button></div>-->
          <ExportComponent :packageDefn="exportPackageDefn"  :sessionId="sessionId" :sessionState="sessionState" :metadataProp="metadata" @change:metadata="setMetadata" @openmydatasets="panelView='datasets'" @exportcomplete="exportComplete=true" :queryCost="queryCost" :queryCostHasHours="hasHours" :suggestedView="gridView"></ExportComponent>
        </section>
      </template>
      <template v-if="panelView==='datasets'"  style="min-height:50vh">
        <section class="">
          <div class="row mt-0 mb-0 bg-primary text-light rounded-top py-2">
            <div class="fw-bold my-2">Your saved datasets</div>
            <div id="locationHelpBlock" class="form-text text-light mt-0 mb-1 opacity-70 ">
              Download and edit datasets that you have already run.
              <a href="#" class="ms-1 text-light opacity-80" data-bs-container="body"  data-bs-toggle="popover"  data-bs-trigger="hover focus" data-bs-placement="bottom"  title="Saved datasets" data-bs-content="Download your data as CSV, Excel or JSON files.">
                <i class="bi bi-question-circle" ></i>
              </a>
            </div>
          </div>
          <!--<div class="d-flex justify-content-between align-items-center"><h3 class="div mb-4">Existing datasets</h3> <button type="button" class="btn-close" @click="panelView='explore'"></button></div>-->
          <BulkDefinitionsList :sessionId="sessionId" :sessionState="sessionState"  @select:task="setMetadata"></BulkDefinitionsList>
        </section>
      </template>
    
     
      <div  id="offcanvasLeft" class="offcanvas offcanvas-bottom bg-secondary" style="z-index:1010;max-height:100px" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1">
       <!-- <div class="offcanvas-header border-bottom">
          <button class="btn-close ms-auto" type="button" @click="finishWizard()"></button>
        </div>-->
        <div class="offcanvas-body" data-simplebar >
          <div  class="d-flex justify-content-center">
            <div class="btn-group">
              <button type="button" class="btn btn-primary"   @click="previousWizardStep()" :disabled="!hasPreviousWizardStep"> <i class="bi bi-chevron-left"></i>Previous</button>
              <button v-if="!isLastWizardStep" type="button" class="btn btn-primary"  @click="nextWizardStep()" :disabled="!hasNextWizardStep">Next<i class="bi bi-chevron-right"></i></button>
              <button v-if="isLastWizardStep" type="button" class="btn btn-primary"  @click="finishWizard()" >Finish</button>
            </div>
          </div>
        </div>
      </div>
    <!--MODALS-->


    <div class="modal fade" id="processingModal" tabindex="-1" aria-labelledby="processingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" modal-xl>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="processingModalLabel">Loading data...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
               <div class="d-flex align-items-center justify-content-center">
                  <div class="spinner-border mx-2 me-3" role="status" aria-hidden="true"></div>
                  <strong>Loading...</strong>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--
    <div class="modal fade" id="locationsEditorModel" tabindex="-1" aria-labelledby="locationsEditorModelLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl" modal-xl>
        <div class="modal-content d-flex flex-column" >
          <div class="modal-header">
            <h5 class="modal-title" id="locationsEditorModelLabel">Locations</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
         
          <div class="modal-body" >
            <LocationsEditor :parentLocations="locations" :subscriptionParams="subscriptionParams" @locationsChanged="handleLocationsChanged" @selectLocation="handleSelectLocation" style="height: 70vh;"></LocationsEditor>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="modal fade" id="queryCostEditorModel" tabindex="-1" aria-labelledby="queryCostEditorModelLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" modal-xl>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="queryCostEditorModelLabel">Query record cost</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
              <h5>Estimate query record cost: {{queryCostDetails}}</h5>
              <p>
                For more information see our <a href="/weather-data-cost-calculator">query record cost calculator</a>.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="queryEditorModel" tabindex="-1" aria-labelledby="queryEditorModelLabel" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl" >
        <div class="modal-content"  style="height:80vh;">
          <div class="modal-header">
            <h5 class="modal-title" id="queryEditorModelLabel">Weather Query</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <div class="modal-body overflow-auto">
            <QueryEditor ref="queryEditor" :queryDefinition="queryDefn" :startSection="queryEditorSection"  ></QueryEditor>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="packageDefn.params=$refs.queryEditor.getQueryDefn()">Ok</button>
          </div>
        </div>
      </div>
    </div>
    <!--@click="refreshData()-->
    <!--
    <div class="modal fade" id="downloadEditorModel" tabindex="-1" aria-labelledby="downloadEditorModelLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadEditorModelLabel">Download data</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <ExportComponent :packageDefn="exportPackageDefn"  :sessionId="sessionId" :sessionState="sessionState" :metadataProp="metadata" @change:metadata="setMetadata" :queryCost="queryCost" :queryCostHasHours="hasHours"></ExportComponent>
          </div>
        </div>
      </div>
    </div>
    -->

    <div class="modal fade tutorialModal" id="tutorialModel" tabindex="-1" aria-labelledby="tutorialModelLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div  v-if="tutorial" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="tutorialModelLabel">{{tutorial.title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideTutorial()"></button>
          </div>
          <div class="modal-body" v-html="tutorial.content"></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="hideTutorial()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="customDateModal" tabindex="-1" aria-labelledby="customDateModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customDateModalLabel">Free form date editor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">

              <div class="row align-items-end mb-3">
                <div class="col-12 col-md-6" >
                    <div class="text-muted fs-sm my-1 ">From</div>
                    <div class="input-group input-group-sm " >
                      <input type="text" class="form-control"  v-model="customStartdate"  >
                    </div>
                </div>
                <div class="col-12 col-md-6" >
                    <div class="text-muted fs-sm my-1 ">To</div>
                    <div class="input-group input-group-sm " >
                      <input type="text" class="form-control" v-model="customEnddate" :disabled="!customStartdate" >
                    </div>
                </div>
                
              </div>
              <p class="fs-sm"> 
                  Free form dates allow any date, date time and dynamic date supported by the Timeline Weather API. 
                  See the <a href="/resources/documentation/weather-api/timeline-weather-api/" target="_blank">documentation</a> for more information.

              </p>
                <!--
                <h5>From period</h5>
                <div class="row align-items-end">
                  <div class="col-12 col-md-4" >
                    <div class="text-muted fs-sm my-1 ">Period</div>
                    <select class="form-select form-select-sm" aria-label="Select period type">
                      <option disabled value="">Select period</option>
                      <option v-for="p in periodPrefixes"  :value="p">{{p.label}}</option>
                    </select>
                  </div>
                
                
                  <div class="col-4 col-md-4">
                    <div class="input-group input-group-sm " >
                      <input type="number" class="form-control"  style="max-width: 100px;">
                    </div>
                  </div>
                  <div class="col-8 col-md-4" >
                    <div class="text-muted fs-sm my-1">Time interval</div>
                    <select class="form-select form-select-sm" >
                      <option disabled value="">Select type</option>
                      <option v-for="ct in periodChronoTypes"  :value="ct" >{{ct.label}}</option>
                    </select>
                  </div>
                </div> 

                <h5>To period</h5>
                <div class="row align-items-end">
                  <div class="col-12 col-md-4" >
                  <div class="text-muted fs-sm my-1 ">Period type</div>
                    <select class="form-select form-select-sm" aria-label="Select period type">
                      <option disabled value="">Select period</option>
                    </select>
                  </div>
                
                  
                  <div class="col-4 col-md-4">
                    <div class="input-group input-group-sm " >
                      <input type="number" class="form-control"  style="max-width: 150px;">
                    </div>
                  </div>

                  <div class="col-8 col-md-4" >
                    <div class="text-muted fs-sm my-1">Period Type</div>
                    <select class="form-select form-select-sm" >
                      <option disabled value="">Select type</option>
                    </select>
                  </div>
                </div> 

                -->
            </div>

          </form>
          <div class="modal-footer">
            <button type="button" class="btn btn btn-secondary btn-sm" data-bs-dismiss="modal" @click="closeCustomDateEditor()">Cancel</button>
            <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" @click="closeCustomDateEditor(true)">Ok</button>
          </div>
        </div>
      </div>
    </div>
    <CommonModals />
  </div>
 
</template>

<script>
import CommonModals from '/src/components/CommonModals'
import LocationsEditor from '/src/components/LocationsEditor'
import QueryEditor from './QueryEditor'
import ApiQuery from '/src/components/ApiQuery'
import CsvViewer from './CsvViewer'
import ExportComponent from './Export'
import BulkDefinitionsList from './BulkDefinitionsList'
//import BulkDefinitionsListComponent from '/src/components/BulkDefinitionsList'
import QueryResults from './QueryResults'
import MultiPeriodChartWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/multi-period-chart-widget'
import GridWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/grid-widget'
import ApiResultsWidget from '/../bootstrap-vue-app/src/pages/wxwidgets/api-results-widget'
import '/../bootstrap-vue-app/src/pages/wxwidgets/wxwidgets.scss'
import { Calendar, DatePicker } from 'v-calendar';
import {VcDateUtils} from '/../visualcrossing-core-js/src/vcdateutils';
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils';
import {VcLocationsStorage} from "/../visualcrossing-core-js/src/vclocationsstorage";
import {VcConversion} from "/../visualcrossing-core-js/src/vcconversion";
import '/src/VcUtils';
import { VcGlobal } from '../../../../visualcrossing-core-js/src/vcglobal_conn'

export default {
  name: 'queryApp',
  props: {
  
  },
  data() {
    return {

      unitGroups:VcConversion.UNITGROUPS,
      periods:[
            {id:"today",label:"today"},
            {id:"yesterday",label:"yesterday"},
            {id:"tomorrow",label:"tomorrow"},
            {id:"yeartodate",label:"yeartodate"},
            {id:"monthtodate",label:"monthtodate"},
            {id:"last7days",label:"last7days"},
            {id:"last15days",label:"last15days"},
            {id:"last30days",label:"last30days"},
            {id:"last24hours",label:"last24hours"},
            {id:"next7days",label:"next7days"},
            {id:"next30days",label:"next30days"},
            {id:"next24hours",label:"next24hours"},
      ],
      /*
      periodPrefixes:[
        {id:"today",label:"today"},
        {id:"yesterday",label:"yesterday"},
        {id:"tomorrow",label:"Tomorrow"},
        {id:"yeartodate",label:"yeartodate"},
        {id:"monthtodate",label:"monthtodate"},
        {id:"last",label:"last",partial:true},
        {id:"next",label:"next",partial:true},
      ],
      periodChronoTypes:[
        {id:"days",label:"days"},
        {id:"months",label:"months"},
      ],
      */
      dateselectiontypes:[
         {id:"forecast",label:"Next 15 days"},
         {id:"daterange",label:"Date range"},
         {id:"period",label:"Dynamic period", hidden:true},
         {id:"custom",label:"Free form"},
         
      ],
      packageDefn:{
        addressFields:[],
          latitudeColumnId:null,
          longitudeColumnId:null,
          nameColumnId:null,
          params:{
            "goal":"timeline",
            "startdate":null,
            "enddate":null,
            "unitGroup":"metric",
            "contentType":"json",
            "options":"",
            "include":"",
            "maxDistance":-1,
          },
          runTimes:[],
          enabled:false,
          runOnce:false,
          lastRunTime : 0,
          repeatInterval: 0,
          enableNotifications:false,
          notificationAddresses:[],
          type:4,
          maxResultCount:1,
          maxResultAge:3,
          locations:[]
        },
        /*
      range:{
        start:null,
        end:null,
      },
      period:null,
      */
      selectedLocationIndex:-1,
      //locations:[],
      processedData:[],
      newLocation:null,
      manualLocationAdd:false,
      resultData:null,
      showDateChooser:false,
      selectedDateSelectionType:null,
      errorMessage:null,
      isRefreshing:false,
      view:'grid',
      panelView:'explore',
      costSaverMode:true,
      retrieveFullData:false,
      isContainerInited:false,
      sessionId:null,
      sessionState:null,
      gridView:"days",
      metadata:null,
      queryEditorSection:null,
      locationsHistory:null,
      tutorial:null,
      showWizard:false,
      requirePreconfig:false,
      exportComplete:false,
      tutorials:{
        welcome:{
          id:"welcome",
          title:"Welcome to Weather Query Builder!",
          content:"<p>Design, preview and view weather data for free.</p><p>You can then download the data as a CSV, Excel or JSON file. Alteratively switch to the API view to see the Weather API query.</p"
        }
      },
      wizardSteps:[{id:"locations",panelView:"locations", label:"Enter locations"},
      {id:"preconfig",panelView:"explore", label:"Choose data"},
      {id:"viewdata",panelView:"explore", label:"Explore data"},
      {id:"export",panelView:"export", label:"Download & Schedule"},
      {id:"datasets",panelView:"datasets", label:"View your datasets"}
      ],
      currentStep:null,
      customStartdate:null,
      customEnddate:null,
    }
  },
  methods:{
    /*
    createExportPackage() {
      var me=this;
      me.exportPackageDefn={};
      if (me.packageDefn && me.packageDefn.params) {
        me.exportPackageDefn=JSON.parse(JSON.stringify(me.packageDefn));
        me.exportPackageDefn.params.contentType="csv";
        me.exportPackageDefn.params.include= me.packageDefn.params.include || "days";
        me.exportPackageDefn.accountUserId=this.sessionState && this.sessionState.userMdId;
      }
    },
    */
   /*showQueryEditor(value) {
     var me=this;
     me.queryEditorSection=value;
     //setTimeout(function() {me.queryEditorSection=value}, 2000)
   },*/
   /*
   showDynamicDateEditor() {
      var me=this;
      var target=me.$el && me.$el.querySelector("#dynamicDateModalLabel");
      var modal = bootstrap.Modal.getOrCreateInstance(target);
      modal.show();
    },*/
    closeCustomDateEditor(applychanges) {
      let me=this;
      if (applychanges) {
        me.queryDefn.startdate=me.customStartdate;
        me.queryDefn.enddate=me.customEnddate;
      }
      me.toggleModal('#customDateModal', false);

    },
    finishWizard() {
      var me=this;
      me.toggleWizard(false);
      me.panelView="explore";
    },
   toggleWizard(show) {
      var me=this;
      me.showWizard=show;
      var target=me.$el && me.$el.querySelector("#offcanvasLeft");
      var offcanvas =target &&  bootstrap.Offcanvas.getOrCreateInstance(target);
      show && offcanvas && offcanvas.show();
      !show && offcanvas && offcanvas.hide();
      if (show) me.startWizard(); else me.endWizard();
   },
   endWizard() {
    var me=this;
    me.panelView="explore";
    me.requirePreconfig=false;
    if (!me.resultData) {
      me.refreshData();
    }
   },
   startWizard() {
    var me=this;
    
    var steps=me.wizardSteps;
     me.setWizardStep(steps[0]);
    if (!me.metadata) {
      me.requirePreconfig=true;
      me.resultData=null;
    }
    me.setView('grid')
   },
   getWizardStepById(stepId) {
    var me=this;
    var steps=me.wizardSteps;
    steps.reduce(function(a,d) {
      return a || (d && d.id===stepId && d)
    }, null);
   },
   hasCompletedWizardStep(step) {
      var me=this;
      var steps=me.wizardSteps;
      var currentIndex=me.currentWizardStepIndex;
      return steps.indexOf(step)<=currentIndex;
    },
    setWizardStep(step) {
      var me=this;
      me.currentStep=step;
      me.panelView=step.panelView;
      if (window["gtag"]) {
        gtag('event', 'wizardstep_'+step.id, { method : 'Vc' });
      }
      if (me.currentStep.id==="preconfig" && !me.requirePreconfig) {
        me.resultData=null;
        me.requirePreconfig=true;
      } else  if (me.currentStep.id==="viewdata" && me.requirePreconfig) {
        me.requirePreconfig=false;
        me.refreshData();
      } else  if (me.currentStep.id==="export") {
        me.exportComplete=false;
      }
    },
    hasCompletedRequiredWizardStepItems(step) {
      var me=this;
      if (!step) return false;
      var stepId=step.id;
      if (stepId==="locations") {
        return me.validLocation;
      } else if (stepId==="preconfig") {
        return me.requirePreconfig || me.resultData;
      } else if (stepId==="viewdata") {
        return me.requirePreconfig || me.resultData;
      } else if (stepId==="export") {
        return me.exportComplete;
      }


       return true;
    },
   
    nextWizardStep() {
      var me=this;
      if (!me.hasNextWizardStep) return;
      var currentIndex=me.currentWizardStepIndex;
      var steps=me.wizardSteps;
     
       me.setWizardStep(steps[currentIndex+1]);
    },
    previousWizardStep() {
      var me=this;
      if (!me.hasPreviousWizardStep) return;
      var currentIndex=me.currentWizardStepIndex;
      var steps=me.wizardSteps;
      me.setWizardStep(steps[currentIndex-1]);

      
    },
   setView(view) {
      if (window["gtag"]) {
        gtag('event', 'data_view_'+view, { method : 'Vc' });
      }
     this.view=view;
   },
    toggleModal(selector, show) {
      var me=this;
      var myModalEl=me.$el.querySelector(selector);
      var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
      if (show) {
        modal.show();
      } else {
        modal.hide();
      }
    },
   showTutorial(id, dismissable) {
      var me=this;
      if (dismissable && VcLocationsStorage.getValue("tutorial_"+id)) return;
      me.tutorial=me.tutorials[id];
      if (!me.tutorial) return;
      me.toggleModal(".tutorialModal", true);

    },
    hideTutorial() {
      var me=this;
      me.toggleModal(".tutorialModal", false);
      VcLocationsStorage.setValue("tutorial_"+me.tutorial.id, 1);
      me.tutorial=null;

    },
    
    hasInclude(value) {
      return VcListUtils.hasValueInList(this.queryDefn.include,value);
    },
    setInclude(value, include) {
      return VcListUtils.setValueInList(this.queryDefn.include,value, include, false);
    },
    setMetadata(metadata, packageDefn, panelView) {
      console.log("setmetadata...")
      var me=this;
      if (panelView) {
         me.panelView=panelView;
      } else if (me.panelView==='datasets') {
         me.panelView='explore';
      }
      me.metadata=metadata;
      if (packageDefn) {
        me.packageDefn=packageDefn;
        me.selectedLocationIndex=0;
        me.setDateSelectionType(null);
      }
     
    },
    setGridView(value) {
      console.log("GridView:"+value)
      this.gridView=value;
    },
    setPanelView(value) {
      var me=this;
      if (me.showWizard) {
        var step=me.getWizardStepById(value);
        if (step) me.setWizardStep(step);
      } else {
        me.panelView=value;
      }
      
    },
    changeCostSaver(value, confirm) {
      var me=this;
      if (!value && !confirm) {
        window.VcCommonModals.showConfirm("Confirm full dataset mode", 
        "Enabling full dataset mode will show all rows of your dataset with in the browser. Your account will be debited for this data usage and charges may apply. You may return to preview mode at any time. Do you want to continue?")
          .then(function(id) {
            me.changeCostSaver(value, true);
          }).catch((error) => {
          
          });
  
        return;
      }

      if (window["gtag"]) {
        gtag('event', 'costsaver_'+(value?"on":"off"), { method : 'Vc' });
      }

      me.costSaverMode=value;
      me.refreshData();
    },
    setUnitGroup(id) {
      var me=this;
      me.queryDefn.unitGroup=id;
      if (id) VcLocationsStorage.setUnitGroup(id);
      me.refreshData();
    },
    getUnitGroupLabel(id, short) {
      var unitGroup=this.unitGroups.reduce(function(a,d) {
        return a || (d.id===id && d);
      }, null);
      return (unitGroup && short?unitGroup.shortlabel:unitGroup.label) || "-";
    },
   
    /*
    initDateRange() {
      this.range.start=new Date();
      this.range.end=new Date();
    },
    */
    getPeriodFromId(id) {
      return this.periods.reduce(function (a, d) {
        return a || d.id===id
      }, null);
    },
    setDateSelectionType(value) {
      var me=this;
      if (!value) {
        if (!me.queryDefn.startdate) {
          value=me.dateselectiontypes[0]; //15 day forecast
        } else if (me.queryDefn.startdate && !me.queryDefn.enddate && me.getPeriodFromId(me.queryDefn.startdate)) {
           value=me.dateselectiontypes[2]; //period
        } else if (me.queryDefn.startdate && me.queryDefn.enddate) {
          let startDateInstance=VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);
          let endDateInstance=me.queryDefn.enddate && VcDateUtils.dateFromIsoLocal(me.queryDefn.enddate);
          if (startDateInstance && (!me.queryDefn.enddate || endDateInstance)) {
            value=me.dateselectiontypes[1];  //daterange
          } else {
            value=me.dateselectiontypes[3];  //custom

        

          }
         
        } else {
          console.log("No value found for null value");
        }
      }

      me.selectedDateSelectionType=value;
      if (value) {
        if (value.id==="forecast") {
          me.queryDefn.startdate=null;
          me.queryDefn.enddate=null;
        } else if (value.id==="singledate") {
          me.queryDefn.startdate=(me.getPeriodFromId(me.queryDefn.startdate) && null) || me.queryDefn.startdate;
          me.queryDefn.enddate=null;
        } else if (value.id==="daterange") {
          if (!me.queryDefn.startdate || !me.queryDefn.enddate) {
            me.queryDefn.startdate=null;
            me.queryDefn.enddate=null;
          }
        } else if (value.id==="period" && !me.getPeriodFromId(me.queryDefn.startdate)) {
          me.setDynamicPeriod(me.periods[0]);
        } else if (value.id==="custom") {
          me.customStartdate=me.queryDefn.startdate;
          me.customEnddate=me.queryDefn.enddate;
          me.toggleModal("#customDateModal", true);


        }
      }
    },
    /*
    clearDateSelection() {
      var me=this;
      //me.period=null;
      //me.range.start=null;
      //me.range.end=null;
     // me.packageDefn.params.startdate=null;
      me.selectedDateSelectionType=me.dateselectiontypes[0];
      this.refreshData();
    },*/
    setDynamicPeriod(value) {
      var me=this;
      //me.queryDefn.requirePreconfig=false;
      //me.period=value;
      me.selectedDateSelectionType=me.dateselectiontypes[2];
      me.packageDefn.params.startdate=(value && value.id) || null;
    //  me.range.start=null;
     // me.range.end=null;
      me.packageDefn.params.enddate=null;
      
      //this.refreshData();
    },
    showLocationEdit() {
      var me=this;
      me.manualLocationAdd=true;
      if (me.locations[me.selectedLocationIndex]) {
        me.newLocation=me.locations[me.selectedLocationIndex].location;
      }
    },
    /*
    handleDateChanged(range) {
      var me=this;
      if (!me.range) return;
      if ( me.range.start) {
        me.queryDefn.startdate=VcUtils.formatDateIso(me.range.start);
      }
      if ( me.range.end) {
        me.queryDefn.enddate=VcUtils.formatDateIso(me.range.end);
      }
      me.refreshData();
    },
    */
    handleSelectLocation(location) {
      var me=this;
      var index=me.locations.indexOf(location);
      me.selectedLocationIndex=index!==-1?index:0;
      me.refreshData();
    },
    handleLocationsChanged(locations) {
      //this.locations=locations; //no need as locations are bound
      var me=this;
      me.resultData=null;
      if (!locations.length) {
        me.selectedLocationIndex=-1;
        return;
      } else if (me.selectedLocationIndex<0) {
        me.selectedLocationIndex=0;
      } else if (me.selectedLocationIndex>=locations.length) {
        me.selectedLocationIndex=locations.length-1;
      }
      
      me.refreshData();
    },
    getLocationText(location) {
      if (location) return location.name || location.location;
      return "-";
    },
   changeSelectedLocation(index) {
     this.selectedLocationIndex=index;
     this.refreshData();
   },
   hasLocationsHistory() {
      return this.locationsHistory && this.locationsHistory.length;
    },
    refreshLocationsHistory() {
      //console.log("refreshLocationsHistory")
      this.locationsHistory=VcLocationsStorage.getLocations();
    },
   refreshData(confirm) {
    var me=this;
    me.refreshLocationsHistory();
  
    me.retrieveFullData=me.hasSession && (confirm || !me.costSaverMode);
    me.errorMessage=null;
    if (!me.selectedLocation || !me.selectedLocation.location) return;
    VcLocationsStorage.addLocation(me.selectedLocation);
    
    if (me.requirePreconfig) {
      return;
    }

    if (!me.queryDefn.startdate && me.queryDefn.enddate) {
      me.errorMessage="Please select a valid date range";
      return;
    }

    me.isRefreshing=true;
   
    var retrievalQueryDefn=JSON.parse(JSON.stringify(me.queryDefn));
    if (!me.retrieveFullData)  {
      retrievalQueryDefn.options=VcListUtils.setValueInList(retrievalQueryDefn.options,"preview", true, false);
      if (!me.retrieveFullData) retrievalQueryDefn.key=VcGlobal.QUERY_BUILDER_KEY;
      if (window["gtag"]) {
        gtag('event', 'data_preview', { method : 'Vc' });
      }

    } else {
      if (window["gtag"]) {
        gtag('event', 'data_full', { method : 'Vc' });
      }
    }
    if (retrievalQueryDefn.elements) {
      retrievalQueryDefn.elements=VcListUtils.setValueInList(retrievalQueryDefn.elements,"datetimeEpoch", true, false);
    }
    vcCommunicator.retrieveTimelineData(retrievalQueryDefn, me.selectedLocation.location, me.selectedLocation.name, "json", true, false)
      .then(response => {
        me.resultData=response;
        me.isRefreshing=false;
      
      }).catch((error) => {
        //console.log(error);
        me.errorMessage=error;
        me.isRefreshing=false;
      });
   },
   addLocation(location, name) {
      var me=this;
      location=location || me.newLocation;
      var newLocation=null;
      if (location && location.location) {
        newLocation=location;
        //me.locations.push(location);
        //me.selectedLocationIndex=me.locations.length-1;
      } else if (me.locations[me.selectedLocationIndex]) {
        me.locations[me.selectedLocationIndex].location=location;
        //VcLocationsStorage.addLocation( me.locations[me.selectedLocationIndex]);
      } else {
        newLocation={
            location:location,
            name:name
        }
       
      }
      me.manualLocationAdd=false;
      if (newLocation) {
        me.locations.push(newLocation);
        me.selectedLocationIndex=me.locations.length-1;
        //VcLocationsStorage.addLocation(newLocation);
      }
      
      me.refreshData();
    },
  },
  computed:{
    currentWizardStepIndex() {
      var me=this;
      var steps=me.wizardSteps;
      return steps.indexOf(me.currentStep);
    },
    isLastWizardStep() {
      var me=this;
      var currentIndex=me.currentWizardStepIndex;
      var steps=me.wizardSteps;
      return currentIndex===steps.length-1;
    },
    hasNextWizardStep() {
      var me=this;
      var currentIndex=me.currentWizardStepIndex;
      var steps=me.wizardSteps;

      return me.hasCompletedRequiredWizardStepItems(me.currentStep) && currentIndex<steps.length-1;

    },
    hasPreviousWizardStep() {
      var me=this;
      var currentIndex=me.currentWizardStepIndex;
      return currentIndex>0;
    },
    showLegacyLink() {
      return !this.showWizard;
    },
    
    previewLocations() {
      var me=this;
      if (!me.locations || !me.locations.length) return [];
      return me.locations.slice(0,5);
    },
    hasMoreLocations() {
      var me=this;
      return me.locations && me.locations.length>5;
    },
    resolvedDateRange() {
      var data=this.resultData;
      if (!data || !data.days) return "";
      if (data.days.length===1) return data.days[0].datetime;

      return data.days[0].datetime+" > "+data.days[data.days.length-1].datetime;
    },
    
    showChartAsHours() {
      var me=this;
      return me.resultData && me.resultData.days && me.resultData.days.length<4 && me.hasHours;
    },
    hasHours() {
       return this.queryDefn && (!this.queryDefn.include || (this.queryDefn.include && VcListUtils.hasValueInList(this.queryDefn.include, 'hours')));
    },
    queryCost() {
      return  this.resultData && this.resultData.queryCost;
    },
    queryCostDetails() {
      var me=this;
      if (!me.resultData || !me.resultData.queryCost) return "-";
      if (me.isMetered) {
        return `${this.resultData.queryCost} ($${(this.resultData.queryCost*0.0001).toFixed(4)})`;
      } else {
         return `${this.resultData.queryCost}`;
      }
    },
    hasElements() {
      return this.queryDefn && this.queryDefn.elements && this.queryDefn.elements.length;
    },
    includes: {
        get: function () {
          return VcListUtils.valuesInList(this.queryDefn.include);
        },
        set: function (newValue) {
          this.queryDefn.include=VcListUtils.setValuesInList(newValue);
        }
    },
    includeLabel() {
      var me=this;
      return (me.includes && me.includes.join(",")) || "Default";
    },
    taskName() {
      return (this.metadata && this.metadata.name);
    },
    accountUserId() {
      return this.sessionState && this.sessionState.userMdId;
    },
    subscriptionParams() {
      return this.sessionState && this.sessionState.subscriptionParams;
    },
    usageType() {
      return this.subscriptionParams  && this.subscriptionParams.usageType;
    },
    hasCreateBulk() {
      return this.subscriptionParams  && this.subscriptionParams.createBulk;
    },
    isMetered() {
      return this.usageType==="metered"
    },
    exportPackageDefn(){
      var packageDefn=JSON.parse(JSON.stringify(this.packageDefn));
      if (packageDefn.params.contentType==="json") packageDefn.params.contentType="csv";

      packageDefn.params.include=packageDefn.params.include || "days";

      let includeValues=VcListUtils.valuesInList(packageDefn.params.include)

      let filteredIncludes= includeValues.reduce(function(a, d) {
          let isCsvInclude=VcListUtils.CSVINCLUDESIDS.indexOf(d)!==-1;
          if (!isCsvInclude || !a.hasCsvInclude) {
            a.push(d);
            a.hasCsvInclude=a.hasCsvInclude || isCsvInclude;
          }
          return a;
        }, []);
      
      packageDefn.params.include=VcListUtils.setValuesInList(filteredIncludes);

      packageDefn.accountUserId=this.sessionState && this.sessionState.userMdId;
      return packageDefn;
    },
    
    /*
    apiQueryDefn() {
      var me=this;
      var apiQueryDefn=JSON.parse(JSON.stringify(me.queryDefn));
      apiQueryDefn.key=(me.sessionState && me.sessionState.apiKey) || null;
      if (me.selectedLocation) {
        apiQueryDefn.location=me.selectedLocation.location;
        apiQueryDefn.name=me.selectedLocation.name;
      } else {
        apiQueryDefn.location="YOUR_LOCATION";
        apiQueryDefn.name=null;
      }
      return apiQueryDefn;
    },
    */
    blurAboveIndex()  {
      return !this.hasSession || (this.costSaverMode && !this.retrieveFullData);
    },

    startDateText() {
      return this.queryDefn.startdate || "Choose date";
    },
    selectedDateSelectionTypeText() {
      var me=this;
      if (!me.selectedDateSelectionType) {
        me.setDateSelectionType(null);
      }
      
      if (!me.selectedDateSelectionType) return  "-";
      if (me.isDynamicPeriod) return me.dynamicDateLabel;
      return me.selectedDateSelectionType.label;
    },
    startdate: {
      get: function () {
        var me=this;


        if (!me.queryDefn.startdate || me.isDynamicOrCustom) {
          var days=me.resultData && me.resultData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[0].datetime);
        }
        if (me.isDynamicPeriod) return me.queryDefn.startdate;


        return VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);
      },
      set: function (newValue) {
        var me=this;

       
        let currentStartDate=me.queryDefn.startdate && VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);
     
        me.queryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        
        var days=me.resultData && me.resultData.days;
        if (days && !me.queryDefn.enddate) {
          me.queryDefn.enddate=days[days.length-1].datetime;
        } 
        
        let currentEndDate=!me.isDynamicOrCustom && me.queryDefn.enddate && VcDateUtils.dateFromIsoLocal(me.queryDefn.enddate);

        if (currentStartDate) {
          let dayDelta=me.isMetered?-30:-365;

          let newTimeDelta=(newValue.getTime()-currentStartDate.getTime())/86400000;
          if (currentEndDate && currentEndDate.getTime()<newValue.getTime()) { 
            me.queryDefn.enddate=VcDateUtils.formatDateIso(newValue);
          } else if (newTimeDelta<dayDelta) {
            me.queryDefn.enddate=VcDateUtils.formatDateIso(newValue);
          }
        }

        me.selectedDateSelectionType=me.dateselectiontypes[1];
      }
    },
    enddate: {
      get: function () {
        var me=this;

        if (!me.queryDefn.enddate || me.isDynamicOrCustom) {
          var days=me.resultData && me.resultData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[days.length-1].datetime);
        }
        if (me.isDynamicPeriod) return null;
        return VcDateUtils.dateFromIsoLocal(me.queryDefn.enddate);
      },
      set: function (newValue) {
        var me=this;

        
        let currentStartDate=!me.isDynamicOrCustom && me.queryDefn.startdate && VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);

        var days=me.resultData && me.resultData.days;
        if (days && (me.isDynamicPeriod || !me.queryDefn.startdate)) {
          me.queryDefn.startdate=days[0].datetime;
        } 
      


        me.queryDefn.enddate=VcDateUtils.formatDateIso(newValue);

        if (currentStartDate && currentStartDate.getTime()>newValue.getTime()) { 
          me.queryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        } 

        me.selectedDateSelectionType=me.dateselectiontypes[1];
        
      }
    },

/*
     startperiodtype: {
      get: function () {
        var me=this;
        if (!me.queryDefn.startdate || me.isDynamicPeriod) {
          var days=me.resultData && me.resultData.days;
          if (!days || !days.length) return null;
          return VcDateUtils.dateFromIsoLocal(days[0].datetime);
        }
        if (me.isDynamicPeriod) return me.queryDefn.startdate;
        return VcDateUtils.dateFromIsoLocal(me.queryDefn.startdate);
      },
      set: function (newValue) {
        var me=this;
        me.queryDefn.startdate=VcDateUtils.formatDateIso(newValue);
        var days=me.resultData && me.resultData.days;
        if (days && !me.queryDefn.enddate) {
          me.queryDefn.enddate=days[days.length-1].datetime;
        }

        me.selectedDateSelectionType=me.dateselectiontypes[1];
      }
    },
*/

    isDynamicOrCustom() {
      return this.isDynamicPeriod || this.isCustomRange;
    },
    isDynamicPeriod() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="period";
    },
    isCustomRange() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="custom";
    },
    isSingleDate() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="singledate";
    },
    isDateRange() {
      return this.selectedDateSelectionType && this.selectedDateSelectionType.id==="daterange";
    },
    isForecast() {
      return !(this.isDynamicPeriod || this.isSingleDate || this.isDateRange || this.isCustomRange);
    },
    /*
    noDateInfoChooser() {
      return this.noDateInfo && this.showDateChooser;
    },
    noDateInfoNoChooser() {
      return this.noDateInfo && !this.showDateChooser;
    },
    noDateInfo() {
      return !this.queryDefn || (!this.queryDefn.startdate && !this.queryDefn.enddate);
    },*/
    dynamicDateLabel() {
      var me=this;
      return me.queryDefn.startdate || "Select period...";
      //if (!me.period) return "Select period...";
      //return me.period.label;
    },
    showLocationAdd() {
      return this.manualLocationAdd;
    },
    hasNoLocations() {
      return !this.locations || !this.locations.length;
    },
    hasMultiLocations() {
      return this.locationCount>1;
    },
    hasSession() {
      return this.sessionId?true:false;
    },
    showLocationDropdown() {
      return this.locations && this.locations.length>0 && !this.manualLocationAdd;
    },
    queryDefn() {
      return this.packageDefn.params || {};
    },
    locations() {
      return (this.packageDefn.locations) || null;
    },
    locationCount() {
      return (this.locations && this.locations.length) || -1;
    },
    selectedLocationText() {
      return this.getLocationText(this.selectedLocation);
    },
    selectedLocation() {
      return (this.locations && this.locations[this.selectedLocationIndex]) || null;
    },
    validLocation() {
      return this.selectedLocation;
    },
    apiRequest() {
      var me=this;
      return me.selectedLocation && me.queryDefn && me.selectedLocation.location &&
        vcCommunicator.createTimelineRequest(me.queryDefn, me.selectedLocation.location, me.selectedLocation.name, true)
    },
    
  },
  mounted() {
    var me=this;
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    me.sessionState=vcCommunicator.sessionState;
    me.isContainerInited=vcCommunicator.isInited || me.sessionId;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
       me.sessionId=sessionId;
       me.sessionState=sessionState;
      me.isContainerInited=true;
       //console.log("mounted.createsession");
    });
     vcCommunicator.addHandler('terminatesession', function () {
      me.sessionId=null;
      me.sessionState=null;
      me.metadata=null;
      me.view='grid';
      me.panelView='explore';
      me.costSaverMode=true;
      me.retrieveFullData=false;
      me.gridView="days";
    });


    me.refreshLocationsHistory();

    var lastLocation=me.hasLocationsHistory() && me.locationsHistory[0];
    var savedUnitGroup=VcLocationsStorage.getUnitGroup();
    var launchLocation=null;
    var startdate=null;
    var enddate=null;

    if (window.vcconfig) {
      if (window.vcconfig.location==="<?php echo $location ?>") window.vcconfig.location="Herndon,VA";
      if (window.vcconfig.unitGroup==="<?php echo $unitGroup ?>") window.vcconfig.unitGroup="metric";
      if (window.vcconfig.startdate==="<?php echo $startDate ?>") window.vcconfig.startdate="2018-01-01";
      if (window.vcconfig.enddate==="<?php echo $endDate ?>") window.vcconfig.enddate="2018-02-01";


      if (!window.vcconfig.isDefault || !lastLocation) {
        if (window.vcconfig.location) {
         launchLocation={
            location:window.vcconfig.location,
            name:null
          }
          
        }
        if (window.vcconfig.unitGroup) {
          me.unitGroup=window.vcconfig.unitGroup || savedUnitGroup
          VcLocationsStorage.setUnitGroup(me.unitGroup);
        }

        if (window.vcconfig.startdate) {
          startdate=window.vcconfig.startdate;
        }

        if (window.vcconfig.enddate) {
          enddate=window.vcconfig.enddate;
        }
      }
    }
    
    launchLocation=launchLocation || lastLocation;
    if (launchLocation) {
        me.locations.push(launchLocation);
        me.selectedLocationIndex=me.locations.length-1;
        VcLocationsStorage.addLocation(launchLocation);
        me.refreshLocationsHistory();
      }

    var queryDefn=JSON.parse(JSON.stringify(me.queryDefn));
    queryDefn.unitGroup=me.unitGroup || savedUnitGroup || "us";
    queryDefn.startdate=startdate;
    queryDefn.enddate=enddate;
    //me.queryDefn=queryDefn;
    me.packageDefn.params=queryDefn;
   // me.refreshData();

    var requestedView=null;
    requestedView=VcUtils.qs["v"];
    if (requestedView && requestedView==="api") {
      me.view=requestedView
    }
    
    
    me.showTutorial("welcome", true);
  },
  updated() {
    //console.log("Updated");
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return  bootstrap.Popover.getOrCreateInstance(popoverTriggerEl)
    });
  },
  watch: {
    queryDefn:{
        handler:function(newValue,oldValue) {
          var me=this;
          /*
          if (me.packageDefn && me.packageDefn.params) {
            me.exportPackageDefn=JSON.parse(JSON.stringify(me.packageDefn));
            me.exportPackageDefn.params.contentType="csv";
            me.exportPackageDefn.params.include= me.packageDefn.params.include || "days";
            me.exportPackageDefn.accountUserId=this.sessionState && this.sessionState.userMdId;
          }
          */
          //console.log("queryDefn...")
          //console.log(newValue)
          //console.log(oldValue)
          me.refreshData();
        },
        deep: true
    },
    isRefreshing:function(newValue) {
      var me=this;
      var target=this.$el && this.$el.querySelector("#processingModal");
      var modal = bootstrap.Modal.getOrCreateInstance(target);
      target.addEventListener('shown.bs.modal', function (event) {
       if (!me.isRefreshing)  modal.hide();
      });

     if (newValue) {
       setTimeout(function() {
          if (!me.isRefreshing) return;
          console.log("isRefreshing Show ")
          modal.show();
          }, 1000);
      
     } else {
      //console.log("isRefreshing hide ")
      modal.hide();
     }
    }
    /*
    startdate:function(newValue) {
       this.refreshData();
    },
    enddate:function(newValue) {
       this.refreshData();
    },
    */
  },
  components: {
    LocationsEditor,
    QueryEditor,
    ApiQuery,
    CsvViewer,
    QueryResults,
    MultiPeriodChartWidget,
    GridWidget,
    Calendar,
    DatePicker,
    ApiResultsWidget,
    ExportComponent,
    BulkDefinitionsList,
    CommonModals,
    //BulkDefinitionsListComponent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   .minheight50 {
    min-height: 50%;
  }
  .widget {
    max-width: initial;
  }
</style>
