import { VcCommunicator } from "./vccommunicator";
import {VcGlobal} from "./vcglobal_conn";
console.log("VcCommunicatorAccount class")


/*
VcCommunicator.prototype.saveMetadata=function(metadataId,metadataType,name,description, parentFolderId) {
    var me=this;
    var config={
        objectId:metadataId,
        type:metadataType,
        name:name,
        description:description,
        parentFolderId:parentFolderId
    }
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("saveobjectdefinition"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Save failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

    
}

*/
VcCommunicator.prototype.generateAuthCode=function(username) {
    var me=this;
  
    return me.retrieveData(me.createUrl("generateauthcode"), {
        userId:username
    });
}

VcCommunicator.prototype.changeuserpassword=function(username, password, verificationcode) {
    var me=this;
    var config={
        userId:username,
        newPassword:password,
        authCode:verificationcode
    }
    return me.retrieveData(me.createUrl("changeuserpassword"), config);
}
VcCommunicator.prototype.validateuseremail=function(email,isRetry,locale) {
    var me=this;
    var config={
        email:email,
        isretry:isRetry,
        locale:locale
    }
    return me.retrieveData(me.createUrl("validateuseremail"), config);
}
VcCommunicator.prototype.validateemail=function(email,validationcode,locale) {
    var me=this;
    var config={
        email:email,
        validationcode:validationcode,
        locale:locale
    }
    return me.retrieveData(me.createUrl("validateemail"), config);
}
VcCommunicator.prototype.createnewclient=function(username, password,validationcode, message) {
    var me=this;

    var clientId=username+"_personal";

    var config={
        clientId:clientId,
        adminUsername:username,
        adminUserEmail:username,
        password:password,
        usercount:1,
        expirationlength:30,
        allowPassthruAuthenticationForAdmin:false,
        sendClientEmails:true,
        stripeManagedRoles:true,
        requiredStripeProductId:VcGlobal.STRIPE_PRODUCT_ID,
        requiredStripePlanId:VcGlobal.STRIPE_PLAN_ID,
        message:message,
        validationcode:validationcode
    }

    return me.retrieveData(this.createUrl("createnewclient"), config);
}

VcCommunicator.prototype.refreshCustomerInfo=function() {
    var me=this;

    var config=me.config.createsessionpkg || {};

    return me.retrieveData(me.createUrl("stripecustomerinfo"), config);
}
VcCommunicator.prototype.saveCustomerInfo=function(customerInfo) {
    var me=this;

    var config=me.config.createsessionpkg || {};

    return me.retrieveData(me.createUrl("savecustomerinfo"), customerInfo,'application/json', 'POST');
    
    /*
    return new Promise((resolve, reject) => {
      var vcCommunicator=window.vcCommunicator;

      if (!vcCommunicator || !model)  {
        reject("No model provided"); 
        return;
      }
      

      let url=vcCommunicator.createUrl("saveenergymodel", null, "https://weather1.visualcrossing.com/VisualCrossingWebServices/rest/services/");

      //model=JSON.stringify(model);

      vcCommunicator.retrieveData(url, model, 'application/json', 'POST')
          .then(data => {
              if (data.errorCode) {
                  reject(data.message || `saveenergymodel failed ${data.errorCode}`);
                  return;
              }
              resolve(data);
          }).catch(error => {
              reject(error); 
          });

          */
}
    

VcCommunicator.prototype.changeApiKey=function() {
    var me=this;

    var config=me.config.createsessionpkg || {};

    return me.retrieveData(me.createUrl("changeapikey"), config);
}

VcCommunicator.prototype.reenableaccount=function() {
    var me=this;

    var config=me.config.createsessionpkg || {};

    return me.retrieveData(me.createUrl("reenableaccount"), config);
}

VcCommunicator.prototype.stripecreatesubscription=function(customerId,planId,userCount,coupon) {
    var me=this;
    var config={
        customerId:customerId,
        planId:planId,
        userCount:userCount,
    }
    if (coupon) config.coupon=coupon;

    return me.retrieveData(this.createUrl("stripecreatesubscription"), config);
}
VcCommunicator.prototype.createprimarysubscription=function(customerId,planId,userCount,coupon) {
    var me=this;
    var config={
        customerId:customerId,
        planId:planId,
        userCount:userCount,
    }
    if (coupon) config.coupon=coupon;

    return me.retrieveData(this.createUrl("createprimarysubscription"), config);
}
VcCommunicator.prototype.stripecancelsubscription=function(subscriptionId, comment) {
    var me=this;
    var config={
        subscriptionId:subscriptionId,
        comment:comment
    }

    return me.retrieveData(this.createUrl("stripecancelsubscription"), config);
}

VcCommunicator.prototype.stripeupdatesource=function(customerId,srcId) {
    var me=this;
    var config={
        customerId:customerId,
        srcId:srcId,
    }

    return me.retrieveData(this.createUrl("stripeupdatesource"), config);
}
VcCommunicator.prototype.stripeclearpaymentsource=function(customerId) {
    var me=this;
    var config={
        customerId:customerId
    }

    return me.retrieveData(this.createUrl("stripeclearpaymentsource"), config);
}
VcCommunicator.prototype.stripecouponinfo=function(couponId) {
    var me=this;
    var config={
        couponId:couponId
    }
    return me.retrieveData(this.createUrl("stripecouponinfo"), config);
}
VcCommunicator.prototype.createcheckoutsession=function(priceId) {
    var me=this;
    var config={
        priceId:priceId
    }
    return me.retrieveData(this.createUrl("createcheckoutsession"), config);
}
VcCommunicator.prototype.loadcheckoutsession=function(checkoutSessionId) {
    var me=this;
    var config={
        checkoutSessionId:checkoutSessionId
    }
    return me.retrieveData(this.createUrl("loadcheckoutsession"), config);
}
VcCommunicator.prototype.createportalsession=function(flowType, subscriptionId, planId, id) {
    var me=this;
    var config={
       id:id,
       flowType:flowType,
       subscriptionId:subscriptionId,
       planId:planId,
    }
    return me.retrieveData(this.createUrl("createportalsession"), config);
}
VcCommunicator.prototype.createpaymentintent=function() {
    var me=this;
    var config={
       
    }
    return me.retrieveData(this.createUrl("createpaymentintent"), config);
}
VcCommunicator.prototype.usagesummary=function(current,history,info) {
    var me=this;

    var config={
        current:(current=== undefined || current),
        history:(history=== undefined || history),
        info:(info=== undefined || info)
    };

    return me.retrieveData(this.createUrl("usagesummary"), config);
}