<template>
               
              <div class="container-fluid">
                <div class="d-flex flex-wrap align-items-end justify-sm-content-center mb-1" role="group" aria-label="">
                  <div >
                    <div class="text-muted fs-sm my-1" >Weather Data</div>
                    <div class="btn-group">
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:csvInclude==='days'}" @click="csvInclude='days'" ><i class="bi bi-calendar3"></i><span class="d-none d-lg-block"> Daily</span></button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:csvInclude==='hours'}" @click="csvInclude='hours'"><i class="bi bi-clock"></i><span class="d-none d-lg-block"> Hourly</span></button>
                   
                    </div>
                  </div>
                  <div class="vr mx-2 mt-4 text-muted  "></div>
                  <div>
                    <div class="text-muted fs-sm my-1" >Additional Data</div>
                    <div class="btn-group">
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:csvInclude==='current'}" @click="csvInclude='current'"><i class="bi bi-hourglass"></i><span class="d-none d-lg-block"> Current</span></button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:csvInclude==='events'}" @click="csvInclude='events'" ><i class="bi bi-tornado"></i><span class="d-none d-lg-block"> Events</span></button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:csvInclude==='alerts'}" @click="csvInclude='alerts'" ><i class="bi bi-exclamation-triangle"></i><span class="d-none d-lg-block"> Alerts</span></button>
                    </div>
                  </div>
                

                  <div class="ms-auto">
                    <div class="text-muted fs-sm my-1" >View</div>
                    <div class="btn-group">
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='table'}" @click="view='table'" ><i class="bi  bi-grid-3x3"></i><span class="d-none d-lg-block"> Table</span></button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='raw'}" @click="view='raw'" ><i class="bi  bi-file-earmark-spreadsheet"></i><span class="d-none d-lg-block"> Raw</span></button>
                      </div>
                  </div>
                  <!--<ContentTypeChooser v-if="resolvedQueryDefinition" :queryDefinition="resolvedQueryDefinition" :show-content-type="false" />-->
                      
                </div>
                <div v-if="errorMessage" class="alert alert-primary my-3" role="alert">
                  <i class="bi bi-exclamation-diamond"></i> {{errorMessage}}.
                </div>
                <div v-if="view==='table'" class="d-flex fs-sm table-responsive">
                  <div  class="">
                    <table class="table table-striped fs-xs">
                      <thead>
                        <tr>  
                          <th v-for="(col,index) in columns">
                            <div class="">
                              <div>{{col}}</div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(record,index) in records">
                          <tr>
                              <td v-for="(v,index2) in record" scope="col" class="text-nowrap px-2 py-1" >{{v}}</td>
                          </tr>
                        </template>
                      </tbody>
                        
                    </table>
                  </div>
                   
                </div>
                <div v-if="view==='raw'" class="d-flex fs-sm " >
                     <pre class="language-csv w-100"><code>{{filtedResultData}}</code></pre>
                </div>
                <div v-if="hiddenLineCount">+{{hiddenLineCount}}  more rows</div>
              </div>

</template>

<script>


import ContentTypeChooser  from '/src/components/ContentTypeChooser'
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils';
import { parse } from 'csv-parse';

export default {
  name: 'CsvViewer',
  props: {
     queryDefinition: Object,  
     sessionState:Object,
     location:Object,
     costSaverMode:Boolean,

  },
  data() {
    return {
      view:'table',
      resultData:null,
      errorMessage:null,
      isRefreshing:false,
      resolvedQueryDefinition:null,
      rawRecords:null,
    }
  },
  methods:{
    createResolvedQueryDefinition() {
      var me=this;
      me.resolvedQueryDefinition=JSON.parse(JSON.stringify(me.queryDefinition));
      me.resolvedQueryDefinition.contentType="csv";

      var csvInclude=VcListUtils.csvInclude( me.resolvedQueryDefinition.include);
      if (!csvInclude) me.resolvedQueryDefinition.include=VcListUtils.setValueInList(me.resolvedQueryDefinition.include, "days", true, false);



    },
    refreshData(confirm) {
      var me=this;
      console.log("refreshing csv")
      var retrieveFullData=me.sessionState && (confirm || !me.costSaverMode);
      me.errorMessage=null;
      if (!me.location || !me.location.location) return;
      
       if (!me.resolvedQueryDefinition) return;

      me.isRefreshing=true;
    
     

      var retrievalQueryDefn=JSON.parse(JSON.stringify(me.resolvedQueryDefinition));
     
      if (!retrieveFullData)  {
        retrievalQueryDefn.options=VcListUtils.setValueInList(retrievalQueryDefn.options,"preview", true, false);

        if (window["gtag"]) {
          gtag('event', 'data_csv_preview', { method : 'Vc' });
        }

      } else {
        if (window["gtag"]) {
          gtag('event', 'data_csv_full', { method : 'Vc' });
        }
      }

      /*if (retrievalQueryDefn.elements) {
        retrievalQueryDefn.elements=VcListUtils.setValueInList(retrievalQueryDefn.elements,"datetimeEpoch", true, false);
      }*/

      vcCommunicator.retrieveTimelineData(retrievalQueryDefn, me.location.location, me.location.name, "csv", true, !retrieveFullData)
        .then(response => {
          me.resultData=response;
          me.isRefreshing=false;

          parse(response, {
              relax_column_count: true
            }, 
            function(err, records){
              me.errorMessage=err;
              me.rawRecords=records;
            });

        }).catch((error) => {
          me.resultData=null;
          me.rawRecords=null;
          me.errorMessage=error;
          me.isRefreshing=false;
        });
    },
   
   
  },
  computed:{
    csvInclude: {
      get: function () {
        var me=this;
        return me.resolvedQueryDefinition && VcListUtils.csvInclude( me.resolvedQueryDefinition.include);
      },
      set: function (newValue) {
        var me=this;
        var oldValue=VcListUtils.csvInclude( me.resolvedQueryDefinition.include);

        if (oldValue) me.resolvedQueryDefinition.include=VcListUtils.setValueInList(me.resolvedQueryDefinition.include, oldValue, false, false);
      
        me.resolvedQueryDefinition.include=VcListUtils.setValueInList(me.resolvedQueryDefinition.include, newValue, true, false);
      }
    },
    hiddenLineCount() {
      var me=this;
      if (!me.rawRecords || me.rawRecords.length<7 || !me.costSaverMode) return 0;
      return me.rawRecords.length-7;
    },
    filtedResultData() {
      var me=this;
      if (!me.resultData || !me.costSaverMode) return me.resultData;
      var splitLines = me.resultData.split(/\r?\n/);
      return splitLines.reduce(function(a, d,i) {
        if (i<7) {
          return a+'\r\n'+d;
        }
        return a;
      }, "");
    },
    columns() {
      var me=this;
      if (!me.rawRecords || !me.rawRecords[0]) return [];
      return me.rawRecords[0];
    },
    records() {
      var me=this;
      if (!me.rawRecords) return [];
      return me.rawRecords.filter(function(d,i) {
        if (i===0) return false;
        if (me.costSaverMode && i>7) return false;
        return true;
      });
    },
     
  },
  watch:{
    location:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.createResolvedQueryDefinition();
          me.refreshData();
        },
        deep: true
    },
    queryDefinition:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.createResolvedQueryDefinition();
          me.refreshData();
        },
        deep: true
    },
    resolvedQueryDefinition:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.refreshData();
        },
        deep: true
    },
    costSaverMode(newValue) {
      var me=this;
      
      me.refreshData();
    },
  },
  mounted() {
    var me=this;
    me.createResolvedQueryDefinition();
    me.refreshData();
  },
  updated() {

  },
  components: {
    ContentTypeChooser
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
