import dayjs from "dayjs"
import utc  from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
export var VcTimezone = (function () {
	
  dayjs.extend(utc)
  dayjs.extend(timezone)

    return {
      guessTz:function() {
        return dayjs.tz.guess();;
      },
      toTz:function(date, tzid, keepLocal){
        tzid=tzid || dayjs.tz.guess();
        //date=new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0));

        //if ("utc"===tzid.toLowerCase()) return date;

        return dayjs(date).tz(tzid, keepLocal);
      },
      toUtc:function(date){
        
        return dayjs(date).utc();
      },

    }
}());
