<template>
       <!-- Page content-->
  <div class="row" >
    <!-- Sidebar-->
    <aside class="col-lg-4 col-md-5 pe-xl-4 mb-5">
      <!-- Account nav-->
      <div class="card card-body border-0 shadow-sm pb-1 me-lg-1">
        <div class="d-md-block mt-3"  >
          <div class="card-nav nav d-flex flex-column" id="nav-tab" role="tablist">
            <a class="card-nav-link nav-link active datasections" data-bs-toggle="pill"  data-bs-target="#datasections"  type="button" role="tab"><i class="bi-grid-1x2 opacity-60 me-2" ></i>Data sections</a>
            <a class="card-nav-link nav-link elements" data-bs-toggle="pill"  data-bs-target="#elements"  type="button" role="tab"><i class="bi-ui-checks-grid opacity-60 me-2" ></i>Weather elements</a>
           
            <a class="card-nav-link nav-link degreedays" data-bs-toggle="pill"  data-bs-target="#degreedays"  type="button" role="tab"><i class="bi-tree opacity-60 me-2" ></i>Degree Days</a>
            <a class="card-nav-link nav-link energy" data-bs-toggle="pill"  data-bs-target="#energy"  type="button" role="tab"><i class="bi-sun opacity-60 me-2" ></i>Solar &amp; Wind Energy</a>
            <a class="card-nav-link nav-link agri" data-bs-toggle="pill"  data-bs-target="#agri"  type="button" role="tab"><i class="bi-flower3 opacity-60 me-2" ></i>Agriculture</a>
            <a class="card-nav-link nav-link stationoptions" data-bs-toggle="pill"  data-bs-target="#stationoptions"  type="button" role="tab"><i class="bi-three-dots-vertical opacity-60 me-2"></i>Weather Stations</a>
            <a class="card-nav-link nav-link histfcst" data-bs-toggle="pill"  data-bs-target="#histfcst"  type="button" role="tab"><i class="bi-clock-history opacity-60 me-2" ></i>Historical forecast</a>
          <!--  <a class="card-nav-link nav-link dataoptions" data-bs-toggle="pill"  data-bs-target="#dataoptions"  type="button" role="tab"><i class="bi-mortarboard opacity-60 me-2"></i>Advanced</a>-->
           
          </div>
        </div>
      </div>
    </aside>
    <!-- Content-->
    <div class="col-lg-8 col-md-7 mb-5  h-100">
      <div class="tab-content ">
          <div class="tab-pane fade show active" role="tabpanel" id="datasections" >
            <div class="text-primary h6">Data sections</div>
            <p class="form-text"> 
              Format and data section that will be included in download and API requests. 
              Tablular formats (e.g. CSV or Excel) permit a single data section. JSON allows multiple data sections - choosing particular sections will reduce latency.
            </p>
            <ContentTypeChooser :queryDefinition="localQueryDefinition" />
            <div class="mt-4 text-primary h6">Data sources</div>
            <div v-for="(include,i) in datasourceIncludes" :key="include.id" class="form-check form-check-inline" >
              <input type="checkbox"  :id="'include-'+include.id" v-model="include.value" class="form-check-input"  :indeterminate.prop="defaultIncludes && isDefaultInclude(include.id)" />
              <label class="form-check-label" :for="'include-'+include.id">{{include.label}}</label>
            </div>
            <div class="mt-4 text-primary h6">Output options</div>
            <div class="form-check form-check-inline" >
                  <input type="checkbox" id="optionsnonulls"  v-model="optionsnonulls" class="form-check-input"/>
                  <label class="form-check-label" for="optionsnonulls">Omit empty (null) values</label>
            </div>
            <div class="form-check form-check-inline" >
                  <input type="checkbox" id="optionsstnslevel1"  v-model="optionsstnslevel1" class="form-check-input"/>
                  <label class="form-check-label" for="optionsstnslevel1">Include only level 1 stations</label>
            </div>
            
          </div>
          <div class="tab-pane fade" role="tabpanel" id="elements" >


            <div class="d-flex align-items-center ">
              <div class="text-primary h6 m-0">Weather elements</div>
              <button class="btn btn-sm text-primary ms-auto" type="button" @click="clearElements">Clear</button>
              <button class="btn btn-sm text-primary" type="button" @click="resetElements"><i class="bi bi-arrow-counterclockwise" ></i> Reset</button>
            </div>
            
            <p class="form-text"> 
              Elements control what information is included in the result.  You can uncheck elements to reduce the size of the data and reduce latency. 
              In a standard query, most elements are included. Some advanced elements, such as degree days, are turned off by default and can be enabled.
            </p>

            <div class="accordion mt-4" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Core weather elements
                  </button>
                </h2>
              
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body row">
                    <div v-for="(element,i) in coreElements" :key="element.id" class="form-check form-check-inline col-md-3 col-5" >
                      <input type="checkbox"  :id="'element-'+element.id" v-model="element.value"  class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                      <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                    Location elements
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body row">
                  <div v-for="(element,i) in locationElements" :key="element.id" class="form-check form-check-inline col-md-3 col-5" >
                     <input type="checkbox"  :id="'element-'+element.id" v-model="element.value"  class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                    <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                  </div>
                  </div>
                </div>
              </div>


              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                    Astronomy elements
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body row">
                    <div v-for="(element,i) in astroElements" :key="element.id"  class="form-check form-check-inline col-md-3 col-5" >
                       <input type="checkbox"  :id="'element-'+element.id" v-model="element.value"  class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                      <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                    </div>
                  </div>
                </div>
              </div>



              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                    Description elements
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body row">
                    <div v-for="(element,i) in descElements" :key="element.id" class="form-check form-check-inline col-md-3 col-5" >
                       <input type="checkbox"  :id="'element-'+element.id" v-model="element.value"  class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                      <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                    Additional data information elements
                  </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div class="accordion-body row">
                    <div v-for="(element,i) in infoElements" :key="element.id" class="form-check form-check-inline col-md-3 col-5" >
                      <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                      <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="tab-pane fade" role="tabpanel" id="degreedays" >
            <div class="mt-4 text-primary h6">Degree Day Elements</div>
              <p class="form-text"> 
                Degree days are used in agriculture, heating and cooling applications to measure how the temperature affects the operation of a farm or system. 
                Degree days are not available in all plans. 
              </p>
              <div class="row mb-3">
                <div v-for="(element,i) in ddElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                  <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                  <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
                </div>
              </div>
              <div>

                <div class="mb-3">
                  <label class="form-label">Base temperature</label>
                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.degreeDayTempBase"  :disabled="!hasDegreeDayElements">
                    <span class="input-group-text" id="basic-addon2" v-html="tempUnitLabel"></span>
                    <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.degreeDayTempBase=-999" title="Reset to default"  :disabled="!hasDegreeDayElements" ><i class="bi bi-arrow-counterclockwise" ></i></button>
                  </div>
                  <div class="form-text" id="">
                    Degree day base temperature
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Maximum temperature threshold</label>
                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.degreeDayTempMaxThreshold"  :disabled="!hasDegreeDayElements">
                    <span class="input-group-text" id="basic-addon2" v-html="tempUnitLabel"></span>
                    <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.degreeDayTempMaxThreshold=-999" title="Reset to default" :disabled="!hasDegreeDayElements" ><i class="bi bi-arrow-counterclockwise" ></i></button>
                  </div>
                  <div class="form-text" id="">
                    Degree day maximum temperature
                  </div>
                </div>
                <div class="mb-3">
                    <label class="form-text">Calculation method</label>
                    <select class="form-select form-select-sm" style="max-width:200px"  v-model="localQueryDefinition.degreeDayMethod" :disabled="!hasDegreeDayElements">
                      <option disabled value="">&nbsp;</option>
                      <option v-for="(u,i) in DEGREEDAYMETHODS" :value="u">{{u}}</option>
                    </select>
                  <!--
                  <label class="form-label">Calculation method</label>

                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.degreeDayMethod"  :disabled="!hasDegreeDayElements">
                    <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.degreeDayMethod=null" title="Reset to default" :disabled="!hasDegreeDayElements" ><i class="bi bi-arrow-counterclockwise" ></i></button>
                  </div>
                  -->
                  <div class="form-text" id="">
                    Degree day calculation method
                  </div>
                </div>

                <div class="mb-3">
                  <!--<label class="form-label">Inverse calculation</label>-->
                  <div class="form-check form-check-inline ">
                    <input type="checkbox"  id="ddInverse" :model="localQueryDefinition.degreeDayInverse" class="form-check-input"  :disabled="!hasDegreeDayElements" />
                    <label class="form-check-label" for="ddInverse">Invert calculation method</label>
                  </div>
                  <div class="form-text" id="">
                    Invert calculation method to increase degree days with decreasing temperature
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Start date</label>
                  <div class="input-group input-group-sm">
                    <input type="text" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.degreeDayStartDate"  :disabled="!hasDegreeDayElements">
                    <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.degreeDayStartDate=null" title="Reset to default" :disabled="!hasDegreeDayElements" ><i class="bi bi-arrow-counterclockwise" ></i></button>
                  </div>
                  <div class="form-text" id="">
                    Fixed start date for degree day accumulation (yyyy-MM-dd such as 2021-02-15)
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Fix Temperature</label>
                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.degreeDayTempFix"  :disabled="!hasDegreeDayElements">
                    <span class="input-group-text" id="basic-addon2" v-html="tempUnitLabel"></span>
                    <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.degreeDayTempFix=-999" title="Reset to default"  :disabled="!hasDegreeDayElements"><i class="bi bi-arrow-counterclockwise" ></i></button>
                  </div>
                  <div class="form-text" id="">
                    Degree day fix temperature  
                  </div>
                </div>
    
            </div>

          </div>
          <div class="tab-pane fade" role="tabpanel" id="energy" >
            <div class="mt-4 text-primary h6">Energy Elements</div>
            <p class="form-text"> 
              Advanced wind and solar energy elements. Please note that these may require a specific license or plan. See the <a href="/resources/documentation/weather-api/energy-elements-in-the-timeline-weather-api/">energy elements documentation</a> for more information. 
            </p>
            <div class="row mb-3">
              <label class="form-label">Solar energy</label>
              <div v-for="(element,i) in solarEnergyElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            </div>
            
            <div class="row mb-3">
              <label class="form-label">Wind energy</label>
              <div v-for="(element,i) in windEnergyElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            </div>

          </div>


           <div class="tab-pane fade" role="tabpanel" id="agri" >
            <div class="mt-4 text-primary h6">Agriculture Elements</div>
            <p class="form-text"> 
              Advanced agriculture and horticulture elements. Please note that these may require a specific license or plan. See the <a href="/resources/documentation/weather-api/agriculture-elements-in-the-timeline-weather-api/">agriculture elements documentation</a> for more information. 
            </p>
            <div class="row mb-3">
              <label class="form-label">Soil Temperature</label>
              <div v-for="(element,i) in soilTempAgriElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <label class="form-label">Soil Moisture</label>
              <div v-for="(element,i) in soilMoistureAgriElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            </div>
           <div class="row mb-3">
              <label class="form-label">ET0 evapotranspiration</label>
              <div v-for="(element,i) in et0AgriElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            </div>

          </div>

          <div class="tab-pane fade" role="tabpanel" id="histfcst" >
            <div class="mt-4 text-primary h6">Historical forecast</div>
            <p class="form-text"> 
              Provides previous date forecasts. Please note that these may require a specific license or plan.   See the <a href="/resources/documentation/weather-api/timeline-weather-api/">API documentation</a> for more information. 
            </p>
            <div class="mb-3">
              <label class="form-label">Historical forecast basis date</label>
              <div class="input-group input-group-sm">
                <input type="text" class="form-control"  style="max-width:200px"  v-model="localQueryDefinition.forecastBasisDate "  >
                <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.forecastBasisDate=null" title="Reset to default" ><i class="bi bi-arrow-counterclockwise" ></i></button>
              </div>
              <div class="form-text" id="">
                Start date for historical forecast (yyyy-MM-dd format such as 2022-02-15)
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Historical forecast basis day</label>
              <div class="input-group input-group-sm">
                <input type="number" class="form-control" min="0" max="15" step="1" style="max-width:200px"  v-model="localQueryDefinition.forecastBasisDay">
                <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.forecastBasisDay=null" title="Reset to default" ><i class="bi bi-arrow-counterclockwise" ></i></button>
              </div>
              <div class="form-text" id="">
                Forecast basis day for the start date of the query.
              </div>
            </div>
          </div>

          <div class="tab-pane fade" role="tabpanel" id="stationoptions" >
            <div class="mt-4 text-primary h6">Weather stations</div>
            <p class="form-text"> 
              Options to modify how nearby weather stations data is used to calculate historical observations. 
            </p>
            <div v-for="(element,i) in stationsElements" :key="element.id"  class="form-check form-check-inline col-md-5 col-10" >
                <input type="checkbox"  :id="'element-'+element.id" v-model="element.value" class="form-check-input" :indeterminate.prop="isDefaultElements && isDefault(element)"/>
                <label class="form-check-label" :for="'element-'+element.id">{{element.label}}</label>
              </div>
            <div class="mb-3">
              <label class="form-label">Maximum distance</label>
              <div class="input-group input-group-sm">
                <input type="number" class="form-control"  style="max-width:200px" v-model="convertedMaxdistance">
                <span class="input-group-text" id="basic-addon2" v-html="distanceUnitLabel"></span>
                <button class="btn btn-outline-secondary" type="button" @click="localQueryDefinition.maxDistance=-1" title="Reset to default" ><i class="bi bi-arrow-counterclockwise" ></i></button>
              </div>
              <div class="form-text" id="">
                API Parameter distance: {{apiMaxDistance}}. Maximum allowed value: {{maxDistanceMax+distanceUnitLabel}}
              </div>
            </div>
            <div class="">
              <label  class="form-label">Maximum stations</label>
               <div class="input-group input-group-sm">
                <input type="number" class="form-control" style="max-width:200px"  v-model="localQueryDefinition.maxStations" >
               </div>
              <div class="form-text" id="">
                Number of weather stations used to interpolate historical observations.
              </div>
            </div>
            <div class="">
              <label  class="form-label">Maximum elevation difference</label>
               <div class="input-group input-group-sm">
                <input type="number" class="form-control" style="max-width:200px"  v-model="localQueryDefinition.elevationDifference" >
               </div>
              <div class="form-text" id="">
                Allowed difference in elevation between requested location and stations (meters) 
              </div>
            </div>
          </div>   
          <!--
          <div class="tab-pane fade" role="tabpanel" id="dataoptions" >
            <div class="mt-4 text-primary h6">Data sources</div>
            <div v-for="(include,i) in datasourceIncludes" :key="include.id" class="form-check form-check-inline" >
              <input type="checkbox"  :id="'include-'+include.id" v-model="datasourceIncludes[i].value" class="form-check-input"/>
              <label class="form-check-label" :for="'include-'+include.id">{{include.label}}</label>
            </div>
            <div class="mt-4 text-primary h6">Output options</div>
            <div class="form-check form-check-inline" >
                  <input type="checkbox" id="optionsnonulls"  v-model="optionsnonulls" class="form-check-input"/>
                  <label class="form-check-label" for="optionsnonulls">Omit empty (null) values</label>
            </div>
          </div>
          -->
        </div>
      
      </div>
      
  </div>
               
</template>

<script>
import {VcConversion} from '/../visualcrossing-core-js/src/vcconversion'
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils'
import ContentTypeChooser  from '/src/components/ContentTypeChooser'
export default {
  name: 'queryEditor',
  props: {
    queryDefinition: Object,  
    startSection:String
  },
   emits: ['update:querydefinition'],
  data() {
    return {
      breakBys:[
      {id:"years",label:"Years"},
      {id:"self",label:"Self"},
      {id:"none",label:"None"},
    ],
    chronoUnits:[
      {id:"years",label:"Year"},
      {id:"months",label:"Month"},
      {id:"weeks",label:"Week"},
      {id:"days",label:"Day"},
      // {id:"all",label:"Summarize all data"},
    ],
    combinationMethods:[
      {id:"aggregate",label:"aggregate"},
      {id:"list",label:"list"},
      {id:"best",label:"best"},
      {id:"raw",label:"raw"},
    ],
    DEGREEDAYMETHODS:[
      "average","sine","doublesine","triangle","doubletriangle"
    ],
    periods:[
      {id:"today",label:"today"},
      {id:"yesterday",label:"yesterday"},
      {id:"yeartodate",label:"yeartodate"},
      {id:"monthtodate",label:"monthtodate"},
      {id:"last7days",label:"last7days"},
      {id:"last30days",label:"last30days"},
      {id:"lastyear",label:"lastyear"},
      {id:"last24hours",label:"last24hours"},
    ],
    years:[],
    months:[
      {id:1,label:"January", days:31},
      {id:2,label:"February", days:28},
      {id:3,label:"March", days:31},
      {id:4,label:"April", days:30},
      {id:5,label:"May", days:31},
      {id:6,label:"June", days:30},
      {id:7,label:"July", days:31},
      {id:8,label:"August", days:31},
      {id:9,label:"September", days:30},
      {id:10,label:"October", days:31},
      {id:11,label:"November", days:30},
      {id:12,label:"December", days:31},
    ],
    elements:[
      {id:"datetime",label:"Date time",type:"core",value:false, defTable:true, defJson:true},
      {id:"datetimeEpoch",label:"Timestamp",type:"core",value:false, defTable:false, defJson:true},
      {id:"name",label:"Name",type:"location",value:false, defTable:true, defJson:true},
      {id:"address",label:"Address",type:"location",value:false, defTable:false, defJson:true},
      {id:"resolvedAddress",label:"Resolved address",type:"location",value:false, defTable:false, defJson:true},
      {id:"latitude",label:"Latitude",type:"location",value:false, defTable:false, defJson:true},
      {id:"longitude",label:"Longitude",type:"location",value:false, defTable:false, defJson:true},
      {id:"tempmax",label:"Maximum temperature",type:"core",value:false, defTable:true, defJson:true},
      {id:"tempmin",label:"Minimum temperature",type:"core",value:false, defTable:true, defJson:true},
      {id:"temp",label:"Temperature",type:"core",value:false, defTable:true, defJson:true},
      {id:"feelslikemax",label:"Maximum feels like",type:"core",value:false, defTable:true, defJson:true},
      {id:"feelslikemin",label:"Minimum feels like",type:"core",value:false, defTable:true, defJson:true},
      {id:"feelslike",label:"Feels like",type:"core",value:false, defTable:true, defJson:true},
      {id:"dew",label:"Dew",type:"core",value:false, defTable:true, defJson:true},
      {id:"humidity",label:"Humidity",type:"core",value:false, defTable:true, defJson:true},
      {id:"precip",label:"Precipitation amount",type:"core",value:false, defTable:true, defJson:true},
      {id:"precipprob",label:"Precipitation probability",type:"core",value:false, defTable:true, defJson:true},
      {id:"precipcover",label:"Precipitation cover",type:"core",value:false, defTable:true, defJson:true},
      {id:"preciptype",label:"Precipitation type",type:"core",value:false, defTable:true, defJson:true},
      {id:"snow",label:"Snow",type:"core",value:false, defTable:true, defJson:true},
      {id:"snowdepth",label:"Snow depth",type:"core",value:false, defTable:true, defJson:true},
      {id:"windgust",label:"Wind gust",type:"core",value:false, defTable:true, defJson:true},
      {id:"windspeed",label:"Wind speed",type:"core",value:false, defTable:true, defJson:true},
      {id:"windspeedmax",label:"Wind speed (Max)",type:"core",value:false, defTable:false, defJson:false},
      {id:"windspeedmean",label:"Wind speed (Mean)",type:"core",value:false, defTable:false, defJson:false},
      {id:"windspeedmin",label:"Wind speed (Min)",type:"core",value:false, defTable:false, defJson:false},
      {id:"winddir",label:"Wind direction",type:"core",value:false, defTable:true, defJson:true},
      {id:"pressure",label:"Pressure",type:"core",value:false, defTable:true, defJson:true},
      {id:"cloudcover",label:"Cloud cover",type:"core",value:false, defTable:true, defJson:true},
      {id:"visibility",label:"Visibility",type:"core",value:false, defTable:true, defJson:true},
      {id:"solarradiation",label:"Solar radiation",type:"core",value:false, defTable:true, defJson:true},
      {id:"solarenergy",label:"Solar energy",type:"core",value:false, defTable:true, defJson:true},
      {id:"uvindex",label:"UV index",type:"core",value:false, defTable:true, defJson:true},
      {id:"severerisk",label:"Severe risk",type:"core",value:false, defTable:true, defJson:true},
      {id:"sunrise",label:"Sunrise",type:"astro",value:false, defTable:true, defJson:true},
      {id:"sunset",label:"Sunset",type:"astro",value:false, defTable:true, defJson:true},
      {id:"moonphase",label:"Moonphase",type:"astro",value:false, defTable:true, defJson:true},
      {id:"conditions",label:"Conditions",type:"desc",value:false, defTable:true, defJson:true},
      {id:"description",label:"Description",type:"desc",value:false, defTable:true, defJson:true},
      {id:"icon",label:"Icon",type:"desc",value:false, defTable:true, defJson:true},
      {id:"stations",label:"Stations",type:"stations",value:false, defTable:true, defJson:true},
      {id:"source",label:"Source",type:"info",value:false, defTable:false, defJson:true},
      {id:"degreedays",label:"Degree days",type:"dd",value:false, defTable:false, defJson:false},
      {id:"accdegreedays",label:"Accumlated degree days",type:"dd",value:false, defTable:false, defJson:false},

      {id:"windspeed50",label:"Wind speed at 50 meters",type:"windenergy",value:false, defTable:false, defJson:false},
      {id:"winddir50",label:"Wind direction at 50 meters",type:"windenergy",value:false, defTable:false, defJson:false},
      {id:"windspeed80",label:"Wind speed at 80 meters",type:"windenergy",value:false, defTable:false, defJson:false},
      {id:"winddir80",label:"Wind direction at 80 meters",type:"windenergy",value:false, defTable:false, defJson:false},
      {id:"windspeed100",label:"Wind speed at 100 meters",type:"windenergy",value:false, defTable:false, defJson:false},
      {id:"winddir100",label:"Wind direction at 100 meters",type:"windenergy",value:false, defTable:false, defJson:false},

      {id:"dniradiation",label:"Direct normal radiation",type:"solarenergy",value:false, defTable:false, defJson:false},
      {id:"difradiation",label:"Diffuse normal radiation",type:"solarenergy",value:false, defTable:false, defJson:false},
      {id:"ghiradiation",label:"Global horizontal radiation",type:"solarenergy",value:false, defTable:false, defJson:false},
      {id:"gtiradiation",label:"Global tilt radiation",type:"solarenergy",value:false, defTable:false, defJson:false},
      {id:"sunelevation",label:"Sun elevation",type:"solarenergy",value:false, defTable:false, defJson:false},

      {id:"soiltemp01",label:"Soil temperature 0-0.1m",type:"soiltempagri",value:false, defTable:false, defJson:false},
      {id:"soiltemp04",label:"Soil temperature 0.1-0.4m",type:"soiltempagri",value:false, defTable:false, defJson:false},
      {id:"soiltemp10",label:"Soil temperature 0.4-1.0m",type:"soiltempagri",value:false, defTable:false, defJson:false},
      {id:"soiltemp20",label:"Soil temperature 1.0-2.0m",type:"soiltempagri",value:false, defTable:false, defJson:false},
     
      {id:"soilmoisture01",label:"Soil moisture 0-0.1m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisture04",label:"Soil moisture 0.1-0.4m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisture10",label:"Soil moisture 0.4-1.0m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisture20",label:"Soil moisture 1.0-2.0m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},

      {id:"soilmoisturevol01",label:"Soil volumetric moisture 0-0.1m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisturevol04",label:"Soil volumetric moisture 0.1-0.4m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisturevol10",label:"Soil volumetric moisture 0.4-1.0m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},
      {id:"soilmoisturevol20",label:"Soil volumetric moisture 1.0-2.0m",type:"soilmoistureagri",value:false, defTable:false, defJson:false},

      {id:"et0",label:"Reference evapotranspiration et0",type:"et0agri",value:false, defTable:false, defJson:false},
    ],
    //defaultJsonElements:["datetime","datetimeEpoch","name","address","resolvedAddress","latitude","longitude","tempmax","tempmin","temp","feelslikemax","feelslikemin","feelslike","dew","humidity","precip","precipprob","precipcover","preciptype","snow","snowdepth","windgust","windspeed","winddir","pressure","cloudcover","visibility","solarradiation","solarenergy","uvindex","severerisk","sunrise","sunset","moonphase","conditions","description","icon","stations","source",],
    //defaultTableElements:["name","datetime","tempmax","tempmin","temp","feelslikemax","feelslikemin","feelslike","dew","humidity","precip","precipprob","precipcover","preciptype","snow","snowdepth","windgust","windspeed","winddir","sealevelpressure","cloudcover","visibility","solarradiation","solarenergy","uvindex","severerisk","sunrise","sunset","moonphase","conditions","description","icon","stations"],
    includes:[
      {
        id:"obs",
        label:"Historical station observations",
        value:false,
        def:true,
      },
      {
        id:"remote",
        label:"Historical remote observations (satellite, radar)",
        value:false,
        def:true,
      },
      {
        id:"stats",
        label:"Statistics",
        value:false,
        def:false,
      },
      {
        id:"statsfcst",
        label:"Statistical forecast",
        value:false,
        def:false,
      },
      {
        id:"histfcst",
        label:"Forecast as history",
        value:false,
        def:false,
      },
      {
        id:"fcst",
        label:"Forecast",
        value:false,
        def:true,
      },
     /* {
        id:"days",
        label:"Daily data",
        value:false
      },
      {
        id:"hours",
        label:"Hourly data",
        value:false
      },
      {
        id:"alerts",
        label:"Weather alerts",
        value:false
      },,
      {
        id:"current",
        label:"Current conditions",
        value:false
      },
      {
        id:"events",
        label:"Weather events",
        value:false
      }    */       
    ],

  startDate:{
      year:null,
      month:null,
      day:null
    },
    endDate:{
      year:null,
      month:null,
      day:null
    },
    datemode:"none",
    showAdvanced:false,
    advancedSection:"core",
    localQueryDefinition:this.createLocationDefinition(),
  }
    
  },
  methods:{
    isDefaultInclude(id) {
      var selectedIncludes=this.includes;
      return selectedIncludes.reduce(function(a,d) {
        return a || (d.id===id && d.def);
      }, false);
    },
    createLocationDefinition(defn) {
      defn=defn || this.queryDefinition;
       return defn && JSON.parse(JSON.stringify(defn));
    },
    getQueryDefn() {
      console.log("getQueryDefn...")
      return this.localQueryDefinition;
      //this.$emit('update:querydefinition', v);
    },
    resetElements() {
      var me=this;
      me.elements.forEach(function(d) {
        d.value=false;
      });
    },
    clearElements() {
      var me=this;
      me.elements.forEach(function(d) {
        d.value=d.id==="datetime";
      });
    },
    /*
    toggleElement(element) {
      var me=this;
      var isDefault=me.isDefaultElements;
     
      me.elements.forEach(function(d) {
       if (d===element) {
         d.value=isDefault?!me.isDefault(element):!element.value;
       } else if (isDefault) {
          d.value=me.isDefault(element);
       } else {
         //
       }
      });
       
    },*/
   isDefault(element) {
     var me=this;
     if (!element) return false;
     
     if (me.isTable) {
       return element.defTable;
     }
     return element.defJson;
   },
   toggleAdvSection(section) {
      this.advancedSection=section!==this.advancedSection?section:null;
    },
    showAdvSection(section) {
      return this.advancedSection===section;
    },
    
    daysInMonth:function(month, startDay) {
        var me=this;
        var days=[];
        month=+month;
        if (startDay) startDay--;
        if (!month || !me.months[month-1]) return days;
        for (var i=0;i<me.months[month-1].days;i++) {
          if (!startDay || i>=startDay) days.push(i+1);
        }
        return days;
      },
   
    getElements(type) {
      var me=this;
      if (!type) return me.elements;
      return me.elements.filter(function (d) {
        return d.type===type;
      })
    }
  },
  computed:{
     hasDegreeDayElements() {
       return this.ddElements.reduce(function(a, d) {
         return a || d.value;
       }, false);
     },
      isDefaultElements() {
        return this.localQueryDefinition && !this.localQueryDefinition.elements;
      },

      coreElements() {
        return this.getElements('core');
      },
      locationElements() {
        return this.getElements('location');
      },
      astroElements() {
        return this.getElements('astro');
      },
      descElements() {
        return this.getElements('desc');
      },
      infoElements() {
        return this.getElements('info');
      },
      ddElements() {
        return this.getElements('dd');
      },
      windEnergyElements() {
        return this.getElements('windenergy');
      },
      stationsElements() {
        return this.getElements('stations');
      },
      solarEnergyElements() {
        return this.getElements('solarenergy');
      },

      soilTempAgriElements() {
        return this.getElements('soiltempagri');
      },
      soilMoistureAgriElements() {
        return this.getElements('soilmoistureagri');
      },
      et0AgriElements() {
        return this.getElements('et0agri');
      },
     /* dataIncludes() {
        var me=this;
        return VcListUtils.filterArrayById(["days","hours","current","alerts","events"], me.includes);
      },*/
      datasourceIncludes() {
        var me=this;
        return VcListUtils.filterArrayById(["obs","remote","fcst","stats","statsfcst"], me.includes);
      },
      defaultIncludes() {

       

        var selectedIncludes=this.includes;
        return !selectedIncludes.reduce(function(a,d) {
          return a || d.value
        }, false);
    
      },
    /*
      degreedayIncludes() {
        var me=this;
        return VcListUtils.filterArrayById(["degreeday","accdegreeday"], me.includes);
      },*/
      apiMaxDistance() {
        if (this.localQueryDefinition.maxDistance===-1) return "Default";
        return this.localQueryDefinition.maxDistance+" meters";
      },
      maxDistanceMax() {
        return Math.round(VcConversion.convertValue(200000, "m", this.distanceUnit))
        // return VcConversion.formatValueFromUnit(val,this.distanceUnit);
      },
      convertedMaxdistance: {
        get: function () {
          var me=this;
          var queryDefn=me.localQueryDefinition;
          if (queryDefn.maxDistance===-1) return "";
         
          return VcConversion.convertValue(queryDefn.maxDistance, "m", me.distanceUnit, 0);
        },
        set: function (newValue) {
          var me=this;
          if (newValue>me.maxDistanceMax) newValue=me.maxDistanceMax;
          if (newValue<-1) newValue=-1;
          var queryDefn=me.localQueryDefinition;
          if (!newValue || newValue<0) {
            queryDefn.maxDistance=-1
          } else {
            queryDefn.maxDistance=Math.round(VcConversion.convertValue(newValue, me.distanceUnit, "m"));
          }

        }
      },
      distanceUnit() {
        return VcConversion.getQuantityUnit(this.localQueryDefinition.unitGroup, "distance");
      },
      distanceUnitLabel() {
        return VcConversion.formatValueFromUnit(null,this.distanceUnit);
      },
      tempUnit() {
        return VcConversion.getQuantityUnit(this.localQueryDefinition.unitGroup, "temp");
      },
      tempUnitLabel() {
        return VcConversion.formatValueFromUnit(null,this.tempUnit);
      },
      optionsnonulls: {
        get: function () {
          return VcListUtils.hasValueInList(this.localQueryDefinition.options, "nonulls");
        },
        set: function (newValue) {
          this.localQueryDefinition.options=VcListUtils.setValueInList(this.localQueryDefinition.options, "nonulls", newValue);
        }
      },
      optionsstnslevel1: {
        get: function () {
          return VcListUtils.hasValueInList(this.localQueryDefinition.options, "stnslevel1");
        },
        set: function (newValue) {
          this.localQueryDefinition.options=VcListUtils.setValueInList(this.localQueryDefinition.options, "stnslevel1", newValue);
        }
      },

      isJson() {
          return this.localQueryDefinition.contentType==="json";
      },
      isTable() {
          return this.localQueryDefinition.contentType!=="json";
      },
  },
  mounted() {
    var me=this;
   
  },
  watch: {
    queryDefinition:{
      handler:function(newValue) {
        var me=this;
        me.localQueryDefinition=me.createLocationDefinition(newValue);
       // me.localQueryDefinition=newValue && JSON.parse(JSON.stringify(newValue))
      },
      deep: true
    },
    elements:{
      handler:function(newValue,oldValue) {
        var me=this;
        if (!newValue) newValue=[];
        me.localQueryDefinition.elements=newValue.reduce(function(a, d) {
          if (!d.value) return a;
          if (a) a+=",";
          return a+d.id;
        }, "");
      },
      deep: true
    },
   includes:{
        handler:function(newValue,oldValue) {
          var me=this;
          if (!newValue) newValue=[];
          newValue.forEach(function(d) {
              me.localQueryDefinition.include=VcListUtils.setValueInList(me.localQueryDefinition.include,d.id, d.value, false);
          })
         
          /*
          me.queryDefinition.include=newValue.reduce(function(a, d) {
            if (!d.value) return a;
            if (a) a+=",";
            return a+d.id;
          }, "");
          */
         
        },
        deep: true
    },
    startSection:function(newValue) {
      if (!newValue) return;
      console.log("startSection")
      var triggerEl = document.querySelector('.'+newValue)
      var tab = bootstrap.Tab.getOrCreateInstance(triggerEl);
      tab.show();
    },
  },
  components: {
    ContentTypeChooser
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-nav-link.selected {
    color: #00628b;
    pointer-events: none;
}


</style>
