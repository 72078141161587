
<script>

import {VcWidgetHelper} from './common_widget.js'
//import * as d3 from 'd3';
const loadD3 = () => import('d3')

export default {
  name: 'multi-period-summary-widget',
  props:{
    weatherData:Object,
    parentData:Boolean,
    views:Array,
    queryDefn:Object,
    selectedPeriod:String,
    config:Object,
  },
  data() {
    return {
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      options:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      viewincludes:this.views,
      summarykpis:[],
      onresize:null,
      dayIndex:null,
      maxDayIndex:null,
      hourIndex:null,
      period:this.selectedPeriod || "day",
      MARGIN:{top: 5, right: 40, bottom:20, left:25},
    }
  },
  methods:{
   
    refreshData() {
      var me=this;
      if (me.parentData) return;
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    options:me.options,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:me.forceRefresh
                })
      .then(response => {
        var data=response.data;
       
        VcWidgetHelper.processData(data);  
        me.wxdata=data;
        if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
        

        if (  me.wxdata && me.wxdata.days && me.wxdata.days.length<4 && me.wxdata.days[0] && me.wxdata.days[0].hours) {
          me.period="hours";
        }



      }).catch((error) => {
          me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    showPrecip(value) {
      return value && (value.precip>0 && value.source==="obs") || (value.precipprob>30);
    },
    formatPrecip(value) {
      var me=this;
      if (value.source==="obs") {
        return me.formatValue(value.precip,"precip");
      }
      return me.formatValue(value.precipprob,"precipprob");
    },
    formatValue(value, element) {
      var me=this;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup );
    },
    formatTime(period) {
      if (!period) return "-";
      if (typeof period==="number") {
        return VcWidgetHelper.formatTime(period, this.wxdata.timezone);
      } else {
        return VcWidgetHelper.formatTime(period.datetimeEpoch, this.wxdata.timezone);
      }
    },
    formatDoW(period) {
      if (!period) return "-";
      if (typeof period==="number") {
        return VcWidgetHelper.formatDoW(period, this.wxdata.timezone);
      } else {
        return VcWidgetHelper.formatDoW(period.datetimeEpoch, this.wxdata.timezone);
      }
      
    },
    formatDateShort(period) {
      if (!period) return "-";
      if (typeof period==="number") {
        return VcWidgetHelper.formatDateMonth(period, this.wxdata.timezone);
      } else {
        return VcWidgetHelper.formatDateMonth(period.datetimeEpoch, this.wxdata.timezone);
      }
      
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    renderCharts() {
      console.log("renderCharts");
      var me=this;
      loadD3().then(d3=>{
        if (!window.d3) window.d3=d3;
        
        var containers = d3.select(this.$el).selectAll(".chartcontainer");
        
        containers.each(function(d, i) {
          //console.log("chartcontainer "+i);
          d=d3.select(this);
          if (d.classed("temp")) {
            me.renderTempChart(d);
          } else if (d.classed("precip")) {
            me.renderPrecipChart(d);
          } else if (d.classed("wind")) {
            me.renderWindChart(d);
          } else if (d.classed("degreedays")) {
            me.renderDegreeDayChart(d);
          } else if (d.classed("periodsummary")) {
            me.renderPeriodSummary(d);
          } else {
            console.log("Unknown chart type container: "+d.attr("class"));
            d.html("Unknown chart type container: "+d.attr("class"));
          }
        });
      })

     
      
    },
    prepareContainer(container) {
      
      var svg=container.select("svg");
      var rect=svg.node().getBoundingClientRect();
      var margin=this.MARGIN;
      var width = rect.width-(margin.left+margin.right);
      var height = rect.height-(margin.top+margin.bottom);
      
      //svg.attr("width", rect.width)
      //svg.attr("height", rect.height)
      //svg.attr("viewBox", "0 0 "+rect.width+" "+rect.height)

      if (container.select("svg .main").empty()) {
        
        

        svg.append("g")
          .attr("class", "y-axis axis")
          .attr("transform",
            "translate("+margin.left+"," + margin.top + ")")
          
        svg.append("g")
          .attr("class", "y-axis2 axis")
         
        
        svg.append("g")
          .attr("class", "x-axis axis")
          
        svg.append("g")
          .attr("class", "main")
          .attr("transform",
            "translate("+margin.left+"," + margin.top + ")");

        svg.select(".main").append("rect")
          .style("opacity","0")
          .attr("width", width+"px")
          .attr("height", height+"px")

      }

      svg.select(".x-axis")
        .attr("transform","translate("+margin.left+", " + (height+margin.top) + ")");
      
      svg.select(".y-axis2")
        .attr("transform", "translate(" + (width+margin.left) + "," + margin.top + ")")

     

      return {
        svg: svg.select(".main"),
        width: width,
        height: height
      }
    },
    renderPeriodSummary(container) {

      //console.log("renderPeriodSummary");
      var me=this;
      var data=me.periods;

      if (!data) {
        console.log("no data");
        return;
      }
      var chartContainer=container.select(".chart");
      var rect=chartContainer.node().getBoundingClientRect();
      var margin = {top: 75, right: 0, bottom:0, left:0} ; 
      var width = rect.width-(margin.left+margin.right);
      var height = rect.height-(margin.top+margin.bottom);


      var minWidth=me.isHourly?40:50;
      var timeextent=d3.extent(data, function(d) { return d.datetimeInstance; });

      var periodCount=me.isHourly?d3.timeHour.count(timeextent[0],timeextent[1]):d3.timeDay.count(timeextent[0],timeextent[1]);
      console.log("periodCount3="+periodCount);
      if (!periodCount) {
         return;
      }
      var periodWidth=width/(periodCount+1);
      if (periodWidth<minWidth) periodWidth=minWidth;
      var summaryPeriodInterval=Math.round(minWidth/periodWidth);
      //if the forecast periods (days or hours) will be too small, set them to a minimum size
      //in this case the periods will be draggable to view periods out of view
      //if (periodWidth<minWidth) {
      //    periodWidth=minWidth;
     // }

      width=periodWidth*(periodCount);
      chartContainer.style("width",(width+periodWidth)+"px")

      container.selectAll(".period")
        .style("visibility", function (d,i) {
          return (i%summaryPeriodInterval===0)?"visible":"hidden";
        })
        .style("left",  function (d,i) {
          return (i*periodWidth)+"px";
        })
        .style("width", periodWidth+"px")
      //set up the x axis representing time
      var x = d3.scaleTime().range([0, width]);
      x.domain(timeextent);
      
      var chartDrawing;
      if (chartContainer.select("g").empty()) {
          var chartRoot=chartContainer; 

          chartRoot.append("clipPath")
              .attr("id", "clippath")   
              .append("rect")
                  .attr("x",0)
                  .attr("y",0)
                  .attr("width",0)
                  .attr("height",0)


          chartRoot=chartRoot.append("g")
              .attr("transform",
                  "translate(" + margin.left + "," + margin.top + ")")
              .attr("clip-path","url(#clippath)")

          
          chartDrawing=chartRoot.append("g")
              .attr("class", "container")
          

          chartDrawing.append("path")
              .attr("class", "line")

          chartDrawing.append("path")
              .attr("class", "precipline")

          chartDrawing.append("path")
              .attr("class", "precipprobline")

          chartDrawing.append("g")
              .attr("class", 'bars')


          chartDrawing.append("linearGradient")
              .attr("id", "temperature-gradient")
              .attr("gradientUnits", "userSpaceOnUse")
              
          chartDrawing.append("g")			
              .attr("class", "grid")

          
      } else {
          chartDrawing=chartContainer.select(".container");
      }

      chartContainer.select("#clippath rect")
          .attr("x",-margin.left)
          .attr("width", (width+periodWidth))
          .attr("height", rect.height);

      chartDrawing.attr("transform", null);

      //set up vertical y scales for the temperature and precipitation fields
      var y = d3.scaleLinear().range([height, 0]);
      var yprecip = d3.scaleLinear().range([height, height*0.6]);
      var yprecipprob = d3.scaleLinear().range([height, height*0.6]);
      //area creation function for the temperature 
      var temparea = d3.area()
          .x(function(d, i) {
             return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
            /* 
              if (i===0) {
                  return x(d.datetimeInstance) 
              } else if (i===data.length-1) {
                  return x(me.isHourly?d3.timeHour.offset( d.datetimeInstance,1):d3.timeHour.offset( d.datetimeInstance,24));
              } else {
                  return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
              }
              */
          })
          .y0(height)
          .y1(function(d) { return y((me.isHourly?d.temp:d.tempmax) || 0); })
          .curve(d3.curveCatmullRom)  
      

      var preciparea = d3.area()
          .x(function(d, i) { 
            return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
             /* if (i===0) {
                  return x(d.datetimeInstance) 
              } else if (i===data.length-1) {
                  return x(me.isHourly?d3.timeHour.offset( d.datetimeInstance,1):d3.timeHour.offset( d.datetimeInstance,24));
              } else {
                  return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
              }
              */
          })
          .y0(height)
          .y1(function(d) { 
              if (d.source==="fcst") return yprecip(0);
              return yprecip(d.precip || 0);
             })
          .curve(d3.curveCatmullRom)  

      var precipprobarea = d3.area()
          .x(function(d, i) { 
             return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
            /*
              if (i===0) {
                  return x(d.datetimeInstance) 
              } else if (i===data.length-1) {
                  return x(me.isHourly?d3.timeHour.offset( d.datetimeInstance,1):d3.timeHour.offset( d.datetimeInstance,24));
              } else {
                  return x(me.isHourly?d3.timeMinute.offset( d.datetimeInstance,30):d3.timeHour.offset( d.datetimeInstance,12));
              }
              */
          })
          .y0(height)
          .y1(function(d) { 
              if (d.source!=="fcst") return yprecipprob(0);
              return yprecipprob(d.precipprob || 0);
             })
          .curve(d3.curveCatmullRom) 
   

      //scale the temperature scale based on the max and minimum values
      y.domain([d3.min(data, function(d) { return (me.isHourly?d.temp:d.tempmin)-10; }), d3.max(data, function(d) { return (me.isHourly?d.temp:d.tempmax); })]);

      //scale the precipitation scale based on the max and minimum values
      yprecip.domain([0, d3.max(data, function(d) { return d.precip || 0.1; })]);
      yprecipprob.domain([0, 100]);
      
      //create a gradient that maps the colors to particular temperature
      //in this case we will use the turbo colors from d3 and interpolate them between 255 and 311K (approx 0-100F)
      var colorStopCount=10, minTempStop=255, maxTempStop=311;
      var minTempK=VcWidgetHelper.toK(y.domain()[0], me.unitGroup), 
          maxTempK=VcWidgetHelper.toK(y.domain()[1], me.unitGroup);

      var tempInterval= (maxTempK-minTempK)/(colorStopCount+1);
      var colors=[];
      for (var i=0;i<=colorStopCount;i++) {
          //for each gradient stop, find the color in the the turbo palette based on the min and max temp stops
          var t=((minTempK+tempInterval*i)-minTempStop)/(maxTempStop-minTempStop);
          t=Math.max(t,0);
          t=Math.min(t,1);
          colors.push({offset: i*(100/colorStopCount)+"%", color:VcWidgetHelper.turboColor(t)});
        
      }
      //create the gradient stops based on the above stop array
      var stopsContainer=chartDrawing.select("#temperature-gradient").attr("x1", 0).attr("y1", y(y.domain()[0]))
              .attr("x2", 0).attr("y2", y(y.domain()[1]))
              .selectAll("stop")
              .data(colors, function(d) {return d.color})
      stopsContainer.exit().remove();
      stopsContainer=stopsContainer.merge(stopsContainer.enter().append("stop"))
      
      stopsContainer.attr("offset", function(d) { return d.offset; })
              .attr("stop-color", function(d) { return d.color; })
              .attr("stop-opacity", function(d,i) { return 0.5+0.5*i/colors.length; });
      


      //populate the area chart for the temperature view
      chartDrawing.select(".line")
          .data([data])
          //.transition()
          .attr("d", temparea);
      
      chartDrawing.select(".precipline")
          .data([data])
          //.transition()
          .attr("d", preciparea);
      
      chartDrawing.select(".precipprobline")
          .data([data])
          //.transition()
          .attr("d", precipprobarea);

      //draw vertical lines at the boundaries of each period
      chartDrawing.select(".grid")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x)
             // .ticks(d3.timeHour.every(1))
              .ticks(periodCount)
              .tickSize(-height)
              .tickFormat("")
          )

    },
    renderWindChart(container) {

      var me=this;
      var chartContainer=me.prepareContainer(container);

      var width = chartContainer.width;
      var height = chartContainer.height;
      var svg=chartContainer.svg;
      

      var data=me.periods;
      if (!data) return;
      var x = d3.scaleBand()
		  //.padding(0.4);
		
      var y = d3.scaleLinear();
      
      var y1 = d3.scaleLinear();
      
     
      
      x.range([0, width]);
      y.range([height, 0]);
      y1.range([height, 0]);
      
      x.domain(data.map(function(d) { return d.datetimeEpoch; }));
      y.domain([0, d3.max(data, function(d) { return d.windgust || d.windspeed; })]);
      y1.domain([d3.min(data, function(d) { return d.pressure; })-5, d3.max(data, function(d) { return d.pressure; })+5]);
      me.setUpTooltip(container,data,x,height,function(d) {
        var html="<div class='datetime'>"+(me.isHourly?me.formatTime(d):me.formatDateShort(d))+"</div>";
        if (me.isHourly) {
          html+="<div><div class='title'>Wind</div><div class='value'>"+me.formatValue(d.windspeed,'wind')+"</div></div>";
          html+="<div><div class='title'>Gust</div><div class='value'>"+me.formatValue(d.windgust,'wind')+"</div></div>";
        } else {
          html+="<div><div class='title'>Wind</div><div class='value'>"+me.formatValue(d.windspeed,'wind')+"</div></div>";
          html+="<div><div class='title'>Gust</div><div class='value'>"+me.formatValue(d.windgust,'wind')+"</div></div>";
        }
        return html;
      });
     

      var u;
     
      var barwidth=Math.min(x.bandwidth(), 25);
      var buffer=(x.bandwidth()-barwidth)/2;

      u=svg.selectAll(".windgustbar")
        .data(data.filter(function(d,i){ return d.windgust}));
        
      u.exit().remove();
      
      u.enter().append("rect")
          .attr("class", "windgustbar")
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { return y(d.windgust); })
          .attr("height", function(d) { return height - y(d.windgust); })
          .merge(u)
        //  .transition()
        //  .duration(500)
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { return y(d.windgust); })
          .attr("height", function(d) { return height - y(d.windgust); });
      
      
      u=svg.selectAll(".windspeedbar")
        .data(data.filter(function(d,i){ return d.windspeed}));
        
      u.exit().remove();
      u.enter().append("rect")
          .attr("class", "windspeedbar")
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { 
            return y( d.windspeed); 
          })
          .attr("height", function(d) { return height - y(d.windspeed); })
          .merge(u)
        //  .transition()
        //    .duration(500)
            .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("width", barwidth)
            .attr("y", function(d) {
               return y(d.windspeed); 
            })
            .attr("height", function(d) { return height - y(d.windspeed); });
      
      
      var slpline = d3.line()
        .curve(d3.curveBasis)
        .defined(function(d) { return d.pressure;})
        .x(function(d) { return x(d.datetimeEpoch)+x.bandwidth()/2; })
        .y(function(d) { return y1(d.pressure); });
      
      u = svg.selectAll(".slpline")
        .data([data]);

      u.enter()
        .append("path")
        .attr("class","slpline")
        .attr("d",  slpline(data))
        .merge(u)
      //  .transition()
       // .duration(500)
        .attr("d",  slpline(data))
      
      
      

      var ylabelcount=height/30;
      
      var pressureFormatter=d3.format(''); //no 1000 separator

      var yAxis = d3.axisLeft(y).ticks(ylabelcount).tickSize(-width);
      var yAxis2 = d3.axisRight(y1).ticks(ylabelcount).tickFormat(function(d) {	return pressureFormatter(d); });
      
      svg=d3.select(svg.node().parentNode);
      svg.select(".y-axis").call(yAxis);
      svg.select(".y-axis2").call(yAxis2);
      
      
      var xlabelcount=width/50;
      xlabelcount=Math.round(data.length/xlabelcount);

      var xAxis = d3.axisBottom(x).tickFormat(function(d) {	
        return me.isHourly?me.formatTime(d):me.formatDateShort(d);
        })
        .tickValues(x.domain().filter(function(d,i){ return !(i%xlabelcount)})).tickSize(-height);;
      
      svg.select(".x-axis").call(xAxis);


      var legendItems=[
        {
          "label":"Wind",
          "class":"windspeedbar"
        },
        {
          "label":"Gust",
          "class":"windgustbar"
        },
        {
          "label":"Pressure",
          "class":"slpline"
        }
      ];
      legendItems.title="Wind";
      me.renderLegend(container, legendItems);
     },

     renderDegreeDayChart(container) {

      var me=this;
      var chartContainer=me.prepareContainer(container);

      var width = chartContainer.width;
      var height = chartContainer.height;
      var svg=chartContainer.svg;
      

      var data=me.daysperiods;
      if (!data) return;
      var x = d3.scaleBand()
		  //.padding(0.4);
		
      var y = d3.scaleLinear();
      
      var y1 = d3.scaleLinear();
      
     
      
      x.range([0, width]);
      y.range([height, 0]);
     // y1.range([height, 0]);
      
      x.domain(data.map(function(d) { return d.datetimeEpoch; }));
      y.domain([0, d3.max(data, function(d) { return d.accdegreedays; })]);
     // y1.domain([d3.min(data, function(d) { return d.accdegreedays; })-5, d3.max(data, function(d) { return d.accdegreedays; })+5]);
      me.setUpTooltip(container,data,x,height,function(d) {
        var html="<div class='datetime'>"+(me.isHourly?me.formatTime(d):me.formatDateShort(d))+"</div>";
        html+="<div><div class='title'>Degree days</div><div class='value'>"+me.formatValue(d.degreedays,'degreedays')+"</div></div>";
        html+="<div><div class='title'>Accumulated Degree days</div><div class='value'>"+me.formatValue(d.accdegreedays,'degreedays')+"</div></div>";
        
        return html;
      });
     

      var u;
     
      var barwidth=Math.min(x.bandwidth(), 25);
      var buffer=(x.bandwidth()-barwidth)/2;

      u=svg.selectAll(".degreedaysbar")
        .data(data.filter(function(d,i){ return d.degreedays}));
        
      u.exit().remove();
      
      u.enter().append("rect")
          .attr("class", "degreedaysbar")
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { return y(d.degreedays); })
          .attr("height", function(d) { return height - y(d.degreedays); })
          .merge(u)
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { return y(d.degreedays); })
          .attr("height", function(d) { return height - y(d.degreedays); });
      
      
      u=svg.selectAll(".accdegreedaysbar")
        .data(data.filter(function(d,i){ return d.accdegreedays}));
        
      u.exit().remove();
      

      u.enter().append("rect")
          .attr("class", "accdegreedaysbar")
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { 
            return y( d.accdegreedays); 
          })
          .attr("height", function(d) { return height -y(d.degreedays);; })
          .merge(u)
            .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("width", barwidth)
            .attr("y", function(d) {
               return y(d.accdegreedays); 
            })
            .attr("height", function(d) { return y(d.degreedays) -y(d.accdegreedays); });
      
      
      
      
      

      var ylabelcount=height/30;
      
      var pressureFormatter=d3.format(''); //no 1000 separator

      var yAxis = d3.axisLeft(y).ticks(ylabelcount).tickSize(-width);
      //var yAxis2 = d3.axisRight(y1).ticks(ylabelcount).tickFormat(function(d) {	return pressureFormatter(d); });
      
      svg=d3.select(svg.node().parentNode);
      svg.select(".y-axis").call(yAxis);
      //svg.select(".y-axis2").call(yAxis2);
      
      
      var xlabelcount=width/50;
      xlabelcount=Math.round(data.length/xlabelcount);

      var xAxis = d3.axisBottom(x).tickFormat(function(d) {	
        return me.isHourly?me.formatTime(d):me.formatDateShort(d);
        })
        .tickValues(x.domain().filter(function(d,i){ return !(i%xlabelcount)})).tickSize(-height);;
      
      svg.select(".x-axis").call(xAxis);


      var legendItems=[
        {
          "label":"Degree Days",
          "class":"degreedaysbar"
        },
        {
          "label":"Accumulated Degree Days",
          "class":"accdegreedaysbar"
        },
      ];
      legendItems.title="Degree days";
      me.renderLegend(container, legendItems);
     },
    renderPrecipChart(container) {

      var me=this;
      var chartContainer=me.prepareContainer(container);

      var width = chartContainer.width;
      var height = chartContainer.height;
      var svg=chartContainer.svg;
      

      var data=me.periods;


      if (!data) return;

      var x = d3.scaleBand()
		    //.padding(0.4);
		
      var y = d3.scaleLinear();
      var y1 = d3.scaleLinear();
      
      
      x.range([0, width]);
      y.range([height, 0]);
      y1.range([height, 0]);
      
      x.domain(data.map(function(d) { return d.datetimeEpoch; }));

      var maxsum=d3.max(data, function(d) { return d3.max([d.precipsumnormal, d.precipsum, d.precip*1.5, 0]); });

      //y.domain([0, d3.max(data, function(d) { return d.precip*1.5; })]);
      
      y.domain([0, maxsum]);
      y1.domain([0, maxsum]);
      
      me.setUpTooltip(container,data,x,height,function(d) {
        var html="<div class='datetime'>"+(me.isHourly?me.formatTime(d):me.formatDateShort(d))+"</div>";
        if (me.isHourly) {
          html+="<div><div class='title'>Precip</div><div class='value'>"+me.formatValue(d.precip,'precip')+"</div></div>";
          html+="<div><div class='title'>Snow</div><div class='value'>"+me.formatValue(d.snow,'snow')+"</div></div>";
        } else {
          html+="<div><div class='title'>Precip</div><div class='value'>"+me.formatValue(d.precip,'precip')+"</div></div>";
          html+="<div><div class='title'>Snow</div><div class='value'>"+me.formatValue(d.snow,'snow')+"</div></div>";
        }
        return html;
      });
      
      
      var u
      var preciparea = d3.area()
       // .curve(d3.curveBasis)
        .x(function(d) { return x(d.datetimeEpoch)+x.bandwidth()/2; })
        .y0(function(d) { return y1(0); })
        .y1(function(d) { 
          return y1(d.precipsum?d.precipsum:0);
         });
      
      u = svg.selectAll(".preciparea")
        .data([data]);

      u.enter()
        .append("path")
        .attr("class","preciparea")
        .attr("d",  preciparea(data))
        .merge(u)
      //  .transition()
      //  .duration(500)
        .attr("d",  preciparea(data))

      preciparea = d3.line()
        .curve(d3.curveBasis)
        .x(function(d) { return x(d.datetimeEpoch)+x.bandwidth()/2; })
        .y(function(d) { 
          return y1(d.precipsumnormal?d.precipsumnormal:0);
         });
      
      u = svg.selectAll(".precipnormalarea")
        .data([data]);

      u.enter()
        .append("path")
        .attr("class","precipnormalarea")
        .attr("d",  preciparea(data))
        .merge(u)
      //  .transition()
      //  .duration(500)
        .attr("d",  preciparea(data))


      var barwidth=Math.min(x.bandwidth(), 25);
      var buffer=(x.bandwidth()-barwidth)/2;

      u=svg.selectAll(".precipbar")
        .data(data.filter(function(d) {
          return d.precip>0;
        }));
        
      u.exit().remove();
      
      u.enter().append("rect")
          .attr("class", "precipbar")
          .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("width", barwidth)
          .attr("y", function(d) { return y(d.precip); })
          .attr("height", function(d) { return height - y(d.precip); })
          .merge(u)
          //  .transition()
          //  .duration(500)
              .attr("x", function(d) { return x(d.datetimeEpoch)+buffer; })
              .attr("width",barwidth)
              .attr("y", function(d) { return y(d.precip); })
              .attr("height", function(d) { return height - y(d.precip); });
      
      
      /*
      var annotations=[{
          "text":data[data.length-1].precipsum?me.formatValue(data[data.length-1].precipsum, "precip"):"No precipitation reported",
          "x":width-10,
          "y":10
        }
      ]
      
      u = svg.selectAll(".annotation").data(annotations);
      u.exit().remove();
      u.enter()
        .append("text")
        .attr("class","annotation")
        .merge(u)
        .transition()
        .duration(500)
        .attr("x", function(d) {return d.x;})
        .attr("y",function(d) {return d.y;})
        .text(function(d) { return d.text; });
      
      */
      var ylabelcount=height/30;
      

      var yAxis = d3.axisLeft(y).ticks(ylabelcount).tickSize(-width);
      
      d3.select(svg.node().parentNode).select(".y-axis").call(yAxis);

      var yAxis2 = d3.axisRight(y1).ticks(ylabelcount);
      
      
      svg=d3.select(svg.node().parentNode);
      svg.select(".y-axis").call(yAxis);
      svg.select(".y-axis2").call(yAxis2);
      
      
      var xlabelcount=width/50;
      xlabelcount=Math.round(data.length/xlabelcount);

      var xAxis = d3.axisBottom(x).tickFormat(function(d) {	return me.isHourly?me.formatTime(d):me.formatDateShort(d); })
        .tickValues(x.domain().filter(function(d,i){ return !(i%xlabelcount)})).tickSize(-height);;
      
      svg.select(".x-axis").call(xAxis);


      var legendItems=[
			   {
          "label":"Precipitation",
          "class":"precipbar"
        },
        {
          "label":"Total",
          "class":"preciparea"
        }];

      if (me.hasNormals) {
        legendItems=legendItems.concat([
          {
            "label":"Average",
            "class":"precipnormalarea"
          }
        ])
      }

      legendItems.title="Precipitation";
    
      me.renderLegend(container, legendItems);
    },
    renderTempChart(container) {
      var me=this;
      var chartContainer=me.prepareContainer(container);

      var width = chartContainer.width;
      var height = chartContainer.height;
      var svg=chartContainer.svg;
      

      var x = d3.scaleBand();
      var y = d3.scaleLinear();
      var data=me.periods;

      if (!data) return;
      x.range([0, width]);
      y.range([height, 0]);
      
      
      x.domain(data.map(function(d) { return d.datetimeEpoch; }));
      y.domain([d3.min(data, function(d) { 
          return d3.min([d.temp,d.tempmin, d.feelslike, d.feelslikemin, VcWidgetHelper.getSafeNormal(d, "tempmin",0)])-3;
        }), 
        d3.max(data, function(d) { 
          return d3.max([d.temp,d.tempmax, d.feelslike, d.feelslikemax, VcWidgetHelper.getSafeNormal(d, "tempmax",2)])+3; 
        })]
      );
      
       var ylabelcount=height/30;
      
      var yAxis = d3.axisLeft(y).ticks(ylabelcount).tickSize(-width);
      
      d3.select(svg.node().parentNode).select(".y-axis").call(yAxis);
      
      var xlabelcount=width/50;
      xlabelcount=Math.round(data.length/xlabelcount);

      var xAxis = d3.axisBottom(x).tickFormat(function(d, i) {	return me.isHourly?me.formatTime(d):me.formatDateShort(d); }) //d.datetime;
        .tickValues(x.domain().filter(function(d,i){ return !(i%xlabelcount)})).tickSize(-height);
      
      
      d3.select(svg.node().parentNode).select(".x-axis").call(xAxis);
      me.setUpTooltip(container,data,x,height,function(d) {
         var html="<div class='datetime'>"+(me.isHourly?me.formatTime(d):me.formatDateShort(d))+"</div>";
        if (me.isHourly) {
          html+="<div><div class='title'>Temp</div><div class='value'>"+me.formatValue(d.temp,'temp')+"</div></div>";
          html+="<div><div class='title'>Feels like</div><div class='value'>"+me.formatValue(d.feelslike,'temp')+"</div></div>";
        } else {
          html+="<div><div class='title'>Max</div><div class='value'>"+me.formatValue(d.tempmax,'temp')+"</div></div>";
          html+="<div><div class='title'>Min</div><div class='value'>"+me.formatValue(d.tempmin,'temp')+"</div></div>";
          html+="<div><div class='title'>Feels Max</div><div class='value'>"+me.formatValue(d.feelslikemax,'temp')+"</div></div>";
          html+="<div><div class='title'>Feels Min</div><div class='value'>"+me.formatValue(d.feelslikemin,'temp')+"</div></div>";
          if (VcWidgetHelper.getSafeNormal(d, "tempmax", 1, true)) {
            html+="<div class='sectiontitle'>Normals</div>";
            html+="<div><div class='title'>Max</div><div class='value'>"+me.formatValue(d.normal.tempmax[1],'temp')+"</div></div>";
            html+="<div><div class='title'>Min</div><div class='value'>"+me.formatValue(d.normal.tempmin[1],'temp')+"</div></div>";
          }
        }
        return html;
      });

      var u;
      
      
          
      //x.padding(0.0);
      
      u=svg.selectAll(".normalbar")
        .data(data.filter(function(d) {
          return VcWidgetHelper.getSafeNormal(d, me.isHourly?"temp":"tempmax", 1, true)
        }));
      u.exit().remove();
      u.enter().append("rect")
          .attr("class", "normalbar")
          .attr("x", function(d) { return x(d.datetimeEpoch); })
          .attr("width", x.bandwidth())
          .attr("y", function(d) { return y(d.normal.tempmax[1]); })
          .attr("height", function(d) { return y(d.normal.tempmin[1]) - y(d.normal.tempmax[1]); })
          .merge(u)
        //  .transition()
        //  .duration(500)
          .attr("x", function(d) { return x(d.datetimeEpoch); })
          .attr("width", x.bandwidth())
          .attr("y", function(d) { return y(d.normal.tempmax[1]); })
          .attr("height", function(d) { return y(d.normal.tempmin[1]) - y(d.normal.tempmax[1]); });  
      
      u=svg.selectAll(".maxbar")
        .data(data.filter(function(d) {
          return VcWidgetHelper.getSafeNormal(d, me.isHourly?"temp":"tempmax", 2, true)
        }));
      u.exit().remove();
      u.enter().append("rect")
          .attr("class", "maxbar")
          .attr("x", function(d) { return x(d.datetimeEpoch); })
          .attr("width", x.bandwidth())
          .attr("y", function(d) { return y(d.normal.tempmax[2]); })
          .attr("height", function(d) { return y(d.normal.tempmax[1]) - y(d.normal.tempmax[2]); })
          .merge(u)
         // .transition()
         // .duration(500)
          .attr("x", function(d) { return x(d.datetimeEpoch); })
          .attr("width", x.bandwidth())
          .attr("y", function(d) { return y(d.normal.tempmax[2]); })
          .attr("height", function(d) { return y(d.normal.tempmax[1]) - y(d.normal.tempmax[2]); }); 
          
      u=svg.selectAll(".minbar")
        .data(data.filter(function(d) {
          return  VcWidgetHelper.getSafeNormal(d, "tempmin", 0, true)
        }));
      u.exit().remove();
      u.enter().append("rect")
          .attr("class", "minbar")
          .attr("x", function(d) { return x(d.datetimeEpoch); })
          .attr("width", x.bandwidth())
          .attr("y", function(d) { return y(d.normal.tempmin[1]); })
          .attr("height", function(d) { return y(d.normal.tempmin[0]) - y(d.normal.tempmin[1]); })
          .merge(u)
        //  .transition()
        //  .duration(500)
            .attr("x", function(d) { return x(d.datetimeEpoch); })
            .attr("width", x.bandwidth())
            .attr("y", function(d) { return y(d.normal.tempmin[1]); })
            .attr("height", function(d) { return y(d.normal.tempmin[0]) - y(d.normal.tempmin[1]); }); 
      
      

      var buffer=Math.floor(x.bandwidth()*0.5);
      var barwidth=Math.max(Math.min(Math.floor(x.bandwidth()*0.7), 25), 1)+"px";
      var feelslikewidth=barwidth;
      var actualwidth=barwidth;


      u=svg.selectAll(".feelslikebar")
        .data(data.filter(function(d) {
          return   me.isHourly?d.feelslike:(d.feelslikemin && d.feelslikemax);
        }));
        u.exit().remove();

      if (me.isHourly) {
        u.enter().append("line")
          .attr("class", "feelslikebar")
          .attr("stroke-width", 8)
          .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y1", function(d) { return y(d.feelslike); })
          .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y2", function(d) { return y(d.feelslike)+8; })
          .merge(u)
            .attr("stroke-width", 8)
            .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y1", function(d) { return y(d.feelslike); })
            .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y2", function(d) { return y(d.feelslike)+8; })
      } else {
         u.enter().append("line")
          .attr("class", "feelslikebar")
          .attr("stroke-width", feelslikewidth)
          .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y1", function(d) { return y(d.feelslikemin); })
          .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y2", function(d) { return y(d.feelslikemax); })
          .merge(u)
            .attr("stroke-width", feelslikewidth)
            .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y1", function(d) { return y(d.feelslikemin); })
            .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y2", function(d) { return y(d.feelslikemax); })
      }
     
          

    
     u=svg.selectAll(".tempbar")
        .data(data.filter(function(d) {
          return me.isHourly?d.temp:(d.tempmax && d.tempmin);
        }));

      u.exit().remove();
      
      if (me.isHourly) {
        u.enter().append("line")
          .attr("class", "tempbar")
          .attr("stroke-width", 8)
          .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y1", function(d) { return y(d.temp); })
          .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y2", function(d) { return y(d.temp)+8; })
          .merge(u)
            .attr("stroke-width", 8)
            .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y1", function(d) { return y(d.temp); })
            .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y2", function(d) { return y(d.temp)+8; })
      } else {
        u.enter().append("line")
          .attr("class", "tempbar")
          .attr("stroke-width", actualwidth)
          .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y1", function(d) { return y(d.tempmax); })
          .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
          .attr("y2", function(d) { return y(d.tempmin); })
          .merge(u)
            .attr("stroke-width", actualwidth)
            .attr("x1", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y1", function(d) { return y(d.tempmax); })
            .attr("x2", function(d) { return x(d.datetimeEpoch)+buffer; })
            .attr("y2", function(d) { return y(d.tempmin); })
      }
      
      
      

      var legendItems=[
			  {
          "label":"Temperature",
          "class":"tempbar"
        },
        {
          "label":"Feels like",
          "class":"feelslikebar"
        }];

      if (me.hasNormals && !me.isHourly) {
        legendItems=legendItems.concat([
          {
            "label":"Normal",
            "class":"normalbar"
          },
          {
            "label":"Maximum",
            "class":"maxbar"
          },
          {
            "label":"Minimum",
            "class":"minbar"
          }
        ])
      }

      legendItems.title="Temperature";
    
      me.renderLegend(container, legendItems);
     
    },
    setUpTooltip(container,data,x,height,htmlGenerator) {
      var me=this;
      var svg=container.select("svg");
      var tooltip=container.select(".tooltip");

      var showHandler=function(event) {
        var x0 =(d3.pointer(event)[0]-me.MARGIN.left)/x.step();
        x0=Math.floor(x0);
        if (x0<0 || x0>data.length-1) {
          return;

        }
        var d=data[x0];

        me.showTooltip(x(d.datetimeEpoch)+x.bandwidth()/2,height, tooltip, htmlGenerator(d));
      }
      svg.on("mousemove", function(event) {
          showHandler(event);
        })
        .on("touchstart", function(event) {
          showHandler(event);
          if (event.preventable) event.preventDefault();
        })
       svg.on("mouseout", function() {
          me.hideTooltip(tooltip);
        })
        .on("touchend", function(event) {
          me.hideTooltip(tooltip);
        })
    },
    showTooltip(focusX, focusY, tooltip, html) {
        if (!html) html="";

        focusX+=this.MARGIN.left
        //var tooltip=d3.select(selector);

        tooltip.style("visibility", "visible")
                .style("opacity", "1")
                .style("left", (focusX) + "px")
                .style("top",(focusY+5) + "px");
                       
         tooltip=tooltip.select(".contents")
         tooltip.html(html);    

         

        return tooltip;
    },

    hideTooltip(tooltip) {
      tooltip.style("visibility", "hidden")
    },
    renderLegend(container, legendItems) {
      var u=container.select(".legend").selectAll(".item")
        .data(legendItems);
      u.exit().remove();

      var g=u.enter()
        .append("div")
          .attr("class", "item")

      g.append("div")
          .attr("class", function(d) {return "rect "+d.class;})

      g.append("div")
          .text(function(d) {return d.label;})
    }
  },
  computed:{
    periods() {
      var me=this;
      if (!me.wxdata || !me.wxdata.days) return null;
      if (me.period==="hours") {
        return me.wxdata.days.reduce(function(a,d,i) {
          if ((me.dayIndex || me.dayIndex===0) && i!==me.dayIndex) return a;
          if ((me.maxDayIndex || me.maxDayIndex===0) && i>maxDayIndex) return a;
          if (!d.hours) return a;
          return a.concat(d.hours);
        }, [])
      }

      return me.wxdata.days;
    },

    daysperiods() {
      var me=this;
      if (!me.wxdata || !me.wxdata.days) return null;
     
      return me.wxdata.days;
    },

    isHourly() {
      return this.period==='hours';
    },
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation( this.location, this.name);
    },
    hasError:function() {
      return this.error;
    },
    hasData:function() {
      return this.focusDay;
    },
    focusDay:function() {
      var me=this;
      return me.wxdata && me.wxdata.days && me.wxdata.days[0];
    },
    showAlerts:function() {
      return this.alerts && this.alerts.length!==0;
    },
    alerts:function() {
        var me=this;
        return me.wxdata && me.wxdata.alerts;
    },
    summarykpicount:function() {
        return this.summarykpis?this.summarykpis.length:0;
    },
    showSummary() {
      return this.doShow('icon') || this.summarykpicount
    },
    summaryCssClasses() {
      if (this.doShow('icon')) return 'summarykpis'+this.summarykpicount
      return 'summarykpis'+this.summarykpicount+" noicon"; 
    },
    hasNormals() {
      return VcWidgetHelper.hasNormals(this.wxdata);
    },
    
    
  },
  created() {
    var me=this;
    me.onresize=VcWidgetHelper.debounce(function () {
        me.renderCharts();
    }, 250);

    window.addEventListener("resize", me.onresize);
  },
  destroyed() {
    window.removeEventListener("resize", me.onresize);
  },
  watch: {

    periods:{
        handler:function(newValue,oldValue) {
          var me=this;
          me.renderCharts();
        },
        deep: true
    },
    weatherData:function(newValue,oldValue) {
      var me=this;
     
      VcWidgetHelper.processData(newValue); 
      
      me.wxdata=newValue;
      me.renderCharts();
    },
    selectedPeriod:function(newValue) {
      this.period=newValue;
      this.renderCharts();
    },
    queryDefn:{
        handler:function(newValue,oldValue) {
          var me=this;
          var containerElement=me.$el.parentElement;
          VcWidgetHelper.readElementConfig(containerElement, me);
          me.refreshData();
        },
        deep: true
    },
  },
  mounted() {
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);
    me.refreshData();
  },
  updated: function() {
    var me=this;
   if (me.wxdata) {
      VcWidgetHelper.processData(me.wxdata); 
    }
    me.renderCharts();
  }
}
</script>


<template>
   <div class="widget">
    
    <div class="maingrid">
      <!--<div v-if="!hasData" class="nodata" >
        No data available
      </div>
      <div v-if="hasError" class="haserror" >
        {{error}}
      </div>
  
      <template v-if="hasData">
      -->
        <div class="location">{{adjustedLocation}}</div>
        <template v-for="view in viewincludes">
          <div v-if="view==='periodsummary'" class="chartcontainer" :class="view">
            <svg class="chart" />
            <div class="periods">
              <template v-for="(period,index) in periods">
                <div  class="period">
                  <div v-if="!isHourly" class="dow">{{formatDoW(period)}}</div>
                  <div v-if="!isHourly" class="date">{{formatDateShort(period)}}</div>
                  <div v-if="isHourly" class="time">{{formatTime(period)}}</div>
                  <div class="icon small" ><div :class="period.icon"></div></div>
                  <div v-if="!isHourly" class="daytemp" >
                      <div class="maxt" v-html="formatValue(period.tempmax,'temp')"></div>
                      <div class="mint" v-html="formatValue(period.tempmin,'temp')"></div>
                  </div>
                  <div v-if="isHourly" class="temp" >
                      <div class="temp" v-html="formatValue(period.temp,'temp')"></div>
                  </div>
                  <div v-if="showPrecip(period)" class="precip">
                    <span class="value">{{formatPrecip(period)}}</span></div>
                </div>
              </template>
            </div>
          </div>
          <div v-else class="chartcontainer" :class="view">
            <div class="tooltip"><div class="contents">-</div></div>
            <div class="legend" :class="view"></div>
            <svg />
          </div>
        </template>
      <!--</template>-->
    </div>
  </div>

</template>


<style scoped>
  .tooltip {
    background-color: white;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    transform: translate(-50%, 0%);
    pointer-events: none;
    line-height: 13px;
  }
  .contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap:5px
  }
  .tooltip * {
    text-align: center;
  }
  .tooltip :deep() .datetime {
    font-size: 0.9rem !important;
    text-align: center;
    padding-bottom: 5px;
    font-weight: bold;
    grid-column: 1/-1;
  }
  .tooltip :deep() .sectiontitle {
    text-align: center;
    font-weight: bold;
    grid-column: 1/-1;
  }
   .tooltip :deep() .title {
    color:#9691a4 !important;
    font-size: 0.6rem !important;
    font-weight: normal;
   }
   .tooltip :deep() .value {
    font-size: 0.9rem !important;
    text-align: center;
    padding-bottom: 5px;
   }
  .chartcontainer {
    grid-column: 1/-1;
    min-height:150px;
    display:flex;
    flex-direction: column;
    position:relative;
  }

  .chartcontainer svg {
    flex:1;
  }

  .chartcontainer :deep() .axis  {
    font-size:0.6em;
  }

  .chartcontainer :deep() .axis line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }
  .chartcontainer :deep() .axis  path {
    stroke-width: 0;
  }

  .chartcontainer.periodsummary {
    position:relative;
    min-height: 125px;
    font-size: 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .chartcontainer.periodsummary .chart {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .chartcontainer.periodsummary .chart :deep()  .line {
      fill: url(#temperature-gradient);
      stroke-width: 05px;
  }
  .chartcontainer.periodsummary .chart :deep()  .precipline {
      fill: #045a8d;
      stroke-width: 2px;
  }
  .chartcontainer.periodsummary .chart :deep()  .precipprobline {
      fill: #7cb5d6;
      stroke-width: 2px;
  }
  .chartcontainer.periodsummary .chart :deep() .grid line {
    stroke: darkgray;
    shape-rendering: crispEdges;
  }

  .chartcontainer.periodsummary .periods {
    position: absolute;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:flex;
    flex-direction: row;
    height: 100%;
  }
  .chartcontainer.periodsummary .periods .period {
    position: absolute;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    /* grid-gap: 1px; */
    cursor: grab;
    height: 100%;
    color:#8e8b8b;
    font-size: 1.1em;
    white-space: nowrap;
    cursor:pointer;
  }

  .chartcontainer.periodsummary .periods .period .date {
    font-size: 0.8em;
  }
  .chartcontainer.periodsummary .periods .period .dow {
    font-weight:bold;
  }
  .chartcontainer.periodsummary .periods .period .time {
   
  }
  .chartcontainer.periodsummary .periods .period.hour {
    font-size: 0.8em;
    cursor:default;
  }
		

  .chartcontainer.periodsummary .periods .period.conditions {
    display:none;
    color:#8e8b8b;
    font-size: 0.9em;
  }

  .chartcontainer.periodsummary .periods .period  .maxt {
    font-weight: bold;
  }
  .chartcontainer.periodsummary .periods .period  .mint {
    font-size: 0.9em;
    color:#8e8b8b;
  }
  
  .chartcontainer.periodsummary .periods .period  .hidden {
    visibility: hidden;
    display:none;
  }
  .chartcontainer.periodsummary .periods .period .precip {
    margin-top: auto;
    color: #163753;
    font-size: 0.9em;
  }


   .temp :deep() .normalbar, .temp :deep() .maxbar, .temp :deep() .minbar {
     fill-opacity: 0.5;
   }
  .temp :deep() .normalbar {
    background-color:#a1d76a;
		fill:#a1d76a;
	}
	
	.temp :deep() .maxbar{
    background-color:#ef8a62;
		fill:#ef8a62;
	}
	.temp :deep() .minbar  {
    background-color:#67a9cf;
		fill:#67a9cf;
	}
  .temp :deep() .tempbar {
    background-color:#6b6767;
	  fill: #6b6767;
    stroke: #6b6767;
    /*stroke-linecap: round;*/
	}
  .temp :deep() .feelslikebar {
    background-color:#c262da;
    fill: #c262da;
    stroke: #c262da;
    /*stroke-linecap: round;*/
  }
  .precip :deep() .precipbar {
    background-color:#045a8d;
    fill: #045a8d;
  }
  
  .precip :deep() .preciparea {
    background-color:#ccebc5;
    fill: #ccebc5;
    fill-opacity: 0.7;
  }
  .precip :deep() .precipnormalarea {
    stroke: #045a8d;
    stroke-dasharray: 5px 5px;
    stroke-width: 3px;
    fill: none;
    fill-opacity: 1;
  }
  .precip :deep() div.precipnormalarea {
    height:5px;
    border-top:1px dashed #045a8d;
  }

  .wind :deep() .windgustbar {
    background-color:#fd8d3c;
		fill:#fd8d3c;
	}
	
	.wind :deep() .windspeedbar {
    background-color:#fed976;
		fill:#fed976;
	}
	.wind :deep() .slpline {
		fill:none;
		stroke:#636363;
		stroke-width: 3;
	}

  .wind :deep() div.slpline {
    height:5px;
    border-top:1px solid #636363;
  }


  .degreedays :deep() .degreedaysbar {
    background-color:#41ab5d;
		fill:#41ab5d;
	}
	
	.degreedays :deep() .accdegreedaysbar {
    background-color:#c7e9c0;
		fill:#c7e9c0;
	}

  .degreedays :deep() .annotation {
    text-anchor: end;
    alignment-baseline: hanging;
    font-size: 0.6em;
  }

  .legend {
    display: flex;
    align-content: normal;
    justify-content: flex-start;
    margin: 0px 20px 0px 20px;
    /* height: 25px; */
    flex-wrap: wrap;
  }

   .legend :deep()  .item {
    display:flex;
    font-size: 1.0em;
    font-weight: bold;
    align-items: center;
  }

  .legend :deep() .rect {
    width: 15px;
    height: 15px;
    margin: 1px 3px 1px 8px;
  }

  .legend  :deep() line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    stroke-width:5px;
    shape-rendering: crispEdges;
  }

</style>
