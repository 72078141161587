import Prism from "/node_modules/prismjs/prism.js"


// Import PrismJS extensions
//import '/node_modules/prismjs/themes/prism-dark.css';


import '/node_modules/prismjs/plugins/line-numbers/prism-line-numbers';
import '/node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css';
import '/node_modules/prismjs/components/prism-java';
import '/node_modules/prismjs/components/prism-javascript';
import '/node_modules/prismjs/components/prism-http';
import '/node_modules/prismjs/components/prism-json';
import '/node_modules/prismjs/components/prism-python';
import '/node_modules/prismjs/components/prism-bash';
import '/node_modules/prismjs/components/prism-csv';
import '/node_modules/prismjs/themes/prism.css';
Prism.manual = true;
console.log("Prism config complete");


