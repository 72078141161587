import { VcCommunicator } from "./vccommunicator";
console.log("VcCommunicatorMetadata class")
VcCommunicator.prototype.saveMetadata=function(metadataId,metadataType,name,description, parentFolderId) {
    var me=this;
    var config={
        objectId:metadataId,
        type:metadataType,
        name:name,
        description:description,
        parentFolderId:parentFolderId
    }
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("saveobjectdefinition"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Save failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

    
}
VcCommunicator.prototype.createFolder=function(name,desc,targetFolderId) {
    var me=this;
    var config={
        name:name,
        desc:desc,
        targetFolderId:targetFolderId
    }


    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("createfolder"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Create folder failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

}
VcCommunicator.prototype.copyMetadata=function(metadataId,metadataType) {
    var me=this;
    var config={
        metadataId:metadataId,
        metadataType:metadataType,
        parentFolderId:parentFolderId
    }

    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("copymetadata"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Copy failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

}
VcCommunicator.prototype.moveMetadata=function(metadataId,metadataType, parentFolderId) {
    var me=this;
    var config={
        metadataId:metadataId,
        metadataType:metadataType,
        parentFolderId:parentFolderId
    }

    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("movemetadata"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Move failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

}
VcCommunicator.prototype.deleteMetadata=function(metadata,metadataType) {
    var me=this;
    var config={};
    if (!metadata) throw "Invalid request";
    metadataType =(metadata && metadata.type) ||  metadataType;
    metadata =(metadata && metadata.id) ||  metadata;
   
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("deleteobject", [metadata,metadataType]), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Delete failed");
                    return;
                }
                
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });


}
VcCommunicator.prototype.searchMetadata=function(name,metadataType,parentFolderId) {
    var me=this;
    var config={
        //name:name,
        metadataType:metadataType,
        parentFolderId:parentFolderId
    };
    if (name!==null) config["name"]=name;

    //return new Promise((resolve, reject) => {
    return   me.retrieveData(me.createUrl("searchobjects"), config)
    /*        .then(data => {
                if (data.errorCode) {
                    reject(data.message || "Search failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });*/
}