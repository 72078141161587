

import './weather-data-services.scss'

import "/src/main.js"
import "/src/accountcomponents.js"


import { createApp } from 'vue'

import QueryApp from './QueryApp'

(function(w,d) {

  window.addEventListener("load",function(event) {
    //createApp(PageToolbarComponent).mount("#accountToolbar")
    createApp(QueryApp).mount("#queryApp")
  },false);

})(window, document);
