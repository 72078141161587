<template>
               
              <div class="container-fluid">
                   
                  <div class="d-flex m-2 gap-1">
                     <div class="">
                      <label class="text-muted fs-sm my-1">Language</label>
                       <select class="form-select form-select-sm" v-model="selectedLanguage">
                        <option disabled value="">Please select one</option>
                        <option v-for="(u,i) in availableLanguages" :value="u">{{u.label}}</option>
                      </select>
<!--
                      <button class="dropdown-toggle btn text-primary btn-sm d-block" type="button" id="languageDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" >
                        <i class="bi-code-slash"></i> {{selectedLanguageLabel}}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="languageDropdownButton">
                        <li v-for="u in availableLanguages"  @click="setLanguage(u)"><a class="dropdown-item" href="#" v-html="u.label"></a></li>
                      </ul>
                    -->  
                    </div>
                    <ContentTypeChooser v-if="queryDefinition" :queryDefinition="queryDefinition" />
    
                  <button type="button" class="btn btn-outline-primary btn-sm ms-auto" @click="copyToClipboard()" >
                    <i class="bi-clipboard"></i> Copy
                  </button>
                </div>
              
                <!--
                <div  v-if="isCsv" class="row m-1">
                  <div class="col">Data include</div>
                 
                  <div class="col-12">CSV output can include a single data section</div>
                </div>
                -->
                <div class="row fs-sm" v-html="sampleCodeHtml" style="min-height:300px"></div>
              </div>

</template>

<script>
import {VcQueryLanguageGenerator} from "/../visualcrossing-core-js/src/vcquerylanguagegenerator";
import "/src/prismconfig"
import ContentTypeChooser  from '/src/components/ContentTypeChooser'
//import '/src/VcUtils'
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils'
import {VcClipboardUtils} from '/../visualcrossing-core-js/src/vcclipboardutils'
export default {
  name: 'ApiQuery',
  props: {
     queryDefinition: Object,  
     sessionState:Object,
     location:Object,
  },
  data() {
    return {
     
      selectedLanguage:VcQueryLanguageGenerator.LANGUAGES[0],

    }
  },
  methods:{
    
    copyToClipboard() {
      if (window["gtag"]) {
        gtag('event', 'api_copy_'+this.selectedLanguageLabel, { method : 'Vc' });
      }
      VcClipboardUtils.copyToClipboard(this.sampleCode);
      VcCommonModals.showToast("Sample code copied", "The sample code has been copied to the clipboard")
    },
    setLanguage(v) {
      this.selectedLanguage=v;
    },

  },
  computed:{

    userKey() {
      return this.sessionState && this.sessionState.apiKey
    },
 
    resolvedQueryDefinition() {
      var me=this;
      if (!me.queryDefinition) return null;
      var result=JSON.parse(JSON.stringify(me.queryDefinition));
      if (me.location) {
        result.location=me.location.location;
        result.name=me.location.name;
      }
     
      return result;
    },

    languageStyleclass() {
      
      var lang=(this.selectedLanguage && (this.selectedLanguage.class || this.selectedLanguage.id)) || "none";
      //console.log("language-"+lang);
      return "language-"+lang;
    },
    availableLanguages() {
      return VcQueryLanguageGenerator.LANGUAGES || [];
    },
    
    selectedLanguageLabel(){
      return (this.selectedLanguage && this.selectedLanguage.label) || "Select language";
    },
    sampleCode() {
      var me=this;
      if (!me.selectedLanguage) return "";
      return VcQueryLanguageGenerator.generateCode(me.selectedLanguage.id, me.resolvedQueryDefinition, me.userKey)
    },
    sampleCodeHtml() {
      var me=this;
      var code=me.sampleCode;
      code=code.replaceAll('&','&amp;')
      return `<pre class="line-numbers" ><code class="${me.languageStyleclass}">${code}</code></pre>`;
    }
  },
  watch:{
    
  },
  mounted() {
    var me=this;
   // console.log("Mounted...");
     Prism.highlightAll();
  },
  updated() {

    /*var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    });*/

    Prism.highlightAll();
  },
  components: {
    ContentTypeChooser
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
