import { VcCommunicator } from "./vccommunicator";
import {VcMetadataUtils} from "/../visualcrossing-core-js/src/vcmetadatautils";
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
console.log("VcCommunicatorBulk class")



VcCommunicator.prototype.constructBulkWeatherDataRequest=function(task, apiKey, downloadAsZip) {
    var me=this;


    downloadAsZip=downloadAsZip || false;
    var url=me.createUrl("retrievebulkdataset");
    var data={
        "sessionId":null,
        "key":null,
    	"taskId":task?task.id:null,
        //"taskResultId":result?result.id:null,
        "zip":downloadAsZip
    };
    
    if (apiKey) {
        data.key=me.sessionState && me.sessionState.apiKey;
    } else {
        data.sessionId=me.sessionId;
    }
    
    data=Object.keys(data).reduce(function(a, d) {
        var v=data[d];
        if (v===null) return a;
        return a+"&"+d+"="+v;
    }, "")

   return url+"?"+data;

}
VcCommunicator.prototype.sortResultsByTimeDesc=function(a,b) {
   return VcMetadataUtils.getSafeResultTime(b) - VcMetadataUtils.getSafeResultTime(a) ;
}
VcCommunicator.prototype.processJobPackageResults=function(results) {
    var me=this;
    results && results.forEach(function(taskResults) {
      if (!taskResults || !taskResults.jobPackage ) return; //|| !taskResults.results || !taskResults.results.length
      //me.processJobPackageResultList(taskResults.results, taskResults.jobPackage, taskResults.instance);
      me.processJobPackageResultList(taskResults);
      taskResults.results=taskResults.results && taskResults.results.sort(me.sortResultsByTimeDesc);
      taskResults.jobPackage.results=taskResults.results
      taskResults.jobPackage.instance=taskResults.instance;
    })

}
VcCommunicator.prototype.processJobPackageResultList=function(taskResults) {
    //let results=taskResults.results;
    let jobPackage=taskResults.jobPackage || taskResults.metadata;
   //let instance=taskResults.instance;

    if (taskResults.instance) {
        taskResults.instance.dateTime=VcDateUtils.dateFromIso(taskResults.instance.dateTime);
        taskResults.instance.endDatetime=VcDateUtils.dateFromIso(taskResults.instance.endDatetime);
        taskResults.instance.expirationDateTime=VcDateUtils.dateFromIso(taskResults.instance.expirationDateTime);
        taskResults.results=[taskResults.instance];
    } else {
   
        taskResults.results && taskResults.results.forEach(result => {
            result.runTime=VcMetadataUtils.getTagPrefixValue(result, "RunTime");
            result.dateTime=VcDateUtils.dateFromIso(result.runTime);
            result.taskId=jobPackage && jobPackage.id;
        });
    }
}
VcCommunicator.prototype.resultsByJobPackage=function(id) {
    var me=this;
    var config={
        id:id
    };


    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("resultsbyjobpackage"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "b failed");
                    return;
                }
                //console.log("resultsByJobPackage")
                me.processJobPackageResults(data);


                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });


}

VcCommunicator.prototype.loadBulkSourceDefn=function(id) {
    var me=this;
    var config={
        id:id
    };


    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("loadbulksourcedefn"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "loadbulksourcedefn failed");
                    return;
                }
                
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });


}

VcCommunicator.prototype.loadBulkSourceResultDefn=function(id) {
    var me=this;
    var config={
        id:id
    };


    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("loadbulksourceresultdefn"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "loadbulksourceresultdefn failed");
                    return;
                }
                
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });


}
VcCommunicator.prototype.saveBulkSourceDefn=function(id,name,desc,defn) {
    var me=this;
    var config={
        id:id,
        name:name,
        desc:desc,
        defn:JSON.stringify(defn),
    };
    //console.log(config);
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("savebulksourcedefn"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "savebulksourcedefn failed");
                    return;
                }

                //me.processJobPackageResultList(data.results, data.metadata, data.instance);
                me.processJobPackageResultList(data);
                resolve(data);
            }).catch(error => {
                reject(error); 
            });
    });
    
}
VcCommunicator.prototype.readBulkProcessData=function(id) {
    var me=this;
    var config={
        id:id
    };
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("readbulkprocesseddata"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "readbulkprocesseddata failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });
}
VcCommunicator.prototype.processBulkLocations=function(id,defn) {
    var me=this;

    var config={
        id:id,
        defn:JSON.stringify(defn)
    };
 
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("processbulklocations"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "processbulklocations failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });


}
VcCommunicator.prototype.bulkSourceDataPreview=function(id, callback) {
    var me=this;

    var config={
        id:id
    };
    
    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("bulksourcedatapreview"), config)
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "bulksourcedatapreview failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

    
}

VcCommunicator.prototype.uploadBulkLocations=function(metadataId,metadataName, file,rawLocations,callback) {

    var me=this;
    var formData = new FormData();
    if (file) formData.append('file', file);
    if (rawLocations) formData.append('rawLocations', rawLocations);
    if (me.sessionId) {
        formData.append("sessionId",me.sessionId);
    }
    if (metadataId) {
        formData.append("id",metadataId);
    }
    if (metadataName) {
        formData.append("name",metadataName);
    }

    return new Promise((resolve, reject) => {
        me.retrieveData(me.createUrl("uploadbulklocations"), formData, "multipart")
            .then(data => {
                if (data.errorCode) {
                    reject(data.message || "uploadbulklocations failed");
                    return;
                }
                
                resolve(data.result);
            }).catch(error => {
                reject(error); 
            });
    });

}