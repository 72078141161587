<template>

      <div class="modal fade" id="energyParamsModal" tabindex="-1" aria-labelledby="energyParamsModalLabel" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false">
       <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl" >
        <div class="modal-content"  style="height:80vh;">
            <div class="modal-header">
              <h5 class="modal-title" id="energyParamsModalLabel">Energy Model Parameters</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div class="modal-body overflow-auto relative">
              
              <div class="d-flex flex-column" style="min-height:100%">

                <div class="steps mb-1 mb-lg-3">
                  <template v-for="(step,index) in steps">
                   <a  v-if="index>0" href="#" class="step " :class="[hasCompletedImportStep(step)?['pe-auto','active']:['pe-none'] ]" @click="setImportStep(step)">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">{{index}}</span>
                    </div>
                    <div class="step-label">{{STEPS[step].label}}</div>
                  </a>
                  </template>
                  <!--
                   <a href="#" class="step " :class="[hasCompletedImportStep('intro')?['pe-auto','active']:['pe-none'] ]" @click="setImportStep('intro')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">0</span>
                    </div>
                    <div class="step-label">Intro</div>
                  </a>

                  <a href="#" class="step " :class="[hasCompletedImportStep('rawdata')?['pe-auto','active']:['pe-none'] ]" @click="setImportStep('rawdata')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">1</span>
                    </div>
                    <div class="step-label">Enter Data</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('reviewparse')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('reviewparse')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">2</span>
                    </div>
                    <div class="step-label">Review data</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('fieldConfig1')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('fieldConfig1')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">3</span>
                    </div>
                    <div class="step-label">Configure energy</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('fieldConfig2')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('fieldConfig2')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">4</span>
                    </div>
                    <div class="step-label">Configure dates</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('executeModel')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('executeModel')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">5</span>
                    </div>
                    <div class="step-label">Create model</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('energyCosts')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('energyCosts')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">6</span>
                    </div>
                    <div class="step-label">Energy costs</div>
                  </a>
                  <a href="#" class="step pe-none" :class="[hasCompletedImportStep('submitModel')?['pe-auto','active']:['pe-none'] ]"  @click="setImportStep('submitModel')">
                    <div class="step-progress">
                      <span class="step-progress-start"></span>
                      <span class="step-progress-end"></span>
                      <span class="step-number">7</span>
                    </div>
                    <div class="step-label">Share &amp; Submit model</div>
                  </a>
                  -->
                </div>
                <div v-if="importStep==='intro'" class="mb-3 flex-grow-1">
                  <p>
                    The Winter Energy Calculator is built using models to estimate the energy needed to heat a house. 
                    The calculator uses historical weather data to calculate the Heating Degree Days that a location experiences based on the daily average temperatures.
                    The energy usage model calculates the amount energy as a function of the heating degrees days. 
                  </p>
                  <!--
                  <h4>Preset energy models</h4>
                  <p>
                    We include a set of preset usage models collected from our users. These represent values for typical households collected from our users. 
                    If you would like to calculate how your own bills may change, create your model below.
                  </p>
                  -->
                  <h4>Create your own usage model</h4>
                  <p>
                    If you have a record of your recent usage, you can teach the calculator to calculate your own person energy model.
                    Simply upload a your energy usage and we will calculate how your energy usage varies with your heating needs.
                    To get started, download your energy usage from your energy provider or your smart meter and we will guide you through the process. 
                  </p>
                  <p>
                    Once you are complete, you can choose to share your model with us so we can add your model to our system and help all our users. 
                    No data is shared with our servers unless you explicitely agree. 
                  </p>
                  <div class="col-12 col-lg-6 mb-3">
                    <label for="input-normal" class="form-label">Model will be created for following location</label>
                    <input v-model="selectedLocation.location"  class="form-control" type="text" placeholder="Enter the location" >
                  </div>

                  <!--
                  <div>
                  
                    <button type="button" class="btn btn-primary  ms-auto " @click="createCustomModel()">
                        <i class="bi bi-clipboard"></i> Get started building your model
                    </button>
                   
                  </div>
                   -->
                </div>

                <div v-if="importStep==='rawdata'" class="mb-3 flex-grow-1">
                  <p>
                    To teach your personal energy model, please provide your heating provider energy usage in CSV format. 
                    The usage file should include the date or month of usage and the usage for that period. 
                    If possible include a full year of data so we can identify your base usage when heating is not used.
                  </p>
                  <template v-if="!totalRecords">
                  <div v-if="bulkMode=='file'" >
                      <label for="formFile" class="form-label fs-sm">Provide data in CSV format that includes energy and date columns</label>
                      <input class="form-control" type="file" id="formFile" accept=".csv" @change="addTextFile($event)">
                      <div class="col-12 d-flex">
                        <button  type="button" class="btn btn-sm text-primary ms-auto px-0"  @click="bulkMode='clipboard'" :class="{disabled:false}">
                          <i class="bi bi-clipboard"></i>Import from clipboard
                        </button>
                      </div>
                  </div>
                  <div v-if="bulkMode=='clipboard'" class="pasteTextInput" >
                      <label for="exampleFormControlTextarea1" class="form-label">Provide data in CSV format that includes energy and date columns</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" v-model="pasteRawData" placeholder="Paste data here"></textarea>
                      <div class="col-12 d-flex mt-1 mb-3">
                        <button  type="button" class="btn btn-primary btn-sm ms-auto" :class="{disabled:!pasteRawData}" @click="rawData=pasteRawData">
                          Process Data
                        </button>
                      </div>
                      <div class="col-12 d-flex">
                        <button  type="button" class="btn btn-sm text-primary  ms-auto px-0"  @click="bulkMode='file'" :class="{disabled:false}">
                          <i class="bi bi-file-earmark"></i>Import from file
                        </button>
                      </div>
                  </div>
                  </template>
                  <template v-if="totalRecords">
                    <p>
                      Please the review data we have read from your file to check that it looks good.
                    </p>
                    <div v-if="parsingError" class="col-12 d-flex">
                      {{parsingError}}
                    </div>
                    <div v-if="totalRecords" class="col-12 d-flex align-items-center justify-content-center">
                       <div class="form-check form-check-inline">
                        <input type="checkbox"  id="hasheaders" v-model="dataHasHeaders" class="form-check-input"   />
                        <label class="form-check-label" for="hasheaders">My data has headers</label>
                      </div>

                      <span class=" ms-auto">
                          {{totalRecords}} total rows
                      </span>
                      <button type="button" class="btn btn-sm text-primary "  @click="rawData=null" >
                          <i class="bi bi-trash"></i>Clear
                      </button>
                     
                    
                    </div>

                    <div class="">
                      <div class="table-responsive">
                        <table class="table table-sm fs-sm">
                          <thead v-if="dataHasHeaders">
                            <tr>  
                              <th v-for="(col,index) in columns">
                                <div class="">
                                  <div>{{col}}</div>
                              
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(record,index) in previewRecords">
                              <tr>
                                  <td v-for="(v,index2) in record" scope="col" class="text-nowrap px-2 py-1" >{{v}}</td>
                              </tr>
                            </template>
                          </tbody>
                            
                        </table>
                      </div>
                    </div>
                    

                  </template>
                </div>
                <!--
                <div v-if="importStep==='reviewparse'" class="mb-3 flex-grow-1">
                  <p>
                    Please the review data we have read from your file to check that it looks good.
                  </p>
                  <div class="">
                    <div class="table-responsive">
                      <table class="table table-sm fs-sm">
                        <thead>
                          <tr>  
                            <th v-for="(col,index) in columns">
                              <div class="">
                                <div>{{col}}</div>
                            
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(record,index) in previewRecords">
                            <tr>
                                <td v-for="(v,index2) in record" scope="col" class="text-nowrap px-2 py-1" >{{v}}</td>
                            </tr>
                          </template>
                        </tbody>
                          
                      </table>
                    </div>
                  </div>
                  <div v-if="parsingError" class="col-12 d-flex">
                    {{parsingError}}
                  </div>
                  <div v-if="totalRecords" class="col-12 d-flex">
                    <div class="form-text mt-0 mb-1 ">
                        {{totalRecords}} total rows.
                    </div>
                    <div class="form-check form-check-inline ms-auto">
                      <input type="checkbox"  id="hasheaders" v-model="dataHasHeaders" class="form-check-input"   />
                      <label class="form-check-label" for="hasheaders">My data has headers</label>
                    </div>
                  </div>
                </div>
                -->
                <div v-if="importStep==='fieldConfig1'" class="mb-3 flex-grow-1">

                  <div class="row">
                    <h5 class="col-12">
                      Select the column that includes the energy usage.
                    </h5>
                    <div class="col-12 mb-3">
                      <label class="form-label mb-0" for="select-input">Usage column</label> 
                     
                      <select class="form-select  form-select-sm" id="select-input"  v-model="usageColumn">
                        <option value="">&nbsp;</option>
                        <option v-for="(col,index2) in columns" :value="col">{{col}}</option>
                      </select> 
                      <div class="form-text">The column that best shows the amount of energy used in each period.</div>
                    </div>
                    <div class="col-12 mb-3">
                      <label class="form-label mb-0" for="select-input">What is your energy measured in?</label> 
                     
                      <select class="form-select  form-select-sm" id="select-input"  v-model="usageUnit">
                        <option value="">&nbsp;</option>
                        <option v-for="(unit,index2) in USAGEUNITS" :value="unit">{{unit.label}}</option>
                      </select> 
                      <div class="form-text">The energy unit matching the above usage column.</div>
                    </div>
                    <div class="col-12 mb-3">
                      <label for="input-normal" class="form-label">Heating energy type</label>
                      <select class="form-select  form-select-sm" id="select-input"  v-model="energyusage.energyType">
                        <option v-for="(col,index2) in ENERGYTYPES" :value="col">{{col}}</option>
                      </select> 
                      <div class="form-text">What type of energy do you use for heating?</div>
                    </div>
                  </div>
                </div>
                <div v-if="importStep==='fieldConfig2'" class="mb-3 flex-grow-1">
                  <div  class="row">
                    <h5 class="col-12">
                      Select the column with the time, date or month. 
                    </h5>
                    <div  class="col-12 mb-3">
                      <label class="form-label mb-0" for="select-input">Date column</label> 
                     
                      <select class="form-select  form-select-sm" id="select-input"  v-model="dateColumn">
                        <option value="">&nbsp;</option>
                        <option v-for="(col,index2) in columns" :value="col">{{col}}</option>
                      </select> 
                      <div class="form-text">Select the column which provides times, dates or months for the energy column.</div>
                    </div>
                    <div  class="col-12 mb-3">
                      <label class="form-label mb-0" for="select-input">Date format (optional)</label> 
                      <input type="text" class="form-control form-control-sm"  placeholder="Date format" v-model="dateFormat"  >
                      <div class="form-text">Provide a format so we can interpret the date time values.</div>
                    </div>
                  </div>
                  <div class="row">
                    <div  class="col-12 mb-3">
                        <div class="">There are {{processedRecords.length}} records. Please review the following table to ensure we are interpreting the date time values correctly.</div>
                    </div>

                    <div class="table-responsive" style="flex-basis: 0;    flex-grow: 1;    min-height: 0;">
                    
                        <table class="table table-sm">
                          <thead>
                            <tr>  
                              <th>Column Value</th>
                              <th>Date Time Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  v-for="(record,index) in sampleDateTimeRecords">
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{record[0]}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{record[1]}}</td>
                            </tr>
                          </tbody>
                              
                        </table>
              
                    </div>
                  </div>
                </div>
                <div v-if="importStep==='executeModel'" class="row  flex-fill">
              

                  <!--
                  <div class="d-flex mt-3 mb-2 align-items-center justify-content-center">
                    
                    <div class="btn-group" role="group" aria-label="View options">
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='chart'}" @click="view='chart'">Chart</button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='grid'}" @click="view='grid'">Grid</button>
                      <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='params'}" @click="view='params'">Parameters</button>
                  
                    </div>
                  </div>
                  -->
                
                  <div v-if="hasAggregatedRecords" class="col-12 col-lg-6 usagechart">
                      <div class="tooltip"><div class="contents">-</div></div>
                      <div class="legend" ></div>
                      <svg class="w-100 h-100" ></svg>
                  </div>


                  <div class="col-12 col-lg-6  row">
                  
                    
                    <div class="col-12 mb-2">
                      <label for="input-normal" class="form-label">Usage per HDD (kWh/degC)</label>
                      <input v-model.number="energyusage.m"  class="form-control " type="text" placeholder="Enter a hdd/kWh" >
                      <div class="form-text">Calculated amount of energy used for each heating degree day.</div>
                    </div>
                    <div class="col-12 mb-2">
                      <label for="input-normal" class="form-label">Heating intersection (degC)</label>
                      <input v-model.number="energyusage.c"  class="form-control " type="text" placeholder="Heating intersection" >
                      <div class="form-text">Model intersection where heating usage is zero</div>
                    </div>

                    <div class="col-12 mb-2">
                      <label for="input-normal" class="form-label">Base usage (kWh)</label>
                      <input v-model.number="energyusage.baseUsage"  class="form-control " type="text" placeholder="Enter a base usage" >
                      <div class="form-text">Energy usage where heating usage is zero</div>
                    </div>
                    <div class="col-12 mb-2">
                      <label for="input-normal" class="form-label">Balance Point Temp</label>
                      <input v-model.number="energyusage.balancePointTemp"  class="form-control " type="text" placeholder="Enter a temperature" >
                      <div class="form-text">Calculated temperature where heating is required</div>
                    </div>
                    <!--
                    <div class="col-1">
                      <button type="button" class="btn text-primary p-1 fs-5 "  @click="refreshHdd()"><i class="bi-arrow-repeat"></i></button>
                    </div>
                    -->
                  </div>

                  <div v-if="hasAggregatedRecords" class="col-12">
                    <div class="table-responsive" style="flex-basis: 0;    flex-grow: 1;    min-height: 0;">
                    
                        <table class="table table-sm fs-sm">
                          <thead>
                            <tr>  
                              <th>Period</th>
                              <th>HDD</th>
                              <th>Temp</th>
                              <th>Usage (kWh)</th>
                              <th>Usage (raw)</th>
                              <th>Heating?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  v-for="(record,index) in previewAggregatedRecords">
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{record.period}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{format(record.hdd,0,true)}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{formatElementValue(record.temp, 'temp')}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{format(record.usage,3, true)}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{format(record.rawUsage,3, true)}}</td>
                              <td  scope="col" class="text-nowrap px-2 py-1" >{{record.heating}}</td>
                            </tr>
                          </tbody>
                              
                        </table>
              
                    </div>
                  </div>
                </div>
                <div v-if="importStep==='energyCosts'" class="flex-fill mb-3">
                  <div  class="row">
                    <p class="col-12">
                      To help estimate your historical energy costs, you can import prices from an existing model or enter your own.
                    </p>
                    
                    <div class="col-12 col-md-6">
                      <label for="input-normal" class="form-label">Import prices</label>
                      <select class="form-select  form-select-sm" id="select-input" @change="importPrices" v-model="pricesPreset" >
                        <option >Choose prices</option>
                        <option v-for="(preset,index2) in presetModels" :value="preset" >{{preset.label}}</option>
                      </select> 
                    </div>
                    <div class="col-4 col-md-3">
                      <label for="input-normal" class="form-label">Currency</label>
                      <input v-model="energyusage.currency"  class="form-control" type="text" placeholder="Enter the currency of your pricing information" />
                    </div>
                    <div class="col-12">
                      <div class="table-responsive" style="flex-basis: 0;    flex-grow: 1;    min-height: 0;">
                    
                        <table class="table table-sm">
                          <thead>
                            <tr>  
                              <th>Period</th>
                              <th>Cost</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  v-for="(record,index) in pricesList">
                              <td  scope="col" class="text-nowrap px-2 py-1" >
                                <input v-model="record[0]"  class="form-control" type="text" placeholder="Enter a month" />
                              </td>
                              <td  scope="col" class="text-nowrap px-2 py-1">
                                <input v-model="record[1]"  class="form-control" type="text" placeholder="Enter a price" />
                              </td>
                              <td>
                                 <button  type="button" class="btn-primary btn-sm p-2"   @click="removePriceItem(index)">
                                  <i class="bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td  scope="col" class="text-nowrap px-2 py-1" >
                                <input v-model="newPricePeriod" ref="newPricePeriod" class="form-control" type="text" placeholder="New month"  />
                              </td>
                              <td  scope="col" class="text-nowrap px-2 py-1">
                                <input v-model="newPriceValue"  class="form-control" type="text" placeholder="New price" v-on:keyup.enter="submitNewPrice()"  />
                              </td>
                              <td>
                                <button  type="button" class="btn-primary btn-sm p-2"  :class="{disabled:!newPricePeriod}" :disabled="!newPricePeriod"  @click="submitNewPrice()">
                                  <i class="bi-chevron-right"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                              
                        </table>
              
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="importStep==='submitModel'" class="flex-fill mb-3">
                  <div  class="row">
                    <p class="col-12">
                      We are collecting heating energy usage data from volunteers to help improve our models and energy usage estimates. The exact data you would share is available through the link below.
                    </p>
                    <div class="col-12 mb-2">
                      <label for="input-normal" class="form-label">Model title</label>
                      <input v-model="energyusage.label"  class="form-control" type="text" placeholder="Enter a tile for your energy model" >
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                      <label for="input-normal" class="form-label">Heating energy type</label>
                      <select class="form-select  form-select-sm" id="select-input"  v-model="energyusage.energyType">
                        <option v-for="(col,index2) in ENERGYTYPES" :value="col">{{col}}</option>
                      </select> 
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                      <label for="input-normal" class="form-label">Location (city &amp; country/state)</label>
                      <input v-model="energyusage.location"  class="form-control" type="text" placeholder="Enter the location" >
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                      <label for="input-normal" class="form-label">Building type</label>
                      <select class="form-select  form-select-sm" id="select-input"  v-model="energyusage.building">
                        <option v-for="(col,index2) in BUILDINGTYPES" :value="col">{{col}}</option>
                      </select> 
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                      <label for="input-normal" class="form-label">Building size</label>
                      <select class="form-select  form-select-sm" id="select-input"  v-model="energyusage.buildingSize">
                        <option v-for="(col,index2) in BUILDINGSIZE" :value="col">{{col}}</option>
                      </select> 
                    </div>

                    <div class="col-12 col-lg-6 mb-3">
                      <label for="input-normal" class="form-label">Country</label>
                      <input v-model="energyusage.country"  class="form-control" type="text" placeholder="Enter a country" >
                    </div>



                    <div class="d-none d-md-block col-lg-6 mb-3">
                      &nbsp;
                    </div>
                    <div class="col-12 col-lg-6">
                      <a href="#"  data-bs-toggle="modal" data-bs-target="#sharedDataViewModal">See exactly what data you are sharing</a>
                    </div>
                  
                    <div class="col-12 col-lg-6"> 
                      <button  class="btn btn-primary px-5 mb-3" :class="{disabled:!sharedModelDataText}" :disabled="!sharedModelDataText" type="button" @click="shareModelData()">Share data</button>
                      <!--
                      <div class="toast align-items-center modelshareconfirmation" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="d-flex">
                          <div class="toast-body">
                            Thank you for sharing your model! We will review the model and add it to our list.
                          </div>
                          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                      </div>
                      -->
                    </div>
                  </div>
                </div>
                
                <div class="modal fade error" id="sharedDataViewModal" tabindex="-1" aria-labelledby="sharedDataViewModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                  <div class="modal-dialog modal-dialog-centered  modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="sharedDataViewModalLabel">Model sharing data</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <form>
                        <div class="modal-body">
                          <p>
                            The following information will be shared with us.
                          </p>
                          <div class="col-12">
                            <label for="sharedModelDataTextarea" class="form-label">The following information will be shared:</label>
                            <textarea class="form-control" id="sharedModelDataTextarea" disabled="true" rows="8" v-model="sharedModelDataText" placeholder="-"></textarea>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal" >Close</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal fade modelshareconfirmation" id="thankYouForSubmission" tabindex="-1" aria-labelledby="thankYouForSubmissionLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                  <div class="modal-dialog modal-dialog-centered  modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="thankYouForSubmissionLabel">Thank you for your submission!</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <form>
                        <div class="modal-body">
                          <p>
                            Thank you for sharing your energy usage model with us. We will process it and add the information to the models we supply on this page.
                          </p>
                          
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal" >Close</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>   
 
              <div v-if="processingText" class="position-fixed top-50 start-50 translate-middle d-flex align-items-center justify-content-center shadow-lg p-3 mb-5 bg-body rounded">
                <div class="spinner-border" role="status"></div>
                <div class="ms-2">{{processingText}}</div>
              </div>


            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
                <button type="button" class="btn btn-sm btn-secondary ms-auto me-2" :class="{disabled:importStep==='intro'}" :disabled="!importStep==='intro'" @click="stepBack()">Back</button>
                <button v-if="importStep==='intro'" class="btn btn-secondary btn-sm "  :class="{disabled:!energyusage}" :disabled="!energyusage" type="button" @click="editExistingmodel()">Edit existing model</button>
                <button v-if="importStep==='intro'" class="btn btn-primary btn-sm "  type="button" @click="createCustomModel()">Creating new model</button>
                <button v-if="importStep==='rawdata'" class="btn btn-primary btn-sm " :class="{disabled:!rawData}" :disabled="!rawData" type="button" @click="setImportStep('fieldConfig1')">Continue</button>
                <!--<button v-if="importStep==='reviewparse'" class="btn btn-primary btn-sm" :class="{disabled:!rawData}" :disabled="!rawData" type="button" @click="setImportStep('fieldConfig1')">Continue</button> -->
                <button v-if="importStep==='fieldConfig1'" class="btn btn-primary btn-sm" type="button" :disabled="!usageColumn || !usageUnit || !energyusage.energyType"  @click="setImportStep('fieldConfig2')">Continue</button>
                <button v-if="importStep==='fieldConfig2'" class="btn btn-primary btn-sm" type="button" :disabled="!hasGoodConfig"  @click="setImportStep('executeModel')">Continue</button>
                <button v-if="importStep==='executeModel'" class="btn btn-primary btn-sm" :class="{disabled:!energyusage}" :disabled="!energyusage" type="button"  @click="setImportStep('energyCosts')">Continue</button>
                <button v-if="importStep==='energyCosts'" class="btn btn-primary btn-sm" :class="{disabled:!energyusage}" :disabled="!energyusage" type="button"  @click="setImportStep('submitModel')">Continue</button>
                <button v-if="importStep==='submitModel'" class="btn btn-primary btn-sm" :class="{disabled:!energyusage}" :disabled="!energyusage" type="button" data-bs-dismiss="modal"   @click="importModel()">Import model</button>

            </div>
          </div>
        </div>
      </div>


  
    
 

</template>

<script>
import '/src/VcUtils'
import { parse } from 'csv-parse';
import {VcDateUtils} from "/../visualcrossing-core-js/src/vcdateutils";
import {VcStatistics} from "/../visualcrossing-core-js/src/vcstatistics";
import {VcConversion} from "/../visualcrossing-core-js/src/vcconversion";
import {WinterTrackerHelper} from "./winter-tracker-helper";

export default {
  name: 'regressionEditor',
  props: {
   queryDefn: Object,
   selectedLocation:Object,
   energyusagein:Object,
  },
  emits: ['change:energyusage','cancel'],
  data() {
    return {
      IMPORTSTEPS:["intro", "rawdata","fieldConfig1","fieldConfig2","executeModel","energyCosts","submitModel"],
      MODIFYSTEPS:["intro", "executeModel","energyCosts","submitModel"],
      STEPS:{
        "intro":{ label:"Intro"}, 
        "rawdata":{ label:"Import"}, 
        "reviewparse":{ label:"Review"}, 
        "fieldConfig1":{ label:"Energy"}, 
        "fieldConfig2":{ label:"Dates"}, 
        "executeModel":{ label:"Model"}, 
        "energyCosts":{ label:"Prices"}, 
        "submitModel":{ label:"Share"}, 
      },
      ENERGYTYPES:["Natural Gas", "Electricity", "Heating Oil", "Geothermal", "Other"],
      BUILDINGTYPES:["Flat/Apartment/Condo", "Townhouse/Terrace", "Semi-detached/Duplex", "Detached/Single Family Home", "Other"],
      BUILDINGSIZE:["Small", "Medium", "Large"],
      DATE_RESOLUTIONS:[
        "month", "day"
      ],
      USAGEUNITS:WinterTrackerHelper.USAGEUNITS,
      energyusage:null,
      pricesPreset:null,
      mode:'import',
      records: null,
      entryMode:"single",
      bulkMode:"file",
      importStep:"intro",
      rawData:null,
      pasteRawData:null,
      records:null,
      parsingError:null,
      dataHasHeaders:true,
      concatenator:",",
      clearOnImport:true,
      cleanLocations:true,
      columns:[],
      usageColumn:null,
      usageUnit:WinterTrackerHelper.USAGEUNITS[0],
      dateColumn:null,
      dateFormat:null,
      dateResolution:"month",
      view:'chart',
      processingText:null,
      messageText:null,
      newPricePeriod:null,
      newPriceValue:0,
    }
  },
  methods:{
    submitNewPrice() {
      let me=this;
      if (!me.energyusage || !me.newPricePeriod || !isFinite(me.newPriceValue)) return;
      if (!me.energyusage.monthlycosts) me.energyusage.monthlycosts={};
      me.energyusage.monthlycosts[me.newPricePeriod]=me.newPriceValue;
      me.newPricePeriod=null;
      me.newPriceValue=0;
      me.$refs.newPricePeriod.focus();
    },
    removePriceItem(index) {
      let me=this;
      let id=me.pricesList[index] && me.pricesList[index][0];
      if (id) {
        delete me.energyusage.monthlycosts[id];
      }
    },
    
    importPrices() {
      let me=this;
     
      if (!me.pricesPreset || !me.energyusage || !me.energyusage.monthlycosts && me.pricesPreset.monthlycosts) return;
      
      me.energyusage.monthlycosts=JSON.parse(JSON.stringify(me.pricesPreset.monthlycosts));
      me.energyusage.currency=me.pricesPreset.currency;
    },
    shareModelData() {
      let me=this;
      if (!me.sharedModelData) return;
      me.processingText="Submitting model...";

      WinterTrackerHelper.saveEnergyModel(me.sharedModelData)
        .then(response=> {
          me.processingText=null;
          let el = document.querySelector('.modelshareconfirmation')
          let dialog = bootstrap.Modal.getOrCreateInstance(el)
          dialog.show();

          /*
          let myToastEl = document.querySelector('.modelshareconfirmation')
          let myToast = bootstrap.Toast.getOrCreateInstance(myToastEl)
          myToast.show();
          */
        })
        .catch(errorResponse => {
          console.log(`Error sharing energy model ${errorResponse} `)
          me.processingText=null;
        });

      
      
    },
    editExistingmodel() {
       let me=this;
      me.mode="modify";
      if (!me.energyusage) return;
      me.setImportStep('executeModel')
    },
    createCustomModel() {
      let me=this;
      me.mode="import";
      me.energyusage={
        label:"Custom energy model",
        building:me.BUILDINGTYPES[0],
        buildingSize:me.BUILDINGSIZE[0],
        energyType:me.ENERGYTYPES[0],
        country:null,
        unit:me.USAGEUNITS[0].id,
        unitGroup:null,
        location:null,
        m:0,
        b:0,
        c:0,
        baseUsage:0,
        cost:0,
        balancePointTemp:0,
        currency:"usd",
        monthlycosts:{ },
      };
      me.setImportStep('rawdata')
      //me.modelView='custom'
    },
    formatEpochTime(epochTime) {
      return VcDateUtils.formatDateTime2(epochTime);
    },
    format(value,decimals,separators) {
      decimals=(decimals>=0)?decimals:1;
      if (!Number.isFinite(value) || value===Number.MIN_VALUE || value===Number.MAX_VALUE) return "-";

      if (separators) {
        return value.toLocaleString('en-US', {maximumFractionDigits:decimals});
      }
      return value.toFixed(decimals);
    },
    formatElementValue(value, element) {
        let me=this;
        if (!element) {
          return me.format(value, 0, true);
        }
        return VcConversion.formatValue(me.queryDefn.unitGroup, element, value, true);
        
    },
    refreshHdd() {
      let me=this;
      if (!me.aggregatedRecords) return [];
      let records=me.aggregatedRecords; 
      if (!records) return;
      let recordsList=me.aggregatedRecordList;

      if (!recordsList || !recordsList.length) return;

      


      let hddCoverage=recordsList.reduce(function(a,d) {
        let noHdd=(!d.hdd && d.hdd!==0);
        a.requiresHdd= a.requiresHdd || noHdd;
        if (noHdd) {
          if (!a.minDate || a.minDate.getTime()>d.date.getTime()) {
            a.minDate=d.date;
          }
          if (!a.maxDate || a.maxDate.getTime()<d.date.getTime()) {
            a.maxDate=d.date;
          }
        }
        return a;
      }, {
        requiresHdd:false,
        minDate:null,
        maxDate:null,
      });

      if (hddCoverage.requiresHdd) {
        me.retrieveEnergyModelHdd(hddCoverage, records);
        return;
      }

      //me.refreshRegression();
    },


    retrieveEnergyModelHdd(hddCoverage, records) {
      let me=this;

      

      let queryDefn=JSON.parse(JSON.stringify(me.queryDefn));

      queryDefn.startdate=VcDateUtils.formatDateIso(hddCoverage.minDate);

      let maxDate=hddCoverage.maxDate;
      if (me.dateResolution==="month") {
        maxDate.setMonth(maxDate.getMonth()+1)
      }
      queryDefn.enddate=VcDateUtils.formatDateIso(hddCoverage.maxDate);

     
      me.processingText="Retrieving weather data...";

      vcCommunicator.retrieveTimelineData(queryDefn, me.selectedLocation.location, null, "json", true,false)
        .then(queryData => {
          me.processingText=null;
          me.processHddData(queryData, records);
         
         
        }).catch((error) => {
          me.processingText=null;
          console.log(error);
          me.errorMessage=error;
      });
    },


    processHddData(queryData, records) {
      let me=this;
      me.processingText="Calculating model...";
      me.energyusage.location=queryData.resolvedAddress;
      me.energyusage.unitGroup= me.queryDefn.unitGroup;
      if (me.dateResolution==="month") {
       
        WinterTrackerHelper.calculateMonthlyAggregates(queryData, me.queryDefn.unitGroup,null,  true);

        let monthKeys=Object.keys(queryData.months);

        monthKeys.forEach(function(key) {
          
          let hddData=queryData.months[key];
       

          if (hddData && records[hddData.month]) {
            records[hddData.month]["hdd"]=WinterTrackerHelper.forceMetricHdd(hddData.hdd, me.queryDefn.unitGroup);
            records[hddData.month]["temp"]=hddData.temp;
          }
          

        });
      } else {
        queryData.days.forEach(function(d) {
          let date=VcDateUtils.dateFromIso(d.datetime);
          let key=WinterTrackerHelper.date2Key(date);
          if (records[key]) {
            records[key]["hdd"]=WinterTrackerHelper.forceMetricHdd(d.degreedays, me.queryDefn.unitGroup);
            records[key]["temp"]=d.temp;
          }
        });

      }
    

      me.refreshRegression();
      me.processingText=null;
    },
    refreshRegression() {
      let me=this;
      let recordsList=me.aggregatedRecordList;
      let energyusage=me.energyusage;
      if (!recordsList || !recordsList.length) return;
      
      let maxUsageLowHdd=0;
      let minimumHDD=me.dateResolution==="month"?150:1;   //HDD in K
   
      recordsList.forEach(function(d,i) {
        if (d.usage>0 && d.hdd<=minimumHDD && d.usage>maxUsageLowHdd) {
          maxUsageLowHdd=d.usage;
        }
      });
      

      //if (energyusage.balancePointTemp===0) {

        energyusage.balancePointTemp=d3.quantile(recordsList.filter(d=>d.usage<maxUsageLowHdd), 0.1, d=>d.temp);
        energyusage.balancePointTemp=Math.ceil(energyusage.balancePointTemp);
      //}


      energyusage.baseUsage=maxUsageLowHdd; 

      recordsList.forEach(function(d,i) {
        if (d &&  (!isFinite(d.usage) || !isFinite(d.hdd) || d.usage<=energyusage.baseUsage)) {
          d.heating="off";
        } else {
          d.heating="on";
        }
      });

     


      let data=recordsList.filter(d=>d.heating==='on').map(function(d) {
        return [d.hdd, d.usage];
      });

 

      let model=VcStatistics.regression(data);
      if (model) {
        energyusage.m=model.m;
        energyusage.b=model.b;
        energyusage.c=model.c;
        energyusage.unit=me.usageUnit.id;
      }
      

      me.refreshUsageChart();
     

    },
     refreshUsageChart(onTick) {
       let me=this;
      if (!onTick) {
        me.$nextTick(
          function() {
            me.refreshUsageChart(true);
          }
        )
      return;
      }

     
      let energyusage=me.energyusage;
      let recordsList=me.aggregatedRecordList;
      if (!recordsList || !recordsList.length) return;
      

      let svg = d3.select(".usagechart").select("svg");
        
      if (!svg || !svg.node()) return;

      let rect=svg.node().getBoundingClientRect();
  
    
      let margin = {
          top: 10,
          right: 40,
          bottom: 20,
          left: 40
        },
        width = rect.width - margin.left - margin.right,
        height =rect.height - margin.top - margin.bottom;


      let x = d3.scaleLinear().range([0, width])
        .domain([0, d3.max(recordsList, function(d) {return d.hdd;})]).nice();

      let y = d3.scaleLinear()
         .range([height, 0])
         .domain([0, d3.max(recordsList, function(d) {return d.usage;})]).nice();

  
   
     


      let g;
      if (svg.select("g").empty())  {
          g = svg.append("g")
            .attr("clip-path","url(#usagechart)")
            .attr("class", "chartcontainer");
          g=g.append("g")
            .attr("class", "chart");
            
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
          
          svg.append("g")			
            .attr("class", "x-axis")
          svg
            .append("g")
            .attr("class", "y-axis")

          g.append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 12)
            .attr("x", 5)
            .attr("y", margin.top)
            .html("Energy (kWh)")

          g.append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 12)
            .attr("x",width-120)
            .attr("y", height-5)
            .html("Heating Degree Days")


          svg.append("clipPath")
            .attr("id", "usagechart")
            .append("rect")
              .attr("class", "clippath");
        } else {
          g=svg.select(".chart");
          g.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        }

        svg.select(".clippath")
            .attr("x",margin.left)
            .attr("y", margin.top)
            .attr("height",height)
            .attr("width",width)

        let u;

        u=g.selectAll(".hddpoint")
          .data(recordsList.filter(function(d) {
            return d.hdd;
          }));

        u.exit().remove();
      
      
        u.enter().append("path")
          .attr("class", "hddpoint")
          .attr("d", d3.symbol().type(d3.symbolCross).size(30))
          .merge(u)
            .classed("notheating", function(d) { return d.heating!=="on" })
            .attr("transform", function(d) { return `translate(${x(d.hdd)},${y(d.usage)})`});
            //.attr("x", function(d) { return x(d.hdd) })
            //.attr("y", function(d) { return y(d.usage); })
            
   

        let bestFitLinePoints=[
          [x(0), energyusage.m*x(0)+energyusage.b ],
          [x.domain()[1], energyusage.m*x.domain()[1]+energyusage.b],
        ];

        let bestFitLine = d3.line()
          .x(function(d) { return x(d[0]); })
          .y(function(d) { return y(d[1]); }) 
         

        u=g.selectAll(".bestfitline")
          .data([bestFitLinePoints]);
        
        u.enter()
          .append("path")
          .attr("class", "bestfitline") 
          .merge(u)
          .datum(bestFitLinePoints) 
          .attr("d", bestFitLine); 
        


        
        svg.select(".x-axis").attr("transform", "translate("+margin.left+","+(height+margin.top)+")")
            .call(d3.axisBottom(x ).ticks(10));

        svg.select(".y-axis").attr("transform", "translate("+margin.left+","+margin.top+")")
          .call(d3.axisLeft(y ).ticks(5));

    //    svg.select(".y1-axis").attr("transform", "translate("+(margin.left+width)+","+margin.top+")")
     //     .call(d3.axisRight(y1 ).ticks(5));

      /*
      if (me.kpis && me.kpis.hddstart && me.kpis.hddend) {
        u = svg.selectAll(".winterrange").data([me.kpis.hddstart.value, me.kpis.hddend.value]);
        u.exit().remove();
        u.enter()
          .append("text")
          .attr("class","winterrange")
          .merge(u)
          .attr("x", function(d) {return x(d*1000)})
          .attr("y",function(d) {return 20;})
          .text(function(d) { return VcDateUtils.formatDateShort(d); });
       }

      me.setUpTooltip(d3.select(".chart"),margin, days,x,height);
      */
    },
    refreshColumns() {
      let me=this;
      if (!me.records || !me.records.length) {
        me.columns= [];
        return;
      } 
      var idlist;
      if (me.dataHasHeaders) {
        idlist= me.records[0];
      } else {
        var colcount=me.records.reduce(function(a, d) {
          return (d && d.length>a)?d.length:a;
        },0);

        idlist= Array.from({ length: colcount},function(a,i) {return "Col "+i});
      }

      me.columns= (idlist && idlist.map(function(id) {
        return id;
      }) ) || [];
    },
    cancelImportLocations() {
      let me=this;
      me.rawData=null;
      me.records=null,
     
      me.entryMode='single';
      me.bulkMode="file";
      me.importStep="rawdata";
      me.columns=[];
    },

   
    hasCompletedImportStep(step) {
      let me=this;
     
      let currentIndex=me.steps.indexOf(me.importStep);
      return currentIndex>=0 && me.steps.indexOf(step)<=currentIndex;
    },
    stepBack() {
      let me=this;
      let currentIndex=me.steps.indexOf(me.importStep);
      if (currentIndex>0) {
        me.setImportStep(me.steps[currentIndex-1]);
      }
    },
    setImportStep(step) {
      let me=this;
      me.importStep=step;
      if (step==="executeModel") {
        me.refreshHdd();
        me.refreshUsageChart();
      }
    },
    
    cancelImportModel() {
      let me=this;
      me.$emit("cancel");
    },
    importModel() {
      let me=this;
      me.$emit("change:energyusage", me.energyusage);
    },

    addTextFile:function(event) {
      let me=this;
      var input = event.target;

      me.readFile(input.files[0]);
    },

    readFile:function(file) {
      let me=this;
      var reader = new FileReader();
      reader.onload = function(){
        me.rawData = reader.result;
        //if (me.rawData) {
        //  me.setImportStep("reviewparse");
        //}
      };
      reader.readAsText(file);
    },

    date2Key(date) {
      return date.getFullYear()*10000+(date.getMonth()+1)*100+date.getDate();
    }
  },
  computed:{
    steps(){
      let me=this;
      return me.mode==="import"?me.IMPORTSTEPS:me.MODIFYSTEPS;
    },
    pricesList() {
      let me=this;
      let prices=[];
      if (!me.energyusage || !me.energyusage.monthlycosts) return prices;
      prices=Object.keys(me.energyusage.monthlycosts).map(function(d) {
        return [d,me.energyusage.monthlycosts[d]];
      });

      return prices;
    },
    presetModels() {
      return WinterTrackerHelper.PRESETMODELS;
    },
    sharedModelDataText() {
      let me=this;
      let data=me.sharedModelData;
      if (!data) return "";
      return JSON.stringify(data, null, 1)
    },
    sharedModelData() {
      let me=this;
      if (!me.energyusage) return null;
      me.energyusage.records=me.aggregatedRecordList;
      return me.energyusage;
    /*
      return {
        location:me.locationShare,
        energyusage:me.energyusage,
        records:me.aggregatedRecordList,
      }*/
    },
    hasAggregatedRecords() {
      let me=this;
      return me.aggregatedRecords && Object.keys(me.aggregatedRecords).length;
    },
    previewAggregatedRecords() {
      let me=this;
      if (!me.aggregatedRecordList) return [];
      let records=me.aggregatedRecordList;

      return records && records.length?records.slice(0,365):[];
    },
    aggregatedRecordList() {
      let me=this;
      if (!me.aggregatedRecords) return [];

      return Object.values(me.aggregatedRecords);
    },
    sampleDateTimeRecords() {
      let me=this;
      if (!me.dateColumn) return null;
      let dateColumnIndex=me.columns.indexOf(me.dateColumn);
     
      let retVal= me.resolvedRecords.filter((d,i)=>i<5).reduce(function(a, d) {
        let date="-";
        if (d[dateColumnIndex]) {
          date=VcDateUtils.dateFromString(d[dateColumnIndex],me.dateFormat);
          date=VcDateUtils.formatDateTime2(date,null, 
          {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            /*hour12: false, */
            hourCycle: 'h23',
            
          });
        }
        a.push([d[dateColumnIndex], date]);
        
        return a;
      }, []);

      return retVal;
    },
    aggregatedRecords() {
      let me=this;
      let processedRecords=me.processedRecords;
      if (!processedRecords || !processedRecords.length) return {};

      
      let aggregateRecords=processedRecords.reduce(function(a, d) {
        if (!d || !d.date || !d.usage) return a;
        let key, date;
        if (me.dateResolution==="month") {
          date=new Date(d.date.getFullYear(), d.date.getMonth(), 1); //.getFullYear()*100+(d.date.getMonth()+1);
        } else {
          date=new Date(d.date.getFullYear(), d.date.getMonth(), d.date.getDate()); //.getFullYear()*10000+(d.date.getMonth()+1)*100+d.date.getDate();
        }
        key=WinterTrackerHelper.date2Key(date);

        let usage=+d.usage;
        let rawUsage=usage;

        usage=WinterTrackerHelper.unit2kWh(usage, me.usageUnit);

        /*
        if (me.usageUnit) {
          
          if (me.usageUnit.id==="m3") {
            usage=10.55*usage; //https://learnmetrics.com/m3-gas-to-kwh/
          } else if (me.usageUnit.id==="therm") {
            usage=29.3001*usage; //https://www.metric-conversions.org/energy-and-power/therms-us-to-kilowatt-hours.htm
          }
          
        }
        */
        if (a[key]) {
          a[key].usage+= usage;
          a[key].rawUsage+= rawUsage;
        } else {
          a[key]= {
            date:date,
            period:key,
            usage: usage,
            rawUsage: rawUsage,
            hdd:NaN,
            temp:NaN,
          }
        }

        return a;
      }, {});

      return aggregateRecords;
    },
    processedRecords() {
      let me=this;
      if (!me.dateColumn || !me.usageColumn) return [];
      let dateColumnIndex=me.columns.indexOf(me.dateColumn);
      let usageColumnIndex=me.columns.indexOf(me.usageColumn);

      let retVal= me.resolvedRecords.reduce(function(a, d) {
        let date=VcDateUtils.dateFromString(d[dateColumnIndex],me.dateFormat);
        let usage=+d[usageColumnIndex];
        if (date) {
          a.push({date:date, usage:usage});
        }
        return a;
      }, []);
      return retVal;
    },  
  
    hasGoodConfig() {
      let me=this;
      return me.dateColumn && me.usageColumn && me.processedRecords && me.processedRecords.length;
    },
    
    
    resolvedRecords() {
      let me=this;
      if (!me.records || !me.records.length) return [];
       return me.dataHasHeaders?me.records.slice(1):me.records;
    },
    previewRecords() {
      let me=this;
      if (!me.resolvedRecords || !me.resolvedRecords.length) return [];
      return me.resolvedRecords.slice(0,5);
    },
    hasPreview() {
      let me=this;
       if (!me.previewRecords || !me.previewRecords.length) return false;
       return me.previewRecords.reduce(function(a, d) {
         return a || me.getRecordLocation(d);
       },false);
    },

    totalRecords() {
      let me=this;
      if (!me.resolvedRecords || !me.resolvedRecords.length) return 0;
      return me.resolvedRecords.length;
    },

    /*
    badTotalRecords() {
      let me=this;
      return me.resolvedRecords.reduce(function(a, d) {
        if (!d) return a+1;
        var location=me.getRecordLocation(d);
        if (!location) return a+1;
        return a;
      }, 0);
    },
  */
      

  },
  mounted() {
   let me=this;
   if (me.energyusagein) {
    me.energyusage=JSON.parse(JSON.stringify(me.energyusagein));
    me.energyusage.monthlycosts=me.energyusage.monthlycosts || {};
   }
  },
  watch: {
    view:function() {
      let me=this;
      setTimeout(function() {me.refreshUsageChart();}, 1000); //hack 
    },
    aggregatedRecords:function() {
      let me=this;
      //me.refreshHdd();
    },
    records:function() {
      let me=this;
      me.refreshColumns();
    },
    
    rawData:function(rawData){
      let me=this;
     
      if (!rawData) {
        me.records=[];
        me.parsingError="No raw data available";
        return;
      }
      me.parsingError=null;
      me.usageColumn=null;
      me.dateColumn=null;

      parse(rawData, {
       // comment: '#',
        //relax_column_count: true
      }, function(err, records){
        me.parsingError=err;
        me.records=records;
      });
    },
 
  },
  components: {
    
  },
}
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .usagechart {
      background-color: #fff;
      position:relative;
  }

  .usagechart :deep() .hddpoint {
    fill: #d85b1c;
    stroke: #d85b1c;
	}
  .usagechart :deep() .hddpoint.notheating  {
    fill: none;
    stroke: #918f8e;
	}
  .usagechart :deep() .temppoint {
    fill: none;
    stroke: #1f1cd8;
	}

  .usagechart :deep() .bestfitline {
     background-color:#6b6767;
	  fill: #6b6767;
    stroke: #6b6767;
    stroke-dasharray: 5;
  }
</style>
