<template>
  <div class="modal fade" id="metadataInfoModal" tabindex="-1" aria-labelledby="metadataInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" modal-xl>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="metadataInfoModalLabel">Dataset information</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!--<form>-->
          <div class="modal-body">
            <!--<form>-->
              <div class="mb-3">
                <label for="name-input" class="form-label">Name</label>
                <input class="form-control" type="text" id="name-input"  v-model="metadataCopy.name" placeholder="Name">
              </div>
              <div class="mb-3">
                <label for="desc-input" class="form-label">Description (optional)</label>
                <textarea class="form-control" id="desc-input" rows="5" v-model="metadataCopy.desc" placeholder="Description"></textarea>
              </div>
            <!--</form>-->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal" @click="cancel">Close</button>
            <button type="button" class="btn btn-primary btn-sm" @click="submit" :disabled="!isValid">Save changes</button>
          </div>
        <!--</form>-->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'mdinfo',
  props: {
    metadata:Object,
    show:Boolean
  },
  emits: ['submit:metadata', 'cancel'],
  data() {
    return {
      metadataCopy:this.getDefaultMd()
     
    }
  },
  methods:{
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      this.$emit("submit:metadata", this.metadataCopy);
      this.setModalState(false);
    },
    getDefaultMd() {
      return this.metadata && JSON.parse(JSON.stringify(this.metadata)) ||
        {
          id:null,
          name:null,
          desc:null
        }
    },
    setModalState(state) {
      var modal = bootstrap.Modal.getOrCreateInstance(this.$el);
      if (state) {
        modal.show();
      } else { 
        modal.hide();
      } 
    }
  },
  computed:{
    isValid() {
      return this.metadataCopy && this.metadataCopy.name && this.metadataCopy.name.length;
    },
    
  },
  watch: {
    show(newValue) {
      this.setModalState(newValue);
    },
    //rally the best way?
    metadata: {
      handler:function() {
         this.metadataCopy=this.getDefaultMd();
      },
      deep:true
    }
  },
  mounted() {
    var me=this;
   
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>