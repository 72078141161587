
import {VcGlobal} from "/../visualcrossing-core-js/src/vcglobal_conn";

const COMPASS=["N","NNE","NE","ENE","E","ESE","SE","SSE","S","SSW","SW","WSW","W","WNW","NW","NNW"];

const ELEMENT_INFO={
    temp: {
        title:"Temp"
    },
    tempmax: {
        title:"Max",
        baseelement:"temp"
    },
    tempmin: {
        title:"Min",
        baseelement:"temp"
    },
    feelslike: {
        title:"Feels like",
        baseelement:"temp"
    },
    feelslikemax: {
        title:"Feels like max",
        baseelement:"temp"
    },
    feelslikemin: {
        title:"Feels like min",
        baseelement:"temp"
    },
    precip: {
        title:"Rain",
    },
    precipprob: {
        title:"Precip %",
        baseelement:"percent"
    },
    snow: {
        title:"Snow",
    },
    snowdepth: {
        title:"Snow depth",
        baseelement:"snow"
    },
    rainsnow: {
        title:"Rain/Snow",
    },
    windspeed: {
        title:"Wind",
        baseelement:"wind",
    },
    wind: {
        title:"Wind",
        baseelement:"wind",
    },
    windgust: {
        title:"Gust",
        baseelement:"wind"
    },
    humidity: {
        title:"Humidity",
        baseelement:"percent"
    },
    dew: {
        title:"Dew point",
        baseelement:"temp"
    },
    pressure: {
        title:"Pressure",
    },
    cloudcover: {
        title:"Cloud cover",
        baseelement:"percent"
    },
    solarradiation: {
        title:"Solar radiation",
    },
    uvindex: {
        title:"UV index",
        fixed:0,
    },
    uvindex2: {
        title:"UV index",
        fixed:0,
    },
    sunrise: {
        title:"Sunrise",
    },
    sunset: {
        title:"Sunset",
    },
    moonphase: {
        title:"Moonphase",
    },
    reflectivity: {
        title:"Reflectivity",
    },
    pm2p5: {
        title:"Particulates (2.5)",
    },
	pm10: {
        title:"Particulates (10)",
    },
	pm1: {
        title:"Particulates (1.0)",
    },
	co: {
        title:"CO",
    },
	so2: {
        title:"SO2",
    },
	no2: {
        title:"NO2",
    },
	o3: {
        title:"Ozone",
    },
}
const UNITGROUPS={
    "base":{
        "temp":"K",
        "precip":"mm",
        "snow":"cm",
        "wind":"m/s",
        "visibility":"km",
        "percent":"%",
        "distance":"m",
        "pressure":"mb"
    },
    "us":{
        "temp":"&#8457;",
        "temp_u":"\u2109",
        "precip":"in",
        "snow":"in",
        "wind":"mph",
        "visibility":"mi",
        "percent":"%",
        "distance":"mi",
        "pressure":"mb"
    },
    "uk":{
        "temp":"&#8451;",
        "temp_u":"\u2103",
        "precip":"mm",
        "snow":"cm",
        "wind":"mph",
        "visibility":"mi",
        "percent":"%",
        "pressure":"mb"
    },
    "metric":{
        "temp":"&#8451;",
        "temp_u":"\u2103",
        "precip":"mm",
        "snow":"cm",
        "wind":"kph",
        "visibility":"km",
        "percent":"%",
        "distance":"km",
        "pressure":"mb"
    }
}


export let VcWidgetHelper = (function(){
    console.log("Starting VcWidgetHelper..." )
    var isRequestingData=false;
    var datastore={

    }
    var querystore={

    }
    var datastorePromises={

    }
    var handlers={

    }

    function readElementConfig(containerElement, widget) {
        var widgetConfig=widget.config || {};
        var queryDefn=(widget && widget.queryDefn) || {};

        widget.id = containerElement.getAttribute('data-id') || widgetConfig.id;
        widget.location = containerElement.getAttribute('data-location') || widgetConfig.location;
        widget.key = containerElement.getAttribute('data-key') || widgetConfig.key;
        widget.startdate = containerElement.getAttribute('data-startdate') || widgetConfig.startdate;
        widget.enddate = containerElement.getAttribute('data-enddate') || widgetConfig.enddate;
        widget.unitGroup = containerElement.getAttribute('data-unitgroup') || widgetConfig.unitgroup || queryDefn.unitGroup;
        widget.name = containerElement.getAttribute('data-name') || widgetConfig.name;
        widget.elements = containerElement.getAttribute('data-elements') || widgetConfig.elements;
        widget.options = containerElement.getAttribute('data-options') || widgetConfig.options;
        widget.include = containerElement.getAttribute('data-include') || widgetConfig.include;
        widget.forecastBasisDate =  containerElement.getAttribute('data-forecastbasisdate') || widgetConfig.forecastbasisdate;
        widget.forecastBasisDay =  containerElement.getAttribute('data-forecastbasisday') || widgetConfig.forecastbasisday;

        widget.period=containerElement.getAttribute('data-period') ||  widgetConfig.period || widget.period ;
        //console.log("Period:"+widget.period);
        widget.dayIndex = containerElement.getAttribute('data-dayindex') ||  widgetConfig.dayindex;
       
        if (widget.dayIndex) {
            widget.dayIndex=+widget.dayIndex;
        }
        widget.endDayIndex = containerElement.getAttribute('data-enddayindex') ||  widgetConfig.enddayindex;
        if (widget.endDayIndex) {
            widget.endDayIndex=+widget.endDayIndex;
        }
        widget.hourIndex = containerElement.getAttribute('data-hourindex') ||  widgetConfig.hourindex;
       
        if (widget.hourIndex) {
            widget.hourIndex=+widget.hourIndex;
        }

        var viewincludes = containerElement.getAttribute('data-view-includes') ||  widgetConfig.viewincludes;
        if (viewincludes) {
            widget.viewincludes=viewincludes.split(",");
        }

        var summaryKpis = containerElement.getAttribute('data-summary-kpis') ||  widgetConfig.summarykpis;
        if (summaryKpis) {
            widget.summarykpis=summaryKpis.split(",");
            if (widget.summarykpis && widget.summarykpis.length>4) {
                console.log("More than four summary kpis are not supported");
            }
        }
        widget.rooturl=containerElement.getAttribute('data-rooturl') ||  widgetConfig.rooturl;
        widget.widgetcolumncount=containerElement.getAttribute('data-columncount') ||  widgetConfig.columncount;
        if (widget.widgetcolumncount) {
            widget.widgetcolumncount=+widget.widgetcolumncount;
        }
    }

    function encodeURIComponentIfNotNull(value) {
        return  value?encodeURIComponent(value):value;
    }
    function addExternalData(id, data, unitGroup) {
        if (!data.result) {
            data={'result':'success',data:data}
        }
        if (unitGroup) data.data.unitGroup=unitGroup;
        datastore[id]=data;

        _processExternalDataPromises();
    }
    function _processExternalDataPromises() {

        for (const [id, v] of Object.entries(datastorePromises)) {
            if (datastore[id]) {
                v.forEach(element => {
                    if (datastore[id].result==='fail') {
                        element.reject(datastore[id]);
                    } else {
                        element.resolve(datastore[id]);
                    }
                });
               
            }
        }
  
    }
    function _requestData(locationOrDefn,startdate,enddate,key,unitgroup,elements,include, id, forceRefresh, resolve, reject) {
        if (isRequestingData) {
            
            setTimeout(function() {
                //console.log("Delated _requestData for "+id);
                _requestData(locationOrDefn,startdate,enddate,key,unitgroup,elements,include, id, forceRefresh, resolve, reject);
            }, 100);
            return;
        }
        
        var defn;
        if (typeof locationOrDefn==='string') {
            defn={
                locationOrDefn:locationOrDefn,
                startdate:startdate,
                enddate:enddate,
                key:key,
                unitgroup:unitgroup,
                elements:elements,
                include:include,
                id:id,
                forceRefresh:forceRefresh
            }
        } else {
            defn=JSON.parse(JSON.stringify(locationOrDefn));
        }


        if (!defn.location && defn.id) {
            //console.log("Added a external data request by id "+defn.id);
            datastorePromises[defn.id]=(datastorePromises[defn.id] || [])
            datastorePromises[defn.id].push({
                resolve:resolve,
                reject:reject
            });
             _processExternalDataPromises();
            return;
        }

      
        defn.location=encodeURIComponentIfNotNull(defn.location);
        defn.startdate=encodeURIComponentIfNotNull(defn.startdate);
        defn.enddate=encodeURIComponentIfNotNull(defn.enddate);
        defn.key=defn.key || VcGlobal.GLOBAL_KEY;
        if (!defn.id) defn.id=JSON.stringify(defn);

        if (datastore[defn.id] && !defn.forceRefresh) {
            var v=datastore[defn.id];
            //console.log("Using store data for..."+defn.id);
            if (v.result==='fail') {
                reject(v);
            } else {
                resolve(v);
            }
            return;
        }
        //console.log("Storing querydefn")
        querystore[defn.id]=JSON.parse(JSON.stringify(defn));
        delete querystore[defn.id].key;
        querystore[defn.id].contentType="json";

        isRequestingData=true;
        var postdata={
            key:defn.key,
            unitGroup:defn.unitgroup,
            elements:defn.elements,
            include:defn.include,
            options:defn.options,
            iconSet:"icons2"
        };

        if (defn.forecastBasisDate) {
            postdata.forecastBasisDate=defn.forecastBasisDate;
        } else  if (defn.forecastBasisDay) {
            postdata.forecastBasisDay=defn.forecastBasisDay;
        }


        var url=VcGlobal.SERVER_ROOT+"/VisualCrossingWebServices/rest/services/timeline/"+defn.location;
        
        if (defn.startdate) {
            url+="/"+defn.startdate;
            if (defn.enddate) {
                url+="/"+defn.enddate;
            }
        }
        fetch(url, {
            method: 'POST', 
            mode: 'cors',
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
            'Content-Type': 'application/json'
            },
            redirect: 'error',
           // referrerPolicy: 'no-referrer', 
           referrerPolicy: 'strict-origin-when-cross-origin',
            body: JSON.stringify(postdata) 
        }).then(response => {
            if (!response.ok) {
                //if (!response.ok) throw new Error("Error retrieving the data ("+response.status+")");
                throw response
            }
            
            return response.json();
        }).then(response => {
            datastore[defn.id]={'result':'success',data:response}
            dispatchEvent('datastored',defn.id,querystore[defn.id], datastore[defn.id]);
            resolve(datastore[defn.id]);
            isRequestingData=false;
        }).catch((errorResponse) => {
            isRequestingData=false;
            
            if (errorResponse.text) {
                errorResponse.text().then( errorMessage => {
                    datastore[defn.id]={'result':'fail', msg:errorMessage}
                    reject(errorMessage);
                    dispatchEvent('datastorederror', defn.id, querystore[defn.id], errorMessage);
                })
            } else {
                let msg="An error occurred retrieving the data";
                datastore[defn.id]={'result':'fail', msg:msg}
                reject(msg);
                dispatchEvent('datastorederror',defn.id,  querystore[defn.id],msg);
            } 
        });
    }
    function getSafeNormal(d, element, index, testIsNaN) {
        if (!d || !d.normal || !d.normal[element]) return null;
        if (!testIsNaN) {
            return d.normal[element][index];
        } else {
            return d.normal[element][index] || d.normal[element][index]===0?true:false;
        }
    }
    function populateData(locationOrDefn,startdate,enddate,key,unitgroup,elements,include, id, forceRefresh) {
        
        return new Promise((resolve, reject) => {
            _requestData(locationOrDefn,startdate,enddate,key,unitgroup,elements,include, id, forceRefresh, resolve, reject);
        });
    }

    function getMoonPhaseDesc(moonphase, simple) {
        if (!moonphase && moonphase!==0) return "-";
        var moonIllumination=Math.round(200*(moonphase<=0.5?moonphase:(1-moonphase)))+"%";

        if (simple) return moonIllumination;
        if (moonphase===0 || moonphase===1) return "New moon";
        
        if (moonphase<0.25) return "Waxing Crescent";
        if (moonphase===0.25) return "First Quarter";
        if (moonphase<0.5) return "Waxing Gibbous";
        if (moonphase===0.5) return "Full moon";
        if (moonphase<0.75) return "Waning Gibbous";
        if (moonphase===0.75) return "Last Quarter";
        if (moonphase<1.0) return "Waning Crescent";
        return moonphase+"?";
    }
    function getAqiElementText(aqielement) {
        if (!aqielement && aqielement!==0) return "-";
        let me=this;
        let pieces=aqielement.split(",");
        
        let output=pieces.reduce(function(a,d) {
            let desc;
            if (d) desc=me.getElementTitle(d);
            if (!desc) return a;
            if (!a) return desc;
            return a+", "+desc;

        }, null);

        if (output) return output;

        return `*${aqielement}`
    }   
    function getAqiText(aqi, shortForm) {
        if (!aqi && aqi!==0) return "-";
        if (aqi<51) {
            return shortForm?"OK":"Good";
        } else if (aqi<101) {
            return shortForm?"Mod":"Moderate";
        } else if (aqi<151) {
            return shortForm?"Poor":"Unhealthy for Sensitive Groups";
        } else if (aqi<201) {
            return shortForm?"Poor":"Unhealthy";
        } else if (aqi<300) {
            return shortForm?"Bad":"Very Unhealthy";
        } else {
            return shortForm?"Bad":"Hazardous";
        }
    }

    function getCompassDirection(degrees) {
        if (!degrees && degrees!==0) return "";
        var winddir=Math.round(degrees/22.5);
        if (winddir<0) winddir+=16;
        if (winddir>15) winddir-=16;
        return COMPASS[winddir];
    }

    function getElementTitle(element) {
        if (ELEMENT_INFO[element]) {
            return ELEMENT_INFO[element].title;
        } else {
            console.log("Element info not found "+element);
        }
        return "*"+element;
    }

    function calcSummaryKpis(data, unitgroup) {
        var me=this;
        if (!data) return null;
        var kpis={
        tempmax:{label:"Max temp",element:"temp",fvalue:null, value:-9999},
        tempmin:{label:"Min temp",element:"temp",fvalue:null, value:9999},
        precip:{label:"Total Precip",element:"precip",fvalue:null, value:0},
        precipdailymax:{label:"Max daily precip",element:"precip",fvalue:null, value:0},
        precipdays:{label:"Rain days",element:"",fvalue:null, value:0},
        //snow:{label:"Max temp",value:0},
        windspeedmax:{label:"Max sustained wind",element:"wind",fvalue:null, value:0},

        }
        data.days.forEach(function(d) {
                if (d.tempmax>kpis.tempmax.value) kpis.tempmax.value=d.tempmax;
                if (d.tempmin<kpis.tempmin.value) kpis.tempmin.value=d.tempmin;
                if (d.precip>0) {
                    kpis.precip.value+=d.precip;
                    kpis.precipdays.value++;
                    if (d.precip>kpis.precipdailymax.value) kpis.precipdailymax.value=d.precip;
                }
                if (d.windspeed>kpis.windspeedmax.value) kpis.windspeedmax.value=d.windspeed;
            });

        Object.values(kpis).forEach(function(d) {
            d.fvalue=formatElementValue(d.value, d.element, unitgroup, null, null,true);
        });
        
        return kpis;
    }

    function formatElementValue(value, element, unitgroup, fixed, emptyvalue,  unicode, noSymbol) {
        if (!value && value!==0) {
            return emptyvalue || "-";
        }

        if (typeof value==="string") return value;

        if (ELEMENT_INFO[element] && ELEMENT_INFO[element].baseelement) {
            element=ELEMENT_INFO[element].baseelement;
        } 

        unitgroup=unitgroup || "us";

        if (!fixed && fixed!==0) {
            if (ELEMENT_INFO[element] && (ELEMENT_INFO[element].fixed || ELEMENT_INFO[element].fixed===0)) {
                fixed =ELEMENT_INFO[element].fixed;
            } else {
                var absValue=Math.abs(value);
                fixed = (absValue > 0 && absValue<1)?2: (absValue > 0 && absValue<10)?1:0;
            }
            
        }
        
        if (!noSymbol) {
            var symbol;
            if (unicode) {
                symbol=UNITGROUPS[unitgroup][element+"_u"];
            }
            if (!symbol) {
                symbol=UNITGROUPS[unitgroup][element];
            }
            if (symbol) {
                return value.toFixed(fixed)+symbol;
            }
        }
        
        return value.toFixed(fixed);
        
        
    }

    function isSameDate(date1EpochSecs,date2, tz) {
        const options={
            year: 'numeric', 
            month: 'numeric', 
            day:'numeric',
            timeZone: tz,
        }
        let date1Fmt=formatDateTime(date1EpochSecs, tz, options);
        let date2Fmt=formatDateTime(date2, tz, options);
        return date1Fmt===date2Fmt;
    }
    function isYesterday(epochSecs,tz) {
       
        let yesterdayDate=new Date();
        yesterdayDate.setDate(yesterdayDate.getDate()-1);
        return isSameDate(epochSecs, yesterdayDate, tz);
        /*
        const options={
            year: 'numeric', 
            month: 'numeric', 
            day:'numeric',
            timeZone: tz,
        }
        let dateFmt=formatDateTime(epochSecs, tz, options);
        let yesterdayDateFmt=formatDateTime(yesterdayDate, tz, options);
        return dateFmt===yesterdayDate;
        */
    }

    function isToday(epochSecs,tz) {
        
        let todayDate=new Date();
        return isSameDate(epochSecs, todayDate, tz);
        /*
        const options={
            year: 'numeric', 
            month: 'numeric', 
            day:'numeric',
            timeZone: tz,
        }
        let dateFmt=formatDateTime(epochSecs, tz, options);
        let todayFmt=formatDateTime(todayDate, tz, options);
        return dateFmt===todayFmt;
        */
    }

    function formatDateTime(epochSecsOrDate,tz, options) {

        let date;
        if (epochSecsOrDate instanceof Date) {
            date=epochSecsOrDate;
        } else {
            date=new Date(epochSecsOrDate*1000);
        }
        
       


        var options = options || {
           month: 'short', 
           day:'numeric',
           hour: 'numeric', 
           minute: 'numeric',  
           hour12: false,
           timeZone: tz
         };
         if (tz) options.timeZone=tz;
         try {
            return (new Intl.DateTimeFormat('en-US', options).format(date));
         } catch(e) {
            console.error("Error formatting date with epoch sec value:"+epochSecsOrDate);
            return "?";
         }
     }
     function formatTime(epochSecs,tz) {
        return formatDateTime(epochSecs,tz, {
            hour: 'numeric', 
            minute: 'numeric',  
            /*hour12: false,*/ //replaced to stop 24:00 vs 00:00 (!)
            hourCycle: 'h23',
            timeZone: tz,
           
          });
     }
     function formatDate(epochSecs,tz) {
        return formatDateTime(epochSecs,tz,{
            year: 'numeric', 
            month: 'short', 
            day:'numeric',
            weekday: "long",
            timeZone: tz,
          });
     }
     function formatDateVeryShort(epochSecs,tz) {
        return formatDateTime(epochSecs,tz,{
            weekday: "short",
            day:'numeric',
            timeZone: tz
          });
     }
      function formatDateMonth(epochSecs,tz) {
        return formatDateTime(epochSecs,tz,{
            month: 'short', 
            day:'numeric',
            timeZone: tz
          });
     }
     function formatDateShort(epochSecs,tz) {
        return formatDateTime(epochSecs,tz,{
            weekday: "short",
            month: 'short', 
            day:'numeric',
            timeZone: tz
          });
     }
     function formatDoW(epochSecs,tz) {
        return formatDateTime(epochSecs,tz,{
            weekday: 'short',
            timeZone: tz
          });
     }
     function formatDateIso(epochSecs,tz) {
        var date=new Date(epochSecs*1000);
        var options = {
            timeZone: tz
          };
         
          try {
             return (new Intl.DateTimeFormat('sv', options).format(date));
          } catch(e) {
             console.error("Error formatting date with epoch sec value:"+epochSecs);
             return "?";
          }
    }
    function resolveLocation(data, location,name) {
      
        var maxLength=50;
        location=name || location || (data && data.resolvedAddress);
        if (!location) return location;
        var l=location.length;
        if (l<maxLength) return location;
        return "..."+location.substr(l-maxLength,maxLength);
    }
    function debounce (fn, delay) {
        var timeoutID = null
        return function () {
          clearTimeout(timeoutID)
          var args = arguments
          var that = this
          timeoutID = setTimeout(function () {
            fn.apply(that, args)
          }, delay)
        }
    }


    function processData(data) {
        if (!data || !data.days) return;
       
        var current=new Date();
        var tzoffset=current.getTimezoneOffset()*60000;

        data.days.reduce(function(a,d) {
            if (d.precip>0) a.precipsum+=d.precip;
            if (d.snow>0) a.snowsum+=d.snow;
            if (d.normal) {
                if (d.normal.precip[1]>0) {
                    a.precipsumnormal+=d.normal.precip[1];
                }
            }

            d.precipsum=a.precipsum;
            d.precipsumnormal=a.precipsumnormal;
            d.snowsum=a.snowsum;

            d.datetimeInstance=new Date(d.datetimeEpoch*1000+(d.tzoffset?d.tzoffset:data.tzoffset)*3600000+tzoffset);
            if (d.hours) {
                d.hours.forEach(function(h) {
                    h.datetimeInstance=new Date(h.datetimeEpoch*1000+(h.tzoffset?h.tzoffset:data.tzoffset)*3600000+tzoffset);
                }); 
            }
            return a;
        }, {
            precipsum:0,
            snowsum:0,
            precipsumnormal:0
        });
    }

    function hasNormals(data) {

        return data && data.days && data.days.reduce(function(a, d) {
            return a || d.normal;
        }, false);
  
    }
    function toK(t, unitGroup) {
        if (unitGroup==="metric") {
            return t+273.15;
        } else {
            return (t+ 459.67)*5/9
        }
    }    
    function turboColor(t) {
        t = Math.max(0, Math.min(1, t));
        return "rgb("
            + Math.max(0, Math.min(255, Math.round(34.61 + t * (1172.33 - t * (10793.56 - t * (33300.12 - t * (38394.49 - t * 14825.05))))))) + ", "
            + Math.max(0, Math.min(255, Math.round(23.31 + t * (557.33 + t * (1225.33 - t * (3574.96 - t * (1073.77 + t * 707.56))))))) + ", "
            + Math.max(0, Math.min(255, Math.round(27.2 + t * (3211.1 - t * (15327.97 - t * (27814 - t * (22569.18 - t * 6838.66)))))))
            + ")";
    }
    
    function dbzColor(v) {
        let min=0, max=90;
        const colors=["#3ed188","#259700","#eecf00","#fe8600","#f70000","#ffc9ff","#ff02e6","#7000e0"];//"#7756a7","#8f8b93","#d0d3b2","#40619f",
        let index=colors.length*(v-min)/(max-min);
        if (index<0) index=0;
        if (index>=colors.length-1) index=colors.length-1;
        return colors[Math.round(index)];
    }


    function addHandler(type, handler) {
        handlers=handlers || {};
        handlers[type]=(handlers[type] || []);
        handlers[type].push(handler)
    }
    
    function dispatchEvent(type, ...params) {
        handlers && handlers[type] && handlers[type].forEach(function(f) {
            f(...params);
        })
    }

    return {
        turboColor:turboColor,
        dbzColor:dbzColor,
        toK:toK,
        addExternalData:addExternalData,
        hasNormals:hasNormals,
        getSafeNormal:getSafeNormal,
        processData:processData,
        debounce:debounce,
        resolveLocation:resolveLocation,
        getElementTitle:getElementTitle,
        isToday:isToday,
        isYesterday:isYesterday,
        formatDateTime:formatDateTime,
        formatTime:formatTime,
        formatDate:formatDate,
        formatDateShort:formatDateShort,
        formatDateVeryShort:formatDateVeryShort,
        formatDateMonth:formatDateMonth,
        formatDoW:formatDoW,
        formatDateIso:formatDateIso,
        readElementConfig:readElementConfig,
        populateData:populateData,
        getMoonPhaseDesc:getMoonPhaseDesc,
        getCompassDirection:getCompassDirection,
        getAqiText:getAqiText,
        getAqiElementText:getAqiElementText,
        formatElementValue:formatElementValue,
        queryDefinitions:querystore,
        getDataset:function(id) {
            return datastore && datastore[id] && datastore[id].data;
        },
        addHandler:addHandler,
        dispatchEvent:dispatchEvent,
        calcSummaryKpis:calcSummaryKpis,

   };
})();

