export var VcClipboardUtils = (function () {
    

    return {
      copyToClipboard:function(text) {

        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(text)
            .then(() => { console.log("Copy success") })
            .catch((error) => { console.log(`Copy failed! ${error}`) })

          return;
        }

          // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = text;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
      }
    }
}());