import {VcDateUtils} from '/../visualcrossing-core-js/src/vcdateutils';
L.Control.WxLegend = L.Control.extend({

    _layers:{},

    //_wxLayers:[],
    _currentLayer:null,
    _timeLabel:null,
    _animationStepInterval:750,
    getTimeInterval() {
        let me=this;
        return me._currentLayer?me._currentLayer.options.dateTimeInterval:10;
    },
    addDateTime: function(map, step) {
        let me=this;
        if (! map.options.dateTime) return null;
        var interval=me.getTimeInterval()*60000; //me.dateTimeIntervalMs;
        me.setDateTime(map, new Date( map.options.dateTime.getTime()+step*interval));
 
       
        
    },
    
    setDateTime:function(map, dateTime) {
        let me=this;

        map.options.dateTime=me.truncateToMinuteInterval(me.getTimeInterval(), dateTime);
        if (me._timeLabel) {
            me._timeLabel.innerHTML=VcDateUtils.formatDateCustom(map.options.dateTime,"HHmm");
        }
        if (me._dateLabel) {
            me._dateLabel.innerHTML=VcDateUtils.formatDateCustom(map.options.dateTime,"D MMM");
        }
        map.fire("datetimeupdated");
        

        //me._wxLayers &&  me._wxLayers.forEach((wxLayer)=>  {
            //wxLayer._update();
        //});
       
    },
    
    createDateTimeControl:function(parent, map) {
        let me=this;
        


        let animationCtrl=L.DomUtil.create('div','d-flex flex-row  mb-1',parent);
        let animationCtrlItem= L.DomUtil.create('div','d-flex flex-row leaflet-control-zoom leaflet-bar1 leaflet-control bg-white px-1 align-items-center justify-content-center',animationCtrl);
        let backButton=L.DomUtil.create('a','leaflet-control-zoom-in px-1  ',animationCtrlItem);
        L.DomUtil.create('i','bi bi-chevron-left',backButton);

        let dateTimeItem= L.DomUtil.create('div','d-flex flex-column align-items-center justify-content-center',animationCtrlItem);
        let timeLabel=L.DomUtil.create('span','fs-sm',dateTimeItem);
        let dateLabel=L.DomUtil.create('span','fs-xs',dateTimeItem);
    
        me._timeLabel=timeLabel;
        me._dateLabel=dateLabel;

        me._timeLabel.innerHTML=VcDateUtils.formatDateCustom(map.options.dateTime,"HHmm");
        me._dateLabel.innerHTML=VcDateUtils.formatDateCustom(map.options.dateTime,"D MMM");

        let fwdButton=L.DomUtil.create('a','leaflet-control-zoom-in px-1 ',animationCtrlItem);
        L.DomUtil.create('i','bi bi-chevron-right',fwdButton);

        let playPauseButton=L.DomUtil.create('a','leaflet-control-zoom-in px-1 ',animationCtrlItem);
        L.DomUtil.create('i','bi bi-play mapplay',playPauseButton);
        L.DomUtil.create('i','bi bi-pause mappause',playPauseButton);

        let resetTimeButton=L.DomUtil.create('a','leaflet-control-zoom-in px-1',animationCtrlItem);
        L.DomUtil.create('i','bi bi-clock',resetTimeButton);
        
        let fullScreenButton=L.DomUtil.create('a','leaflet-control-zoom-in px-1',animationCtrlItem);
        L.DomUtil.create('i','bi bi-arrows-fullscreen mapexpand ',fullScreenButton);
        L.DomUtil.create('i','bi bi-arrows-collapse mapcollapse',fullScreenButton);
   

		L.DomEvent.disableClickPropagation(backButton);
        L.DomEvent.disableClickPropagation(resetTimeButton);
        L.DomEvent.disableClickPropagation(fwdButton);
        L.DomEvent.disableClickPropagation(playPauseButton);
        L.DomEvent.disableClickPropagation(fullScreenButton);

        L.DomEvent.on(backButton, 'click', function(ev) {
            me.addDateTime(map, -1);
            L.DomEvent.stop(ev);
        });
        L.DomEvent.on(resetTimeButton, 'click', function(ev) {
            me.setDateTime(map);
            L.DomEvent.stop(ev);
        });
        L.DomEvent.on(fwdButton, 'click', function(ev) {
            me.addDateTime(map, 1);
            L.DomEvent.stop(ev);
        });

        L.DomEvent.on(fullScreenButton, 'click', function(ev) {
            const mapContainer=map.getContainer().parentElement;
            if (mapContainer.classList.contains("fullscreen")) {
                mapContainer.classList.remove("fullscreen");
            } else {
                mapContainer.classList.add("fullscreen");
            }

            L.DomEvent.stop(ev);
        });

        L.DomEvent.on(playPauseButton, 'click', function(ev) {
            const mapContainer=map.getContainer().parentElement;
            if (mapContainer.classList.contains("isplaying")) {
                mapContainer.classList.remove("isplaying");
                me.togglePlay(map, false);
            } else {
                mapContainer.classList.add("isplaying");
                me.togglePlay(map, true);
            }

            L.DomEvent.stop(ev);
        });


        if (!map.options.dateTime) {
            me.setDateTime(map );
           
        }
    },

    togglePlay:function(map, play) {
        let me=this;
        if (!play) {
            clearTimeout(me._animationHandle);
            me._animationHandle=0;
            return;
        }

        me.setAnimationFrame(map);

        

    },
    setAnimationFrame:function(map) {
        let me=this;
        let endDate=me.truncateToMinuteInterval(me.getTimeInterval());
        if (map.options.dateTime.getTime()>endDate.getTime()) {
            me._animationHandle=setTimeout(function() {
                me.addDateTime(map, -10);
                me.setAnimationFrame(map);
            }, me._animationStepInterval*3);
        } else {
            me._animationHandle=setTimeout(function() {
                me.addDateTime(map, 1);
                me.setAnimationFrame(map);
            }, me._animationStepInterval);
        }
    },

    truncateToMinuteInterval:function(minuteInterval, date) {
        let me=this;
        if (!date) {
            let nowDate=new Date();
            let truncatedDate=me.truncateToMinuteInterval(minuteInterval, nowDate);
            //if the current truncated time is too recent, data won't be available to step back another interval
            if (((nowDate.getTime()-truncatedDate.getTime())/60000)<minuteInterval/2) {
                truncatedDate= new Date(truncatedDate.getTime()-minuteInterval*60000);
            }
            return truncatedDate;
        }
        let timeToReturn = new Date(date.getTime());
        timeToReturn.setMilliseconds(0);
        timeToReturn.setSeconds(0);
        timeToReturn.setMinutes(Math.floor(timeToReturn.getMinutes() / minuteInterval) * minuteInterval);
        return timeToReturn;
    },
    

    onAdd: function(map) {

        let me = this;
        let div = L.DomUtil.create('div','d-flex flex-column');
        
        div.innerHTML=""

        if (me.options.showDateTimeControl) {
           me.createDateTimeControl(div, map);
        }
        


        map.on('layeradd', function(evt) {
            if (evt.layer && evt.layer.options && evt.layer.options.model) {
                me._currentLayer=evt.layer;
            }
            //me._wxLayers.push(evt.layer);

            evt.layer.on('legendchanged', function(legend) {
                me.addLayerLegend(legend)
            })
            
        });
        map.off('layerremove');
        return div;
    },

    onRemove: function(map) {
        // Nothing to do here
    },
    _layers:{

    },
    addLayerLegend(evt) {
        let me = this;
        console.log('legendchanged: ', evt);
        let container=me._container;
        let legendContainer=me._layers[evt.target];
        if (!legendContainer) {
            me._layers[evt.target]=L.DomUtil.create('div','d-flex fs-xs text-white',container);
            legendContainer=me._layers[evt.target];
        } else {
            //legendContainer.innerHTML="";
        }
        
        


        L.DomUtil.empty(legendContainer);

        let items=evt.items;
        for (let i=0;i<items.length;i++) {
            let legendItem=L.DomUtil.create('div','flex-fill px-2',legendContainer);
            let decimals=Math.abs(items[i].min)<10?1:0;
            legendItem.innerHTML=items[i].min.toFixed(decimals);
            legendItem.style="background-color:"+items[i].color;
        }
        
    }

});

