<template>
  <div>
    <div class="container">
      <table v-if="hasResultData" class="table table-striped">
        <thead>
          <tr>
            <th v-for="c in columnHeaders" scope="col">{{c}}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="day in resultViewData">
            <tr>
              <td v-for="c in columnHeaders" scope="col">{{day[c]}}</td>
            </tr>
            <tr  v-for="hour in day.hours">
               <td v-for="c in columnHeaders" scope="col">{{hour[c]}}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-else>
        Please run some data
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'queryResults',
  props: {
    packageDefn:Object,
    resultData:Object
  },
  data() {
    return {
    
    }
  },
  methods:{

  },
  computed:{
    columnHeaders() {
      var me=this;
      if (!me.hasResultData) return [];
      var iterateProps=function(a, d) {
        for (var p in d) {
          if (p==='hours') continue;
          if (p && a.indexOf(p)===-1) a.push(p);
        }
      }

      return me.resultData.days.reduce(function (a, d) {
        iterateProps(a,d)
        d.hours && d.hours.forEach(h => {
           iterateProps(a,h)
        });

        return a;
      }, []);
    },
    resultViewData() {
      var me=this;
      if (!me.hasResultData) return [];
      
      return me.resultData.days
    },
    hasResultData() {
      return this.resultData;
    },
    queryDefn() {
      return this.packageDefn.params || {};
    },
  },
  mounted() {
   
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
