

<script>

import {VcWidgetHelper} from './common_widget.js'
//import KpiWidget from './kpiwidget.vue'
//import LocationEditor from './locationeditor.vue'
import {VcDataProcessing} from '/../visualcrossing-core-js/src/vcdataprocessing';
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils';
import StationInfoWidget from './station-info-widget'
export default {
  name: 'grid-widget',
  props:{
    weatherData:Object,
    parentData:Boolean,
    blurAboveIndex:Number,
    queryDefn:Object,
    config:Object,
    hasSession:Boolean,
    initialView:String,
  },
  emits: ['update:view','update:costsaver','update:panel'],
  data() {
    return {
      GROUPINGS:[
        {
          label:"Date time",
          columns:['datetime'],
          id:'datetime'
        },
        {
          label:"Temperature",
          columns:['tempmax', 'tempmin', 'temp', 'feelslikemax', 'feelslikemin', 'feelslike'],
        },
        {
          label:"Humidity & Clouds",
          columns:[ 'dew', 'humidity',  'cloudcover', 'visibility'],
        },
        {
          label:"Precipitation",
          columns:[ 'precip', 'precipprob', 'precipcover', 'preciptype', 'snow', 'snowdepth'],
        },
        {
          label:"Wind & Pressure",
          columns:[  'windgust', 'windspeed', 'winddir', 'pressure', 'severerisk'],
        },
        {
          label:"Solar",
          columns:[ 'solarradiation', 'solarenergy', 'uvindex'],
        },
        {
          label:"Astronomy",
          columns:[ 'sunrise',  'sunset',  'moonphase'],
        },
        {
          label:"Conditions",
          columns:[ 'conditions','description', 'icon'],
        },
        {
          label:"Info",
          columns:[ 'stations', 'source'],
        }
    
      ],
      id:null,
      location:"?",
      name:null,
      key:null,
      startdate:null,
      enddate:null,
      unitGroup:null,
      wxdata:this.weatherData,
      error:null,
      include:null,
      forecastBasisDate:null,
      forecastBasisDay:null,
      dayIndex:0,
      hourIndex:null,
      period:"day",
      rooturl:null,
      widgetcolumncount:1,
      view:this.initialView || "days",
      pageSize:10,
      pageIndex:0,
      //currentCols:null,
      //version:1
    }
  },
  methods:{
    hasQueryInclude(include) {
      var me=this;
      if ('stations'===include) {
        return !me.queryElements || me.queryElements.indexOf("stations")!==-1;
      }
      if ('events'===include) { //default off sp special case for now
        return me.queryDefn && me.queryDefn.include &&  VcListUtils.hasValueInList(me.queryDefn.include, include);
      }
      return !me.queryDefn || !me.queryDefn.include || VcListUtils.hasValueInList(me.queryDefn.include, include);
    },
    refreshData() {
      var me=this;
      if (me.parentData) return;
      VcWidgetHelper.populateData( {
                    location:me.location,
                    startdate:me.startdate,
                    enddate:me.enddate,
                    key:me.key,
                    unitgroup:me.unitGroup,
                    elements:me.elements,
                    include:me.include,
                    forecastBasisDate:me.forecastBasisDate,
                    forecastBasisDay:me.forecastBasisDay,
                    id:me.id,
                    forceRefresh:me.forceRefresh,
                })
      .then(response => {
          me.wxdata=response.data;
          if (me.wxdata.unitGroup ) me.unitGroup=me.wxdata.unitGroup;
          if (!me.location) me.location=me.wxdata.resolvedAddress;
      }).catch((error) => {
          me.error=(error && error.msg) || "An unknown error occurred";
      });
    },
    formatValue(value, element) {
      var me=this;
      return VcWidgetHelper.formatElementValue(value, element,me.unitGroup );
    },
    formatTime(period) {
      return VcWidgetHelper.formatTime(period.datetimeEpoch, this.wxdata.timezone);
    },
    formatDate(period) {
      return VcWidgetHelper.formatDate(period.datetimeEpoch, this.wxdata.timezone);
    },
    doShow(element, hideByDefault) {
      if (hideByDefault && !this.viewincludes) return false;
      return !this.viewincludes || this.viewincludes.indexOf(element)!==-1;
    },
    tdValue(value, prop) {
      //if (this.blurAboveIndex && this.view==="info" && ("latitude"===prop || "longitude"===prop)) {
      //  return "-";
      //}
      if (prop==='name') return this.wxdata.name || this.wxdata.resolvedAddress;
      //if (!value) value=this.wxdata[prop];
      if (!value) return value;
      if (Array.isArray(value) ) value= value.join(",");

      if (value && value.length>25) value=value.substring(0,25)+"...";
      return value;
    },
    setView(v) {
      this.view=v;
      this.$emit('update:view', v);
    },
    changeCostSaver(value) {
      var me=this;
      if (value) me.pageIndex=0;
      me.$emit('update:costsaver',value)
    },
    changePanel(value) {
      var me=this;
      me.$emit('update:panel',value)
    }
  },
  computed:{
    includeLabel() {
      var me=this;
      var includes=me.queryDefn && me.queryDefn.include &&  VcListUtils.valuesInList(me.queryDefn.include);
      return (includes && includes.join(", ")) || "days, hours, current and alerts";
    },
    queryElements() {
      return this.queryDefn && this.queryDefn.elements && VcListUtils.valuesInList(this.queryDefn.elements);
    },
    sectionQueryCost() {
      var sectionCost=this.wxdata && this.wxdata.queryCost;
     
      if (this.view!=="hours" && sectionCost>1 && this.wxdata.days && this.wxdata.days.length<sectionCost) sectionCost=this.wxdata.days.length;
       
      return (sectionCost && (`${sectionCost} ($${(sectionCost*0.0001).toFixed(4)})`)) || "-";

    },
    adjustedLocation() {
      return VcWidgetHelper.resolveLocation(this.wxdata, this.location, this.name, );
    },
    hasError:function() {
      return this.error;
    },
    

    columnHeaders() {
      var me=this;
      if (!me.hasResultData) return [];
      
      return VcDataProcessing.columnHeaders(me.queryElements,me.resultViewData, me.view);
     
    },
    resultViewData() {
      var me=this;
      if (!me.hasResultData) return [];
      return VcDataProcessing.gridFromResult(me.wxdata,me.view );
     
    },
    hasResultData() {
      return this.wxdata;
    },
    resultViewRowcount() {
      return (this.hasResultData && this.resultViewData && this.resultViewData.length);
    },
    isEmptyData() {
      return this.hasResultData && this.resultViewRowcount==0;
    },
    pageCount() {
      return Math.ceil(this.resultViewRowcount/this.pageSize);
    },
    hasPages() {
      return this.pageCount>1;
    },
    pageResultViewData() {
      var me=this;
      var allData=me.resultViewData;
      if (!me.hasResultData || !me.hasPages) return allData;
      return allData.slice(me.pageIndex*me.pageSize, (me.pageIndex+1)*me.pageSize)
    },
    minPage() {
      return Math.floor(this.pageIndex/10)*10;
    },
    maxPage() {
       return Math.min(Math.ceil((this.pageIndex+1)/10)*10, this.pageCount-1);
    },
    visiblePages() {
      var output=[];
      for (var i=this.minPage;i<=this.maxPage;i++) {
        output.push(i);
      }
      return output;
    },
  },
  mounted() {
    //console.log("mounted")
    var me=this;
    var containerElement=me.$el.parentElement;
    VcWidgetHelper.readElementConfig(containerElement, me);
    me.refreshData();
  },
  watch: {
  
     
    weatherData:{
      handler:function(newValue,oldValue) {
        this.wxdata=newValue;
      },
      deep: true
    },
     queryDefn:{
        handler:function(newValue,oldValue) {
          var me=this;
          var containerElement=me.$el.parentElement;
          VcWidgetHelper.readElementConfig(containerElement, me);
          me.refreshData();
        },
        deep: true
    },
  },
  components: {
    StationInfoWidget
  }
}
</script>
<template>

    <div class="d-flex flex-column" style="min-height:400px">
      <div class="d-flex flex-wrap align-items-end justify-sm-content-center mb-1" role="group" aria-label="">
        <div >
          <div class="text-muted fs-sm my-1" >Weather Data</div>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='days'}" @click="setView('days')" ><i class="bi bi-calendar3"></i><span class="d-none d-lg-block"> Daily</span></button>
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='hours'}" @click="setView('hours')" ><i class="bi bi-clock"></i><span class="d-none d-lg-block"> Hourly</span></button>
            <!--<button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='subhourly'}" @click="setView('subhourly')" :disabled="!hasQueryInclude('subhourly')"><i class="bi bi-clock"></i><span class="d-none d-lg-block"> Subhourly</span></button>-->
          </div>
        </div>
        <div class="vr mx-2 mt-4 text-muted  "></div>
        <div>
          <div class="text-muted fs-sm my-1" >Additional Data</div>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='current'}" @click="setView('current')"><i class="bi bi-hourglass"></i><span class="d-none d-lg-block"> Current</span></button>
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='events'}" @click="setView('events')" ><i class="bi bi-tornado"></i><span class="d-none d-lg-block"> Events</span></button>
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='alerts'}" @click="setView('alerts')"><i class="bi bi-exclamation-triangle"></i><span class="d-none d-lg-block"> Alerts</span></button>
          </div>
        </div>
        <div class="vr mx-2 mt-4 text-muted "></div>
        <div>
          <div class="text-muted fs-sm my-1" >Data Details</div>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='info'}" @click="setView('info')"><i class="bi bi-info"></i><span class="d-none d-lg-block"> Info</span></button>
            <button type="button" class="btn btn-outline-primary btn-sm" :class="{active:view==='stations'}" @click="setView('stations')"><i class="bi bi-rulers"></i><span class="d-none d-lg-block"> Stations</span></button>
          </div>
        </div>
        <div v-if="hasResultData" class="text-center ms-auto">
            <a href="/resources/documentation/weather-data/weather-data-documentation/" target="_blank" class="btn btn-primary btn-sm me-2" type="button" >
              <i class="bi-question-circle" ></i> <span class="d-none d-lg-inline" >Questions about the data?</span>
            </a>
            <button type="button" class="btn btn-primary btn-sm " @click="changePanel('export')" >
              <i class="bi-download"></i><span class="d-none d-lg-inline" > Download</span>
            </button>
        </div> 
      </div>
      <div class="text-muted fs-sm my-1" >Available weather data for <span class="fw-bold">{{adjustedLocation}}</span>. These results are filtered by your query options.</div>

      <div v-if="isEmptyData" class="flex-fill d-flex justify-content-center mt-3 mb-2">
        <template v-if="view==='current'">
          <div v-if="!hasQueryInclude('current')" class="d-flex align-items-center  flex-column">
            <div class="mb-3 fw-bold"><i class="bi bi-code-slash me-1"></i>Current conditions have not been selected for this query.</div>
            <div>
              If you would like to see this data you must select it from the <a href="#" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='datasections'">Data Section</a> 
              page. CSV format can include one data section. JSON  format can include multiple data section. Available datasets sections are: Hourly, Daily, Alerts, Events, Stations.  The currently selected datasets are: <span class="fw-bold">{{includeLabel}}</span>. 
            </div>
          </div>
          <div v-else  class="d-flex align-items-center ">
            <i class="bi bi-code-slash me-1"></i>There is no current conditions data. Current data is only available for requests including today's date or specific time requests.
          </div>
        </template>
        <template v-else-if="view==='alerts'">
          <div v-if="!hasQueryInclude('alerts')" class="d-flex align-items-center  flex-column">
            <div class="mb-3 fw-bold"><i class="bi bi-code-slash me-1"></i>Alerts have not been selected for this query.</div>
            <div>
              If you would like to see this data you must select it from the <a href="#" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='datasections'">Data Section</a> 
              page. CSV format can include one data section. JSON  format can include multiple data section. Available datasets sections are: Hourly, Daily, Alerts, Events, Stations.  The currently selected datasets are: <span class="fw-bold">{{includeLabel}}</span>. 
            </div>
          </div>
          <div v-else  class="d-flex align-items-center ">
            <i class="bi bi-code-slash me-1"></i>There is no alert data.  There may not be any active locations for this locations or you have not requested data for today's date.
          </div>
        </template>
        <template v-else-if="view==='events'">
          <div v-if="!hasQueryInclude('events')" class="d-flex align-items-center flex-column">
           <div class="mb-3 fw-bold"><i class="bi bi-code-slash me-1"></i>Events have not been selected for this query.</div>
           <div>
              If you would like to see this data you must select it from the <a href="#" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='datasections'">Data Section</a> 
              page. CSV format can include one data section. JSON  format can include multiple data section. Available datasets sections are: Hourly, Daily, Alerts, Events, Stations.  The currently selected datasets are: <span class="fw-bold">{{includeLabel}}</span>. 
            </div>
          </div>
          <div v-else  class="d-flex align-items-center ">
            <i class="bi bi-code-slash me-1"></i>There is no events data. There may not have been any recorded weather events in the selected time range.
          </div>
        </template>

        <template v-else-if="view==='stations'">
          <div v-if="!hasQueryInclude('stations')" class="d-flex align-items-center">
            <i class="bi bi-code-slash me-1"></i>Stations are not selected in the query elements.<button class="btn btn-sm text-primary px-1 py-0 " type="button" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='elements'">Enable stations in the elements list.</button>  .
          </div>
          <div v-else  class="d-flex align-items-center ">
            <i class="bi bi-code-slash me-1"></i>There are no available stations near this location.
          </div>
        </template>
        <template v-else-if="view==='hours'">
          <div v-if="!hasQueryInclude('hours')" class="d-flex align-items-center  flex-column">
            <div class="mb-3 fw-bold"><i class="bi bi-code-slash me-1"></i>Hours have not been selected for this query.</div>
            <div>
              If you would like to see this data you must select it from the <a href="#" data-bs-toggle="modal" data-bs-target="#queryEditorModel" @click="queryEditorSection='datasections'">Data Section</a> 
              page. CSV format can include one data section. JSON  format can include multiple data section. Available datasets sections are: Hourly, Daily, Alerts, Events, Stations.  The currently selected datasets are: <span class="fw-bold">{{includeLabel}}</span>. 
            </div>
          </div>
          <div v-else  class="d-flex align-items-center ">
            <i class="bi bi-code-slash me-1"></i>There is no data in this selection.
          </div>
        </template>
         <template v-else>
          <i class="bi bi-code-slash me-1"></i>There is no data in this selection.
         </template>
      </div>
      <div v-if="hasResultData" class="table-responsive">
        
       <table v-if="view==='days'" class="table table-striped fs-xs">
          <thead>
            <tr>
              <th v-for="c in columnHeaders" scope="col" class="px-2 py-1">{{c}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item,index) in pageResultViewData">
              <tr :class="{'vc-blur':(blurAboveIndex && index>blurAboveIndex)}">
                <td v-for="c in columnHeaders" scope="col" class="text-nowrap px-2 py-1">
                  <template v-if="c==='datetime'">
                    <a href="#" class="link"  @click="view='hours'"> {{tdValue(item[c])}}</a>
                  </template>
                  <template v-else>
                    {{tdValue(item[c], c)}}
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
           
        </table>
     
        <table v-else-if="view==='hours'" class="table table-striped  fs-xs">
          <thead>
            <tr>
              <th  v-for="c in columnHeaders" scope="col" class="px-2 py-1">{{c}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item,index) in pageResultViewData">
               
              <tr v-if="item.daydatetime">
                <th :colspan="columnHeaders.length" class="text-nowrap px-2 py-1">{{item.daydatetime}}</th>
              </tr>
              <tr :class="{'vc-blur':(blurAboveIndex && index>blurAboveIndex)}">
                <td v-for="c in columnHeaders" scope="col" class="text-nowrap px-2 py-1">
                    {{tdValue(item[c], c)}}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <StationInfoWidget v-else-if="view==='stations' && hasQueryInclude('stations')" :weather-data="wxdata" :parent-data="true" :queryDefn="queryDefn" :config="{unitgroup:unitGroup}" />
        <table v-else class="table table-striped  fs-xs">
          <thead>
            <tr>
              <th v-for="c in columnHeaders" scope="col"  class="px-2 py-1">{{c}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item,index) in pageResultViewData">
              <tr :class="{'vc-blur':(blurAboveIndex && index>blurAboveIndex  && view!=='stations')}">
                <td v-for="c in columnHeaders" scope="col" class="text-nowrap px-2 py-1">
                  {{tdValue(item[c], c)}}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="hasPages && blurAboveIndex" class="my-1">
        <i class="bi bi-lock me-1"></i> 1 of {{pageCount}} pages. 

        <template v-if="!hasSession" class="" >
          <i class="bi bi-lock"></i> Sign in to see the full dataset.
        </template>
        <button v-else type="button" class="btn btn-sm text-primary text-right"  @click="changeCostSaver(false)">
          <i class="bi bi-chevron-bar-expand"></i><span class="d-none d-md-inline"> Switch to full dataset</span>
        </button>
        <!--Please request full dataset to access additional pages.-->

      </div>
      <div v-else-if="hasPages" >
        <ul class="pagination align-items-center ">
          <li class="page-item"  :class="{disabled: 0===pageIndex}">
            <a class="page-link" @click="pageIndex=0" href="#" aria-label="Start">
              <span aria-hidden="true">&lt;&lt;</span>
            </a>
          </li>
            <li class="page-item"  :class="{disabled: 0===pageIndex}">
            <a class="page-link" @click="pageIndex--" href="#" aria-label="Previous">
              <span aria-hidden="true">&lt;</span>
            </a>
          </li>
          <li v-for="page in visiblePages" class="page-item" :class="{active: page===pageIndex}"><a class="page-link" href="#" @click="pageIndex=page">{{page+1}}</a></li>
          
          <li class="page-item"   :class="{disabled: pageCount-1===pageIndex}">
            <a class="page-link" @click="pageIndex++"  href="#" aria-label="Next">
              <span aria-hidden="true">&gt;</span>
            </a>
          </li>
          <li class="page-item"    :class="{disabled: pageCount-1===pageIndex}">
            <a class="page-link" @click="pageIndex=pageCount-1" href="#" aria-label="Last">
              <span aria-hidden="true">&gt;&gt;</span>
            </a>
          </li>
          <button type="button" class="btn btn-sm text-primary"  @click="changeCostSaver(true)">
            <i class="bi-chevron-bar-contract"></i><span class="d-none d-md-inline"> Switch to preview dataset</span>
          </button>
        </ul>
        
      </div>
      <div v-if="!hasResultData" class="flex-fill d-flex justify-content-center mt-5">
        <i class="bi bi-code-slash me-1"></i>Please run some data
      </div>
  </div>
</template>
<style scoped>
  tr.vc-blur, tr.vc-blur td {
    text-shadow: 0px 0px 5px #aaa !important;
    color: transparent  !important;
  }
  .vc-vh-50 {
    height: 50vh;
  }

</style>
