


export var VcConversion = (function () {
  const UNITGROUPS=[
        {
          label:"US (&#176;F, miles)",
          shortlabel:"&#176;F, mi",
          id:"us",
        },
        {
          label:"Metric (&#176;C, km)",
          shortlabel:"&#176;C, km",
          id:"metric",
        },
        {
          label:"UK (&#176;C, miles)",
          shortlabel:"&#176;C, mi",
          id:"uk",
        },
    ]
    const UNITS={
        //speed
        MPERSEC:{
            name:"m/s",
            symbol:"m/s",
            base:function() {return UNITS.MPERSEC},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v; },
                fromBase:function(v) { return v; }
            }
        },
        STATUTEMILESPERHOUR:{
            name:"mph",
            symbol:"mph",
            base:function() {return UNITS.MPERSEC},
            othersymbols:["mi/s"],
            converter:{
                toBase:function(v) { return v/2.2369363; },
                fromBase:function(v) { return v*2.2369363; }
            }
        },
        KMPERHOUR:{
            name:"kph",
            symbol:"kph",
            base:function() {return UNITS.MPERSEC},
            othersymbols:["km/s"],
            converter:{
                toBase:function(v) { return v/3.6; },
                fromBase:function(v) { return v*3.6; }
            }
        },
        //percent
        PERCENT:{
            name:"Percent",
            symbol:"%",
            base:function() {return UNITS.PERCENT},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v; },
                fromBase:function(v) { return v; }
            }
        },
        //temp

        KELVIN:{
            name:"Kelvin",
            symbol:"K",
            base:function() {return UNITS.KELVIN},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v; },
                fromBase:function(v) { return v; }
            }
        },
        CELCIUS:{
            name:"Celcius",
            symbol:"degC",
            symbol_html:"&#8451;",
            symbol_unicode:"\u2103",
            base:function() {return UNITS.KELVIN},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v+273.15; },
                fromBase:function(v) { return v-273.15; }
            }
        },
        FAHRENHEIT:{
            name:"Fahrenheit",
            symbol:"degF",
            symbol_html:"&#8457;",
            symbol_unicode:"\u2109",
            base:function() {return UNITS.KELVIN},
            othersymbols:[],
            converter:{
                toBase:function(v) { return (v + 459.67)*5/9; },
                fromBase:function(v) { return v* 9/5 - 459.67; }
            }
        },


        METER:{
            name:"Meter",
            symbol:"m",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v; },
                fromBase:function(v) { return v; }
            }
        },
        MILLIMETER:{
            name:"Millimeter",
            symbol:"mm",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v/1000; },
                fromBase:function(v) { return v*1000; }
            }
        },
        CENTIMETER:{
            name:"Centimeter",
            symbol:"cm",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v/100; },
                fromBase:function(v) { return v*100; }
            }
        },
        KILOMETER:{
            name:"Kilometer",
            symbol:"km",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v*1000; },
                fromBase:function(v) { return v/1000; }
            }
        },
        STATUTEMILE:{
            name:"Mile",
            symbol:"mi",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v*1609.34; },
                fromBase:function(v) { return v/1609.34; }
            }
        },
        INCH:{
            name:"Inch",
            symbol:"in",
            base:function() {return UNITS.METER},
            othersymbols:[],
            converter:{
                toBase:function(v) { return v*0.0254; },
                fromBase:function(v) { return v/0.0254; }
            }
        }

    }


    const quantityElements={
      "temp":["temp", "maxt", "mint","tempmin","tempmax","tempmean","dew"],
      "wind":["wgust","wspd","windspeed","windgust"],
      "precip":["precip", "qpf"],
      "visibility":["visibility"],
      "pressure":["pressure", "sealevelpressure"],
      "percent":["percent","pop"],
      "distance":["distance"],
      "snow":["snow"]
    }

    const quantityUnitGroups={
      base:{
        "temp":"K",
        "precip":"mm",
        "snow":"cm",
        "wind":"m/s",
        "visibility":"km",
        "percent":"%",
        "distance":"m"
      },
      us:{
        "temp":"degF",
        "precip":"in",
        "snow":"in",
        "wind":"mph",
        "visibility":"mi",
        "percent":"%",
        "distance":"mi"
      },
      uk:{
        "temp":"degC",
        "precip":"mm",
        "snow":"cm",
        "wind":"mph",
        "visibility":"mi",
        "percent":"%"
      },
      metric:{
        "temp":"degC",
        "precip":"mm",
        "snow":"cm",
        "wind":"kph",
        "visibility":"km",
        "percent":"%",
        "distance":"km"
      }
    }

    var symbolUnitLookup=null;
    var elementQuantityLookup=null;
  

    function findUnit(unitSymbol) {
        
        if (!symbolUnitLookup) {
            symbolUnitLookup={};
            Object.keys(UNITS).forEach(function(d) {
                var unit=UNITS[d];
                symbolUnitLookup[unit.symbol.toLowerCase()]=unit;
                unit.othersymbols.forEach(function(e) {
                    symbolUnitLookup[e.toLowerCase()]=unit;
                });
            });
        }
        
        if (unitSymbol) unitSymbol=unitSymbol.toLowerCase();

        if ( symbolUnitLookup[unitSymbol]) {
            return  symbolUnitLookup[unitSymbol];
        }

        console.log("Could not find unit for symbol "+unitSymbol);
        return null;
    }

    function convertValue(value, fromUnit, toUnit, decimalplaces) {
        


        if (fromUnit!=null && fromUnit===toUnit) return value;

        if ((typeof fromUnit === 'string' || fromUnit instanceof String)) {
            fromUnit= findUnit(fromUnit);
        }
        if ((typeof toUnit === 'string' || toUnit instanceof String)) {
            toUnit= findUnit(toUnit);
        }
        if (!fromUnit)  return value;

        if (!toUnit) return value;
        
        if (fromUnit==toUnit.base()) {
            value=toUnit.converter.fromBase(value);
        } else if (fromUnit.base()==toUnit) {
            value=fromUnit.converter.toBase(value);
        } else {
            value= toUnit.converter.fromBase(fromUnit.converter.toBase(value));
        }
        if (!decimalplaces && decimalplaces!==0) return value;

        return round(value, decimalplaces);
    }

    

   function  getElementQuantityType(element) {
        
        if (!elementQuantityLookup) {
            elementQuantityLookup={};
            Object.keys(quantityElements).forEach(function(d) {
                quantityElements[d].forEach(function(e) {
                    elementQuantityLookup[e]=d;   
                });
            });
        }
        return elementQuantityLookup[element];
    }
    function getElementUnit(group, element) {
        
        var quantity=getElementQuantityType(element);
        if (!quantity) return null;
        
        return getQuantityUnit(group, quantity);
    }
    function getQuantityUnit(group,  quantity) {
        
        if (!quantityUnitGroups[group]) throw ("Unknown unit group id"+group);
        return quantityUnitGroups[group][quantity];
    }
    function addValue( group,element, value, delta) {
        
        var v=convertValueFromBase(group, element, value);
        return convertValueToBase(group, element,  v+delta);
    }
    function convertValueFromBase(group, element, value, decimalplaces) {
        
      var baseUnit=getElementUnit("base", element);
      var groupUnit=getElementUnit(group, element);
      value=convertValue(value, baseUnit, groupUnit, decimalplaces);
      return value;
    }
    function convertValueToBase(group, element,  value, decimalplaces) {
      
      var baseUnit=getElementUnit("base", element);
      var groupUnit=getElementUnit(group, element);
      return convertValue(value, groupUnit, baseUnit, decimalplaces);
    }
    function formatValue(group,  element,  value, unicode) {
        if (element==="%") {
           return formatValueOnly(value)+element;
        }
        var groupUnit=getElementUnit(group, element);
        return formatValueFromUnit(value, groupUnit, unicode);
    }
    function formatValueFromUnit(value, unit, unicode) {
        
        if ((typeof unit === 'string' || unit instanceof String)) {
            unit= findUnit(unit);
        }
        if (!unit) {
          return formatValueOnly(value);
        }
      //var symbol=(unicode?unit.symbol_unicode:unit.symbol_html) || unit.symbol || "";
      const symbol=getUnitSymbol(unit, unicode);
      if (!value && value!==0) return symbol;
      return formatValueOnly(value)+symbol;
    }
    function getElementSymbol(group,  element, unicode) {
        let unit=getElementUnit(group, element);
        unit=findUnit(unit);
        return (unicode?unit.symbol_unicode:unit.symbol_html) || unit.symbol || "";
    }
    function getUnitSymbol(unit, unicode) {
        return (unicode?unit.symbol_unicode:unit.symbol_html) || unit.symbol || "";
    }
    function formatValueOnly(value) {
        if (!isFinite(value) || (!value && value!==0)) return "-";
        if (value>10) {
        value=round(value);
        } else  if (value>1) {
        value=round(value, 1);
        } else {
        value=round(value, 2);
        }
        
        return value;
    }
    
    function round(value, decimals) {
        decimals=decimals || 0 ;
        return Math.round(value*Math.pow(10,decimals))/Math.pow(10,decimals);
        //let precision=decimals+Math.floor(Math.log10(value));
        //let n=Number(value);
        //return n.toPrecision(precision);
    }


    return {
      UNITGROUPS:UNITGROUPS,
      findUnit:findUnit,
      convertValue:convertValue,
      getElementUnit:getElementUnit,
      getQuantityUnit:getQuantityUnit,
      convertValueToBase:convertValueToBase,
      convertValueFromBase:convertValueFromBase,
      formatValueFromUnit:formatValueFromUnit,
      formatValueOnly:formatValueOnly,
      formatValue:formatValue,
      getElementSymbol:getElementSymbol,
    }
}());
