<template>
  <div class="row">
    <div v-if="showContentType" class="col-12 " :class="{'col-md-6':showAll}">
      <div class="text-muted fs-sm my-1">Output content type</div>
      <select class="form-select form-select-sm" aria-label="Select content type" v-model="queryDefinition.contentType" :disabled="disabled">
        <option disabled value="">Select content type</option>
        <option value="csv">CSV</option>
        <option value="xlsx">Excel</option>
        <option value="json">JSON</option>
      </select>
    </div>
    <!--<div class="mt-3 text-primary h6">Selected data sections</div>-->
    <template v-if="showInclude">
      <div v-if="isTable"  class="col-12" :class="{'col-md-6':showAll}">
        <div class="text-muted fs-sm my-1">Output section</div>
        <select class="form-select form-select-sm" v-model="tableinclude" :disabled="disabled">
          <option disabled value="">Please select one</option>
          <option v-for="(include,i) in dataIncludes" :value="include.id">{{include.label}}</option>
        </select>
      </div>
      <div v-else class="col-12" :class="{'col-md-6':showAll}">
        <div class="text-muted fs-sm my-1">Output sections</div>
        <div v-for="(include,i) in dataIncludes" :key="include.id" class="form-check form-check-inline fs-sm  my-0" >
          <input type="checkbox"  :id="'include-'+include.id" v-model="include.value" class="form-check-input" :indeterminate.prop="defaultIncludes && isDefault(include.id)" :disabled="disabled"/>
          <label class="form-check-label" :for="'include-'+include.id" >{{include.label}}</label>
        </div>
      </div>
    </template>
   <!-- {{queryDefinition.contentType}}: {{queryDefinition.include}}-->
  </div>
</template>

<script>
import {VcListUtils} from '/../visualcrossing-core-js/src/vclistutils';
import {VcRandomStringGenerator} from '/../visualcrossing-core-js/src/vcrandomstringgenerator';
export default {
  name: 'contenttypechooser',
  props: {
    queryDefinition:Object,
    disabled:Boolean,
    showContentType:{
       type:Boolean,
       default:true
    },
      showInclude:{
       type:Boolean,
       default:true
    },
  },
  data() {
    return {
      scopeId:VcRandomStringGenerator.make(6),
      
      includes:[
      {
        id:"days",
        label:"Daily",
        value:false,
        defJson:true,
      },
      {
        id:"hours",
        label:"Hourly",
        value:false,
         defJson:true,
      },
      {
        id:"alerts",
        label:"Alerts",
        value:false,
         defJson:true,
      },
      {
        id:"current",
        label:"Current",
        value:false,
        defJson:true,
      },
      {
        id:"events",
        label:"Events",
        value:false,
        defJson:false,
      }           
      
    ],
    }
  },
  methods:{

   isDefault(id) {
      var selectedIncludes=this.dataIncludes;
      return selectedIncludes.reduce(function(a,d) {
        return a || (d.id===id && d.defJson);
      }, false);
    },
  },
  computed:{
    showAll() {
      var me=this;
      return me.showContentType && me.showInclude;
    },
   
    defaultIncludes() {
      var selectedIncludes=this.dataIncludes;
      return !selectedIncludes.reduce(function(a,d) {
        return a || d.value
      }, false);
  
    },
    dataIncludes() {
      return VcListUtils.filterArrayById(VcListUtils.CSVINCLUDESIDS, this.includes);
    },
    tableinclude: {
      get: function () {
        let values=VcListUtils.valuesInList(this.queryDefinition.include)
        if (!values || !values.length) return null;
        return values.reduce(function(a, d) {
          return a || (VcListUtils.CSVINCLUDESIDS.indexOf(d)!==-1?d:null) ;
        }, null);
        //return VcListUtils.valuesInList(this.queryDefinition.include)[0];
      },
      set: function (newValue) {
        let me=this;
        VcListUtils.CSVINCLUDESIDS.forEach(function(d) {
          me.queryDefinition.include=VcListUtils.setValueInList(me.queryDefinition.include, d, d===newValue, false);
        });
        
      }
    },
    
    isTable() {
        return this.queryDefinition.contentType!=="json";
    },
  },
  mounted() {
    //console.log("Created with id "+this.scopeId)
  },
  watch: {
    "queryDefinition.contentType":function(newValue) {
      var me=this;
      if (newValue==="json") return;
      var hasDataInclude=me.includes.reduce(function(a, d) {
                        if (a && d.value) d.value=false;
                        return a || (d && d.value);
                      }, false);
      

      if (!hasDataInclude) {
        VcListUtils.filterArrayById(["days"], me.includes)[0].value=true;
      }
      
    },
    "queryDefinition.include":function(newValue) {
      //console.log("queryDefinition.include "+this.scopeId)
      this.includes.forEach(function(d) {
        d.value=newValue && VcListUtils.hasValueInList(newValue, d.id);
      });
    },
    includes:{
        handler:function(newValue,oldValue) {
          var me=this;
          
          newValue.forEach(function(d) {
             me.queryDefinition.include=VcListUtils.setValueInList(me.queryDefinition.include,d.id, d.value, false);
          })
         
          /*
          me.queryDefinition.include=newValue.reduce(function(a, d) {
            if (!d.value) return a;
            if (a) a+=",";
            return a+d.id;
          }, "");
          */
        },
        deep: true
    },
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>