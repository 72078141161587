export var VcStatistics = (function () {
    

    return {
      regression:function(data) {
        if (!data || !data.length) return null; 
        let xsum=0, ysum=0, x2sum=0, xysum=0, n=0;
        data.forEach(d => {
          if (!d || d.length!==2 ) return;
          xsum+=d[0];
          ysum+=d[1];
          x2sum+=d[0]*d[0];
          xysum+=d[0]*d[1];
          n++;
        });

        let output={ 
          m:  (n*xysum-xsum*ysum)/(n*x2sum - xsum*xsum),
          c: 0
        }
          
        output.b=(ysum-output.m*xsum)/n;
        //also calculate y intercept
        output.c=-output.b/output.m;
        return output;
      }
    }
}());