
import './wintertracker.scss'

import "/src/main.js"
import "/src/basecomponents.js"

import { createApp } from 'vue'
import WinterTrackerComponent from './WinterTrackerComponent'


(function(w,d) {
 
  
  window.addEventListener("load",function(event) {
    createApp(WinterTrackerComponent).mount("#winterTrackerComponent")
  },false);
  
})(window, document);