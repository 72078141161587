<template>
  <div>
    <!--<div v-if="hasSession" class="">
      <a class="btn btn-sm text-primary order-lg-3" href="#signoutModal" data-bs-toggle="modal"><i class="bi bi-person me-2"></i>Sign out</a>
      <a class="btn btn-primary btn-sm rounded-pill" href="/account">Account</a>
    </div>
    <div v-else class="">
      <a class="btn btn-sm text-primary order-lg-3" href="#signinModal" data-bs-toggle="modal"><i class="bi bi-person me-2"></i>Sign in</a>
      <a class="btn btn-primary btn-sm rounded-pill"  href="/sign-up">Sign up</a>
    </div>
    -->
    <div class="modal" id="signinModal" tabindex="-1" aria-labelledby="signinModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="signinModalLabel">Sign in to your account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="username"  autocomplete="username">
                <div  class="form-text">Don't have any account? <span><a href="/sign-up" >Sign up</a></span></div>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" v-model="password"  autocomplete="current-password">
                <div class="form-text"><span><a href="/change-password" >Reset password</a>.</span></div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click.stop="signIn()" >Sign in</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" id="signoutModal" tabindex="-1" aria-labelledby="signoutModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="signoutModalLabel">Are you sure you want to sign out?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
            <div class="modal-body">
              <div class="form-text">Confirm that you want to log out</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"  @click="signOut()">Sign out</button>
            </div>
        </div>
      </div>
    </div>
    
    
    <div class="modal errorModal" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="errorModalLabel1">{{errorTitle}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
              <div class="modal-body">
                <div class="form-text">{{errorDetails}}</div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
        </div>
    </div>

    <div class="modal cookieModal " id="errorModal" tabindex="-1" aria-labelledby="cookieModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="cookieModalLabel">Cookie consent</h5>
            </div>
              <div class="modal-body">
                <div class="form-text">
                  <p>
                    We use necessary cookies to make our site work. We'd like to set additional cookies to understand site usage, make site improvements and to remember your settings. We also use cookies set by other sites to help deliver content from their services.  
                  </p>
                  <p>
                    To learn more please read our <a target="_blank" href="/privacy">Privacy and Cookie Policy</a>.
                  </p>
                </div>
                <div class="row">
                  <a href="/cookies" class="btn btn-sm btn-secondary col-lg-3" >Customize my cookies</a>
                  <div class="d-none d-lg-block col-lg-3">&nbsp;</div>
                  <button type="button" class="btn btn-sm btn-primary col-lg-3" data-bs-dismiss="modal" @click="acceptAllCookies()">Accept all cookies</button>
                  <button type="button" class="btn btn-sm btn-secondary col-lg-3" data-bs-dismiss="modal" @click="rejectOptionalCookies()">Reject optional cookies</button>
                  
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {VcLocationsStorage} from "/../visualcrossing-core-js/src/vclocationsstorage";

export default {
  name: 'SignInSignOut',
  props: {
    msg: String
  },
  data() {
    return {
      username:null,
      password:null,
      sessionId:null,
      errorTitle:null,
      errorDetails:null
    }
  },
  methods:{
    showCookieConfirmation() {
      var me=this;
      if (VcLocationsStorage.getValue("hasSetCookies")) return;
      if (window['vc-nocookieprompt']) return;

      var myModalEl=me.$el.querySelector(".cookieModal");
      var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
     // var modal = new bootstrap.Modal(myModalEl,{backdrop: 'static', keyboard: false})
      modal.show();

    },
    acceptAllCookies() {
     
      //VcLocationsStorage.setValue("hasSetCookies", 1, true);
      VcLocationsStorage.enableAllCookies();
    },
    rejectOptionalCookies() {
       
      //VcLocationsStorage.setValue("hasSetCookies", 1, true);
      VcLocationsStorage.disableAllCookies();
    },

    showError(title, details) {
      var me=this;
      me.errorTitle=title; 
      me.errorDetails=details;
      var myModalEl=me.$el.querySelector(".errorModal");
      var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
      modal.show();
    },
    signIn() {
      var me=this;
      
      vcCommunicator.createsession(me.username,me.password)
        .then(function (response) {
          console.log("Successful login");
          me.sessionId=vcCommunicator.sessionId;

        })
        .catch((error) => {
          console.log("Failed login");

          me.sessionId=null;
          me.showError("An error occurred during sign in", error || "An unknown problem occurred")
        });
      me.password=null;
    },
    signOut() {
      var me=this;
        vcCommunicator.signOut().then(function (response) {
    
        me.sessionId=vcCommunicator.sessionId;
      }).catch((error) => {
        console.log("signOut Failed:"+error);
        me.sessionId=null;
      });
    }
  },
  computed:{
    hasSession() {
      return this.sessionId;
    }
  },
  mounted() {
    var me=this;
    
    var vcCommunicator=window.vcCommunicator;
    if (!vcCommunicator) {
      console.log("No vcCommunicator");
      return;
    }
    me.sessionId=vcCommunicator.sessionId;
    vcCommunicator.addHandler('createsession', function (sessionId,sessionState) {
       me.sessionId=sessionId;
    });
     vcCommunicator.addHandler('terminatesession', function () {
       me.sessionId=null;
    });

    me.showCookieConfirmation();
  },
  components: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
